import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { invoiceCount: Number, total: Number }
  static targets = ['input', 'more', 'total', 'complete']

  updateTotals() {
    if (!this.hasCompleteTarget) return

    if (this.inputTarget.disabled) {
      this.completeTarget.classList.add('hidden')
      return
    }

    let value = Number(this.inputTarget.value)

    if (value < this.invoiceCountValue) {
      value = this.invoiceCountValue
    }

    this.totalValue = value

    if (!this.inputTarget.value) {
      this.completeTarget.classList.add('hidden')
      return
    }

    if (this.moreValue > 0) {
      this.completeTarget.classList.add('hidden')
    } else {
      this.completeTarget.classList.remove('hidden')
    }
  }

  totalValueChanged() {
    if (!this.hasTotalTarget) return
    if (!this.hasMoreTarget) return

    this.totalTarget.textContent = this.totalValue
    this.moreTarget.textContent = this.moreValue
  }

  get moreValue() {
    const value = this.totalValue - this.invoiceCountValue

    if (value < 0) return 0

    return value
  }
}
