import { Turbo } from '@hotwired/turbo-rails'
import { Controller } from '@hotwired/stimulus'

export default class StudentsFilterController extends Controller {
  static targets = ['programFilter', 'rankFilter']
  static values = { frame: String }

  // TODO: Rename this whole file or build it into a filter component
  filter() {
    const url = new URL(window.location)

    if (this.checkedProgramTargets.length) {
      url.searchParams.set(...this.programParams)
    }

    if (this.checkedRankTargets.length) {
      url.searchParams.set(...this.rankParams)
    }

    if (this.hasFrameValue) {
      const frame = document.querySelector(`#${this.frameValue}`)
      frame.src = url
    } else {
      Turbo.cache.clear()
      Turbo.visit(url)
    }
  }

  clear() {
    const url = `${window.location.pathname}`

    Turbo.cache.clear()
    Turbo.visit(url)
  }

  ///
  /// private
  ///

  queryParams(targets) {
    const ids = targets.map((target) => target.value)
    const name = targets[0].id
    return [name, ids]
  }

  get checkedRankTargets() {
    return this.rankFilterTargets.filter((target) => target.checked)
  }

  get checkedProgramTargets() {
    return this.programFilterTargets.filter((target) => target.checked)
  }

  get programParams() {
    return this.queryParams(this.checkedProgramTargets)
  }

  get rankParams() {
    return this.queryParams(this.checkedRankTargets)
  }
}
