function isEven(num) {
  return num % 2 === 0
}

export function roundHalfEven(num) {
  const strNm = num.toString()
  const decimalIndex = strNm.indexOf('.')

  if (decimalIndex === -1) {
    return num
  }

  const [integer, decimal] = strNm.split('.').map((i) => parseInt(i))

  if (decimal !== 5) {
    return Math.round(num)
  }

  if (isEven(integer)) {
    return integer
  }

  return integer + 1
}
