import ApplicationController from '../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static outlets = ['filterable-list--action']
  static targets = ['entityCheckbox', 'banner', 'anchor', 'list', 'pagination']
  static values = { selectedEntities: { type: Array, default: [] }, allEntities: Array, perPage: Number }

  connect() {
    document.addEventListener(
      'DOMContentLoaded',
      () => {
        window.FontAwesome.dom.watch()
      },
      { once: true },
    )

    this.renderCallback = this.render.bind(this)
    this.beginActionCallback = this.deselectAllEntities.bind(this)
    document.addEventListener('turbo:before-frame-render', this.renderCallback)
    document.addEventListener('quick-action-progress:begin-action', this.beginActionCallback)
  }

  disconnect() {
    window.FontAwesome.dom.watch()

    document.removeEventListener('turbo:before-frame-render', this.renderCallback)
    document.removeEventListener('quick-action-progress:begin-action', this.beginActionCallback)
  }

  render(event) {
    if (!document.startViewTransition) return

    event.preventDefault()

    document.startViewTransition(() => {
      event.detail.resume()
    })
  }

  entityCheckboxTargetConnected(element) {
    const value = parseInt(element.value)

    if (!this.selectedEntitiesValue.includes(value)) return

    element.checked = true
  }

  enableSelection({ detail: { applyFilters, bannerText } }) {
    this.enableAllCheckboxes()

    if (bannerText) {
      this.bannerTarget.innerText = bannerText
      this.bannerTarget.classList.remove('hidden')
      setTimeout(() => {
        this.bannerTarget.classList.add('opacity-100')
        this.bannerTarget.classList.remove('opacity-0')
      }, 10)
    } else {
      this.bannerTarget.classList.remove('opacity-100')
      this.bannerTarget.classList.add('opacity-0')

      this.waitForAnimations(this.bannerTarget, () => {
        this.bannerTarget.classList.add('hidden')
      })
    }

    if (applyFilters) {
      this.applyFilters()
    }
  }

  disableSelection({ detail: { applyFilters } }) {
    this.disableAllCheckboxes()
    this.selectedEntitiesValue = []

    if (applyFilters) {
      this.applyFilters()
    }

    this.bannerTarget.classList.remove('opacity-100')
    this.bannerTarget.classList.add('opacity-0')

    this.waitForAnimations(this.bannerTarget, () => {
      this.bannerTarget.classList.add('hidden')
    })
  }

  toggleEntitySelected({ params: { id } }) {
    if (this.selectedEntitiesValue.includes(id)) {
      this.selectedEntitiesValue = this.selectedEntitiesValue.filter((entityId) => entityId !== id)
    } else {
      this.selectedEntitiesValue = [...this.selectedEntitiesValue, id]
    }
  }

  selectAllEntitiesOnPage() {
    this.checkAllCheckboxes()

    this.selectedEntitiesValue = this.entityCheckboxTargets.map((checkbox) => parseInt(checkbox.value))
  }

  selectAllEntitiesOnAllPages() {
    this.checkAllCheckboxes()

    this.selectedEntitiesValue = this.allEntitiesValue
  }

  deselectAllEntities() {
    this.uncheckAllCheckboxes()

    this.selectedEntitiesValue = []
  }

  executeQuickAction({ params: { path } }) {
    // Take the path, add the current query string to it
    const fullPath = `${path}?${window.location.search}&ids=${this.selectedEntitiesValue.join(',')}`

    this.anchorTarget.href = fullPath

    this.anchorTarget.click()
  }

  selectedEntitiesValueChanged() {
    this.dispatch('selected-entities-changed', {
      prefix: 'list',
      detail: {
        amount: this.selectedEntitiesValue.length,
        total: this.allEntitiesValue.length,
        perPage: this.perPageValue,
      },
    })
  }

  cancelQuickAction() {
    this.filterableListActionOutlets.filter((action) => action.checked).forEach((action) => action.cancel())
  }

  ///
  /// private
  ///

  checkAllCheckboxes() {
    this.entityCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = true
    })
  }

  uncheckAllCheckboxes() {
    this.entityCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
  }

  enableAllCheckboxes() {
    this.entityCheckboxTargets.forEach((checkbox) => {
      checkbox.disabled = false
    })
  }

  disableAllCheckboxes() {
    this.entityCheckboxTargets.forEach((checkbox) => {
      checkbox.disabled = true
      checkbox.checked = false
    })
  }
}
