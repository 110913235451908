import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'selectAll',
    'checkbox',
    'dropdown',
    'programSelect',
    'program',
    'rank',
    'programsDropdown',
  ]

  static values = {
    selectText: String,
    deselectText: String,
  }

  updateSelectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.selectAllTarget.checked
    })
    this.handleCheckboxChange()
  }

  handleCheckboxChange() {
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.dropdownTarget.classList.remove('disabled')
    } else {
      this.dropdownTarget.classList.add('disabled')
    }
  }

  toggleDropdownSelect() {
    const programsChecked = this.programTargets.some((target) => target.checked)
    if (programsChecked) {
      this.programsDropdownTarget.classList.add('border-kicksite-yellow-600')
    } else {
      this.programsDropdownTarget.classList.remove('border-kicksite-yellow-600')
    }
  }

  toggleProgramSelect({ target }) {
    const parent = document.getElementById(target.dataset.program)
    parent.checked = true
    this.toggleDropdownSelect()
  }

  toggleSelectAll({ target }) {
    if (target.hasAttribute('data-select-all')) {
      if (target.dataset.attendancesTarget === 'programSelect') {
        this.selectProgramTargets()
      } else {
        this.selectRankTargets(target)
      }
    } else if (target.dataset.attendancesTarget === 'programSelect') {
      this.deselectProgramTargets()
    } else {
      this.deselectRankTargets(target)
    }
  }

  selectProgramTargets() {
    this.programTargets.forEach((programTarget) => {
      programTarget.checked = true
    })
    this.programSelectTarget.innerText = this.deselectTextValue
    this.programSelectTarget.removeAttribute('data-select-all')
    this.programsDropdownTarget.classList.add('border-kicksite-yellow-600')
  }

  selectRankTargets(target) {
    const selectedRanks = target.closest('ul').querySelectorAll('input')
    selectedRanks.forEach((rankItem) => {
      rankItem.checked = true
    })
    target.innerText = this.deselectTextValue
    target.removeAttribute('data-select-all')
    const parent = document.getElementById(target.dataset.programid)
    parent.checked = true
    this.toggleDropdownSelect()
  }

  deselectProgramTargets() {
    this.programTargets.forEach((programTarget) => {
      programTarget.checked = false
    })
    this.programSelectTarget.innerText = this.selectTextValue
    this.programSelectTarget.setAttribute('data-select-all', '')
    this.programsDropdownTarget.classList.remove('border-kicksite-yellow-600')
  }

  deselectRankTargets(target) {
    const deselectedRanks = target.closest('ul').querySelectorAll('input')
    deselectedRanks.forEach((rankTarget) => {
      rankTarget.checked = false
    })
    target.innerText = this.selectTextValue
    target.setAttribute('data-select-all', '')
    const parent = document.getElementById(target.dataset.programid)
    parent.checked = false
    this.toggleDropdownSelect()
  }
}
