import {
  addCountToCounter,
  activateApplyButtonWhenMembershipChecked,
  initializeTrialMembershipBehavior,
  initializeProspectOffWarning,
} from '../../assets/javascripts/school/bizbuilder/landing_pages/trial_memberships'

window.addCountToCounter = addCountToCounter
window.activateApplyButtonWhenMembershipChecked = activateApplyButtonWhenMembershipChecked
window.initializeTrialMembershipBehavior = initializeTrialMembershipBehavior
window.initializeProspectOffWarning = initializeProspectOffWarning
