// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.
/* global App */
/* eslint no-unused-expressions: 0 */

import { createConsumer } from '@rails/actioncable'
;(function () {
  'use strict'
  this.App || (this.App = {})
  window.App = this.App

  App.cable = createConsumer()
}.call(this))
