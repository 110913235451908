import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'requiredField']

  // Need to trigger this upon connecting or else it causes weird behavior
  connect() {
    this.change()
  }

  change() {
    if (this.inputTarget.checked) {
      this.requiredFieldTarget.required = true
    } else {
      this.requiredFieldTarget.required = false
    }
  }
}
