import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.element.classList.contains('select2-with-image')) {
      let placeholderText = ''
      if (this.element.dataset.placeholder) {
        placeholderText = this.element.dataset.placeholder
      }

      $(this.element).select2({
        theme: 'bootstrap',
        templateSelection: this.templateImageOptions,
        templateResult: this.templateImageOptions,
        placeholder: placeholderText,
        allowClear: true,
        width: null,
      })
    } else if (this.element.classList.contains('select2-search')) {
      let placeholderText = ''
      if (this.element.dataset.placeholder) {
        placeholderText = this.element.dataset.placeholder
      }

      $(this.element).select2({
        theme: 'bootstrap',
        placeholder: placeholderText,
        allowClear: true,
        width: null,
      })
    } else if (this.element.classList.contains('select2-checkmark')) {
      $(this.element).select2({
        theme: 'bootstrap',
        width: null,
        minimumResultsForSearch: Infinity,
        templateResult(state) {
          return $(
            `<span class='select2--checkmark flex items-center'>
              <i class='fas fa-check soft-hidden'></i>
              <span class='ml-2'>${state.text}</span>
            </span>`,
          )
        },
      })
    } else if (this.element.classList.contains('select2-people')) {
      let searchType = this.element.dataset.searchType
        ? this.element.dataset.searchType
        : '[type:Student,Prospect]'

      $(this.element).select2({
        theme: 'bootstrap',
        templateSelection: this.templateImageOptions,
        templateResult: this.templateImageOptions,
        escapeMarkup(markup) {
          return markup
        },
        placeholder: $('.person-select-placeholder').html(),
        ajax: {
          url: '/select_search?format=json',
          dataType: 'json',
          data(params) {
            var query = { query: `${searchType} ${params.term}` }

            return query
          },
        },
      })
    } else {
      $(this.element).select2({
        theme: 'bootstrap',
        width: null,
        minimumResultsForSearch: Infinity,
      })
    }
  }

  templateImageOptions(state) {
    if (!state.id) return state.text

    let $state = $(`<span class="flex items-center">${state.text}</span>`)
    if ($(state.element).data('image') !== undefined) {
      const image_data = $(state.element).data('image')
      $state = $(`<span class="flex items-center">
                    <img src="${image_data}" class="employee-image" />
                    ${state.text}
                  </span>`)
    }
    if (state.image) {
      $state = $(`<span class="flex items-center">
                    <img src="${state.image}" class="employee-image" />
                    ${state.text}
                  </span>`)
    }
    return $state
  }

  formatText(icon) {
    return $(`<span><i class="fas ${$(icon.element).data('icon')}"></i> ${icon.text}</span>`)
  }
}
