// eslint-disable-next-line no-unused-vars
export function initializeClipboardCopyButton(parent) {
  'use strict'

  $(parent)
    .find('.copy-btn')
    .unbind()
    .on('click', function () {
      var btn = $(this)
      btn.tooltip('show')
      setTimeout(function () {
        btn.tooltip('hide')
      }, 1000)

      $(parent).find('.copiable').removeClass('hidden')
      $(parent).find('.copiable').select()
      document.execCommand('copy')
      $(parent).find('.copiable').addClass('hidden')
    })
}
