import { Controller } from '@hotwired/stimulus'

export default class PrettyCheckboxController extends Controller {
  static targets = ['click', 'input', 'icon', 'selectAll', 'deselectAll']

  connect() {
    for (const t of this.clickTargets) {
      t.addEventListener('click', function (e) {
        let target = e.target.classList.contains('.menu-item') ? e.target : e.target.closest('.menu-item')

        let input = target.querySelector('input[type="checkbox"]')
        let icon = target.querySelector('.fa-check')

        input.checked = !input.checked
        icon.classList.toggle('checkbox-disabled')
      })
    }
  }

  selectAll() {
    this.selectAllTarget.classList.add('hidden')
    this.deselectAllTarget.classList.remove('hidden')
    for (const i of this.inputTargets) {
      i.checked = true
    }
    for (const i of this.iconTargets) {
      i.classList.remove('checkbox-disabled')
    }
  }

  deselectAll() {
    this.selectAllTarget.classList.remove('hidden')
    this.deselectAllTarget.classList.add('hidden')
    for (const i of this.inputTargets) {
      i.checked = false
    }
    for (const i of this.iconTargets) {
      i.classList.add('checkbox-disabled')
    }
  }
}
