import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static outlets = ['filter-section-filter']
  static targets = ['all', 'checkbox', 'childAll', 'childCheckbox']

  connect() {
    this.toggleAllCheckbox(false)
  }

  selectAll(dispatch = true, destroyChildren = true) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })

    if (this.hasAllTarget) {
      this.allTarget.checked = true
    }

    if (dispatch) this.dispatch('toggle')

    // This fixes a stupid timing issue on connect causing a bunch of warnings.
    // The warnings realistically don't matter, but they're annoying.
    if (!destroyChildren) return

    this.filterSectionFilterOutlets.forEach((filterSectionFilter) => {
      filterSectionFilter.destroyChildren(false)
    })
  }

  selectAllChildren({ params: { childName } }, dispatch = true) {
    const childCheckboxes = this.childCheckboxTargets.filter((checkbox) => checkbox.name === childName)

    childCheckboxes.forEach((checkbox) => {
      checkbox.checked = false
    })

    const childAll = this.childAllTargets.find((allTarget) => allTarget.parentElement.id === `${childName}_all`)

    if (childAll) {
      childAll.checked = true
    }

    if (dispatch) this.dispatch('toggle')

    this.toggleAllCheckbox()
  }

  toggleOne({ detail }) {
    this.toggleAllCheckbox()

    const dispatch = detail?.dispatch ?? true
    if (dispatch) this.dispatch('toggle')
  }

  toggleOneChild({ currentTarget }) {
    const childName = currentTarget.parentElement.dataset.filterSectionFilterChildNameValue
    this.toggleChildAllCheckbox(childName)

    this.dispatch('toggle')
  }

  ///
  /// private
  ///

  toggleAllCheckbox(destroyChildren = true) {
    if (!this.hasAllTarget) return

    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked)
    const allUnchecked = this.checkboxTargets.every((checkbox) => !checkbox.checked)
    const allChildrenChecked = this.childCheckboxTargets.every((checkbox) => checkbox.checked)
    const allChildrenUnchecked = this.childCheckboxTargets.every((checkbox) => !checkbox.checked)

    if (allChildrenChecked || allChildrenUnchecked) {
      this.allTarget.checked = allChecked || allUnchecked

      if (allChecked || allUnchecked) this.selectAll(false, destroyChildren)
    }
  }

  toggleChildAllCheckbox(childName) {
    if (!this.hasChildAllTarget) return

    const allChecked = this.childCheckboxTargets
      .filter((checkbox) => checkbox.name === childName)
      .every((checkbox) => checkbox.checked)
    const allUnchecked = this.childCheckboxTargets
      .filter((checkbox) => checkbox.name === childName)
      .every((checkbox) => !checkbox.checked)

    const childAll = this.childAllTargets.find((allTarget) => allTarget.parentElement.id === `${childName}_all`)
    childAll.checked = allChecked || allUnchecked

    if (allChecked || allUnchecked) {
      this.selectAllChildren({ params: { childName } }, false)
    } else {
      this.toggleAllCheckbox()
    }
  }
}
