import { Controller } from '@hotwired/stimulus'
import { createModal, manageClassEventDragAndDrop } from '../helpers/confirmation_submit_helper'
import { removeExtraBackdrops } from '../helpers/modal_helper'
import { flatten } from 'q-flat'

// TODO: This sucks, in reality any modals with confirmations should just send along
// the actual confirmation modal and the confirmation should just be a part of
// modal_controller.js
export default class extends Controller {
  static targets = ['form']
  static values = {
    editMessage: String,
    deleteMessage: String,
    editTitle: String,
    editSubmitText: String,
    deleteTitle: String,
    deleteSubmitText: String,
    deletePath: String,
    denyText: String,
    showBackdrop: Boolean,
  }

  connect() {
    document.addEventListener('confirmation-modal:open', this.showConfirmationModal, { once: true })
  }

  showConfirmationModal(event) {
    event.preventDefault()
    const payload = event.detail.data
    const queryString = new URLSearchParams(flatten(payload))
    const calendar = event.detail.calendar

    this.modal = createModal(
      event.detail.info.dataset.title,
      event.detail.info.dataset.confirmText,
      event.detail.info.dataset.denyText,
      event.detail.info.dataset.message,
      {
        path: `${event.detail.path}${queryString}`,
        method: 'post',
        payload: `${queryString}`,
        showBackdrop: true,
      },
    )

    manageClassEventDragAndDrop(this.modal, calendar)
  }

  showEditConfirmation(event) {
    event.preventDefault()
    this.element.classList.add('hidden')

    removeExtraBackdrops()

    this.modal = createModal(
      this.editTitleValue,
      this.editSubmitTextValue,
      this.denyTextValue,
      this.editMessageValue,
      {
        form_id: this.formTarget.id,
      },
    )

    $(this.modal).modal('show')
  }

  showDeleteConfirmation(event) {
    event.preventDefault()

    if (!document.querySelector('#classes-wrapper')) {
      this.element.classList.add('hidden')
    }

    removeExtraBackdrops()

    this.modal = createModal(
      this.deleteTitleValue,
      this.deleteSubmitTextValue,
      this.denyTextValue,
      this.deleteMessageValue,
      {
        path: this.deletePathValue,
        method: 'delete',
        showBackdrop: this.showBackdropValue || false,
        classes: 'title-center',
      },
    )

    $(this.modal).modal('show')
  }

  hide() {
    this.modal.classList.add('hidden')
    document.querySelector('.modal-backdrop').classList.add('hidden')
  }
}
