$(document).on('turbo:load', function () {
  // Tooltips
  initializeTooltips()

  // Dropdowns as Tabs
  initializeDropdownTabs()
})

export function initializeTooltips(parent) {
  var selector = '[data-toggle="tooltip"]'
  if (parent === undefined) {
    var $elements = $(selector)
  } else {
    var $elements = $(parent).find(selector)
  }

  $elements.tooltip({ html: true, delay: { show: 250, hide: 100 } })
}

export function initializeDropdownTabs(parent) {
  var selector = '.dropdown-tabs'
  if (parent === undefined) {
    var $elements = $(selector)
  } else {
    var $elements = $(parent).find(selector)
  }

  $elements.each(function () {
    var $this = $(this)
    $this.fadeIn()
    if ($this.find('.dropdown-tabs-menu li.active').length) {
      var selText = $this.find('.dropdown-tabs-menu li.active').text()
      $this.find('.dropdown-toggle').html(selText + ' <i class="fas fa-chevron-down"></i>')
    }

    $(this)
      .find('.dropdown-tabs-menu li a')
      .click(function () {
        if ($this.find('.dropdown-tabs-menu li.active').length) {
          var selText = $(this).text()
          $(this)
            .parents('.dropdown-tabs')
            .find('.dropdown-toggle')
            .html(selText + ' <i class="fas fa-chevron-down"></i>')
          $(this).parents('.dropdown-tabs').find('.dropdown-tabs-menu li').removeClass('active')
        }

        $(this).trigger('voltage:dropdown-tabs:selected')
      })
  })
}

export function initializeCounters(parent) {
  var selector = '.counter-value'
  if (parent === undefined) {
    var $elements = $(selector)
  } else {
    var $elements = $(parent).find(selector)
  }

  $elements.each(function () {
    var $this = $(this),
      countTo = $this.attr('data-count')

    $({ countNum: $this.text() }).animate(
      {
        countNum: countTo,
      },
      {
        duration: 1000,
        easing: 'linear',
        step: function () {
          $this.text(Math.floor(this.countNum))
        },
        complete: function () {
          $this.text(this.countNum)
        },
      },
    )
  })
}
