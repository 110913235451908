import ApplicationController from '../../javascript/controllers/application_controller'
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/url.min.js'

FroalaEditor.ICON_DEFAULT_TEMPLATE = 'font_awesome'
FroalaEditor.DefineIcon('token', { NAME: 'coins' })
FroalaEditor.DefineIcon('emoticons', { NAME: 'face-smile' })
FroalaEditor.DefineIcon('signature', { NAME: 'signature' })
FroalaEditor.RegisterCommand('insertToken', {
  title: 'Insert Special Token',
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  showOnMobile: true,
  options: {
    last_name: 'Last Name',
    first_name: 'First Name',
    street: 'Street',
    street_2: 'Street 2',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    country: 'Country',
    date_of_birth: 'Date of Birth',
    memberships: 'Memberships',
  },
  type: 'dropdown',
  callback(_, value) {
    this.html.insert(`((${value}))`)
  },
  icon: 'token',
})
FroalaEditor.RegisterCommand('insertSignature', {
  title: 'Insert Signature',
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  showOnMobile: true,
  callback() {
    this.html.insert('((signature_area))<br/>')
  },
  icon: 'signature',
})
FroalaEditor.RegisterQuickInsertButton('signature', {
  icon: 'signature',
  title: 'Insert Signature',
  callback() {
    this.html.insert('((signature_area))<br/>')
  },
  undo: true,
})

const staticOptions = {
  key: 'bMA6aC5B5D2G2A2B2J2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A3C4E3D1D4D2E2==',
  attribution: false,
  imageUploadURL: '/files.js',
  imageUploadMethod: 'POST',
  imageUploadParam: 'file',
}

const textButtons = [
  'token',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'subscript',
  'superscript',
  'fontSize',
  'textColor',
  'backgroundColor',
  'clearFormatting',
]

const paragraphButtons = [
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
  'formatOL',
  'formatUL',
  'paragraphFormat',
  'outdent',
  'indent',
]

const miscButtons = ['undo', 'redo', 'selectAll', 'help']

export default class extends ApplicationController {
  static values = { includeTokens: Boolean }

  initialize() {
    this.editor = new FroalaEditor(this.element, {
      ...staticOptions,
      theme: document.body.classList.contains('dark') ? 'dark' : 'default',
      imageUploadParams: { authenticity_token: document.querySelector('meta[name="csrf-token"]').content },
      quickInsertButtons: this.quickInsertButtons,
      toolbarButtons: {
        moreText: {
          buttons: textButtons,
        },
        moreParagraph: {
          buttons: paragraphButtons,
        },
        moreRich: {
          buttons: this.richButtons,
          buttonsVisible: 1,
        },
        moreMisc: {
          buttons: miscButtons,
          align: 'right',
          buttonsVisible: 0,
        },
      },
      events: {
        initialized: () => {
          // There is some weirdness with Froala thinking it needs this dummy element on load when it.. doesnt.
          document.querySelector('.fr-sticky-dummy')?.remove()

          this.element.parentNode.classList.add('opacity-100')
          this.element.parentNode.classList.remove('opacity-0')

          this.dispatch('ready', { bubbles: true })
        },
      },
    })
  }

  get wysiwygElement() {
    return this.element.previousElementSibling
  }

  ///
  /// private
  ///

  get quickInsertButtons() {
    const buttons = ['image', 'video', 'table', 'ul', 'ol', 'signature', 'hr']

    if (this.includeTokensValue) return buttons

    return buttons.filter((button) => button !== 'signature')
  }

  get richButtons() {
    const buttons = ['insertSignature', 'insertToken', 'insertImage', 'insertVideo', 'insertTable', 'insertHR']

    if (this.includeTokensValue) return buttons

    return buttons.filter((button) => button !== 'insertSignature' && button !== 'insertToken')
  }
}
