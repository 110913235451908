import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card']
  static values = { url: String, placement: String }

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove('hidden')
    } else {
      let loadingHovercard = this.buildHovercardWith(this.loadingContent())
      this.element.appendChild(loadingHovercard)

      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          let newHovercard = this.buildHovercardWith(html)
          let existingHovercard = this.element.querySelector('.hovercard')

          existingHovercard.replaceWith(newHovercard)
        })
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add('hidden')
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove()
    }
  }

  loadingContent() {
    return `
    <div class='hovercard'>
      <div class='placeholder-bar placeholder-bar-highlight'></div>
      <div class='placeholder-row'>
        <div class='placeholder-bar placeholder-bar-med'></div>
        <div class='placeholder-bar placeholder-bar-lg'></div>
      </div>
      <div class='placeholder-bar'></div>
      <div class='placeholder-bar'></div>
      <div class='placeholder-row'>
        <div class='placeholder-bar placeholder-bar-med'></div>
        <div class='placeholder-bar placeholder-bar-lg placeholder-bar-highlight'></div>
      </div>
    </div>
      `
  }

  buildHovercardWith(html) {
    let fragment = document.createRange().createContextualFragment(html)

    if (this.placementValue) {
      fragment.querySelector('.hovercard').classList.add(this.placementValue)
    }

    return fragment
  }
}
