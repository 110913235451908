import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'selectAll', 'checkbox', 'dropdown']

  dropdownTargetConnected() {
    this.handleCheckboxChange()
  }

  submit(event) {
    this.formTarget.action = event.params.url
    this.formTarget.requestSubmit()
  }

  updateSelectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.selectAllTarget.checked
    })
    this.handleCheckboxChange()
  }

  handleCheckboxChange() {
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.dropdownTarget.classList.remove('disabled')
    } else {
      this.dropdownTarget.classList.add('disabled')
    }
  }
}
