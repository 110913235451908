import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class ClickOutsideController extends Controller {
  static values = { ignore: String }

  connect() {
    useClickOutside(this, {
      dispatchEvent: false,
    })
  }

  clickOutside(event) {
    if (this.hasIgnoreValue) {
      const ignoreElement = document.querySelector(this.ignoreValue)
      if (event.target === ignoreElement || ignoreElement.contains(event.target)) {
        return
      }
    }

    this.element.dispatchEvent(new Event('click:outside'))
  }
}
