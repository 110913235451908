import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { prevEndDate: String, prevKind: String }
  static targets = [
    'disabler',
    'input',
    'submit',
    'dateField',
    'attendanceField',
    'dateRadio',
    'attendanceRadio',
    'unlimitedRadio',
    'programLabel',
    'prevExpiration',
    'prevUnlimited',
  ]

  checkboxBehavior(event) {
    if (event.target.id === 'date') {
      this.enableDateField()
      this.dateRadioTarget.checked = true
    } else if (event.target.id === 'attendance') {
      this.enableAttendanceField()
      this.programLabelTarget.classList.add('required')
      this.attendanceRadioTarget.checked = true
    } else {
      if (this.hasDateFieldTarget) {
        this.disableDateField()
      } else {
        this.disableAttendanceField()
        this.programLabelTarget.classList.remove('required')
      }
      this.unlimitedRadioTarget.checked = true
    }
  }

  checkInactivate() {
    this.inputTargets.forEach((inputTarget) => {
      inputTarget.checked = !this.disablerTarget.checked
      inputTarget.disabled = !this.disablerTarget.checked
    })
  }

  enableDateField() {
    this.dateFieldTarget.nextSibling.classList.add('!border-kicksite-yellow-600')
    this.dateFieldTarget.disabled = false
    this.dateFieldTarget.nextSibling.disabled = false
  }

  disableDateField() {
    this.dateFieldTarget.nextSibling.classList.remove('!border-kicksite-yellow-600')
    this.dateFieldTarget.nextSibling.disabled = true
  }

  enableAttendanceField() {
    this.attendanceFieldTarget.classList.add('!border-kicksite-yellow-600')
    this.attendanceFieldTarget.disabled = false
  }

  disableAttendanceField() {
    this.attendanceFieldTarget.classList.remove('!border-kicksite-yellow-600')
    this.attendanceFieldTarget.disabled = true
  }

  checkPreviousValue() {
    if (this.unlimitedRadioTarget.checked) {
      if (this.prevKindValue === 'unlimited') {
        this.prevUnlimitedTarget.classList.add('hidden')
        this.prevExpirationTarget.classList.add('hidden')
      } else {
        this.prevUnlimitedTarget.classList.remove('hidden')
        this.prevExpirationTarget.classList.add('hidden')
      }
    } else if (this.dateRadioTarget.checked) {
      if (this.prevKindValue === 'unlimited' || this.prevEndDateValue !== this.dateFieldTarget.value) {
        this.prevExpirationTarget.classList.remove('hidden')
        this.prevUnlimitedTarget.classList.add('hidden')
      } else if (this.prevEndDateValue === this.dateFieldTarget.value) {
        this.prevExpirationTarget.classList.add('hidden')
        this.prevUnlimitedTarget.classList.add('hidden')
      }
    }
  }
}
