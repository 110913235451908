import { Controller } from '@hotwired/stimulus'
import { getCookie, setCookie } from '../helpers/cookies'

export default class extends Controller {
  static targets = ['install', 'iosPrompt', 'androidPrompt', 'modal']

  connect() {
    window.addEventListener('beforeinstallprompt', this.initializePrompt.bind(this))

    if (!this.isAllowedToShowPrompt) {
      this.destroy()
      return
    }

    if (this.isAndroid) {
      this.destroyIosPrompt()
    }

    if (this.isIos) {
      this.destroyAndroidPrompt()
    }

    if (!this.shouldShowIosPrompt) return

    this.showIosPrompt()
  }

  disconnect() {
    window.removeEventListener('beforeinstallprompt', this.initializePrompt.bind(this))
  }

  dismissIosPrompt() {
    setCookie(this.cookieName, 'true', this.softExpirationDate)

    this.iosPromptTarget.classList.remove('open')
    this.destroy()
  }

  hideIosPrompt() {
    setCookie(this.cookieName, 'true', this.hardExpirationDate)

    this.iosPromptTarget.classList.remove('open')
    this.destroy()
  }

  dismissAndroidPrompt() {
    setCookie(this.cookieName, 'true', this.softExpirationDate)

    this.androidPromptTarget.classList.remove('open')
    this.destroy()
  }

  hideAndroidPrompt() {
    setCookie(this.cookieName, 'true', this.hardExpirationDate)

    this.androidPromptTarget.classList.remove('open')
    this.destroy()
  }

  showIosPrompt() {
    if (!this.hasIosPromptTarget) return

    this.iosPromptTarget.classList.add('open')
    if (this.isNotSafari) {
      this.iosPromptTarget.querySelector('#not-safari').classList.remove('hidden')
    } else {
      this.iosPromptTarget.querySelector('#safari').classList.remove('hidden')
    }
  }

  showAndroidPrompt() {
    if (!this.hasAndroidPromptTarget) return

    this.androidPromptTarget.classList.add('open')
  }

  destroyIosPrompt() {
    if (!this.hasIosPromptTarget) return

    this.iosPromptTarget.remove()
  }

  destroyAndroidPrompt() {
    if (!this.hasAndroidPromptTarget) return

    this.androidPromptTarget.remove()
  }

  async install() {
    if (this.deferredPrompt !== undefined) {
      this.deferredPrompt.prompt()

      const { outcome } = await this.deferredPrompt.userChoice

      if (outcome === 'accepted') {
        for (const target of this.installTargets) {
          target.classList.add('hidden')

          if (this.isAndroid) {
            $(this.modalTarget).modal('show')
            this.hideAndroidPrompt()
          }
        }
      }

      this.destroy()
    }
  }

  initializePrompt(event) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault()

    // Stash the event so it can be triggered later.
    this.deferredPrompt = event

    // Update UI notify the user they can add to home screen
    for (const target of this.installTargets) {
      target.classList.remove('hidden')
    }

    if (!this.isAllowedToShowPrompt) return

    if (this.isAndroid) {
      this.showAndroidPrompt()
    }
  }

  destroy() {
    if (this.hasAndroidPromptTarget) {
      this.waitForAnimations(this.androidPromptTarget).then(() => {
        this.androidPromptTarget.remove()
      })
    }

    if (this.hasIosPromptTarget) {
      this.waitForAnimations(this.iosPromptTarget).then(() => {
        this.iosPromptTarget.remove()
      })
    }
  }

  async waitForAnimations(element) {
    await Promise.all(element.getAnimations().map((animation) => animation.finished))
  }

  get isIos() {
    return /iphone|ipad|ipod/.test(this.userAgent.toLowerCase())
  }

  get isNotSafari() {
    return this.isIos && /CriOS|FxiOS/.test(this.userAgent)
  }

  get isAndroid() {
    return /android/.test(this.userAgent.toLowerCase())
  }

  get shouldShowIosPrompt() {
    return this.hasIosPromptTarget && this.isIos && !this.isStandalone
  }

  get softExpirationDate() {
    const expirationDays = 3
    const today = new Date()
    const expirationDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + expirationDays)
    return expirationDate
  }

  get hardExpirationDate() {
    const expirationYears = 10
    const today = new Date()
    const expirationDate = new Date(today.getFullYear() + expirationYears, today.getMonth(), today.getDate())

    return expirationDate
  }

  get isAllowedToShowPrompt() {
    const cookie = getCookie(this.cookieName)
    return cookie !== 'true'
  }

  get cookieName() {
    return 'kicksite_SuppressPWAInstallPrompt'
  }

  get userAgent() {
    return window.navigator.userAgent
  }

  get isStandalone() {
    return 'standalone' in window.navigator && window.navigator.standalone
  }
}
