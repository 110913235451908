import { Controller } from '@hotwired/stimulus'

const THIRTY_MEGABYTES = 1024 * 1024 * 30
export default class extends Controller {
  static targets = ['input', 'uploadedFiles', 'banner', 'fileArea']

  connect() {
    this.element
      .closest('form')
      .addEventListener('direct-uploads:start', this.#disableForm.bind(this), { once: true })
  }

  add({ dataTransfer }) {
    this.#addFiles(dataTransfer.files)

    this.#render()
  }

  remove({ params: { index } }) {
    this.#removeFile(index)

    this.#render()
  }

  fileSelected() {
    this.#render()
  }

  renderProgressBar({ detail: { id, file } }) {
    this.#renderWithProgressBar(id, file)
  }

  updateProgressBar({ detail: { id, progress } }) {
    this.#updateProgressBar(id, progress)
  }

  markUploaded({ detail: { id } }) {
    this.#markUploaded(id)
  }

  openFormField() {
    this.inputTarget.click()
  }

  #validateFileSize(file) {
    return file.size <= THIRTY_MEGABYTES
  }

  #validateFileType(file) {
    const type = file.type
    const accept = this.inputTarget.accept

    if (accept === '') return

    const acceptedTypes = accept.split(',').map((t) => t.trim())

    const fileTypeIsValid = acceptedTypes.some((acceptedType) => {
      const [typePrefix] = acceptedType.split('/')
      if (acceptedType.includes('*') && type.startsWith(typePrefix)) {
        return true
      } else if (type === acceptedType) {
        return true
      }

      return false
    })

    return fileTypeIsValid
  }

  #addFiles(otherFiles) {
    const dataTransfer = new DataTransfer()

    Array.from(this.inputTarget.files).forEach((file) => {
      dataTransfer.items.add(file)
    })

    Array.from(otherFiles).forEach((file) => {
      dataTransfer.items.add(file)
    })

    this.inputTarget.files = dataTransfer.files
  }

  #removeFile(index) {
    const files = Array.from(this.inputTarget.files)
    const dataTransfer = new DataTransfer()

    files.forEach((file, i) => {
      if (i !== index) {
        dataTransfer.items.add(file)
      }
    })

    this.inputTarget.files = dataTransfer.files
  }

  #render() {
    this.uploadedFilesTarget.innerHTML = ''

    if (this.inputTarget.files.length === 0) {
      this.#showBanner()
      this.#disableForm()

      return
    }

    this.#hideBanner()

    const fileData = this.#buildFileData()

    const allValid = fileData.every((file) => file.error === null)

    if (allValid) {
      this.#enableForm()
    } else {
      this.#disableForm()
    }

    fileData.forEach((file, index) => {
      this.#renderFile(file, index)
    })
  }

  #buildFileData() {
    return Array.from(this.inputTarget.files).map((file, index) => {
      let error = null

      if (!this.#validateFileSize(file)) {
        error = 'Too large.'
      }

      if (!this.#validateFileType(file)) {
        error = 'Invalid file type.'
      }

      return {
        name: file.name,
        size: this.#humanizeFileSize(file.size),
        index,
        error,
      }
    })
  }

  #renderFile(file, index) {
    const wrapper = document.createElement('div')
    wrapper.classList.add('flex', 'justify-between', 'gap-2', 'items-center', 'w-full')
    wrapper.dataset.index = index

    const html = `
      <div class="flex gap-4">
        <span data-file-name class="truncate max-w-48">${file.name}</span>
        <span data-file-text class="text-basic-700">${file.size}</span>
      </div>

      <button type="button" data-action="click->file-field#remove" data-file-field-index-param="${index}" 
              class="text-danger-600 w-5 h-5">
        <i class="fas fa-xmark w-5 h-5"></i>
      </button>
    `

    wrapper.innerHTML = html

    if (file.error) {
      wrapper.querySelector('[data-file-text]').textContent = file.error
      wrapper.querySelector('[data-file-text]').classList.add('text-danger-600')
      wrapper.querySelector('[data-file-text]').classList.remove('text-basic-700')
      wrapper.querySelector('[data-file-name]').classList.add('line-through')
      wrapper.querySelector('[data-file-name]').classList.add('text-basic-700')
    }

    this.uploadedFilesTarget.insertAdjacentHTML('beforeend', wrapper.outerHTML)
  }

  #renderWithProgressBar(id, file) {
    const element = this.uploadedFilesTarget.querySelector('[data-index]')

    element.removeAttribute('data-index')
    element.id = `file-${id}`

    element.innerHTML = `
      <div class="flex flex-col gap-2 max-w-xs">
        <span class="truncate">${file.name}</span>
        <div class="flex gap-4 items-center">
          <span class="text-basic-700">Uploading...</span>
          <progress hidden class="progress progress-success h-2" value="0" max="100"></progress>
        </div>
      </div>
    `
  }

  #updateProgressBar(fileIndex, progress) {
    const target = this.uploadedFilesTarget.querySelector(`#file-${fileIndex}`)

    target.querySelector('.progress').value = progress
  }

  #markUploaded(fileIndex) {
    const target = this.uploadedFilesTarget.querySelector(`#file-${fileIndex}`)

    target.querySelector('.text-basic-700').textContent = 'Uploaded.'
  }

  #showBanner() {
    this.bannerTarget.classList.remove('hidden')
    this.fileAreaTarget.classList.add('hidden')
  }

  #hideBanner() {
    this.bannerTarget.classList.add('hidden')
    this.fileAreaTarget.classList.remove('hidden')
  }

  #humanizeFileSize(size) {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    const unit = ['B', 'KB', 'MB', 'GB', 'TB'][i]
    const humanSize = Number(size / 1024 ** i)
      .toFixed(1)
      .replace(/\.0$/, '')

    return `${humanSize} ${unit}`
  }

  #enableForm() {
    const id = this.element.closest('form').id
    document.querySelector(`[form="${id}"]`).disabled = false
  }

  #disableForm() {
    const id = this.element.closest('form').id
    document.querySelector(`[form="${id}"]`).disabled = true
  }
}
