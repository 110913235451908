import { Controller } from '@hotwired/stimulus'
import { Pallette } from '../../../vendor/assets/javascripts/voltage-themes/pallette'
export default class extends Controller {
  static values = { publicToken: String }

  saveCard(event) {
    event.target.disabled = true

    this.setPaymentToken(true).then((success) => {
      if (success) {
        let parentForm = document.querySelector(`#new-card`)
        parentForm.requestSubmit()
      } else {
        event.target.disabled = false
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  setPaymentToken(tokenize, tokenTarget) {
    if (!tokenize) return Promise.resolve(true)

    return this.client
      .getPaymentKey(this.paymentCard)
      .then(function (result) {
        $('input#credit-card-token').val(result)
        return true
      })
      .catch(function () {
        return false
      })
  }

  connect() {
    var USA_EPAY_PUBLIC_TOKEN = this.publicTokenValue
      ? this.publicTokenValue
      : $('#usa-epay-public-token').data('public-token')

    if (USA_EPAY_PUBLIC_TOKEN === undefined) throw new Error('No public_token found.')

    // NOTE: This is dumb
    let secondaryColor = Pallette.black

    if ($('body.dark').length) {
      secondaryColor = Pallette.white
    }

    const invalidColor = Pallette.red

    // eslint-disable-next-line no-undef
    this.client = new usaepay.Client(USA_EPAY_PUBLIC_TOKEN)
    this.paymentCard = this.client.createPaymentCardEntry()
    var style = {
      base: {
        backgroundColor: 'transparent',
        color: secondaryColor,
        lineHeight: '36px',
        fontFamily: '"Hind Vadodara", sans-serif',
        fontSize: '19px',
        textTransform: 'uppercase',
        height: '36px',
        border: `solid 2px ${Pallette.secondaryDark} !important`,
        borderRadius: '5px !important',
        padding: '20px 10px !important',
        marginRight: '5px',
      },
      // eslint-disable-next-line max-len
      '{}\n.payjs-input-icon { display: none; }  @media (max-width: 767px) { #payjs-cnum { width: 100%; padding-left: 0; } #payjs-cvv { min-width: 3em; } #payjs-exp { min-width: 3.5em; } }':
        '',
      invalid: { color: invalidColor },
    }
    this.paymentCard.generateHTML(style)

    if ($('#usa-epay-card-form').html() === '') {
      this.paymentCard.addHTML('usa-epay-card-form')
    }

    this.paymentCard.addEventListener('error', function (errorMessage) {
      var errorContainer = document.getElementById('usa-epay-card-errors')
      errorContainer.textContent = errorMessage
    })

    document.querySelector('#usa-epay-card-form').addEventListener('clear:usaepay', () => {
      this.innerHTML = ''
      this.paymentCard.addHTML('usa-epay-card-form')
    })
  }
}
