import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'programIdField',
    'membershipTemplateIdField',
    'membershipTemplatePriceField',
    'programName',
    'membershipName',
    'hiddenVault',
    'saveVaultOption',
    'saveVaultNoOption',
  ]

  revealPrograms(event) {
    event.preventDefault()

    const id = event.currentTarget.id.replace(/[^0-9]/g, '')
    this.dontSignUpButton(id).classList.toggle('hidden')

    this.toggleMembershipTemplates(event.currentTarget, id)
  }

  hidePrograms(event) {
    event.preventDefault()

    const id = event.currentTarget.id.replace(/[^0-9]/g, '')
    this.signUpButton(id).classList.toggle('hidden')

    this.toggleMembershipTemplates(event.currentTarget, id)
  }

  selectMembership(event) {
    event.preventDefault()
    const element = event.currentTarget
    const id = element.id.replace(/[^0-9]/g, '')
    this.membershipTemplateIdFieldTarget.value = id
    this.membershipTemplatePriceFieldTarget.value = element.dataset.price

    this.programIdFieldTarget.value = event.params.id

    if (this.hasProgramNameTarget) {
      this.programNameTarget.textContent = element.dataset.name
      this.membershipNameTarget.textContent = element.dataset.membership
    }

    if (this.hasHiddenVaultTarget) {
      if (element.dataset.recurringBilling === 'true') {
        this.hiddenVaultTarget.value = true
        this.saveVaultOptionTarget.classList.add('hidden')
        this.saveVaultNoOptionTarget.classList.remove('hidden')
      } else {
        this.hiddenVaultTarget.value = false
        this.saveVaultOptionTarget.classList.remove('hidden')
        this.saveVaultNoOptionTarget.classList.add('hidden')
      }
    }

    this.element.dispatchEvent(new Event('landing-page:update-order-total'))
  }

  ///
  /// private
  ///
  toggleMembershipTemplates(button, id) {
    document.querySelector(`#membership-options-for-${id}`).classList.toggle('hidden')

    button.classList.toggle('hidden')
  }

  signUpButton(id) {
    return document.querySelector(`#sign-up-${id}`)
  }

  dontSignUpButton(id) {
    return document.querySelector(`#dont-sign-up-${id}`)
  }
}
