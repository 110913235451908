import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modulesNav']
  static outlets = ['form-sortable']

  formSortableOutletConnected(outlet) {
    outlet.syncNav = (event) => {
      if (event.oldIndex === event.newIndex) return

      const travelingIndex = event.oldIndex - 1
      const putBeforeIndex = event.oldIndex < event.newIndex ? event.newIndex : event.newIndex - 1

      this.modulesNavTarget.querySelectorAll('div').forEach((breakpoint) => breakpoint.remove())
      this.modulesNavTarget.insertBefore(
        this.modulesNavTarget.children[travelingIndex],
        this.modulesNavTarget.children[putBeforeIndex],
      )

      Array.from(this.modulesNavTarget.children).forEach((tab, index) => {
        tab.insertAdjacentHTML('beforebegin', `<div class="h-0 breakpoint-${index}"></div>`)
      })
    }
  }

  toggleHidden({ target }) {
    if (target.tagName === 'path') target = target.parentElement

    target.classList.toggle('pr-px')
    target.classList.toggle('fa-eye')
    target.classList.toggle('fa-eye-slash')
    const listItem = target.closest('li')
    listItem.classList.toggle('opacity-50')

    const input = listItem.querySelector('input')
    const sectionName = input.id.split('-')[1]
    const isHidden = input.value === ''
    input.value = isHidden ? sectionName : ''

    if (input.id.startsWith('modules_settings')) {
      this.modulesNavTarget.querySelector(`#${sectionName}-nav`).classList.toggle('opacity-50')
    }
  }
}
