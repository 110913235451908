import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['sendEmail', 'agreementDropdown', 'emailInput']
  static outlets = ['tooltip']
  static values = { needsAgreement: String, needsEmail: String }

  toggleEmailButton() {
    if (!this.hasAgreementSelected) {
      this.tooltipOutlet.swapText({ params: { text: this.needsAgreementValue } })
    } else if (!this.emailInputValid) {
      this.tooltipOutlet.swapText({ params: { text: this.needsEmailValue } })
    }

    if (this.hasAgreementSelected && this.emailInputValid) {
      this.enableEmailButton()
    } else {
      this.disableEmailButton()
    }
  }

  ///
  /// private
  ///

  enableEmailButton() {
    this.sendEmailTarget.disabled = false
    this.sendEmailTarget.checked = true

    this.tooltipOutlet.off()
  }

  disableEmailButton() {
    this.sendEmailTarget.disabled = true
    this.sendEmailTarget.checked = false

    this.tooltipOutlet.on()
  }

  get hasAgreementSelected() {
    return !isNaN(parseInt(this.agreementDropdownTarget.value))
  }

  get emailInputValid() {
    return this.emailInputTarget.value.length > 0
  }
}
