import { Controller } from '@hotwired/stimulus'
import { get } from '../helpers/fetch_helper'

export default class extends Controller {
  static targets = ['dark', 'default']

  toggleTheme(event) {
    const [key, value] = this.getKeyValue(event.currentTarget)

    get(this.path(key, value))

    if (this.isDark) {
      document.body.classList.remove('dark')

      // Text Editor
      let textEditorComponents = document.querySelectorAll('.text-editor-component')
      textEditorComponents.forEach((element) => {
        element.classList.remove('trumbowyg-dark')
      })

      for (const target of this.darkTargets) {
        target.classList.add('hidden')
      }
      for (const target of this.defaultTargets) {
        target.classList.remove('hidden')
      }

      this.element.dataset.value = 'dark'
    } else {
      document.body.classList.add('dark')

      for (const target of this.darkTargets) {
        target.classList.remove('hidden')
      }
      for (const target of this.defaultTargets) {
        target.classList.add('hidden')
      }

      // Text Editor
      let textEditorComponents = document.querySelectorAll('.text-editor-component')
      textEditorComponents.forEach((element) => {
        element.classList.add('trumbowyg-dark')
      })

      this.element.dataset.value = 'default'
    }
  }

  toggleProfileCollapse(event) {
    const [key, value] = this.getKeyValue(event.currentTarget)

    get(this.path(key, value))
  }

  ///
  /// private
  ///
  getKeyValue(element) {
    return [element.dataset.key, element.dataset.value]
  }

  path(key, value) {
    return `/preferences/${key}/${value}`
  }

  get isDark() {
    return document.body.classList.contains('dark')
  }
}
