import IMask from 'imask'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const maskOptions = {
      overwrite: true,
      autofix: true,
      mask: 'H:MM',
      blocks: {
        H: {
          mask: Number,
          placeholderChar: '0',
          min: 1,
          max: 12,
          scale: 0,
          normalizeZeros: true,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          maxLength: 2,
        },
      },
    }

    IMask(this.element, maskOptions)
  }
}
