import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static values = { url: String }

  refreshFrame(event) {
    const params = new URLSearchParams(new FormData(event.target.form))
    const src = `${this.urlValue}?${params.toString()}`

    Turbo.visit(src, { frame: 'ranks_programs' })
  }
}
