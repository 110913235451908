import ApplicationController from '../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static targets = ['editable', 'display', 'hiddenInput', 'wrapper', 'counter', 'error']
  static values = { max: Number }

  connect() {
    this.editableText = this.hiddenText
    this.displayHTML = this.constructDisplayHTML(this.hiddenText)
    this.counterText = this.hiddenText.length

    if (this.isSingleLine) {
      this.updateScrollCallback = this.updateScroll.bind(this)
      this.addScrollSyncEvents()
    }
  }

  disconnect() {
    if (this.isSingleLine) {
      this.removeScrollSyncEvents()
    }
  }

  update() {
    const text = this.editableText

    this.hiddenText = text
    this.counterText = this.hiddenText.length
    this.displayHTML = this.constructDisplayHTML(text)

    if (this.isSingleLine) {
      this.displayTarget.scrollLeft = this.displayTarget.scrollWidth
    }

    this.validate(text)
  }

  clear() {
    this.editableText = ''
    this.update()
  }

  ///
  /// private
  ///
  validate(text) {
    if (this.hasErrorTarget) return

    if (this.maxValue < text.length) {
      this.makeInvalid()
    } else {
      this.makeValid()
    }
  }

  makeInvalid() {
    this.editableTarget.classList.add('!input-danger')
    this.wrapperTarget.classList.add('!text-danger-600')

    this.vibrate(50)
  }

  makeValid() {
    this.editableTarget.classList.remove('!input-danger')
    this.wrapperTarget.classList.remove('!text-danger-600')
  }

  constructDisplayHTML(text) {
    const unhighlighted = this.clean(text.substr(0, this.maxValue))
    const highlighted = `<span class='bg-danger-600/50'>${this.clean(text.substr(this.maxValue))}</span>`

    return `${unhighlighted}${highlighted}`
  }

  clean(text) {
    return text.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br>')
  }

  addScrollSyncEvents() {
    const events = ['blur', 'change', 'focus', 'input', 'keydown', 'keyup', 'scroll']

    for (const event of events) {
      this.editableTarget.addEventListener(event, this.updateScrollCallback, true)
    }
  }

  removeScrollSyncEvents() {
    const events = ['blur', 'change', 'focus', 'input', 'keydown', 'keyup', 'scroll']

    for (const event of events) {
      this.editableTarget.removeEventListener(event, this.updateScrollCallback, true)
    }
  }

  updateScroll() {
    requestAnimationFrame(() => {
      this.displayTarget.scrollLeft = this.editableTarget.scrollLeft
    })
  }

  get isSingleLine() {
    return this.editableTarget.tagName === 'INPUT'
  }

  get editableText() {
    if (this.isSingleLine) return this.editableTarget.value

    return this.editableTarget.innerText
  }

  set editableText(text) {
    if (this.isSingleLine) {
      this.editableTarget.value = text
    } else {
      this.editableTarget.innerText = text
    }
  }

  get hiddenText() {
    return this.hiddenInputTarget.value
  }

  set hiddenText(text) {
    this.hiddenInputTarget.value = text
  }

  set counterText(text) {
    this.counterTarget.innerText = text
  }

  set displayHTML(text) {
    this.displayTarget.innerHTML = text
  }
}
