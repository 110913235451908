import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['selector', 'active', 'inactive', 'frozen']
  static values = ['active', 'inactive', 'frozen']

  connect() {
    this.showRelevantMessage()
  }

  showRelevantMessage() {
    const selected = this.selectorTarget.options[this.selectorTarget.selectedIndex].value

    this.activeTarget.classList.add('hidden')
    this.inactiveTarget.classList.add('hidden')
    this.frozenTarget.classList.add('hidden')

    if (selected === 'active') {
      this.activeTarget.classList.remove('hidden')
    } else if (selected === 'inactive') {
      this.inactiveTarget.classList.remove('hidden')
    } else if (selected === 'frozen') {
      this.frozenTarget.classList.remove('hidden')
    }
  }
}
