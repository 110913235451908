import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

// TODO: Remove this when the Person Profiles are ready
export default class extends Controller {
  static targets = ['submit']
  static values = { path: String }

  connect() {
    useClickOutside(this)
  }

  // Sort of a hacky workaround to get the same
  // click outside submit behavior as the other inline fields
  clickOutside() {
    this.submitTarget.click()
  }
}
