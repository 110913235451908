/* eslint-env jquery */

$(document).on('turbo:load', function () {
  'use strict'

  $('nav.navbar-utility .navbar-nav .dropdown-menu .toggle-theme').on('click', function (event) {
    event.stopPropagation()
    $('#sidebar-container #nav-sidebar .nav-sidebar-icon').toggleClass('active')
    $('.modal-body .item-icon img').toggleClass('active')
  })
})
