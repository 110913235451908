import ApplicationController from '../../javascript/controllers/application_controller'
import { useMutation } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['display', 'hiddenInput']
  static values = { code: String, max: Number, exponent: Number }

  VALID_KEYS = ['Backspace', 'Enter', 'Tab', '0', 'ArrowLeft', 'ArrowRight']

  connect() {
    this.displayValue = this.hiddenValue || '0'
    this.update(false)

    useMutation(this, { element: this.hiddenInputTarget, attributes: true, attributeFilter: ['value'] })
  }

  denyInvalidKeys(event) {
    if (this.validKey(event.key)) return

    event.preventDefault()
  }

  mutate() {
    this.displayValue = this.constructDisplayValue(this.hiddenValue)
  }

  focus(event) {
    setTimeout(() => {
      const input = event.target
      input.selectionStart = input.value.length
      input.selectionEnd = input.value.length
      input.focus()
    }, 0)
  }

  update(dispatch = true) {
    this.hiddenValue = parseInt(this.displayValue.match(/\d+/g)?.join('')) || 0
    this.capHiddenValue()
    this.displayValue = this.constructDisplayValue(this.hiddenValue)

    if (dispatch) this.dispatch('change')
  }

  maxValueChanged() {
    this.capHiddenValue()
    this.displayValue = this.constructDisplayValue(this.hiddenValue)
  }

  ///
  /// private
  ///
  validKey(key) {
    return this.VALID_KEYS.includes(key) || !isNaN(key)
  }

  capHiddenValue() {
    if (this.hiddenValue > this.maxValue) {
      this.hiddenValue = this.maxValue
      this.vibrate(100)
    }
  }

  constructDisplayValue(text) {
    if (text === '') return ''

    const options = {
      minimumFractionDigits: this.exponentValue,
      maximumFractionDigits: this.exponentValue,
    }
    const result = new Intl.NumberFormat(this.codeValue, options).format(parseFloat(text) * this.subunitMultiplier)

    return result
  }

  get subunitMultiplier() {
    return 10 ** -this.exponentValue
  }

  get hiddenValue() {
    return this.hiddenInputTarget.value
  }

  set hiddenValue(text) {
    this.hiddenInputTarget.value = text
  }

  get displayValue() {
    return this.displayTarget.value
  }

  set displayValue(text) {
    this.displayTarget.value = text
    this.displayTarget.size = text.length || 1
  }
}
