/**
 *  Function to determine if a given element is visible without observers
 *
 * @param {HTMLElement} element - The element to check the visibility of
 * @returns {boolean} true if element is visible, false otherwise
 *
 * @example
 * const visible = elementIsVisible(myElement)
 */
export function elementIsVisible(element) {
  return element.offsetWidth > 0 && element.offsetHeight > 0
}
