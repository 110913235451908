export function setCookie(name, value, expires) {
  if (expires) {
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}`
  } else {
    document.cookie = `${name}=${value}; path=/`
  }
}

export function getCookie(name) {
  const cookies = Object.fromEntries(
    document.cookie.split(/; /).map((c) => {
      const [key, v] = c.split('=', 2)
      return [key, decodeURIComponent(v)]
    }),
  )
  return cookies[name] || ''
}
