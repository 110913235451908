import AutoSortController from '../../javascript/controllers/auto_sort_controller'
import { useMutation } from 'stimulus-use'

export default class extends AutoSortController {
  static values = { scroll: Boolean }

  initialize() {
    useMutation(this, { childList: true })
  }

  mutate(entries) {
    this.sortChildren()

    if (this.scrollValue) {
      const mutation = entries[0]
      const addedNodes = Array.from(mutation.addedNodes)

      if (addedNodes.length > 0) {
        addedNodes[0].scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
