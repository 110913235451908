import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['emailSection', 'smsSection', 'emailInput', 'smsInput']

  handleOptionChange({ target: { value } }) {
    if (value === 'email') {
      this.showEmailOption()
    } else {
      this.showSMSOption()
    }
  }

  ///
  /// private
  ///

  showEmailOption() {
    this.emailSectionTarget.classList.remove('hidden')
    this.emailInputTarget.disabled = false
    this.smsSectionTarget.classList.add('hidden')
    this.smsInputTarget.disabled = true
    this.emailInputTarget.focus()
  }

  showSMSOption() {
    this.emailSectionTarget.classList.add('hidden')
    this.emailInputTarget.disabled = true
    this.smsSectionTarget.classList.remove('hidden')
    this.smsInputTarget.disabled = false
    this.smsInputTarget.focus()
  }
}
