/* eslint-env jquery */

$(document).on('turbo:load', function () {
  'use strict'

  $('.visible').on('click', function () {
    $(this).find('span').toggleClass('hidden')

    const $hiddenFormField = $(this).find("input[type='Checkbox']")[0]
    $hiddenFormField.checked = !$hiddenFormField.checked
  })
})
