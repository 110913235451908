import { Controller } from '@hotwired/stimulus'

export default class RecurringClassesController extends Controller {
  static targets = ['classNameInput']

  showClassOptions(event) {
    if (event.target.type === 'checkbox') return

    const classNameOptions = event.target.closest('.class-name-options-fields')
    for (const classNameOption of classNameOptions.querySelectorAll('.class-name-option')) {
      classNameOption.classList.add('hidden')
    }

    event.target.parentNode.querySelector('.class-name-option').classList.remove('hidden')
  }
}
