import {
  registerProfileModulesDropdownClickEvents,
  initializeViewAllProfileItemsActionable,
  initializeViewMoreProfileItemsActionable,
  initializeInfiniteViewAll,
  initializeAgreementDeletes,
  handleAttendanceModuleContextSwitching,
} from '../../assets/javascripts/school/people/profile'

window.registerProfileModulesDropdownClickEvents = registerProfileModulesDropdownClickEvents
window.initializeViewAllProfileItemsActionable = initializeViewAllProfileItemsActionable
window.initializeViewMoreProfileItemsActionable = initializeViewMoreProfileItemsActionable
window.initializeInfiniteViewAll = initializeInfiniteViewAll
window.initializeAgreementDeletes = initializeAgreementDeletes
window.handleAttendanceModuleContextSwitching = handleAttendanceModuleContextSwitching
