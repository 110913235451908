import ApplicationController from './application_controller'
import Fuse from 'fuse.js'
import { useClickOutside, useHotkeys } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['aside', 'search', 'link', 'divider']
  static outlets = ['sidebar--component']

  connect() {
    useHotkeys(this, {
      hotkeys: {
        '/': {
          handler: (event) => {
            event.preventDefault()
            this.open()
          },
          options: {
            keydown: true,
            keyup: false,
          },
        },
        escape: {
          handler: (event) => {
            event.preventDefault()
            this.close()
          },
          options: {
            keydown: true,
            keyup: false,
          },
        },
      },
      filter: (event) => {
        if (!event.key) return

        const { tagName } = event.target
        const disallowedField =
          event.target.isContentEditable ||
          ((tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') && !event.target.readOnly)

        return event.key === 'Escape' || !disallowedField
      },
    })

    useClickOutside(this, { element: this.asideTarget, events: ['mousedown'] })
  }

  open() {
    this.asideTarget.classList.remove('hidden')

    this.focusSearchField()
  }

  close() {
    this.asideTarget.classList.add('hidden')

    this.sidebarComponentOutlet.close()
    this.resetSearch()
  }

  resetSearch() {
    this.searchTarget.value = ''
    this.showAllLinks()
    this.focusSearchField()
  }

  filter(event) {
    if (event.key === 'Tab') return

    if (!this.searchTarget.value) {
      this.showAllLinks()

      return
    }

    const fuse = new Fuse(this.fuseList, {
      includeScore: true,
      keys: [
        {
          name: 'title',
          weight: 0.7,
        },
        {
          name: 'tags',
          weight: 0.3,
        },
      ],
    })
    const results = fuse.search(this.searchTarget.value).filter((result) => result.score <= 0.5)

    this.hideAllLinks()
    results.forEach(({ item: { element } }) => {
      element.classList.remove('hidden')
      if (element.hasAttribute('data-section')) return

      const section = element.closest('[data-section]')
      section.classList.remove('hidden')

      if (!section.hasAttribute('data-controller')) return

      section.classList.remove('collapsed')
    })

    this.dividerTargets.forEach((divider) => {
      const shownElementAboveDivider = shownElementAbove(divider)
      const shownElementBelowDivider = shownElementBelow(divider)

      if (shownElementAboveDivider && shownElementBelowDivider) {
        divider.classList.remove('hidden')
      }
    })
  }

  clickOutside() {
    if (this.asideTarget.classList.contains('hidden')) return

    this.close()
  }

  ///
  /// private
  ///

  get fuseList() {
    return this.linkTargets.map((link) => ({
      title: link.innerText,
      element: link,
      tags: link.dataset.tags,
    }))
  }

  focusSearchField() {
    this.searchTarget.focus()
  }

  showAllLinks() {
    this.linkTargets.forEach((link) => {
      link.classList.remove('hidden')
      if (!link.hasAttribute('data-section')) {
        const section = link.closest('[data-section]')

        section.classList.remove('hidden')

        if (!section.hasAttribute('data-controller')) return

        section.classList.add('collapsed')
      }
    })

    this.dividerTargets.forEach((divider) => {
      divider.classList.remove('hidden')
    })
  }

  hideAllLinks() {
    this.linkTargets.forEach((link) => {
      link.classList.add('hidden')
      if (!link.hasAttribute('data-section')) {
        link.closest('[data-section]').classList.add('hidden')
      }
    })

    this.dividerTargets.forEach((divider) => {
      divider.classList.add('hidden')
    })
  }
}

function shownElementAbove(element) {
  let previousElement = element.previousElementSibling
  while (previousElement && previousElement.tagName !== 'DIV') {
    if (!previousElement.classList.contains('hidden')) {
      return true
    }

    previousElement = previousElement.previousElementSibling
  }

  return false
}

function shownElementBelow(element) {
  let nextElement = element.nextElementSibling
  while (nextElement && nextElement.tagName !== 'DIV') {
    if (!nextElement.classList.contains('hidden')) {
      return true
    }

    nextElement = nextElement.nextElementSibling
  }

  return false
}
