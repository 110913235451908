import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    fetch(this.element.dataset.src)
      .then((response) => response.blob())
      .then((imageBlob) => {
        const url = URL.createObjectURL(imageBlob)
        this.loadImage(url)
      })
  }

  // TODO: these should be targets instead of querySelectors
  loadImage(src) {
    this.element.querySelector('.loading').remove()

    this.element.querySelector('img').src = src
  }
}
