/* eslint-disable no-unused-vars */
$(document).on('turbo:load', function () {
  'use strict'

  let $createProspectToggle = $('#prospect-creation-toggle')

  $createProspectToggle.bootstrapToggle('destroy')
  $createProspectToggle.bootstrapToggle()
  $createProspectToggle.bootstrapToggle('enable')

  if ($createProspectToggle.hasClass('disabled')) {
    $createProspectToggle.parent().addClass('disabled')
  }

  $createProspectToggle.change(function () {
    if (this.checked) {
      $('.create-prospect-toggle-container .helper-text').removeClass('hidden')
    } else {
      $('.create-prospect-toggle-container .helper-text').addClass('hidden')
    }
  })
})

export function enableCreateProspectToggle() {
  let $createProspectToggle = $('#prospect-creation-toggle')
  $createProspectToggle.addClass('disabled')
  $createProspectToggle.parent().addClass('disabled')

  $createProspectToggle.bootstrapToggle('on')
}
/* eslint-disable no-unused-vars */
