import { Controller } from '@hotwired/stimulus'
import { grayOutTheNone } from '../../helpers/gray_out_helper'
import { previewBeltGraphic } from '../../helpers/belt_graphic_preview_helper'

function formChanged(element) {
  const $form = $(element).parents('form.ranks-modal')
  const html = previewBeltGraphic($form)

  if (html) {
    $form.find('.graphic-preview .preview-empty-message').addClass('hidden')
    $form.find('.graphic-preview .preview').removeClass('hidden')
  } else {
    $form.find('.graphic-preview .preview-empty-message').removeClass('hidden')
    $form.find('.graphic-preview .preview').addClass('hidden')
  }

  $form.find('.graphic-preview .preview').html(html)
}

export default class extends Controller {
  static targets = ['colorOptions', 'emblemOptions', 'dropdown', 'checkbox']

  connect() {
    if (this.hasEmblemOptionsTarget) {
      this.emblemOptionsTargets.forEach((emblem) => {
        $(emblem).on('select2:select', this.revealSubOptions)
      })
    }
    if (this.hasColorOptionsTarget) {
      $(this.colorOptionsTarget).on('select2:select', this.activateSecondOption)
    }
    if (this.hasDropdownTarget) {
      this.dropdownTargets.forEach((dropdown) => {
        grayOutTheNone(dropdown)
        $(dropdown).on(
          'select2:select',
          (function (that, element) {
            return function () {
              that.formChangedSelect(element)
            }
          })(this, dropdown),
        )
      })
    }
    if (this.hasCheckboxTarget) {
      this.checkboxTargets.forEach((checkbox) => {
        $(checkbox).on(
          'click',
          (function (that, element) {
            return function () {
              that.formChangedCheckbox(element)
            }
          })(this, checkbox),
        )
      })
    }
  }

  revealSubOptions(e) {
    if (e.delegateTarget.value === '') {
      $(this).parent().find('.emblem-option-selects').removeClass('revealed')
    } else {
      $(this).parent().find('.emblem-option-selects').addClass('revealed')
      $(this).parent().find('.emblem-option-selects').addClass('fade-in')
    }
  }

  activateSecondOption(e) {
    if (e.delegateTarget.value === '') {
      $(e.delegateTarget).parent().parent().find('.color-2-select').addClass('disabled')
    } else {
      $(e.delegateTarget).parent().parent().find('.color-2-select').removeClass('disabled')
    }
  }

  formChangedSelect(element) {
    grayOutTheNone(element)
    formChanged(element)
  }

  formChangedCheckbox(element) {
    formChanged(element)
  }
}
