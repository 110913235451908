/* eslint-env jquery */
/* eslint no-unused-vars: 0 */

/* global SignaturePad */
/* global intlTelInput */

var SIGNATURE_PADS = []

export function formatSelect2Option(state) {
  'use strict'

  if (!state.id) return state.text

  let $state = $(`<span class="flex items-center">${state.text}</span>`)
  if ($(state.element).data('image') !== undefined) {
    const image_data = $(state.element).data('image')
    $state = $(`<span class="flex items-center">
                  <img src="${image_data}" class="employee-image" />
                  ${state.text}
                </span>`)
  }
  if (state.image) {
    $state = $(`<span class="flex items-center">
                  <img src="${state.image}" class="employee-image" />
                  ${state.text}
                </span>`)
  }
  return $state
}

export function initializeSelect2Fields(parent) {
  'use strict'

  var fields = []
  var tagFields = []
  if (parent === undefined || parent === null) {
    fields = $('select.select2:not([multiple="multiple"]):not([data-controller="select2"])')
    tagFields = $('select.select2[multiple="multiple"]:not([data-controller="select2"])')
  } else {
    fields = $(parent).find('select.select2:not([multiple="multiple"]):not([data-controller="select2"])')
    tagFields = $(parent).find('select.select2[multiple="multiple"]:not([data-controller="select2"])')
  }

  fields.select2({
    theme: 'bootstrap',
    templateSelection: formatSelect2Option,
    templateResult: formatSelect2Option,
    width: null,
  })
  tagFields.select2({
    theme: 'bootstrap',
    tags: true,
    allowClear: false,
    placeholder: '',
    insertTag(data, tag) {
      data.push({
        id: tag.id,
        text: `${tag.text} (New)`,
      })
    },
    width: null,
  })

  $('select.select2.no-search').select2({
    theme: 'bootstrap',
    minimumResultsForSearch: Infinity,
  })
}

export function findCheckedCheckBoxes() {
  'use strict'

  var checkedCheckBoxes = $("input.select-all-item[type='checkbox']:checked")
  return checkedCheckBoxes
}

export function initializeSelectAllCheckboxes(checkboxes, initializeAllCheckbox) {
  'use strict'

  if (initializeAllCheckbox) {
    $('#select-all').on('click', function () {
      var desiredState = $(this).prop('checked')
      var checkBoxes = $("input.select-all-item[type='checkbox']")
      checkBoxes.prop('checked', desiredState)
    })

    $('#select-all').on('click', function () {
      var checkedCheckBoxes = $("input.select-all-item[type='checkbox']:checked")

      if (checkedCheckBoxes.length > 0) $('#select-all-action').prop('disabled', false)
      else $('#select-all-action').prop('disabled', true)
    })
  }

  $(checkboxes).on('click', function () {
    var totalCheckBoxes = $("input.select-all-item[type='checkbox']").length
    var totalCheckedCheckBoxes = $("input.select-all-item[type='checkbox']:checked").length

    if (totalCheckedCheckBoxes === totalCheckBoxes) $('#select-all').prop('checked', true)
    else $('#select-all').prop('checked', false)
  })

  $(checkboxes).on('click', function () {
    var checkedCheckBoxes = $("input.select-all-item[type='checkbox']:checked")

    if (checkedCheckBoxes.length > 0) $('#select-all-action').prop('disabled', false)
    else $('#select-all-action').prop('disabled', true)
  })
}

export function initializeColorPickerFields() {
  'use strict'

  jQuery(function ($) {
    $('.color-picker').minicolors({ theme: 'bootstrap' })
  })
}

export function initializeButtonToggleFormFields(parent) {
  'use strict'

  var fields = []
  if (parent === undefined) fields = $('.btn-group.btn-group-toggle label.btn')
  else fields = $(parent).find('.btn-group.btn-group-toggle label.btn')

  fields.click(function () {
    $(this).parent().find('input').removeAttr('checked')
    $(this).find('input').attr('checked', '')
  })
}

export function autoexpandDefinedInputs() {
  'use strict'

  $('input.auto-expand').each(function () {
    $(this).width($(this).val().length * 7)
  })

  $('input.auto-expand').keyup(function () {
    $(this).width($(this).val().length * 7)
  })
}

export function clickedOutsideInPlaceEditableField(e, $target) {
  'use strict'

  return (
    e.target !== $target[0] &&
    $(e.target).closest('.form-field.editable')[0] !== $target[0] &&
    !e.target.classList.contains('select2-selection__choice__remove')
  )
}

// Discard the changes to a field by clicking the "discard" button
export function initializeDiscardButton($field) {
  var path = $field.data('path')

  $field.find('.discard').on('click', function () {
    var token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      type: 'GET',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(updatedData) {
        if (updatedData.status === 200) {
          $field.html(updatedData.responseText)
          $field.removeClass('active')
          $field.removeClass('processing')
        } else {
          $('#global-alerts').append(updatedData.responseText)
          $.ajax({
            type: 'GET',
            url: path,
            complete(errorData) {
              $field.html(errorData.responseText)
              $field.removeClass('active')
              $field.removeClass('processing')
            },
          })
        }

        $('body').off('click')
      },
    })
  })
}

$(document).on('turbo:load', function () {
  'use strict'

  // NOTE: This is no longer used by landing_pages agreement signature, but
  // might be in use by the public profile agreement signature code?
  $('canvas.signature-pad').each(function () {
    const signaturePad = new SignaturePad(this, { penColor: '#5d5c57' })
    signaturePad.addEventListener(
      'beginStroke',
      () => {
        this.closest('.signature-wrapper').querySelector('label').classList.add('hidden')
      },
      { once: true },
    )

    SIGNATURE_PADS.push(signaturePad)
  })

  // Add new profile modal
  const phoneContainerInput = document.querySelector('.phone-toggle-container input[type=tel].fancy')
  if (phoneContainerInput) {
    intlTelInput(phoneContainerInput, {
      utilsScript: '/assets/intl-tel-input/utils.js',
      formatOnDisplay: true,
      separateDialCode: true,
      hiddenInput: 'full_number',
      initialCountry: document.body.dataset.countryCode || 'us',
    })
  }

  // Other phone fields
  const phoneInputs = document.querySelectorAll('input[type=tel].fancy:not(.new-profile)')
  for (const phoneInput of phoneInputs) {
    if (window.intlTelInputGlobals.getInstance(phoneInput) === undefined) {
      intlTelInput(phoneInput, {
        utilsScript: '/assets/intl-tel-input/utils.js',
        hiddenInput: phoneInput.dataset.inputname || '',
        formatOnDisplay: true,
        separateDialCode: true,
        initialCountry: document.body.dataset.countryCode || 'us',
      })
    }
  }

  $(function () {
    var requiredCheckboxes = $('.options :checkbox[required]')
    requiredCheckboxes.change(function () {
      if (requiredCheckboxes.is(':checked')) requiredCheckboxes.removeAttr('required')
      else requiredCheckboxes.attr('required', 'required')
    })
  })

  // Handle fancy styling when user is focused on a field
  $('input, select, textarea').on('focus', function () {
    $(this).prev('label').addClass('active')
  })
  $('input, select, textarea').on('focusout', function () {
    if (!$(this).val()) $(this).prev('label').removeClass('active')
  })

  autoexpandDefinedInputs()

  $("input[type='number']").keypress(function (e) {
    if (e.currentTarget.classList.contains('decimals')) return true

    var regex
    var str
    regex = /^[0-9]+$/
    str = String.fromCharCode(e.charCode ? e.charCode : e.which)
    if (e.charCode === 0 || regex.test(str)) return true

    e.preventDefault()
    return false
  })

  initializeSelect2Fields(null)
  initializeColorPickerFields()

  initializeButtonToggleFormFields()

  $('.form-field.editable, .form-field.editable svg.fa-pencil-alt').click(function (e) {
    initializeFormFieldEdits($(this), e)
  })
})

export function initializeFormFieldEdits($this, e) {
  if ($this.hasClass('fa-pencil-alt')) $this = $($this.closest('.form-field.editable'))

  if ($this.hasClass('active')) return

  if ($(e.target).is('a') || ($(e.target).is('i') && !$(e.target).hasClass('fa-pencil-alt'))) return

  var path = $this.data('path')
  var $target = $this

  $.ajax({
    type: 'GET',
    url: `${path}/edit`,
    complete(data) {
      $target.addClass('active')
      if (data.status === 200) {
        $target.html(data.responseText)
        $target.find('select.select2:not([multiple="multiple"])').select2({
          theme: 'bootstrap',
          templateSelection: formatSelect2Option, // these will format data-image
          templateResult: formatSelect2Option, // paths as images if provided
        })
        $target.find('select.select2.no-search').select2({
          theme: 'bootstrap',
          minimumResultsForSearch: Infinity,
        })
        $target.find('select.select2[multiple="multiple"]').select2({
          theme: 'bootstrap',
          tags: true,
          allowClear: false,
          placeholder: '',
          insertTag(insertData, tag) {
            insertData.push({
              id: tag.id,
              text: `${tag.text} (New)`,
            })
          },
        })
        $target.find('select.select2').select2('open')
        const targetPhoneInput = $target.find('input[type=tel].fancy').get(0)
        if (
          targetPhoneInput !== undefined &&
          window.intlTelInputGlobals.getInstance(targetPhoneInput) === undefined
        ) {
          intlTelInput(targetPhoneInput, {
            utilsScript: '/assets/intl-tel-input/utils.js',
            formatOnDisplay: true,
            separateDialCode: true,
            initialCountry: document.body.dataset.countryCode || 'us',
          })
        }

        initializeBootstrapToggle($target)

        initializeDiscardButton($target)

        // click out of body to update
        $('body').on('click', function (error) {
          if ($target.hasClass('processing')) return

          if (clickedOutsideInPlaceEditableField(error, $target)) handleEditableFieldUpdate($target, path)
        })

        //  press enter to update
        $target.keypress(function (_e) {
          if ($target.hasClass('processing')) return

          var keycode = _e.keyCode ? _e.keyCode : _e.which
          if (keycode === '13') handleEditableFieldUpdate($target, path)
        })
      } else {
        $('#global-alerts').append(data.responseText)
      }
    },
  })
}

function initializeBootstrapToggle($target) {
  let toggle = $target.find('.bootstrap-toggle')
  toggle.bootstrapToggle({
    size: 'mini',
  })
}

function handleDiscardButton($target, tooltip) {
  var btn = $('#dismiss-x')
  if (tooltip) {
    btn.tooltip('show')
    setTimeout(function () {
      btn.tooltip('hide')
    }, 2000)
  }
  $target.find('.discardable .fas.fa-xmark').css({ color: 'red' })
  initializeDiscardButton($target)
}

/* eslint-disable complexity */
// This function has a cyclomatic complexity of 9 because of the giant
// condition to find the targets value -- and I'm not feeling up to refactoring
// it right now.
function handleEditableFieldUpdate($target, path) {
  $target.addClass('processing')

  var token = $('meta[name="csrf-token"]').attr('content')
  var value

  if ($target.find('select.select2').length) {
    value = $target.find('select.select2').select2().val()
  } else if ($target.find('.bootstrap-toggle').length) {
    value = !$target.find('.bootstrap-toggle').parent().hasClass('off')
  } else if ($target.find('input').is(':checkbox')) {
    value = $target.find('input')[0].checked
  } else if ($target.find('input').length) {
    value = $target.find('input').val()
    const targetInput = $target.find('input')
    if (targetInput[0].type === 'tel') {
      const targetTelInput = window.intlTelInputGlobals.getInstance(targetInput.get(0))
      value = targetTelInput.getNumber()
    }
  } else if ($target.find('textarea').length) {
    value = $target.find('textarea').val()
  }

  $.ajax({
    type: 'PUT',
    url: path,
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    },
    data: { value },
    dataType: 'json',
    success(updatedData) {
      $target.html(updatedData.partial)
      $target.removeClass('processing')
      $target.removeClass('active')
      $('body').off('click')
    },
    error(updatedData) {
      var responseData = updatedData.responseJSON

      if (
        !responseData.additional_params ||
        (responseData.additional_params && !responseData.additional_params.inline_error)
      ) {
        $('#global-alerts').append(responseData.partial)
        $.ajax({
          type: 'GET',
          url: path,
          complete(errorData) {
            $target.html(errorData.responseText)
            $target.removeClass('active')
            $target.removeClass('processing')
          },
        })
        $('body').off('click')
      } else {
        $target.html(responseData.partial)
        $target.removeClass('processing')

        if (responseData.additional_params.intl_phone_input) {
          const phoneInput = $target.find('input[type=tel].fancy').get(0)

          if (phoneInput !== undefined && window.intlTelInputGlobals.getInstance(phoneInput) === undefined) {
            intlTelInput(phoneInput, {
              utilsScript: '/assets/intl-tel-input/utils.js',
              formatOnDisplay: true,
              separateDialCode: true,
              initialCountry: document.body.dataset.countryCode || 'us',
            })
          }
        }

        handleDiscardButton($target, responseData.additional_params.dismissal_tooltip)
      }
    },
  })
}
/* eslint-enable complexity*/
