import { Controller } from '@hotwired/stimulus'

// Takes a frame, and replaces the src of the frame with the frame src or window source + a query param generated
// by the attached input field.
export default class extends Controller {
  static values = { frame: String }

  change(e) {
    const input = e.target
    const frame = document.querySelector(`#${this.frameValue}`)

    let src = frame.src || window.location.href
    const url = new URL(src)

    let params = new URLSearchParams(url.search)
    params.set(input.id, input.value)

    frame.src = `${window.location.href}?${params.toString()}`
  }
}
