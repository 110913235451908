import ApplicationController from '../../javascript/controllers/application_controller'

import { useClickOutside, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['result', 'form']
  static debounces = ['search']

  initialize() {
    useDebounce(this, { wait: 600 })
    useClickOutside(this)
  }

  clickOutside() {
    this.element.classList.add('w-48')
    this.element.classList.remove('w-72')
    this.resultTarget.classList.add('hidden')
  }

  focus() {
    this.element.classList.remove('w-48')
    this.element.classList.add('w-72')
    if (this.resultTarget.dataset.resultSize > 0) {
      this.resultTarget.classList.remove('hidden')
    }
  }

  search() {
    this.formTarget.requestSubmit()
  }
}
