import { elementIsVisible } from '../helpers/visibility_helper'
import { elementIsFlatpickr } from '../helpers/flatpickr_helper'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit', 'required', 'radioBtnGroup']

  connect() {
    this.checkForDisable()
  }

  requiredTargetConnected() {
    this.change()
  }

  requiredTargetDisconnected() {
    this.change()
  }

  change() {
    this.checkForDisable()
  }

  checkForDisable() {
    if (!this.hasSubmitTarget) return

    if (this.requiredTargets.length === 0 || this.valid) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  isTargetBlank(el) {
    if (el.disabled) return false
    const blank = el.value === null || el.value === ''
    return blank
  }

  get valid() {
    if (this.hasBlankFormFields()) {
      return false
    }

    if (!this.hasCheckedRadioButtons() || !this.hasCheckedCheckboxes()) {
      return false
    }

    return true
  }

  hasBlankFormFields() {
    if (this.formFields.length === 0) {
      return false
    }
    return this.formFields.some(this.isTargetBlank)
  }

  hasCheckedRadioButtons() {
    if (this.radioButtons.length === 0 || !this.radioButtons.some((el) => !el.disabled)) {
      return true
    }
    return this.radioBtnGroupTargets.every((group) => {
      const checkedRadios = group.querySelectorAll('input[type="radio"]:checked')
      return checkedRadios.length > 0
    })
  }

  hasCheckedCheckboxes() {
    return this.checkboxFieldsets.length === 0 ? true : this.checkedCheckboxes
  }

  get radioButtons() {
    return this.requiredTargets.filter((target) => target.type === 'radio')
  }

  get checkboxFieldsets() {
    return this.requiredTargets.filter((target) => target.tagName === 'FIELDSET')
  }

  get checkedCheckboxes() {
    return this.checkboxFieldsets.every((fieldset) => {
      const checkboxes = fieldset.querySelectorAll('input[type="checkbox"]')
      return Array.from(checkboxes).some((checkbox) => checkbox.checked)
    })
  }

  get formFields() {
    return this.requiredTargets.filter(
      (target) =>
        target.type !== 'radio' &&
        target.tagName !== 'FIELDSET' &&
        (elementIsVisible(target) || elementIsFlatpickr(target)),
    )
  }
}
