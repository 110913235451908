import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['location']

  connect() {
    if (this.scrollOffset > 0) {
      this.element.scrollTo({ top: this.scrollOffset })
    }
  }

  get top() {
    return this.element.getBoundingClientRect().top
  }

  get distances() {
    return this.locationTargets.map((locationTarget) => locationTarget.getBoundingClientRect().top - this.top)
  }

  get scrollOffset() {
    return Math.min(...this.distances) - 10
  }
}
