import { Controller } from '@hotwired/stimulus'

//
// Membership Template Add/Edit modal
//      a lot of conditional rendering and toggling of the form
//      based on if the membership is individual or trial, or recurring billing or not
//
export default class MembershipTemplatesController extends Controller {
  static targets = ['recurringBillingHide', 'occurrencesHide', 'billingOptions', 'noChargeText', 'paymentReason']

  hideRecurringBilling() {
    this.hideNoCharge()
    this.paymentReasonTarget.classList.add('col-span-3')
    this.recurringBillingHideTarget.classList.add('hidden')
    this.occurrencesHideTarget.classList.add('hidden')
  }

  showRecurringBilling() {
    this.hideNoCharge()
    this.paymentReasonTarget.classList.remove('col-span-3')
    this.recurringBillingHideTarget.classList.remove('hidden')
    this.occurrencesHideTarget.classList.remove('hidden')
  }

  hideNoCharge() {
    this.billingOptionsTarget.classList.remove('hidden')
    this.noChargeTextTarget.classList.add('hidden')
  }

  showNoCharge() {
    this.billingOptionsTarget.classList.add('hidden')
    this.noChargeTextTarget.classList.remove('hidden')
  }
}
