import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.setNextDisabledState()
  }

  selectClassFromDropdown(event) {
    event.preventDefault()
  }

  selectClassEvent(event) {
    let calendarEvent = this.findCalEventFromTarget(event.target)
    this.setSelectedClassAttributes(calendarEvent.dataset)

    document.querySelector('#scheduler-next').disabled = false

    for (const item of document.querySelectorAll('.calendar-event')) {
      item.classList.remove('selected')
    }
    calendarEvent.classList.add('selected')
  }

  setNextDisabledState() {
    let required = document.querySelector('#class_required').value
    let items = document.querySelectorAll('.class-selection-menu .menu-item')

    if (items.length === 0 && required === 'true') {
      document.querySelector('#scheduler-next').disabled = false
    } else {
      document.querySelector('#scheduler-next').disabled = required === 'true'
    }
  }

  //
  // private
  //
  findCalEventFromTarget(target) {
    // depending on if the user clicks the time or the space around it
    if (target.hasAttribute('data-name')) {
      return target
    }

    return target.closest('.calendar-event')
  }

  setSelectedClassAttributes(dataset) {
    let className = document.querySelector('#selected-class-name')
    className.textContent = dataset.name
    let classDate = document.querySelector('#selected-class-date')
    classDate.textContent = dataset.date
    let classTime = document.querySelector('#selected-class-time')
    classTime.textContent = dataset.time

    let desc = document.querySelector('#selected-class-desc')
    if (dataset.description) {
      desc.textContent = dataset.description
      desc.parentElement.classList.remove('hidden')
    } else {
      desc.parentElement.classList.add('hidden')
    }

    let instructor = document.querySelector('#selected-class-instructor')
    if (dataset.instructor) {
      instructor.textContent = dataset.instructor
      instructor.classList.remove('hidden')
    } else {
      instructor.textContent = ''
      instructor.classList.add('hidden')
    }

    document.querySelector('#scheduler_selected_class_object_type').value = dataset.type
    document.querySelector('#scheduler_selected_class_object_id').value = dataset.id
    document.querySelector('#scheduler_selected_class_date').value = dataset.date
    document.querySelector('#scheduler_selected_class_time').value = dataset.time
    document.querySelector('#scheduler_selected_class_name').value = dataset.name
  }
}
