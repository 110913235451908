import ApplicationController from '../../../javascript/controllers/application_controller'

import { useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['spinner']
  static debounces = ['search']

  initialize() {
    useDebounce(this, { wait: 600 })
  }

  connect() {
    this.submitCallback = this.hideSpinner.bind(this)
    document.addEventListener('turbo:submit-end', this.submitCallback, true)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.submitCallback, true)
  }

  search() {
    this.spinnerTarget.classList.remove('hidden')

    this.form?.requestSubmit()
  }

  hideSpinner(event) {
    if (event.target !== this.form) return

    this.spinnerTarget.classList.add('hidden')
  }

  ///
  /// private
  ///

  get form() {
    return this.element.closest('form')
  }
}
