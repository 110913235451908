import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'hiddenField',
    'display',
    'drop',
    'innerCanvas',
    'outerCanvas',
    'cropCanvas',
    'fileField',
    'error',
    'zoomSliderWrapper',
    'zoomSlider',
  ]

  static values = { disablesSubmit: { type: Boolean, default: true } }

  dragging = false
  _dragStart = { x: 0, y: 0 }

  _cameraOffset = { x: 0, y: 0 }
  cameraZoom = 1
  lastZoom = 1

  DRAGPAN_SENSITIVITY = 0.25
  MAX_ZOOM = 5
  MIN_ZOOM = 0.25
  MIN_ZOOM_SCALE_FACTOR = 0.1

  SCROLL_SENSITIVITY = 0.0005

  connect() {
    this.displayCtx = this.innerCanvasTarget.getContext('2d')
    this.cropCtx = this.cropCanvasTarget.getContext('2d')
    this.outsideCtx = this.outerCanvasTarget.getContext('2d')

    this.addDisplayListeners()

    this.drawing = false

    if (this.disablesSubmitValue) {
      this.submitButton = document.querySelector(`[form=${this.element.closest('form').id}`)
      this.submitButton.disabled = true
    }
  }

  disconnect() {
    this.removeDisplayListeners()
  }

  selectFile() {
    this.hiddenFieldTarget.click()
  }

  async dropFile(event) {
    event.preventDefault()

    const file = event.dataTransfer.files[0]

    await this.displayFile(file)
  }

  async fileSelected() {
    const file = this.hiddenFieldTarget.files[0]

    await this.displayFile(file)
  }

  setZoomFromSlider() {
    const zoom = parseFloat(this.zoomSliderTarget.value)

    this.cameraZoom = zoom

    this.drawFrame()
  }

  crop() {
    const matrix = this.displayCtx.getTransform()

    // We have to cut and paste the 300x300 piece of the image from the source image into the cropCanvas
    // So, since the source image is scaled to a max of 800px, we first need to grab the scale factor,
    // to go from the visible canvas coordinates to the source image coordinates
    // Then, we can can directly grab the offset from the top left of the source image from its transform matrix
    // and use all of that to draw the cropped image into the cropCanvas
    const sourceX = matrix.e
    const sourceY = matrix.f

    const sourceWidth = this.cropCanvasTarget.width * this.visibleToSourceScale.x
    const sourceHeight = this.cropCanvasTarget.height * this.visibleToSourceScale.y

    this.cropCtx.clearRect(0, 0, this.cropCanvasTarget.width, this.cropCanvasTarget.height)
    this.cropCtx.drawImage(
      this.innerCanvasTarget,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      this.cropCanvasTarget.width,
      this.cropCanvasTarget.height,
    )

    this.cropCanvasTarget.toBlob((blob) => {
      const file = new File([blob], 'avatar.png', { type: 'image/png' })
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)

      this.fileFieldTarget.files = dataTransfer.files
    })
  }

  reset() {
    if (this.nextFrame) {
      cancelAnimationFrame(this.nextFrame)
      this.drawing = false
    }

    this.displayCtx.resetTransform()
    this.outsideCtx.resetTransform()
    this.cropCtx.resetTransform()

    this.displayCtx.clearRect(0, 0, 10000, 10000)
    this.outsideCtx.clearRect(0, 0, 10000, 10000)
    this.cropCtx.clearRect(0, 0, 10000, 10000)

    this.cameraZoom = 1
    this.lastZoom = 1
    this._cameraOffset = { x: 0, y: 0 }
    this._dragStart = { x: 0, y: 0 }
    this.dragging = false

    this.zoomSliderTarget.value = 1

    this.fileFieldTarget.value = null
    this.hiddenFieldTarget.value = null

    if (this.disablesSubmitValue) {
      this.submitButton.disabled = true
    }

    this.errorTarget.classList.add('hidden')

    this.displayTarget.classList.add('hidden')
    this.dropTarget.classList.remove('hidden')
    this.zoomSliderWrapperTarget.classList.remove('flex')
    this.zoomSliderWrapperTarget.classList.add('hidden')
  }

  ///
  /// private
  ///

  draw() {
    if (!this.drawing) {
      cancelAnimationFrame(this.nextFrame)
      this.nextFrame = null
      return
    }

    this.drawFrame()
    this.nextFrame = requestAnimationFrame(this.draw.bind(this))
  }

  drawFrame() {
    // This has to be hard coded to a big number to handle zooming and panning
    this.displayCtx.clearRect(0, 0, 10000, 10000)

    this.displayCtx.save()
    this.displayCtx.scale(this.cameraZoom, this.cameraZoom)
    this.displayCtx.translate(this.cameraOffset.x, this.cameraOffset.y)
    this.displayCtx.drawImage(this.image, 0, 0)
    this.displayCtx.restore()

    this.outsideCtx.clearRect(0, 0, 10000, 10000)

    this.outsideCtx.save()
    this.outsideCtx.globalAlpha = 0.35
    this.outsideCtx.scale(this.cameraZoom, this.cameraZoom)
    this.outsideCtx.translate(this.cameraOffset.x, this.cameraOffset.y)
    this.outsideCtx.drawImage(this.image, 0, 0)
    this.outsideCtx.restore()

    this.crop()
  }

  displayFile(file) {
    try {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        this.image = new Image()
        this.image.onload = this.start.bind(this)
        this.image.src = fileReader.result
      }

      fileReader.readAsDataURL(file)

      this.errorTarget.classList.add('hidden')
    } catch {
      this.errorTarget.classList.remove('hidden')
    }
  }

  start() {
    this.innerCanvasTarget.width = this.image.width
    this.innerCanvasTarget.height = this.image.height

    this.outerCanvasTarget.width = this.image.width
    this.outerCanvasTarget.height = this.image.height

    this.displayCtx.resetTransform()
    this.outsideCtx.resetTransform()

    this.displayCtx.drawImage(this.image, 0, 0)
    this.outsideCtx.drawImage(this.image, 0, 0)

    this.dropTarget.classList.add('hidden')
    this.displayTarget.classList.remove('hidden')
    this.zoomSliderWrapperTarget.classList.remove('hidden')
    this.zoomSliderWrapperTarget.classList.add('flex')

    const boundingRect = this.innerCanvasTarget.getBoundingClientRect()
    const scale = {
      x: this.innerCanvasTarget.width / boundingRect.width,
      y: this.innerCanvasTarget.height / boundingRect.height,
    }

    this.visibleToSourceScale = scale

    this.dragging = false
    this._dragStart = { x: 0, y: 0 }
    this._cameraOffset = {
      x: -this.image.width / 2 + (this.cropCanvasTarget.width / 2) * this.visibleToSourceScale.x,
      y: -this.image.height / 2 + (this.cropCanvasTarget.height / 2) * this.visibleToSourceScale.y,
    }
    this.cameraZoom = 1

    if (this.disablesSubmitValue) {
      this.submitButton.disabled = false
    }

    this.drawFrame()
  }

  startDrag(event) {
    this.dragging = true

    this.dragStart = this.getPosition(event)

    this.drawing = true
    this.draw()
  }

  stopDrag() {
    this.dragging = false
    this.lastZoom = this.cameraZoom

    this.drawing = false
  }

  drag(event) {
    if (!this.dragging) return

    this.cameraOffset = this.getPosition(event)
  }

  zoom(event) {
    event.preventDefault()

    if (this.dragging) return

    const zoomAmount = event.deltaY * this.SCROLL_SENSITIVITY

    // Clamp the zoom amount to the min and max zoom
    this.cameraZoom = Math.max(this.MIN_ZOOM, Math.min(this.MAX_ZOOM, this.cameraZoom + zoomAmount))
    this.zoomSliderTarget.value = this.cameraZoom

    this.drawFrame()
  }

  pinchZoom(event) {
    event.preventDefault()

    const touch1 = event.touches[0]
    const touch2 = event.touches[1]

    // sqrt isnt really needed here and is expensive
    const distance = (touch1.clientX - touch2.clientX) ** 2 + (touch1.clientY - touch2.clientY) ** 2

    if (!this.initialPinchDistance) {
      this.initialPinchDistance = distance
      return
    }

    if (this.dragging) return

    const zoomFactor = distance / this.initialPinchDistance

    this.cameraZoom = Math.max(this.MIN_ZOOM, Math.min(this.MAX_ZOOM, this.lastZoom * zoomFactor))
    this.zoomSliderTarget.value = this.cameraZoom

    this.drawFrame()
  }

  touch(event, singleTouchHandler) {
    if (!event.touches) return

    event.preventDefault()

    if (event.touches.length === 1) {
      singleTouchHandler(event)
      return
    }

    if (!(event.touches.length === 2 && event.type === 'touchmove')) return

    this.dragging = false

    this.pinchZoom(event)
  }

  getPosition(event) {
    const rect = this.innerCanvasTarget.getBoundingClientRect()
    const inverseMatrix = this.displayCtx.getTransform().inverse()
    const clientPosition = this.clientPosition(event)

    const position = {
      x: (clientPosition.x - rect.left) * this.visibleToSourceScale.x,
      y: (clientPosition.y - rect.top) * this.visibleToSourceScale.y,
    }

    // All this does is take the position of the mouse and transform it from being in
    // screen space coordinates to the source image coordinates (with 0,0 at the top left of the image)
    return {
      x: inverseMatrix.a * position.x + inverseMatrix.c * position.y + inverseMatrix.e,
      y: inverseMatrix.b * position.x + inverseMatrix.d * position.y + inverseMatrix.f,
    }
  }

  clientPosition(event) {
    if (event.touches) {
      return {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      }
    }

    return {
      x: event.clientX,
      y: event.clientY,
    }
  }

  addDisplayListeners() {
    this.mouseDownListener = this.startDrag.bind(this)
    this.mouseUpListener = this.stopDrag.bind(this)
    this.mouseMoveListener = this.drag.bind(this)
    this.mouseLeaveListener = this.stopDrag.bind(this)

    this.zoomListener = this.zoom.bind(this)

    this.touchStartListener = (event) => {
      this.touch(event, this.startDrag.bind(this))
    }
    this.touchEndListener = (event) => {
      this.initialPinchDistance = null
      this.touch(event, this.stopDrag.bind(this))
    }
    this.touchMoveListener = (event) => {
      this.touch(event, this.drag.bind(this))
    }

    this.displayTarget.addEventListener('mousedown', this.mouseDownListener)
    this.displayTarget.addEventListener('mouseup', this.mouseUpListener)
    this.displayTarget.addEventListener('mousemove', this.mouseMoveListener)
    this.displayTarget.addEventListener('mouseleave', this.mouseLeaveListener)

    this.displayTarget.addEventListener('touchstart', this.touchStartListener)
    this.displayTarget.addEventListener('touchend', this.touchEndListener)
    this.displayTarget.addEventListener('touchmove', this.touchMoveListener)

    this.displayTarget.addEventListener('wheel', this.zoomListener)
  }

  removeDisplayListeners() {
    this.displayTarget.removeEventListener('mousedown', this.mouseDownListener)
    this.displayTarget.removeEventListener('mouseup', this.mouseUpListener)
    this.displayTarget.removeEventListener('mousemove', this.mouseMoveListener)
    this.displayTarget.removeEventListener('mouseleave', this.mouseLeaveListener)

    this.displayTarget.removeEventListener('touchstart', this.touchStartListener)
    this.displayTarget.removeEventListener('touchend', this.touchEndListener)
    this.displayTarget.removeEventListener('touchmove', this.touchMoveListener)

    this.displayTarget.removeEventListener('wheel', this.zoomListener)
  }

  get zoomScaleFactor() {
    const normalized = (this.cameraZoom - this.MIN_ZOOM) / (this.MAX_ZOOM - this.MIN_ZOOM)

    return Math.max(this.MIN_ZOOM_SCALE_FACTOR, normalized)
  }

  get dragStart() {
    return this._dragStart
  }

  set dragStart({ x, y }) {
    this._dragStart = {
      x: (this.DRAGPAN_SENSITIVITY * x) / this.zoomScaleFactor - this.cameraOffset.x,
      y: (this.DRAGPAN_SENSITIVITY * y) / this.zoomScaleFactor - this.cameraOffset.y,
    }
  }

  get cameraOffset() {
    return this._cameraOffset
  }

  set cameraOffset({ x, y }) {
    this._cameraOffset = {
      x: (this.DRAGPAN_SENSITIVITY * x) / this.zoomScaleFactor - this.dragStart.x,
      y: (this.DRAGPAN_SENSITIVITY * y) / this.zoomScaleFactor - this.dragStart.y,
    }
  }

  get visibleToSourceScale() {
    return this._scale
  }

  set visibleToSourceScale(scale) {
    this._scale = scale
  }
}
