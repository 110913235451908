import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['iframe']
  static values = { onLoad: Boolean }

  connect() {
    if (this.onLoadValue) window.print()
  }

  print() {
    this.iframeTarget.contentWindow.print()
  }
}
