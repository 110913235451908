import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { code: String, exponent: Number, amount: { type: Number, default: 0 } }

  format() {
    const options = {
      minimumFractionDigits: this.exponentValue,
      maximumFractionDigits: this.exponentValue,
      style: 'currency',
      currency: this.codeValue,
      currencyDisplay: 'narrowSymbol',
    }
    const result = new Intl.NumberFormat(this.codeValue, options).format(
      parseFloat(this.amountValue) * this.subunitMultiplier,
    )

    this.element.innerText = result
  }

  amountValueChanged() {
    this.format()
  }

  get subunitMultiplier() {
    return 10 ** -this.exponentValue
  }
}
