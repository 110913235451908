/* eslint-env jquery */

$(document).on('turbo:load', function () {
  'use strict'

  $('body').on('click', '#add-new-agreement', function () {
    $('#new-agreement-nav-warning').modal('toggle')
  })

  $('body').on('click', '.go-to-new-agreement', function () {
    window.open(window.location.origin + $(this).data().path, '_blank')
  })
})
