/* global Turbo */

$(document).on('turbo:load', function () {
  'use strict'
  //
  // Set sidebar items to expanded or collapsed based on preferences
  //
  $('.profile-sidebar-content .sidebar-header').each(function () {
    var preferences = $('.profile-sidebar-content').data()
    var section_name = this.id.substring(8) // chop off the 'sidebar_'

    if (preferences[section_name] !== 'expanded') toggleSidebarSection($(this))

    $(this).on('click', function () {
      toggleSidebarSection($(this))

      section_name = this.id.substring(8) // chop off the 'sidebar_'
      var pref_update = {}
      if (preferences[section_name] === 'expanded') pref_update[section_name] = 'collapsed'
      else pref_update[section_name] = 'expanded'

      updateProfileSidebarPreferences(pref_update)
    })
  })

  function updateProfileSidebarPreferences(preferences) {
    var path = '/preferences/'
    $.ajax({
      type: 'PUT',
      url: path,
      data: {
        key: 'profile_sidebar',
        preferences,
      },
      complete(data) {
        if (data.status === 200) Turbo.cache.clear()
        else $('#global-alerts').append(data.responseText)
      },
    })
  }

  function toggleSidebarSection($this) {
    var section = $this.closest('.section')
    var content = section.find('.section-content')
    content.toggle('hidden')

    var chevron = $this.find('.pull-right')
    if (chevron.css('transform') === 'none') chevron.css({ transform: 'rotate(180deg)' })
    else chevron.css({ transform: 'none' })
  }

  // Handle expand/collapse of entire sidebar in mobile/tablet
  $(
    '#student-profile .toggle-profile-sidebar-content, \
     #prospect-profile .toggle-profile-sidebar-content',
  ).on('click', function () {
    var section = $(this).parent().find('.profile-sidebar-content')
    section.toggle('hidden')

    var icon = $(this).find('i')
    if (icon.css('transform') === 'none') icon.css({ transform: 'rotate(180deg)' })
    else icon.css({ transform: 'none' })
  })

  // If we resize and vacate tablet/mobile, we need to clear
  // display: none that may have been added from above toggling
  $(window).on('resize', function () {
    var tabletSize = 993
    var sidebar = $('.profile-sidebar-content')[0]
    if (sidebar !== undefined && window.innerWidth >= tabletSize && sidebar.style.display != null) {
      sidebar.style.display = null
    }
  })
})
