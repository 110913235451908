/* global moment */
/* eslint-disable no-unused-vars */

// TODO: Refactor this to use components + hydration
export function buildListViewElement(segs, calendar) {
  const content = document.createElement('div')
  const scroller = document.createElement('div')
  const list = document.createElement('div')
  const days = dateRange(calendar.view.currentStart, calendar.view.currentEnd)
  const segsByDay = sliceSegmentsByDay(segs, calendar)
  const items = segsByDay.map((_segs, index) => buildListViewItem(_segs, calendar, moment.utc(days[index])))

  content.classList.add('fc-list', 'fc-list-sticky', 'fc-customListView')
  scroller.classList.add('fc-scroller', 'fc-scroller-liquid')
  list.classList.add('fc-customListView-list')

  for (const item of items) {
    list.appendChild(item)
  }

  scroller.appendChild(list)
  content.appendChild(scroller)

  return content
}

function buildListViewItem(segs, calendar, date) {
  const item = document.createElement('div')
  const itemDate = buildListViewItemDateBit(date, calendar)

  item.classList.add('fc-customListView-item')
  item.appendChild(itemDate)

  if (segs.length) {
    const itemEvents = document.createElement('div')
    const events = segs.map((seg) => buildListViewSegmentAnchor(seg, calendar, itemEvents))

    for (const event of events) {
      itemEvents.appendChild(event)
    }

    item.appendChild(itemEvents)
  }

  return item
}

export function buildEventContent(info, showFormFields, calendar) {
  const eventContent = info.event.extendedProps.eventContent
  const mainDiv = document.createElement('div')
  const timeDiv = document.createElement('div')
  const subjectDiv = document.createElement('div')
  const subjectLink = document.createElement('a')
  const titleContainerDiv = document.createElement('div')
  const titleDiv = document.createElement('div')

  const iconMap = {
    scheduled: 'fa-calendar-day',
    rescheduled: 'fa-history',
    confirmed: 'fa-check-square',
    noshowed: 'fa-user-slash',
    todo: 'fa-clipboard-list',
    overdue: 'fa-exclamation-circle',
  }

  mainDiv.classList.add('fc-event-main-frame-state')
  timeDiv.classList.add('fc-event-time')
  titleContainerDiv.classList.add('fc-event-title-container')
  titleDiv.classList.add('fc-event-title')
  titleDiv.classList.add('fc-sticky')

  if (!(info.event.id?.includes('class') || info.event._def?.ui?.classNames?.includes('completed'))) {
    const htmlDiv = document.createElement('div')
    const customIcon = document.createElement('i')

    htmlDiv.classList.add('fc-event-custom-icon')
    customIcon.classList.add('fas')
    customIcon.classList.add(iconMap[info.event.extendedProps.eventContent])

    htmlDiv.appendChild(customIcon)
    mainDiv.appendChild(htmlDiv)
  }

  timeDiv.textContent = info.timeText
  titleDiv.textContent = info.event.title
  titleContainerDiv.appendChild(timeDiv)
  titleContainerDiv.appendChild(titleDiv)

  if (info.event.extendedProps.subject && info.event.extendedProps.subject.name) {
    const linkIcon = document.createElement('i')
    linkIcon.classList.add('fas', 'fa-arrow-up-right-from-square', 'pl-1')
    subjectLink.textContent = info.event.extendedProps.subject.name
    subjectLink.href = info.event.extendedProps.subject.path
    subjectLink.target = '_blank'
    subjectLink.appendChild(linkIcon)

    subjectLink.classList.add('!text-white', '!underline')
    subjectDiv.appendChild(subjectLink)
    subjectDiv.classList.add('pt-1')
    titleDiv.appendChild(subjectDiv)
  }

  mainDiv.appendChild(titleContainerDiv)

  if (showFormFields && info.event.extendedProps.eventContent !== 'completed') {
    const formFields = buildListViewFormFields(info, calendar)
    mainDiv.appendChild(formFields)
  }

  return mainDiv
}

function buildListViewSegmentAnchor(seg, calendar, parent) {
  const anchor = document.createElement('a')
  const eventFrame = document.createElement('div')
  const start = moment.utc(seg.range.start)
  const end = moment.utc(seg.range.end)
  const timeText = `${start.format('h:mm A')} - ${end.format('h:mm A')}`

  anchor.classList.add('fc-event', 'fc-customlistView-event', ...seg.ui.classNames)
  eventFrame.classList.add('fc-event-main')
  eventFrame.appendChild(
    buildEventContent(
      {
        timeText,
        event: seg.def,
        range: seg.range,
      },
      true,
      calendar,
    ),
  )

  parent.classList.add('fc-customListView-item-events')

  anchor.appendChild(eventFrame)

  anchor.addEventListener('click', (jsEvent) => {
    if (jsEvent.target.href) return
    if (jsEvent.target.closest('.dropdown')) return

    calendar.trigger('eventClick', {
      el: anchor,
      event: seg.def,
      jsEvent,
      view: calendar.view,
    })
  })

  return anchor
}

function buildListViewItemDateBit(date, calendar) {
  const itemDate = document.createElement('div')
  const itemDateMonthBit = document.createElement('div')
  const itemDateDayBit = document.createElement('div')
  const itemDateDayOfWeekBit = document.createElement('div')
  const itemDateWrapper = document.createElement('a')
  const itemDateMonthDayWrapper = document.createElement('div')

  itemDate.classList.add('fc-customListView-item-date')
  itemDateMonthBit.classList.add('fc-customListView-item-date-month-bit')
  itemDateDayBit.classList.add('fc-customListView-item-date-day-bit')
  itemDateDayOfWeekBit.classList.add('fc-customListView-item-date-day-of-week-bit')
  itemDateWrapper.classList.add('fc-customListView-item-date-wrapper')

  itemDateMonthBit.textContent = moment(date).format('MMM').toUpperCase()
  itemDateDayBit.textContent = moment(date).format('D')
  itemDateDayOfWeekBit.textContent = moment(date).format('ddd')

  itemDateMonthDayWrapper.appendChild(itemDateMonthBit)
  itemDateMonthDayWrapper.appendChild(itemDateDayOfWeekBit)
  itemDateWrapper.appendChild(itemDateMonthDayWrapper)
  itemDateWrapper.appendChild(itemDateDayBit)
  itemDate.appendChild(itemDateWrapper)

  itemDateWrapper.addEventListener('click', () => {
    calendar.trigger('navLinkDayClick', date)
  })

  return itemDate
}

function buildListViewFormFields(info, calendar) {
  const quickActionsWrapper = document.createElement('div')
  const eventType = parseEventType(info)

  let values = []
  if (eventType === 'appointment') {
    values = ['scheduled', 'rescheduled', 'confirmed', 'no showed', 'completed']
  } else if (eventType === 'task') {
    values = ['to do', 'completed']
  }

  const statusToggler = buildListViewStatusToggler(values, info, calendar)

  quickActionsWrapper.classList.add('fc-customListView-form-fields')

  if (statusToggler) {
    const statusText = document.createElement('span')
    statusText.classList.add('fc-customListView-form-status-text')
    statusText.textContent = 'Status:'
    quickActionsWrapper.appendChild(statusText)
    quickActionsWrapper.appendChild(statusToggler)
  }

  return quickActionsWrapper
}

function buildListViewStatusToggler(values, info, calendar) {
  const updateCallback = debounce(updateEvent, 500) // debounce to prevent rapid updates
  if (!values.length) return

  const statusDropdown = document.createElement('div')
  const statusDropdownToggler = document.createElement('div')
  const statusDropdownList = document.createElement('ul')
  const path = info.event.extendedProps.dataPath.replace('/edit', '')
  const eventType = parseEventType(info)

  statusDropdown.classList.add('dropdown')
  statusDropdownToggler.classList.add('dropdown-toggle')
  statusDropdownToggler.setAttribute('data-toggle', 'dropdown')
  statusDropdownToggler.setAttribute('aria-haspopup', 'true')
  statusDropdownToggler.setAttribute('aria-expanded', 'false')
  statusDropdownToggler.setAttribute('role', 'button')

  statusDropdownList.classList.add('dropdown-menu')
  statusDropdownToggler.textContent = values[0]
  for (const value of values) {
    if (value.replace(/\s/g, '') === info.event.extendedProps.eventContent) {
      statusDropdownToggler.textContent = value
    }

    const statusDropdownListItem = document.createElement('li')
    const statusDropdownListItemLink = document.createElement('a')
    statusDropdownListItemLink.textContent = value
    statusDropdownListItemLink.addEventListener('click', () => {
      statusDropdownToggler.textContent = value
      updateCallback(path, { [`${eventType}[state]`]: value.replace(/\s/g, '') }, calendar)
    })
    statusDropdownListItem.appendChild(statusDropdownListItemLink)
    statusDropdownList.appendChild(statusDropdownListItem)
  }

  statusDropdown.appendChild(statusDropdownToggler)
  statusDropdown.appendChild(statusDropdownList)

  return statusDropdown
}

function sliceSegmentsByDay(segs, calendar) {
  const days = dateRange(calendar.view.currentStart, calendar.view.currentEnd)

  return days.map((day) => segs.filter((seg) => moment.utc(day).isSame(moment.utc(seg.range.start), 'day')))
}

function dateRange(start, end) {
  let days = []
  for (let day = start; day < end; day.setDate(day.getDate() + 1)) {
    days.push(new Date(day))
  }

  return days
}

function updateEvent(path, value, calendar) {
  path = path.replace('unpersisted_', '')

  const [modifiedPath, oldQueryString] = path.split('?')
  const queryString = new URLSearchParams(value)

  if (oldQueryString) {
    queryString.append(...oldQueryString.split('='))
  }

  fetch(`${modifiedPath}?${queryString}`, {
    method: 'PUT',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      calendar.shouldReloadView = true
      calendar.refetchEvents()
      return
    }

    return response.json()
  })
}

function parseEventType(info) {
  let type = info.event.publicId.split('_')[0]
  if (type === 'class') type = 'class_event'
  return type
}

function debounce(func, time, immediate) {
  let timeout

  return (...args) => {
    const context = this
    function later() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, time)
    if (callNow) func.apply(context, args)
  }
}
