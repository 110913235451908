import {
  initializeAccountFormSubmit,
  initializeCloseUserAccount,
  initializeAccountRestrictionChecks,
  initializeChangeUserAccountPassword,
} from '../../assets/javascripts/school/people/profile/student_user_account'

window.initializeAccountFormSubmit = initializeAccountFormSubmit
window.initializeCloseUserAccount = initializeCloseUserAccount
window.initializeAccountRestrictionChecks = initializeAccountRestrictionChecks
window.initializeChangeUserAccountPassword = initializeChangeUserAccountPassword
