import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radioBtn']

  hideAllChildren() {
    for (const radioBtn of this.radioBtnTargets) {
      let c = this.application.getControllerForElementAndIdentifier(radioBtn, 'radio-btn')
      c.hideChildren()
    }
  }
}
