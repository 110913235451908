import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { removeExtraBackdrops } from '../helpers/modal_helper'

// Weird wrapper controller that interacts with bootstrap
// TODO: Move all the modals to not use bootstrap jQuery
export default class extends Controller {
  connect() {
    this.modal = $(this.element)
    this.modal.modal('show')

    removeExtraBackdrops()

    if (document.querySelectorAll("span[data-toggle='tooltip'").length > 0) {
      document.querySelectorAll("span[data-toggle='tooltip'").forEach(function (element) {
        $(element).tooltip({ html: true, delay: { show: 250, hide: 100 } })
      })
    }

    $(this.modal).one('hidden.bs.modal', function () {
      this.closest('#modal').innerHTML = ''
    })

    document.addEventListener('turbo:submit-end', this.submit.bind(this))
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.submit.bind(this))
  }

  submit(event) {
    if (event.detail.success) {
      // TODO: See whether or not we still need this with the new turbo update
      if (event.detail.fetchResponse.response.redirected) {
        Turbo.visit(event.detail.fetchResponse.response.url)
      }

      $(this.modal).modal('hide')
    }
  }
}
