/* eslint-env jquery */

/* global initializeDropdownTabs */
/* eslint no-use-before-define: 0 */
/* global initializeAdditionalInfoModalContent */
/* global initializeInfiniteViewAll */
/* global initializeTooltips */
/* global Turbo */
/* global initializeSelectAllCheckboxes */

function updateStudentStatus(container, path, button) {
  'use strict'

  button.attr('disabled', true)

  var token = $('meta[name="csrf-token"]').attr('content')
  $.ajax({
    type: 'PUT',
    url: path,
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    },
    complete(data) {
      if (data.status === 200) {
        handleUpdateStudentStatusResponse(container, path, button, data)
      } else if (data.status === 400) {
        $('#update-student-modal').html('<div class="spinner spinner-grow center" />')
        $('#update-student-modal').modal('show')
        $('#update-student-modal').on('hidden.bs.modal', function () {
          $('#update-student-modal').unbind()
        })
        $('#update-student-modal').html(data.responseText)
        $('#update-student-modal')
          .find('form')
          .on('ajax:complete', function (e) {
            if (e.originalEvent.detail[0].status === 200) {
              getStudentStatus(container, path, null)
            } else {
              $('#global-alerts').append(e.originalEvent.detail[0].responseText)
              $('#update-student-modal').modal('hide')
            }
          })
        $('#update-student-modal')
          .find("button:contains('Cancel'), button.close")
          .on('click', function () {
            getStudentStatus(container, path, null)
          })
        return false
      } else {
        $('#global-alerts').append(data.responseText)
        getStudentStatus(container, path, button)
        return false
      }
      return false
    },
  })
  return true
}

export function initializeStudentStatusDropdowns(parent) {
  'use strict'

  initializeDropdownTabs($(parent))

  $(parent)
    .find('.status-modifier .dropdown-tabs-menu  li a')
    .on('voltage:dropdown-tabs:selected', function () {
      var $this = $(this)
      var $target = $this.closest('.student-wrapper')
      var path = $this.data('path')
      var $parentButton = $this.closest('.dropdown-tabs').find('button')

      return updateStudentStatus($target, path, $parentButton)
    })
}

function handleAdditionalInfoNeededUpdateStudentResponse(container, path, button, data) {
  'use strict'

  $('#update-student-modal').html(data.responseText)
  initializeAdditionalInfoModalContent()
  $('#update-student-modal').modal('show')
  $('#update-student-modal').on('hidden.bs.modal', function () {
    $('#update-student-modal').unbind()
    getStudentStatus(container, path, button)
  })
  $('#update-student-modal')
    .find('form')
    .on('ajax:complete', function (e) {
      if (e.originalEvent.detail[0].status === 200) {
        updateStudentStatus(container, path, button)
      } else {
        $('#global-alerts').append(e.originalEvent.detail[0].responseText)
        $('#update-student-modal').modal('hide')
      }
    })
}

function handleUpdateStudentStatusResponse(container, path, button, data) {
  'use strict'

  if (data.status === 200) {
    if ($('.student-wrapper').length === 0) {
      $('#update-student-modal').unbind()
      $('#update-student-modal').modal('hide')
      Turbo.visit(window.location.toString(), { action: 'replace' })
    } else {
      container.fadeOut('fast', function () {
        $('#update-student-modal').unbind()
        $('#update-student-modal').modal('hide')
        container.html(data.responseText)
        initializeTooltips(container)
        initializeStudentStatusDropdowns(container)
        initializeSelectAllCheckboxes(container.find("input.select-all-item[type='checkbox']"), false)
        initializeStudentAttendanceLog(container)
        container.fadeIn('fast')
      })
    }
  } else if (data.status === 400) {
    handleAdditionalInfoNeededUpdateStudentResponse(container, path, button, data)
  } else if (data.status === 401) {
    $('#global-alerts').append(data.responseText)
    return false
  } else if (data.status === 404) {
    $('#global-alerts').append(data.responseText)
    return false
  } else {
    $('#global-alerts').append(data.responseText)
    getStudentStatus(container, path, button)
    return false
  }

  return true
}

function getStudentStatus(container, path, button) {
  'use strict'

  if (button !== null) button.attr('disabled', true)

  $.ajax({
    type: 'GET',
    url: path,
    complete(data) {
      return handleUpdateStudentStatusResponse(container, path, button, data)
    },
  })
}

function populateProgramAttendances(path, programId) {
  'use strict'

  $.ajax({
    type: 'GET',
    url: path,
    data: { programId },
    complete(data) {
      var $table = $('.attendance-base-container').find('table')
      var $footer = $('.attendance-base-container').find('.footer')

      $table.html(data.responseJSON.table_data)
      $footer.html(data.responseJSON.footer)
      initializeViewMoreAttendancesActionable($('.attendance-base-container a.view-more-results'))
      initializeInfiniteViewAll($('.attendance-base-container a.view-all'), $('.attendance-base-container'))
      initializeAttendanceDelete()
      initializeSelectAllCheckboxes($(".attendance-base-container input[type='checkbox']"), true)
    },
  })
}

function initializeViewMoreAttendancesActionable(actionableElement) {
  'use strict'

  $(actionableElement).on('click', function () {
    var $this = $(this)
    var $table = $(this).closest('.attendance-base-container').find('table')
    var $footer = $(this).closest('.attendance-base-container').find('.footer')
    var path = $this.data('path')

    $.ajax({
      type: 'GET',
      url: path,
      complete(data) {
        $table.append(data.responseJSON.table_data)
        $footer.html(data.responseJSON.footer)
        if (data.status === 200) {
          initializeViewMoreAttendancesActionable($footer.find('.view-more-results'))
          initializeAttendanceDelete()

          $('.attendance-base-container input.select-all[type=checkbox]:checked').prop('checked', false)
        } else {
          $('#global-alerts').append(data.responseText)
        }
      },
    })
  })
}

export function initializeAttendanceLogProgramSelector(selectedProgram) {
  'use strict'

  if ($('#student-programs-selector').length) {
    var programAttendanceContainerPath = $('.program-attendance-container').data('path')
    var initialSelectedProgram
    if (selectedProgram === undefined) {
      initialSelectedProgram = $('#student-programs-selector').find('.selected')[0].getAttribute('value')
    } else {
      initialSelectedProgram = selectedProgram
    }

    populateProgramAttendances(programAttendanceContainerPath, initialSelectedProgram)

    $('#student-programs-selector .select-option').on('click', function (e) {
      var target = e.target
      var programId = target.getAttribute('value')
      var isSelected = target.classList.contains('selected')

      // No need to load again if it's already selected.
      if (isSelected) return

      programAttendanceContainerPath = $('.program-attendance-container').data('path')

      // Remove any "selected" classes
      $('#student-programs-selector .select-option').each(function () {
        this.classList.remove('selected')
      })

      // Add "selected" back to just this element
      target.classList.add('selected')

      populateProgramAttendances(programAttendanceContainerPath, programId)
    })
  }
}

export function initializeStudentAttendanceLog(parent) {
  'use strict'

  if ($('.person-profile').length) {
    var $this = $(this)
    var path = $this.data('path')
    $.ajax({
      type: 'GET',
      url: path,
      complete() {
        initializeAttendanceLogProgramSelector()
      },
    })
  }

  $(parent)
    .find('a.student-attendance-log')
    .on('click', function () {
      var urlPath = $(this).data('path')
      $('#update-student-modal').html('<div class="spinner spinner-grow center" />')
      $('#update-student-modal').modal('show')
      $('#update-student-modal').on('hidden.bs.modal', function () {
        $('#update-student-modal').unbind()
      })

      $.ajax({
        type: 'GET',
        url: urlPath,
        complete(data) {
          $('#update-student-modal').html(data.responseText)
          initializeAdditionalInfoModalContent()
          initializeAttendanceLogProgramSelector()
        },
      })
    })
}

function initializeAttendanceDelete() {
  'use strict'

  var deleteButtonElement = $('.btn.delete-attendances')
  var path = $(deleteButtonElement).data('path')
  var programId = $('.student-attendance-log-items #student-programs-selector .selected').attr('value')

  $(deleteButtonElement).on('click', function () {
    var checkedAttendanceCheckboxes = $(
      '.program-attendance-container input.select-all-item[type=checkbox]:checked',
    )
    var selectedAttendanceIds = $(checkedAttendanceCheckboxes)
      .map(function () {
        return parseInt($(this).attr('id').replace('attendance_', ''))
      })
      .toArray()

    $.ajax({
      type: 'POST',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'))
      },
      data: {
        programId,
        attendanceIds: selectedAttendanceIds,
      },
      complete(data) {
        if (data.status !== 200) $('#global-alerts').append(data.responseText)

        return false
      },
    })

    return true
  })
}

function initializeStudentDelete(element) {
  'use strict'

  $(element).on('click', function (e) {
    e.preventDefault()
    var path = element.data('path')
    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      type: 'DELETE',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(data) {
        if (data.status === 400) {
          $('#update-student-modal').html('<div class="spinner spinner-grow center" />')
          $('#update-student-modal').modal('show')
          $('#update-student-modal').on('hidden.bs.modal', function () {
            $('#update-student-modal').unbind()
          })
          $('#update-student-modal').html(data.responseText)
          return false
        }
        $('#global-alerts').append(data.responseText)
        return false
      },
    })
    return true
  })
}

export function initializeStudentRestore(element) {
  'use strict'

  $(element).on('click', function (e) {
    e.preventDefault()
    var path = $(e.target).data('path')
    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      type: 'PUT',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
    })
    return true
  })
}

$(document).on('turbo:load', function () {
  'use strict'

  var cw = $('.profile-photo').width()
  $('.profile-photo').css({ height: `${cw}px` })

  $(window).resize(function () {
    var width = $('.profile-photo').width()
    $('.profile-photo').css({ height: `${width}px` })
  })

  initializeStudentStatusDropdowns($('#student-profile.panel.left-sidebar'))
  initializeStudentDelete($('#student-delete'))
})
