/* global loadLazyPartial */

function bindClickableDays() {
  'use strict'

  var binds = [
    '#prospects-calendar .simple-calendar .selectable-day',
    '#students-calendar .simple-calendar .selectable-day',
    '.dashboard-calendar .simple-calendar .selectable-day',
  ]
  $(binds.join(', ')).on('click', function (e) {
    e.preventDefault()

    var $this = $(this)
    var $target = $(this).closest('.lazy-load')
    var path = $this.data('path')

    loadLazyPartial(path, $target)
  })
}

function bindMonthTransitions() {
  'use strict'

  var binds = [
    '#prospects-calendar .simple-calendar a.next-view',
    '#prospects-calendar .simple-calendar a.previous-view',
    '#students-calendar .simple-calendar a.next-view',
    '#students-calendar .simple-calendar a.previous-view',
    '.dashboard-calendar .simple-calendar a.next-view',
    '.dashboard-calendar .simple-calendar a.previous-view',
  ]
  $(binds.join(', ')).on('click', function (e) {
    e.preventDefault()

    var $this = $(this)
    var $target = $(this).closest('.lazy-load')
    var path = $this.data('path')

    loadLazyPartial(path, $target, true, false)
  })
}

$(document).on('turbo:load', function () {
  'use strict'
  bindClickableDays()
  bindMonthTransitions()

  $('.lazy-load#prospects-calendar, .lazy-load#students-calendar, .lazy-load.dashboard-calendar').on(
    'lazy-load:complete',
    function () {
      bindClickableDays()
      bindMonthTransitions()
    },
  )
})
