import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sourceType', 'source']
  static values = { provider: String, submitBtnId: String, purpose: String }

  connect() {
    this.submitCallback = this.submit.bind(this)

    this.bindSubmit()
  }

  bindSubmit() {
    this.element.addEventListener('submit', this.submitCallback, true)
  }

  unbindSubmit() {
    this.element.removeEventListener('submit', this.submitCallback, true)
  }

  // eslint-disable-next-line complexity
  submit(event) {
    this.disableSubmit()
    if (
      this.sourceIsManual ||
      this.sourceIsCheck ||
      this.sourceIsAch ||
      this.weHaveAToken ||
      this.weHaveAVaultAndWeAreNotStripe ||
      this.weAreStripeAndHaveConfirmedSetupIntent ||
      this.weAreAStripeVaultThatHasBeenProcessed
    ) {
      return true
    }

    event.preventDefault()
    // This is pretty gross but Stripe is the only one that doesn't require actually making a payment to
    // save the card
    if (this.weAreStripe) {
      this.handleStripeSubmit(event)
    } else {
      this.handleNonStripeSubmit()
    }
  }

  //
  // PRIVATE
  //

  handleStripeSubmit(event) {
    if (this.purposeValue === 'pay') {
      this.setPaymentToken(this.sourceIsToken, this.sourceElement)
    } else {
      this.billingProviderController.saveCard(event, this.sourceElement)
    }
  }

  handleNonStripeSubmit() {
    this.setPaymentToken(this.sourceIsToken, this.sourceElement)
  }

  setPaymentToken(sourceIsToken, sourceElement) {
    this.billingProviderController.setPaymentToken(sourceIsToken, sourceElement).then((success) => {
      if (success) {
        this.element.requestSubmit()
      } else {
        // eslint-disable-next-line no-console
        console.log('tokenizer: tokenization failed')
        this.enableSubmit()
      }
    })
  }

  //
  // private
  //

  disableSubmit() {
    this.submitButton.disabled = true
  }

  enableSubmit() {
    this.submitButton.disabled = false
  }

  get sourceElement() {
    if (this.sourceTypeTarget.value === 'token') {
      return this.sourceTarget
    }
    return this.sourceTargets.filter((i) => i.checked)[0]
  }

  get submitButton() {
    return document.querySelector(`[form="${this.element.id}"]`)
  }

  get billingProviderController() {
    let controllerName = this.providerValue
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
    return this.application.getControllerForElementAndIdentifier(this.element, controllerName)
  }

  get sourceIsManual() {
    return this.sourceTypeTarget.value === 'manual'
  }

  get sourceIsCheck() {
    return this.sourceTypeTarget.value === 'check'
  }

  get sourceIsAch() {
    return this.sourceTypeTarget.value === 'ach'
  }

  get sourceIsVault() {
    return this.sourceTypeTarget.value === 'vault'
  }

  get sourceIsToken() {
    return this.sourceTypeTarget.value === 'token'
  }

  get weHaveAToken() {
    return this.sourceIsToken && this.hasSourceTarget && this.sourceTarget.value
  }

  get weAreStripe() {
    return this.providerValue === 'Stripe'
  }

  get weHaveAVaultAndWeAreNotStripe() {
    return this.sourceIsVault && this.providerValue !== 'Stripe'
  }

  get weAreStripeAndHaveConfirmedSetupIntent() {
    return this.weAreStripe && this.billingProviderController.confirmedValue
  }

  get weAreAStripeVaultThatHasBeenProcessed() {
    // We've already processed the submission once and confirmed the payment from a pm
    // back into a payment intent, so we gucci
    return this.weAreStripe && this.sourceIsVault && this.sourceElement.value.includes('::')
  }
}
