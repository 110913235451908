/* global App */
/* global initializeAttendanceLogProgramSelector */
/* global handleAttendanceModuleContextSwitching */

$(document).on('turbo:load', function () {
  'use strict'

  var schoolId = $('body.school').data('school-id')
  var userId = $('body.school').data('user-id')
  var isStudentsView = $('body').hasClass('students')
  var isStudentProfileView = $('body').hasClass('person-profile') && $('div[id=student]').length > 0
  const shouldSet =
    schoolId && userId && (isStudentsView || isStudentProfileView) && !App.attendances_delete_notifications

  if (shouldSet) {
    App.attendances_delete_notifications = App.cable.subscriptions.create(
      {
        channel: 'AttendancesDeleteNotificationsChannel',
        schoolId,
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel

          if (validId(data.user_id)) {
            if (data.updated_partial) {
              var $attendanceLogItems = $('.student-attendance-log-items')
              if ($attendanceLogItems.length) {
                var $attendanceLogItemsParent = $attendanceLogItems.parent()
                $attendanceLogItemsParent.html(data.updated_partial)

                initializeAttendanceLogProgramSelector(data.selected_program_id)
                handleAttendanceModuleContextSwitching()
              }
            }
            if (!data.error) {
              var bulkCheckbox = $('input#select-all')
              bulkCheckbox.prop('checked', false)
              bulkCheckbox.parent().find('.spinner.spinner-grow').remove()
              bulkCheckbox.show()
            }
            if (data.message) {
              if (data.notification_class) $(`.alert.${data.notification_class}`).hide()

              $('#global-alerts').append(data.message)
            }
          }
        },
      },
    )
  }
  function validId(id) {
    return id === undefined || (id > 0 && id === userId) || (id < 0 && Math.abs(id) !== userId)
  }
})
