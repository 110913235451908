import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['programs']

  connect() {
    if (this.hasProgramsTarget) {
      $(this.programsTarget).on('select2:select', this.updateNameSelector)
    }
  }

  disconnect() {
    if (this.hasProgramsTarget) {
      $(this.programsTarget).off()
    }
  }

  updateNameSelector() {
    const frame = document.querySelector('#name-selector')

    if (frame) {
      const program_id = this.value
      frame.src = `/calendar/class_events?program_id=${program_id}`
    }
  }
}
