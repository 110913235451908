/* global initializeDropdownTabs */
/* global initializeProgramSignupBehavior */
/* global initializeSchedulerBehavior */
/* global initializeTrialMembershipBehavior */
/* global initializeProspectOffWarning */
/* global enableApplyToPreviewButton */
/* global initializeInventoryItemCheckbox */
/* global handleProspectCreationToggle */
/* global initializeEmbedCodeModal */
/* global initializeCollapsibleModule */
/* global enableCreateProspectToggle */

export function initializeLandingPageModules(parent) {
  'use strict'
  $(parent)
    .find('.deselect-module')
    .click(function () {
      var $selectedModule = $(this).closest('.selected-module')
      var $keyField = $selectedModule.find('.selected-module-label input')

      var destroyFlag = $selectedModule.find('.delete')
      if (destroyFlag.length === 0) {
        $selectedModule.remove()
      } else {
        $selectedModule.addClass('hidden')
        destroyFlag.attr('value', 1)
      }

      $selectedModule.find('input[type=checkbox]').removeAttr('checked')

      // Could this not be $keyField.attr('value') ? value is header for the header, and they name is modules[header][key]
      var splittedString = $keyField.attr('name').split('[')

      if (splittedString.length > 1) {
        var key = splittedString[1].split(']')[0]
        const hasTrialMemberships = $selectedModule.find('#trial-membership-module-content').length > 0

        if (key === 'programs') {
          updateProgramModule($selectedModule)
        } else if (key === 'trial_memberships' && !hasTrialMemberships) {
          $('.module-options').find(`.module-option-programs`).removeClass('disabled')
        } else if (key === 'lead_capture_form') {
          if ($('#agreement-module-content').length > 0) {
            $('#agreement-module').find('.deselect-module').trigger('click')
          }
          $('.module-options').find(`.module-option-agreement`).addClass('disabled')
        }

        $('.module-options').find(`.module-option-${key}`).removeClass('disabled')
        enableApplyToPreviewButton()
        handleProspectCreationToggle()
      }
    })

  $('#landing-page-form input[type=radio]').click(function () {
    enableApplyToPreviewButton()
    handleProspectCreationToggle()
  })

  $('#landing-page-form input.landing-page-header-input').keyup(function () {
    enableApplyToPreviewButton()
  })

  handleProspectCreationToggle()
}

function updateProgramModule(programModule) {
  const hasScheduler = $('#scheduler-module').length > 0
  const hasPrograms = programModule.find('#program-module-content').length > 0
  if (hasScheduler) {
    $('#scheduler-module').find('.deselect-module').trigger('click')
  }
  // NOTE: This has to happen after clicking deselect, since deselecting it removes the disabled class.
  $('.module-options').find(`.module-option-scheduler`).addClass('disabled')

  if (hasPrograms) {
    $('.create-prospect-toggle-container .helper-text .either').addClass('hidden')
    $('.create-prospect-toggle-container .helper-text .prospect').removeClass('hidden')
    $('#prospect-creation-toggle').removeClass('disabled')
  } else {
    $('.module-options').find(`.module-option-trial_memberships`).removeClass('disabled')

    $('.create-prospect-toggle-container .helper-text .prospect').addClass('hidden')
    $('.create-prospect-toggle-container .helper-text .either').removeClass('hidden')
    enableCreateProspectToggle()
  }
}

function updateLandingPageStatus(container, path, button) {
  'use strict'

  button.attr('disabled', true)

  var token = $('meta[name="csrf-token"]').attr('content')
  $.ajax({
    type: 'PUT',
    url: path,
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    },
    complete(data) {
      if (data.status === 200) {
        container.fadeOut('fast', function () {
          container.html(data.responseText)
          initializeDropdownTabs(container)
          initializeLandingPageStatusDropdowns(container)

          container.fadeIn('fast')
        })
      } else {
        $('#global-alerts').append(data.responseText)
      }
    },
  })
}

function initializeLandingPageStatusDropdowns(parent) {
  'use strict'

  $(parent)
    .find('.landing-page-container .dropdown-tabs .dropdown-tabs-menu li a')
    .on('voltage:dropdown-tabs:selected', function () {
      var $this = $(this)
      var $target = $this.closest('.landing-page-wrapper')
      var path = $this.data('path')
      var $parentButton = $this.closest('.dropdown-tabs').find('button')

      return updateLandingPageStatus($target, path, $parentButton)
    })
}

function loadModulePartial(targetElement, moduleElement) {
  const path = moduleElement.data('path')
  $.ajax({
    type: 'GET',
    url: path,
    complete(data) {
      if (data.status === 200) {
        targetElement.append(data.responseText)
        var $lastField = $('#additional-sidebar').find('.selected-module').last()
        initializeLandingPageModules($lastField)
        initializeCollapsibleModule($lastField.find('.content'))
        enableApplyToPreviewButton()
        initializeDropdownTabs()
        initializeInventoryItemCheckbox()
        initializeTrialMembershipBehavior()
        initializeProgramSignupBehavior()
        initializeSchedulerBehavior()
        initializeProspectOffWarning()
        $lastField[0].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      } else {
        $('#global-alerts').append(data.responseText)
        moduleElement.removeClass('disabled')
      }
    },
  })
}

function initializeProgramModule() {
  $('.module-options').find(`.module-option-trial_memberships`).addClass('disabled')
  $('.module-options').find(`.module-option-scheduler`).removeClass('disabled')

  $('.create-prospect-toggle-container .helper-text .prospect').addClass('hidden')
  $('.create-prospect-toggle-container .helper-text .either').removeClass('hidden')
  enableCreateProspectToggle()
}

function initializeOtherModules(moduleName) {
  $('.create-prospect-toggle-container .helper-text .prospect').removeClass('hidden')
  $('.create-prospect-toggle-container .helper-text .either').addClass('hidden')
  $('#prospect-creation-toggle').parent().removeClass('disabled')

  if (moduleName === 'trial_memberships') {
    $('.module-options').find(`.module-option-programs`).addClass('disabled')
  } else if (moduleName === 'lead_capture_form') {
    $('.module-options').find('.module-option-agreement').removeClass('disabled')
  }
}

$(document).on('turbo:load', function () {
  'use strict'

  $('.lazy-load#landing-pages').on('lazy-load:complete', function () {
    initializeDropdownTabs($(this))
    initializeLandingPageStatusDropdowns($(this))
    initializeEmbedCodeModal()
  })

  initializeLandingPageStatusDropdowns($(this))

  initializeLandingPageModules($('#additional-sidebar'))

  $('.profit-item-dropdown').on('click', function () {
    initializeTrialMembershipBehavior()
    initializeProgramSignupBehavior()
    initializeSchedulerBehavior()
    initializeInventoryItemCheckbox()
  })

  document.addEventListener('trix-change', function () {
    if ($('#landing-page-form .apply-btn').length) enableApplyToPreviewButton()
  })

  $('.module-option').on('click', function () {
    if (!$(this).hasClass('disabled')) {
      var $this = $(this)
      var $target = $('#landing-page-form > .content')
      var moduleName = $(this).data('module-name')

      $this.addClass('disabled')

      if (moduleName === 'programs') {
        initializeProgramModule()
      } else {
        initializeOtherModules(moduleName)
      }

      if (moduleName === 'agreement') {
        if ($('#agreement-module').length > 0) {
          $('#agreement-module').removeClass('hidden')
        } else {
          loadModulePartial($target, $this)
        }
      } else if (moduleName === 'lead_capture_form') {
        if ($('#lead-capture-form-module').length > 0) {
          $('#lead-capture-form-module').removeClass('hidden')
        } else {
          loadModulePartial($target, $this)
        }
      } else {
        loadModulePartial($target, $this)
      }
    }
  })
})
