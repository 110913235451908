/* global initializeSelect2Fields */
/* global initializeButtonToggleFormFields */

// eslint-disable-next-line no-unused-vars
export function initializeAdditionalInfoModalContent() {
  'use strict'

  $(function () {
    initializeSelect2Fields()
    initializeButtonToggleFormFields()
  })
}
