import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'slide']

  left() {
    const tip = this.slideTarget
    const width = tip.clientWidth
    this.containerTarget.scrollLeft -= width
  }

  right() {
    const tip = this.slideTarget
    const width = tip.clientWidth
    this.containerTarget.scrollLeft += width
  }
}
