/* eslint-env jquery */

$(document).on('turbo:load', function () {
  'use strict'

  $('body').on('click', '.history-metadata-container .fa-plus', function () {
    $(this).hide()
    $(this).parent().find('.fa-minus').show()
    $(this).parent().find('.history-metadata-details').slideDown('fast')
  })

  $('body').on('click', '.history-metadata-container .fa-minus', function () {
    $(this).hide()
    $(this).parent().find('.fa-plus').show()
    $(this).parent().find('.history-metadata-details').slideUp('fast')
    $(this).parent().find('.history-metadata-details + .history-metadata-details').show()
  })

  $('body').on('click', '.view-more-metadata-details', function (e) {
    e.preventDefault()
    $(this).parent().parent().find('.history-event').removeClass('hidden')
    $(this).hide()
  })
})
