import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['issueDate', 'terms', 'dueDate', 'termsSpecified']

  calculate() {
    const theDate = new Date(this.issueDateTarget.value.replaceAll('-', '/'))
    theDate.setDate(theDate.getDate() + this.terms)

    const dueDate = theDate.toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    this.dueDateTarget.value = dueDate
  }

  get terms() {
    const index = this.termsTarget.options.selectedIndex
    let termsOffset = parseInt(this.termsTarget.options[index].value)

    // -1 if a Custom Term was selected
    if (termsOffset === -1) {
      termsOffset = parseInt(this.termsSpecifiedTarget.value) || 0
    }

    if (termsOffset < 1) {
      return termsOffset
    }
    return termsOffset - 1
  }
}
