/* global Pallette */
/* global Rails */
/* global Stripe */
/* global showLoadingIndicator */
/* global hideLoadingIndicator */
/* eslint-disable camelcase */
/* eslint-disable new-cap */

$(document).ready(stripeOnLoad)
$(document).on('lazy-load:complete', stripeOnLoad)

function stripeOnLoad() {
  'use strict'

  if ($('#stripe-card-element').length) {
    let FORM = $('#stripe-card-element').closest('form')[0]
    var STRIPE_PUBLIC_KEY = $('#stripe-account-token').data('public-key')
    var STRIPE_ACCOUNT_TOKEN = $('#stripe-account-token').data('account')

    var secondaryColor = Pallette.black
    if ($('#credit-card-token').data('colors-secondary-color').length) {
      secondaryColor = $('#credit-card-token').data('colors-secondary-color')
    }

    var tertiaryColor = Pallette.white
    if ($('#credit-card-token').data('colors-tertiary-color').length) {
      tertiaryColor = $('#credit-card-token').data('colors-tertiary-color')
    }

    var invalidColor = Pallette.red
    if ($('#credit-card-token').data('colors-secondary-color').length) {
      invalidColor = $('#credit-card-token').data('colors-secondary-color')
    }

    var stripe = Stripe(STRIPE_PUBLIC_KEY, { stripeAccount: STRIPE_ACCOUNT_TOKEN })
    var elements = stripe.elements()
    var card = elements.create('card', {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: secondaryColor,
          backgroundColor: tertiaryColor,
          color: secondaryColor,
          lineHeight: '36px',
          fontFamily: '"Hind Vadodara", sans-serif',
          fontSize: '19px',
          textTransform: 'uppercase',

          '::placeholder': { color: '#aeaeab' },
        },
        invalid: {
          iconColor: invalidColor,
          color: invalidColor,
        },
      },
    })
    card.mount('#stripe-card-element')

    card.addEventListener('change', function (event) {
      var displayError = document.getElementById('stripe-card-errors')
      if (event.error) displayError.textContent = event.error.message
      else displayError.textContent = ''
    })

    document.querySelector('#stripe-card-element').addEventListener('clear:stripe', () => {
      card.clear()
    })

    var $stripeSubmitButton = $('button[data-disable-with]')
    var STRIPE_FORM_SUBMIT_DISABLE_WITH_VALUE = $stripeSubmitButton.data('disable-with')
    var STRIPE_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE = $stripeSubmitButton.html()
    var STRIPE_CHARGE_FORM_READY_FOR_SUBMISSION = false
    $stripeSubmitButton.removeAttr('data-disable-with')
    $('#stripe-card-element')
      .closest('form')
      .on('submit', function () {
        if ($('#stripe-card-element').closest('.hidden').length) {
          return true
        }

        if ($('.StripeElement--empty').length) {
          $('.StripeElement--empty').addClass('StripeElement--invalid')
          return false
        }

        $stripeSubmitButton.attr('disabled', true)
        $stripeSubmitButton.html(STRIPE_FORM_SUBMIT_DISABLE_WITH_VALUE)

        showLoadingIndicator()

        return true
      })

    // NOTE: Second stage of stripe specific concerns needs to bind after all other binds to this form submit
    setTimeout(function () {
      $('#stripe-card-element')
        .closest('form')
        .on('submit', function (event) {
          if (STRIPE_CHARGE_FORM_READY_FOR_SUBMISSION) return true

          if (event.isDefaultPrevented()) {
            $stripeSubmitButton.attr('disabled', false)
            $stripeSubmitButton.html(STRIPE_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
            hideLoadingIndicator()

            return false
          }

          event.preventDefault()

          if ($('#stripe-card-element').closest('.hidden').length) {
            STRIPE_CHARGE_FORM_READY_FOR_SUBMISSION = true
            Rails.fire(FORM, 'submit')

            return false
          }

          if ($('.StripeElement--empty').length || $('.StripeElement--invalid').length) {
            $stripeSubmitButton.attr('disabled', false)
            $stripeSubmitButton.html(STRIPE_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
            hideLoadingIndicator()

            return false
          }

          $('input#credit-card-token').val('payment-intent-only')
          var $form = $(this)
          $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            beforeSend(xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'))
            },
            data: $form.serialize(),
            complete(data) {
              if (data.status === 200) {
                // NOTE: This is kinda hacky, but a 200 response should be handled by
                // landing_pages/new.js.haml or create.js.haml depending on what happened
              } else if (data.status === 402) {
                stripe
                  .confirmCardPayment(data.responseJSON.errors.messages.payment_intent_client_secret, {
                    payment_method: { card },
                    setup_future_usage: 'off_session',
                  })
                  .then(function (result) {
                    if (result.error) {
                      var errorElement = document.getElementById('stripe-card-errors')
                      errorElement.textContent = result.error.message
                      $stripeSubmitButton.attr('disabled', false)
                      $stripeSubmitButton.html(STRIPE_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
                      hideLoadingIndicator()
                    } else {
                      $('input#credit-card-token').val(result.paymentIntent.id)

                      STRIPE_CHARGE_FORM_READY_FOR_SUBMISSION = true
                      Rails.fire(FORM, 'submit')
                    }
                  })
              } else {
                $('#global-alerts').append(data.responseText)
              }
            },
          })

          return false
        })
    }, 500)
  }
}
