import {
  enableApplyToPreviewButton,
  removeDeletedElements,
  initializeEmbedCodeModal,
  initializeTemplateStartDatePicker,
  initializeDeleteMembershipTemplateCheckboxes,
  initializeCollapsibleModule,
  initializeCollapsibleModules,
  handleProspectCreationToggle,
  disableProfitItemOptions,
  enableProfitItemOptions,
  reloadPreview,
} from '../../assets/javascripts/school/bizbuilders.js'

window.enableApplyToPreviewButton = enableApplyToPreviewButton
window.removeDeletedElements = removeDeletedElements
window.initializeEmbedCodeModal = initializeEmbedCodeModal
window.initializeTemplateStartDatePicker = initializeTemplateStartDatePicker
window.initializeDeleteMembershipTemplateCheckboxes = initializeDeleteMembershipTemplateCheckboxes
window.initializeCollapsibleModule = initializeCollapsibleModule
window.initializeCollapsibleModules = initializeCollapsibleModules
window.handleProspectCreationToggle = handleProspectCreationToggle
window.disableProfitItemOptions = disableProfitItemOptions
window.enableProfitItemOptions = enableProfitItemOptions
window.reloadPreview = reloadPreview
