import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static targets = ['checkbox']
  static values = { disables: String, children: Array, childName: String, allText: String }

  initialize() {
    if (!this.hasChildrenValue) return

    this.buildOrDestroyChildren()
  }

  toggleCheckbox() {
    if (this.hasChildrenValue) {
      this.buildOrDestroyChildren()
    }

    if (!this.hasDisablesValue) return

    this.disableOthers()
  }

  ///
  /// private
  ///
  buildOrDestroyChildren() {
    if (this.checkboxTarget.checked) {
      this.buildChildren()
    } else {
      this.destroyChildren()
    }
  }

  buildChildren() {
    this.childrenValue.reverse().forEach((child) => {
      const newElement = this.element.cloneNode(true)

      newElement.id = `${this.childNameValue}_${child.id}`
      newElement.lastElementChild.innerText = child.name
      newElement.lastElementChild.classList.add('pl-4', 'border-l-2', 'border-primary')

      newElement.removeAttribute('data-controller')
      newElement.removeAttribute('data-filter-section-filter-children-value')

      const newCheckbox = newElement.firstElementChild

      newCheckbox.id = `${this.childNameValue}_${child.id}`
      newCheckbox.name = this.childNameValue
      newCheckbox.value = child.id
      newCheckbox.checked = this.currentURL.searchParams.get(this.childNameValue)?.includes(child.id)
      newCheckbox.setAttribute('data-filter-group-target', 'childCheckbox')
      newCheckbox.setAttribute('data-action', 'change->filter-group#toggleOneChild')

      this.element.insertAdjacentElement('afterend', newElement)
    })

    const allElement = this.element.cloneNode(true)
    allElement.lastElementChild.innerText = this.allTextValue
    allElement.lastElementChild.classList.add('pl-4', 'border-l-2', 'border-primary')

    allElement.id = `${this.childNameValue}_all`
    allElement.removeAttribute('data-controller')
    allElement.removeAttribute('data-filter-section-filter-children-value')

    const allCheckbox = allElement.firstElementChild

    allCheckbox.checked = !this.currentURL.searchParams.has(this.childNameValue)
    allCheckbox.setAttribute('data-filter-group-target', 'childAll')
    allCheckbox.setAttribute('data-action', 'change->filter-group#selectAllChildren')
    allCheckbox.setAttribute('data-filter-group-child-name-param', this.childNameValue)
    allCheckbox.removeAttribute('name')
    allCheckbox.removeAttribute('value')

    this.element.insertAdjacentElement('afterend', allElement)

    this.dispatch('built')
  }

  destroyChildren() {
    document.querySelector(`#${this.childNameValue}_all`)?.remove()

    this.childrenValue.forEach((child) => {
      const id = `${this.childNameValue}_${child.id}`
      document.querySelector(`#${id}`)?.remove()
    })
  }

  disableOthers() {
    const filterNamesToDisable = this.disablesValue.split(',')
    const filterGroupElement = this.element.closest('.filter-group')
    filterNamesToDisable.forEach((filterName) => {
      const filterToDisable = filterGroupElement.querySelector(`#${filterName}`)

      if (!filterToDisable) return

      filterToDisable.checked = false
    })
  }
}
