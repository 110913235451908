import { Controller } from '@hotwired/stimulus'
import { Pallette } from '../../../vendor/assets/javascripts/voltage-themes/pallette'
import { post } from '../helpers/fetch_helper'

export default class extends Controller {
  static values = { intent: String, confirmed: Boolean }
  static targets = ['savePaymentMethod', 'cardholderName']
  static outlets = ['searchable-person-field']

  saveCard(event, tokenTarget) {
    event.target.disabled = true

    this.confirmSetupIntent(tokenTarget).then((success) => {
      if (success) {
        this.element.closest('form').requestSubmit()
      } else {
        event.target.disabled = false
      }
    })
  }

  confirmSetupIntent(tokenTarget) {
    let card = this.card
    let opts = {}
    let cardholderName = this.cardholderNameTargets.map((t) => t.value).join(' ')

    opts.payment_method = {
      card,
      billing_details: {
        name: cardholderName,
      },
    }

    return this.stripe.confirmCardSetup(this.intentValue, opts).then((result) => {
      if (result.error) {
        var errorElement = document.getElementById('stripe-card-errors')
        errorElement.textContent = result.error.message
        return false
      }
      tokenTarget.value = result.setupIntent.id
      this.confirmedValue = true
      if (document.getElementById('input#credit-card-token')) {
        document.getElementById('input#credit-card-token').value = result.setupIntent.payment_method
      }
      return true
    })
  }

  // eslint-disable-next-line no-unused-vars
  setPaymentToken(tokenize, tokenTarget) {
    let card = this.card
    let opts = {}
    if (this.hasSavePaymentMethodTarget && this.savePaymentMethodTarget.checked) {
      opts.setup_future_usage = 'off_session'
    }

    if (tokenize) {
      opts.payment_method = { card }
    } else {
      // Token target is the vault ID if we're not tokenizing
      // TODO what if it's not set for some reason?
      opts.payment_method = tokenTarget.dataset.reference
    }

    return this.stripe.confirmCardPayment(this.intentValue, opts).then(function (result) {
      if (result.error) {
        let errorElement = document.getElementById('stripe-card-errors')
        if (!errorElement) {
          errorElement = document.getElementById('errors')
        }
        errorElement.textContent = result.error.message
        return false
      }

      // Token target is the vault ID if we're not tokenizing
      if (tokenize) {
        tokenTarget.value = result.paymentIntent.id
      } else {
        tokenTarget.value += `::${result.paymentIntent.id}`
      }

      return true
    })
  }

  connect() {
    const STRIPE_PUBLIC_KEY = document.getElementById('stripe-account-token').dataset.publicKey
    const STRIPE_ACCOUNT_TOKEN = document.getElementById('stripe-account-token').dataset.account

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(STRIPE_PUBLIC_KEY, { stripeAccount: STRIPE_ACCOUNT_TOKEN })

    if (document.getElementById('stripe-card-form')) {
      this.mountCard()
    }
  }

  generateSetupIntent({ currentTarget }) {
    const url = '/setup_intents'
    const payerId = currentTarget.value || currentTarget.dataset.payerId
    let payerType
    if (this.hasSearchablePersonFieldOutlet) {
      payerType = this.searchablePersonFieldOutlet.selectedTypeValue
    } else {
      payerType = currentTarget.dataset.payerType
    }

    post(url, { payer_id: payerId, payer_type: payerType })
      .then((response) => response.json())
      .then((data) => {
        this.intentValue = data.intent.client_secret
        this.cardholderNameTarget.value = data.payer_name
      })
  }

  mountCard() {
    // NOTE: This is dumb
    let secondaryColor = Pallette.black

    if (document.querySelector('body.dark')) {
      secondaryColor = Pallette.white
    }

    const invalidColor = Pallette.red

    let elements = this.stripe.elements()
    this.card = elements.create('card', {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: secondaryColor,
          color: secondaryColor,
          lineHeight: '36px',
          fontFamily: '"Hind Vadodara", sans-serif',
          fontSize: '19px',
          textTransform: 'uppercase',

          '::placeholder': { color: '#aeaeab' },
        },
        invalid: {
          iconColor: invalidColor,
          color: invalidColor,
        },
      },
    })
    this.card.mount('#stripe-card-form')

    this.card.addEventListener('change', function (event) {
      const displayError = document.getElementById('stripe-card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    document.querySelector('#stripe-card-form').addEventListener('clear:stripe', () => {
      this.card.clear()
    })
  }

  //
  // private
  //

  set intentValue(value) {
    this.data.set('intentValue', value)
  }

  set confirmedValue(value) {
    this.data.set('confirmedValue', value)
  }
}
