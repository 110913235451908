import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent', 'child']

  syncParent() {
    this.parentTarget.checked = this.childTargets.every((child) => child.checked)
  }

  syncChildren() {
    this.childTargets.forEach((child) => (child.checked = this.parentTarget.checked))
  }

  check() {
    this.parentTarget.checked = true
    this.childTargets.forEach((child) => (child.checked = true))
  }

  uncheck() {
    this.parentTarget.checked = false
    this.childTargets.forEach((child) => (child.checked = false))
  }
}
