import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { attendanceCount: Number, total: Number }
  static targets = ['input', 'more', 'total', 'expired']

  updateTotals() {
    if (this.inputTarget.disabled) {
      this.expiredTarget.classList.add('hidden')
      return
    }

    let value = Number(this.inputTarget.value)

    if (value < this.attendanceCountValue) {
      value = this.attendanceCountValue
    }

    this.totalValue = value

    if (!this.inputTarget.value) {
      this.expiredTarget.classList.add('hidden')
      this.moreTarget.parentElement.classList.remove('text-danger-600')
      return
    }

    if (this.moreValue > 0) {
      this.expiredTarget.classList.add('hidden')
      this.moreTarget.parentElement.classList.remove('text-danger-600')
    } else {
      this.expiredTarget.classList.remove('hidden')
      this.moreTarget.parentElement.classList.add('text-danger-600')
    }
  }

  totalValueChanged() {
    if (!this.hasTotalTarget) return
    if (!this.hasMoreTarget) return

    this.totalTarget.textContent = this.totalValue
    this.moreTarget.textContent = this.moreValue
  }

  get moreValue() {
    const value = this.totalValue - this.attendanceCountValue
    if (value < 0) return 0

    return value
  }
}
