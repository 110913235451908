/**
 * Grays out the selected select2 option if it has a '' value, typically ones labeled "None"
 *
 * @param {HTMLElement} element - The select2 element whose option will be deemphasized
 * @example
 *  grayOutTheNone(myElement)
 */
export function grayOutTheNone(element) {
  if ($(element).val() === '') {
    $(element.nextElementSibling).addClass('none-selected')
  } else {
    $(element.nextElementSibling).removeClass('none-selected')
  }
}
