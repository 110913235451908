(function($) {
  $.fn.gauge = function(value, options) {
    return this.each(function() {

      var settings = $.extend({
        min: 0,
        max: 100,
        unit: "%",
        color: "black",
        colorAlpha: 1,
        bgColor: "#222",
        fgColor: "lightgreen",
        fgSize: "",
        bgSize: "",
        fontSize: "",
        fontFamily: "",
        fontWeight: "normal",
        showDial: true,
        type: "default"
      }, options);

      settings.max = value > settings.max ? value : settings.max;

      //canvas initialization
      var ctx = this.getContext("2d");

      var W = this.width;
      var H = this.height;
      var centerW = (W/2);

      var position = 0;
      var new_position = 0;
      var difference = 0;

      var text;
      var animation_loop, redraw_loop;

      // Angle in radians = angle in degrees * PI / 180
      function radians(degrees) {
        return degrees * Math.PI / 180;
      }

      if (settings.type === "default") {
        (function() {
          function update() {
            ctx.clearRect(0, 0, W, H);

            // The gauge will be an arc
            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = settings.bgColor;
            ctx.lineWidth = parseInt(settings.bgSize) ? parseInt(settings.bgSize) : (W * 0.07);
            ctx.arc(centerW, H - (centerW - ctx.lineWidth), (centerW) - ctx.lineWidth, radians(135), radians(45), false);
            ctx.stroke();

            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = settings.fgColor;
            ctx.lineWidth = parseInt(settings.fgSize) ? parseInt(settings.fgSize) : (W * 0.07);

            if (position > 0) {
              ctx.globalAlpha = settings.colorAlpha;
              ctx.arc(centerW, H - (centerW - ctx.lineWidth), (centerW) - ctx.lineWidth, radians(135), radians(135 + position), false);
              ctx.stroke();
            }

            // Add the text
            if (settings.showDial) {
              ctx.fillStyle = settings.color;
              var fontArgs = ctx.font.split(' ');
              fontSize   = settings.fontSize.length ? settings.fontSize : (W*0.2);
              fontFamily = settings.fontFamily.length ? settings.fontFamily : fontArgs[fontArgs.length - 1];
              ctx.font   = "bold " + parseInt(fontSize) + 'px ' + fontFamily;
              text = value + settings.unit;

              // Center the text, deducting half of text width from position x
              text_width = ctx.measureText(text).width;
              ctx.fillText(text, centerW - text_width / 2, H - (centerW - ctx.lineWidth) + 15);
            }
          }

          function draw() {
            // Cancel any animation if a new chart is requested
            if (typeof animation_loop !== undefined) clearInterval(animation_loop);

            if (settings.noDraw)
              new_position = 0;
            else
              new_position = Math.round((value / (settings.max - settings.min)) * 270);

            if (settings.quickDraw)
              position = new_position;

            difference = new_position - position;
            animation_loop = setInterval(animate_to, 100 / difference);
          }

          // Make the chart move to new degrees
          function animate_to() {
            // Clear animation loop if degrees reaches the new_degrees
            if (position == new_position)
              clearInterval(animation_loop);

            if (position < new_position)
              position++;
            else
              position--;

            update();
          }

          draw();
        })();
      }

      if (settings.type === "halfcircle") {
        (function() {
          function update() {
            ctx.clearRect(0, 0, W, H);
              capH = H * 0.07;

            // The gauge will be an arc
            ctx.beginPath();
              ctx.lineCap = "round";
            ctx.strokeStyle = settings.bgColor;
            ctx.lineWidth = parseInt(settings.bgSize) ? parseInt(settings.bgSize) : (W * 0.07);
            ctx.arc(centerW, H - capH, (centerW) - ctx.lineWidth - ((W * 0.07) - ctx.lineWidth), radians(180), radians(0), false);
            ctx.stroke();

            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = settings.fgColor;
            ctx.lineWidth = parseInt(settings.fgSize) ? parseInt(settings.fgSize) : (W * 0.07);

            if (position > 0) {
              ctx.globalAlpha = settings.colorAlpha;
              ctx.arc(centerW, H - capH, (centerW) - ctx.lineWidth - ((W * 0.07) - ctx.lineWidth), radians(180), radians(180 + position), false);
              ctx.stroke();
            }

            // Add the text
            if (settings.showDial) {
              ctx.fillStyle = settings.color;
              var fontArgs = ctx.font.split(' ');
              fontSize   = settings.fontSize.length ? settings.fontSize : (W*0.2);
              fontFamily = settings.fontFamily.length ? settings.fontFamily : fontArgs[fontArgs.length - 1];
              ctx.font   = settings.fontWeight + " " + parseInt(fontSize) + 'px ' + fontFamily;
              text = value + settings.unit;
              // Center the text, deducting half of text width from position x
              text_width = ctx.measureText(text).width;
              ctx.fillText(text, centerW - text_width / 2, centerW/4*3 + 0.1*centerW );
            }
          }

          function draw() {
            // Cancel any animation if a new chart is requested
            if (typeof animation_loop !== undefined) clearInterval(animation_loop);

            if (settings.noDraw)
              new_position = 0;
            else
              new_position = Math.round((value / (settings.max - settings.min)) * 180);

            if (settings.quickDraw)
              position = new_position;

            difference = new_position - position;
            animation_loop = setInterval(animate_to, 100 / difference);
          }

          // Make the chart move to new degrees
          function animate_to() {
            // Clear animation loop if degrees reaches the new_degrees
            if (position == new_position)
              clearInterval(animation_loop);

            if (position < new_position)
              position++;
            else
              position--;

            update();
          }
          draw();
        })();
      }
    });
  };
})(jQuery);
