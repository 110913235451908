/* eslint-env jquery */

$(document).on('turbo:load', function () {
  'use strict'

  let $redirectIframeToggle = $('#redirect-within-iframe')

  $redirectIframeToggle.bootstrapToggle('destroy')
  $redirectIframeToggle.bootstrapToggle()
  $redirectIframeToggle.bootstrapToggle('enable')

  $('#redirect-url').on('keyup', function () {
    let form = document.querySelector('#landing-page-form')
    var hasInvalidURL =
      !$('#redirect-url')[0].checkValidity() || ($('#redirect-url').val() === '' && $('#redirect-toggle').checked)

    if (hasInvalidURL) {
      $('.redirect-url-container').addClass('input-danger')
      $('.redirect-feedback').removeClass('hidden')
      form.querySelector('button[type="submit"]').disabled = true
    } else {
      $('.redirect-url-container').removeClass('input-danger')
      $('.redirect-feedback').addClass('hidden')
      form.querySelector('button[type="submit"]').disabled = false
    }
  })
})
