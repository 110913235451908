import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'show', 'hide']

  showPassword() {
    this.inputTarget.type = 'text'
    this.showTarget.classList.add('hidden')
    this.hideTarget.classList.remove('hidden')
  }

  hidePassword() {
    this.inputTarget.type = 'password'
    this.showTarget.classList.remove('hidden')
    this.hideTarget.classList.add('hidden')
  }
}
