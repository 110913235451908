import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  selectClassEvent(event) {
    let calendarEvent = this.findCalEventFromTarget(event.target)
    this.setSelectedClassAttributes(calendarEvent.dataset)

    for (const item of document.querySelectorAll('.calendar-event')) {
      item.classList.remove('selected')
    }
    calendarEvent.classList.add('selected')
  }

  //
  // private
  //
  // TODO: This just does the same thing as event.currentTarget so it should get removed
  findCalEventFromTarget(target) {
    // depending on if the user clicks the time or the space around it
    if (target.hasAttribute('data-name')) {
      return target
    }

    return target.closest('.calendar-event')
  }

  // TODO: These should proabably be handled by targets rather than id selectors
  setSelectedClassAttributes(dataset) {
    document.querySelector('#selected-class-name').textContent = dataset.name
    document.querySelector('#selected-class-date').textContent = dataset.date
    document.querySelector('#selected-class-time').textContent = dataset.time

    if (dataset.description) {
      document.querySelector('#selected-class-desc').textContent = dataset.description
    }

    if (dataset.instructor) {
      document.querySelector('#selected-class-instructor').textContent = dataset.instructor
    } else {
      document.querySelector('#selected-class-instructor').textContent = ''
    }

    document.querySelector('#scheduler_params_selected_class_object_type').value = dataset.type
    document.querySelector('#scheduler_params_selected_class_object_id').value = dataset.id
    document.querySelector('#scheduler_params_selected_class_date').value = dataset.date

    $('.add-new button.btn.btn-primary').removeAttr('disabled')
    $('.add-new button.btn.btn-primary').removeClass('hidden')
  }
}
