import { Controller } from '@hotwired/stimulus'
import { initializeTooltips } from '../../../../vendor/assets/javascripts/voltage'
import {
  initializeStudentAttendanceLog,
  initializeStudentStatusDropdowns,
  initializeStudentRestore,
} from '../../../assets/javascripts/school/students'
import { initializeSelectAllCheckboxes } from '../../../assets/javascripts/forms'

// TODO: Remove this when the student list is un-bad-ified
export default class extends Controller {
  connect() {
    initializeTooltips($(this.element))
    initializeStudentStatusDropdowns($(this.element))
    initializeStudentAttendanceLog($(this.element))
    initializeSelectAllCheckboxes($("input.select-all-item[type='checkbox']"), true)
    initializeStudentRestore($('.student-restore'))
  }
}
