$(document).on('shown.bs.modal', '#ata-submission-modal', function () {
  $('#queue-for-submission').on('click', function () {
    var promoId = $('#ata-submission-modal').data('promotionId')
    var studentId = $('#ata-submission-modal').data('studentId')

    jQuery.ajax({
      type: 'PUT',
      url: `/students/${studentId}/promotions/${promoId}`,
      data: { promotion: { queued: true } },
      success() {
        $('#ata-submission-modal').modal('hide')
        $('#ata-submission-success').modal('show')
      },
    })
  })
})
