import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  start() {
    this.disabledInputs = Array.from(this.element.querySelectorAll('input:not([disabled])'))

    this.disabledInputs.forEach((input) => (input.disabled = true))
  }

  end() {
    this.disabledInputs?.forEach((input) => (input.disabled = false))
  }
}
