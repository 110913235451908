import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { path: String }

  refreshToggler(event) {
    const selected = event.target.innerText.toLowerCase()
    const frame = document.querySelector('#event_toggler')

    // These have to go to the new path, so that they can take the
    // turbo frame out of the response, and then put it into the dom.
    frame.src = `${this.pathValue}?selected=${selected}`
  }
}
