import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { computePosition, offset, flip, autoUpdate } from '@floating-ui/dom'
import { useClickOutside } from 'stimulus-use'
import { elementIsVisible } from '../../javascript/helpers/visibility_helper'

export default class extends Controller {
  static targets = ['body']
  static values = { hoverTime: { type: Number, default: 300 } }

  #timeoutId = null

  connect() {
    useClickOutside(this, { element: this.bodyTarget })
  }

  show({ target, params: { placement, id } }) {
    this.#timeoutId = setTimeout(() => this.#showBody(target, placement, id), this.hoverTimeValue)
  }

  hide() {
    if (!this.#timeoutId) return

    clearTimeout(this.#timeoutId)

    this.bodyTarget.hidden = true

    if (!this.cleanup) return

    this.cleanup()
  }

  clickOutside() {
    this.hide()
  }

  #showBody(target, placement, id) {
    Turbo.visit(`/people/${id}/hovercard`, { frame: 'person_hovercard' })

    addEventListener(
      'turbo:frame-render',
      () => {
        this.bodyTarget.src = null

        if (elementIsVisible(target)) {
          this.bodyTarget.hidden = false
        }

        this.cleanup = this.#positionBodyAnchoredTo(target, placement)
      },
      { once: true },
    )
  }

  #positionBodyAnchoredTo(element, placement) {
    return autoUpdate(element, this.bodyTarget, () => {
      computePosition(element, this.bodyTarget, {
        placement,
        middleware: [offset(8), flip()],
      }).then(({ x, y }) => {
        Object.assign(this.bodyTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        })
      })
    })
  }
}
