import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    title: String,
    newTitle: String,
    style: String,
    newStyle: String,
    delay: Number,
    immediate: Boolean,
  }

  connect() {
    if (this.immediateValue) {
      this.transition()
    }
  }

  transition() {
    this.style = this.newStyleValue
    this.title = this.newTitleValue

    setTimeout(() => {
      this.style = this.styleValue
      this.title = this.titleValue
    }, this.delayValue)
  }

  ///
  /// private
  ///

  set style(_style) {
    if (!this.hasNewStyleValue) return

    this.element.classList.remove(this.styleValue)
    this.element.classList.remove(this.newStyleValue)
    this.element.classList.add(_style)
  }

  set title(_title) {
    if (!this.hasNewTitleValue) return

    this.element.innerText = _title
  }
}
