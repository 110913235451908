import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'section', 'requiredIcon', 'requiredField']

  connect() {
    document.addEventListener('submit', this.submit.bind(this))
  }

  disconnect() {
    document.removeEventListener('submit', this.submit.bind(this))
  }

  submit(event) {
    if (this.requiredFieldTargets.length === 0 || this.requiredFieldTargets.every((item) => item.disabled)) return

    const anyChecked = this.requiredFieldTargets.some((item) => item.checked)
    if (anyChecked) {
      this.sectionTarget.classList.remove('border-2', 'border-danger-600', 'rounded-md')
      this.requiredIconTarget.setAttribute('hidden', 'hidden')
      this.requiredIconTarget.parentElement.classList.add('text-basic-700')
      this.requiredIconTarget.parentElement.classList.remove('text-primary-600')
    } else {
      this.sectionTarget.classList.add('border-2', 'border-danger-600', 'rounded-md')
      this.element.scrollIntoView({ behavior: 'smooth' })
      this.requiredIconTarget.removeAttribute('hidden')
      this.requiredIconTarget.parentElement.classList.remove('text-basic-700')
      this.requiredIconTarget.parentElement.classList.add('text-primary-600')
      event.preventDefault()
    }
  }
}
