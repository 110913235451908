/* global App */
/* global loadLazyPartial */

$(document).on('turbo:load', function () {
  'use strict'

  var schoolId = $('body.school').data('school-id')
  var userId = $('body.school').data('user-id')
  var isStudentProfileView = $('body').hasClass('person-profile') && $('div[id=student]').length > 0
  var isProspectProfileView = $('body').hasClass('person-profile') && $('div[id=prospect]').length > 0
  var onProfileView = isStudentProfileView || isProspectProfileView
  const shouldSet = schoolId && userId && onProfileView && !App.person_comment_delete_notifications

  if (shouldSet) {
    App.person_comment_delete_notifications = App.cable.subscriptions.create(
      {
        channel: 'PersonCommentDeleteNotificationsChannel',
        schoolId,
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel

          if (validId(data.user_id)) {
            if (data.reload) {
              var $commentsModule = $('#comments')
              if ($commentsModule.length) {
                var $this = $('#comments')
                var path = $this.data('path')

                loadLazyPartial(path, $this)
              }
            }

            if (data.message) {
              if (data.notification_class) $(`.alert.${data.notification_class}`).hide()

              $('#global-alerts').append(data.message)
            }
          }
        },
      },
    )
  }
  function validId(id) {
    return id === undefined || (id > 0 && id === userId) || (id < 0 && Math.abs(id) !== userId)
  }
})
