import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['backdateNotice']
  static outlets = ['flatpickr']

  dateChange() {
    const dateValue = this.flatpickrOutlet.fp.selectedDates[0]
    dateValue.setHours(0, 0, 0, 0)
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)

    if (new Date(dateValue) < currentDate) {
      this.backdateNoticeTarget.classList.remove('hidden')
    } else {
      this.backdateNoticeTarget.classList.add('hidden')
    }
  }
}
