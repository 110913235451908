import ApplicationController from './application_controller'

export default class extends ApplicationController {
  // There is a tacit assumption that the sumTarget is a MoneyField and the distributeToTargets
  // use the Money stimulus controller to allow for ease of use.
  static targets = ['sum', 'allSelector', 'selector', 'distributeTo', 'amountRemaining', 'clearedCheck']
  static values = { max: { type: Number, default: 0 }, start: { type: Number, default: 0 } }

  connect() {
    this.constructItems()
    this.sumTarget.parentElement.setAttribute('data-money-field--component-max-value', this.maxValue)
    this.sumValue = this.startValue

    if (!this.hasSelectorTarget) {
      for (const item of this.items) {
        item.select()
      }
    }

    this.updateAllSelected()
    this.distribute()
  }

  distribute() {
    const distributeSum = (totalRemaining, item) => {
      const remaining = totalRemaining - item.amount

      if (remaining > 0) {
        item.select()
        item.distributed = item.amount
        return remaining
      }

      const amount = remaining + item.amount

      if (amount > 0) {
        item.distributed = amount
      } else {
        item.distributed = 0
      }

      return 0
    }

    this.selectedItems.reduce(distributeSum, this.sumTarget.value)

    this.render()
  }

  select_all({ target }) {
    const checked = target.checked

    for (const element of this.selectorTargets) {
      element.checked = checked
      this.select({ target: element, render: false })
    }

    this.render()
  }

  select({ target, render = true }) {
    const checked = target.checked
    const item = this.items.find((i) => i.id === parseInt(target.dataset.id))

    if (checked) {
      item.select()

      this.resetSelectedItems()
    } else {
      item.unselect()
      item.distributed = 0

      this.allSelectorTarget.checked = false
    }

    this.updateAllSelected()
    this.updateSumAndMax()

    if (render) this.render()
  }

  ///
  /// private
  ///
  render() {
    for (const item of this.items) {
      if (!item.distributeToElement) continue

      item.distributeToElement.dataset.moneyAmountValue = item.distributed

      item.distributeToElement.classList.remove('text-kicksite-yellow-600')
      item.distributeToElement.classList.remove('text-success-400')
      item.distributeToElement.classList.remove('text-danger-600')
      item.clearedCheckElement.classList.remove('invisible')
      item.amountRemainingElement.classList.remove('line-through')

      if (item.cleared) {
        item.amountRemainingElement.classList.add('line-through')
        item.distributeToElement.classList.add('text-success-400')

        continue
      }

      item.clearedCheckElement.classList.add('invisible')

      if (!item.selected) continue

      if (item.distributed > 0) {
        item.distributeToElement.classList.add('text-kicksite-yellow-600')

        continue
      }

      item.distributeToElement.classList.add('text-danger-600')
    }
  }

  constructItems() {
    this.items = []
    for (const element of this.distributeToTargets) {
      const amount = parseFloat(element.dataset.amount) || 0
      const item = new Item(parseInt(element.dataset.id), false, amount)

      item.distributeToElement = element
      item.selectorElement = this.selectorTargets.find((sel) => sel.dataset.id === item.id.toString())
      item.amountRemainingElement = this.amountRemainingTargets.find(
        (amt) => amt.dataset.id === item.id.toString(),
      )
      item.clearedCheckElement = this.clearedCheckTargets.find((check) => check.dataset.id === item.id.toString())

      this.items.push(item)
    }
  }

  updateAllSelected() {
    if (!this.hasAllSelectorTarget) return

    const allSelected = this.items.every((i) => i.selected)

    this.allSelectorTarget.checked = allSelected
  }

  updateSumAndMax() {
    this.maxValue = this.selectedItems.reduce((total, i) => total + i.amount, 0)
    this.sumTarget.parentElement.setAttribute('data-money-field--component-max-value', this.maxValue)
    this.sumValue = this.selectedItems.reduce((total, i) => total + i.distributed, 0)
  }

  resetSelectedItems() {
    for (const selectedItem of this.selectedItems) {
      selectedItem.distributed = selectedItem.amount
    }
  }

  get selectedItems() {
    return this.items.filter((i) => i.selected)
  }

  get items() {
    return this._items
  }

  set items(value) {
    this._items = value
  }

  get sumValue() {
    return this._sum
  }

  set sumValue(value) {
    if (value < 0) value = 0
    if (value > this.maxValue) value = this.maxValue

    this.sumTarget.value = value
    this._sum = value
  }
}

class Item {
  constructor(id, selected, amount, distributed = 0) {
    this.id = id
    this.selected = selected || false
    this.amount = amount
    this._distributed = distributed
    this.cleared = false
  }

  select() {
    this.selected = true
  }

  unselect() {
    this.selected = false
  }

  get distributed() {
    return this._distributed
  }

  set distributed(value) {
    if (value >= this.amount) {
      this.cleared = true
    } else {
      this.cleared = false
    }

    this._distributed = value
  }

  get selectorElement() {
    return this._selectorElement
  }

  set selectorElement(value) {
    this._selectorElement = value

    if (!this._selectorElement) return

    this.selected = this._selectorElement.checked
  }

  get distributeToElement() {
    return this._distributedToElement
  }

  set distributeToElement(value) {
    this._distributedToElement = value
  }

  get amountRemainingElement() {
    return this._amountRemainingElement
  }

  set amountRemainingElement(value) {
    this._amountRemainingElement = value
  }

  get clearedCheckElement() {
    return this._clearedCheckElement
  }

  set clearedCheckElement(value) {
    this._clearedCheckElement = value
  }
}
