/* eslint-env jquery */
$(document).on('hidden.bs.modal', '#new-profile', function () {
  $('input[name=profile_type]').removeAttr('checked')
  $('.form-group.form-field.email').removeClass('hidden')
  $('.phone-toggle-container').removeClass('hidden')
})

$(document).on('click', '.add-new-profile', function () {
  $('#sidebar-container').removeClass('active')
})

$(document).on('mouseenter', '.add-new-profile', function () {
  $('.add-new-profile').addClass('active')
})

$(document).on('mouseleave', '.add-new-profile', function () {
  $('.add-new-profile').removeClass('active')
  $('#enable-sms').bootstrapToggle('off')
})

function openModalFromHash(hash) {
  'use strict'

  $(hash).on('shown.bs.modal', function () {
    $('#new-profile-name').focus()
    $(`${hash} form`).trigger('reset')
  })
}

;['turbo:load', 'hashchange'].forEach(function (e) {
  'use strict'

  window.addEventListener(e, function () {
    openModalFromHash('#new-profile')
  })
})

$(document).on('turbo:load', function () {
  'use strict'
  newProfileToggleBehavior()
})

function newProfileToggleBehavior() {
  'use strict'

  $('body').on('click', '.new-profile-prospect', function () {
    newProfileToggle().prospect()
  })

  $('body').on('click', '.new-profile-student', function () {
    newProfileToggle().student()
  })

  $('body').on('click', '.new-profile-family', function () {
    newProfileToggle().family()
  })
}

function newProfileToggle() {
  'use strict'

  var behavior = {
    prospect() {
      $('.form-group.form-field.email').removeClass('hidden')
      $('.phone-toggle-container').removeClass('hidden')
    },
    student() {
      $('.form-group.form-field.email').removeClass('hidden')
      $('.phone-toggle-container').removeClass('hidden')
    },
    family() {
      $('.form-group.form-field.email').addClass('hidden')
      $('.phone-toggle-container').addClass('hidden')
    },
  }

  return behavior
}
