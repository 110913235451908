import { Controller } from '@hotwired/stimulus'
import { post } from '../helpers/fetch_helper'

export default class extends Controller {
  static values = { section: String, path: String }

  connect() {
    let user_agent = window.navigator.userAgent
    let mobile = window.navigator.standalone

    post('/beacon_transmissions', {
      beacon_transmission: {
        section: this.sectionValue,
        path: this.pathValue,
        user_agent,
        mobile,
      },
    })

    this.element.remove()
  }
}
