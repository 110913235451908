import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = {
    placeholder: String,
    polymorphic: Boolean,
    selectedType: { type: String, default: 'Person' },
  }

  initialize() {
    this.tomSelect = new TomSelect(this.element, {
      search: false,
      persist: false,
      highlight: false,
      addPrecedence: true,
      selectOnTab: true,
      valueField: 'id',
      labelField: 'text',
      placeholder: this.placeholderValue,
      render: {
        item: this.#renderItem.bind(this),
        option: this.#renderOption,
        no_results: null,
      },
    })
  }

  updateType(pluralType) {
    if (pluralType === 'families') {
      this.#type = 'Family'
      return
    }

    this.#type = 'Person'
  }

  set #type(value) {
    this.selectedTypeValue = value
    this.element.nextElementSibling.nextElementSibling.value = value
  }

  #renderItem(item, escape) {
    if (this.polymorphicValue) {
      this.updateType(item.type)
    }

    return `<span class="flex gap-1 items-center">
              <img src="${escape(item.image)}" class="employee-image !w-4 !h-4" />
              ${escape(item.text)}
            </span>`
  }

  #renderOption(item, escape) {
    return `<span class="flex gap-2 items-center">
              <img src="${escape(item.image)}" class="employee-image !w-10 !h-10" />
              ${escape(item.text)}
            </span>`
  }
}
