$(document).on('turbo:load', onLoad)

function onLoad() {
  'use strict'

  initializeTooltips()
}

/* eslint-disable no-unused-vars */
export function hideLoadingIndicator() {
  $('.loading-indicator').addClass('hidden')
  $('.dim-screen').addClass('hidden')
}

export function showLoadingIndicator() {
  $('.loading-indicator').removeClass('hidden')
  $('.dim-screen').removeClass('hidden')
}
/* eslint-enable no-unused-vars */

// Not actually sure if this actually enables any tooltips?
function initializeTooltips(parent) {
  var selector = '[data-toggle="tooltip"]'
  var $elements
  if (parent === undefined) $elements = $(selector)
  else $elements = $(parent).find(selector)

  $elements.tooltip({
    html: true,
    delay: {
      show: 250,
      hide: 100,
    },
  })
}
