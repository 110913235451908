import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
export default class extends Controller {
  static targets = ['categoryFilter']
  static values = {
    frame: String,
    selectedFilters: Object,
    path: String,
  }

  initialize() {
    this.initializeSelectedFilters()
  }

  handleCheck(e) {
    if (e.target.checked) {
      this.addToFilters(e.params)
    } else {
      this.removeFromFilters(e.params)
    }
  }

  filter() {
    const url = this.hasPathValue ? new URL(this.pathValue) : new URL(window.location)

    if (Object.keys(this.selectedFiltersValue).length) {
      let searchParams = new URLSearchParams(this.selectedFiltersValue).toString()
      url.search = searchParams
    }

    if (this.hasFrameValue) {
      const frame = document.querySelector(`#${this.frameValue}`)
      frame.src = url
    } else {
      Turbo.cache.clear()
      Turbo.visit(url)
    }
  }

  clear() {
    const url = `${window.location.pathname}`

    Turbo.cache.clear()
    Turbo.visit(url)
  }

  ///
  /// private
  ///
  initializeSelectedFilters() {
    for (let i = 0; i < this.checkedCategoryTargets.length; i++) {
      let dataset = this.checkedCategoryTargets[i].dataset
      this.addToFilters({
        category: dataset['filter-ComponentCategoryParam'],
        value: dataset['filter-ComponentValueParam'],
      })
    }
  }

  addToFilters(params) {
    let currentSelectedFilters = this.selectedFiltersValue
    if (params.category in currentSelectedFilters) {
      currentSelectedFilters[params.category].push(params.value.toString())
    } else {
      currentSelectedFilters[params.category] = [params.value.toString()]
    }

    this.selectedFiltersValue = currentSelectedFilters
  }

  removeFromFilters(params) {
    let currentSelectedFilters = this.selectedFiltersValue
    let category = currentSelectedFilters[params.category]
    category = category.filter((item) => item !== params.value.toString())
    if (category.length === 0) {
      delete currentSelectedFilters[params.category]
    } else {
      currentSelectedFilters[params.category] = category
    }

    this.selectedFiltersValue = currentSelectedFilters
  }

  get checkedCategoryTargets() {
    return this.categoryFilterTargets.filter((target) => target.checked)
  }
}
