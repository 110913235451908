/* global addCountToCounter */
/* global enableApplyToPreviewButton */

export function activateApplyButtonWhenProgramChecked() {
  'use strict'
  $('.program-item-check').off()
  $('.program-item-check').on('click', function () {
    if ($(this).is(':checked')) {
      $(this).siblings('.delete').attr('value', 0)
    } else {
      $(this).siblings('.delete').attr('value', 1)
    }

    enableApplyToPreviewButton()
    addCountToCounter('program')
  })
}

// eslint-disable-next-line no-unused-vars
export function initializeProgramSignupBehavior() {
  'use strict'

  activateApplyButtonWhenProgramChecked()
}
