import { Controller } from '@hotwired/stimulus'
import Fuse from 'fuse.js'

export default class extends Controller {
  static targets = ['item', 'field']

  search() {
    const value = this.fieldTarget.value

    if (!value) {
      this.reset()

      return
    }

    const fuse = new Fuse(this.#fuseList, { includeScore: true, keys: [{ name: 'title' }] })

    const results = fuse.search(value).filter((result) => result.score <= 0.5)

    this.itemTargets.forEach((target) => (target.hidden = true))

    results.forEach(({ item: { element } }) => (element.hidden = false))
  }

  reset() {
    this.fieldTarget.value = ''
    this.itemTargets.forEach((target) => (target.hidden = false))
  }

  get #fuseList() {
    return this.itemTargets.map((target) => ({
      title: target.dataset.value || target.textContent,
      element: target,
    }))
  }
}
