import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['label', 'toggle']
  static values = { url: String }

  refreshFrame({ target: { form } }) {
    const params = new URLSearchParams(new FormData(form))
    params.delete('utf8')
    params.delete('authenticity_token')
    const src = `${this.urlValue}?${params.toString()}`

    Turbo.visit(src, { frame: 'ranks_programs' })
  }

  handleQueue(event) {
    if (!this.haslabelTarget) return

    const selectedOption = event.target.options[event.target.selectedIndex]
    const queueable = JSON.parse(selectedOption.dataset.queueValue)
    if (queueable) {
      this.labelTarget.classList.remove('hidden')
      this.toggleTarget.classList.remove('hidden')
      this.toggleTarget.disabled = false
    } else {
      this.labelTarget.classList.add('hidden')
      this.toggleTarget.classList.add('hidden')
      this.toggleTarget.disabled = true
    }
  }
}
