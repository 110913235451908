/* eslint-env jquery */

/* global enableApplyToPreviewButton */
/* global addCountToCounter */

export function initializeInventoryItemCheckbox() {
  'use strict'
  $('.inventory-item-check').off()
  $('.inventory-item-check').on('click', function () {
    enableApplyToPreviewButton()
    addCountToCounter('inventory')
  })
}

$(document).on('turbo:load lazy-load:complete ready', function () {
  'use strict'
  addCountToCounter('inventory')
  $('.inventory-item-check').unbind('click')
  initializeInventoryItemCheckbox()
})
