/* eslint-env jquery */

function handleLazyLoadResponse(target, data) {
  'use strict'

  target.html(data.responseText)
  if (data.status === 200) {
    loadLazyPartials(target)
    target.find('.more-results-footer .lazy-load-more-results').on('click', function () {
      var path = $(this).data('path')
      loadLazyPartial(path, $(this).closest('.more-results-footer'), false)
    })
    target.addClass('lazy-loaded')
    target.removeClass('lazy-loading')
    target.trigger('lazy-load:complete')
  }
}

export function loadLazyPartial(path, target, isReload, fadeIn) {
  'use strict'

  target.addClass('lazy-loading')

  if (isReload === undefined) isReload = true

  if (fadeIn === undefined) fadeIn = isReload

  var request = $.ajax({
    type: 'GET',
    url: path,
    dataType: 'html',
    complete(data) {
      if (fadeIn) {
        target.fadeOut('fast', function () {
          handleLazyLoadResponse(target, data)
          target.fadeIn('fast')
        })
      } else {
        handleLazyLoadResponse(target, data)
      }
    },
  })

  if (isReload && target.find('.loading-small').length > 0) {
    setTimeout(function () {
      if (request.readyState !== 0 && request.readyState !== 4) {
        target.find('.loading-small').hide()
        target.find('.loading-small').removeClass('hidden')
        target.find('.loading-small').fadeIn('fast')
      }
    }, 2000)
  }

  return request
}

function loadLazyPartials(parent) {
  'use strict'

  var $containers = []
  if (parent === undefined) $containers = $('.lazy-load:not(.lazy-loading):not(.lazy-loaded)')
  else $containers = $(parent).find('.lazy-load:not(.lazy-loading):not(.lazy-loaded)')

  $containers.each(function () {
    var $this = $(this)
    if ($this.find('.auto-results').length && $this.find('.auto-results').css('display') === 'none') {
      // why would you have a noop branch...
    } else {
      var path = $this.data('path')
      loadLazyPartial(path, $this, false)
    }
  })
}

$(document).on('turbo:load', function () {
  'use strict'

  loadLazyPartials()

  window.onscroll = function () {
    loadLazyPartials()
  }
})
