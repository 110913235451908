import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fieldset', 'body', 'clearableSelect']

  add() {
    const newFieldset = this.fieldset.cloneNode(true)
    const newInputs = newFieldset.querySelectorAll('input')
    const newSelects = newFieldset.querySelectorAll('select')

    for (const input of [...newInputs, ...newSelects]) {
      input.name = input.name.replace(/\d+/, this.number)

      if (input.getAttribute('data-duplicate-fieldset-ignore') !== null) continue

      // This might not work in 100% of cases, but for the most part it should, but it clears errors and adjust labels
      this.adjustFor(input)
      this.removeError(input)
      this.resetValue(input)

      if (input.getAttribute('data-duplicate-fieldset-default') !== null) {
        input.value = input.dataset.duplicateFieldsetDefault
      }
    }

    this.cleanSelects(newFieldset)
    this.bodyTarget.appendChild(newFieldset)

    for (const select of this.clearableSelectTargets) {
      select.lastChild.remove()
    }
  }

  ///
  /// private
  ///

  adjustFor(input) {
    const id = input.getAttribute('id')
    if (!id) return

    // This ensuredly just doesn't work for ToggleComponents, but we'll cross that bridge when we get there
    const label = input.closest('label')

    if (!label) return

    const { newId } = this

    label.setAttribute('for', newId)
    input.id = newId
  }

  removeError(input) {
    const nextSibling = input.nextElementSibling
    if (nextSibling?.classList?.contains('text-danger-600') && input.classList.contains('input-danger')) {
      nextSibling.remove()
    }
    input.classList.remove('input-danger')
  }

  // This surely will need more logic to handle more complex cases, but I can't think of any right now
  resetValue(input) {
    const type = input.type

    if (type === 'checkbox' && (input.name.includes('_destroy') || input.dataset.clearable)) {
      input.checked = false
    } else {
      input.value = ''
    }

    if (input.getAttribute('data-duplicate-fieldset-disabled') === null) {
      input.classList.remove('disabled')
      input.removeAttribute('readonly')
    }
  }

  cleanSelects(newFieldset) {
    const wrappers = newFieldset.querySelectorAll('.ts-wrapper')
    for (const wrapper of wrappers) {
      wrapper.remove()
    }
    const selects = newFieldset.querySelectorAll('select')
    for (const select of selects) {
      select.classList.remove('tomselected', 'ts-hidden-accessible')
    }
  }

  get fieldset() {
    // Grabs the last one in case ordering matters
    return this.fieldsetTargets[this.fieldsetTargets.length - 1]
  }

  get number() {
    return this.fieldsetTargets.length
  }

  get newId() {
    return Math.random().toString(16).substring(2, 12)
  }
}
