import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['processPayment', 'processPaymentBtn']
  static values = { submitPayment: Boolean }

  connect() {
    this.form = this.element
    this.submitFooter = this.form.querySelector('.footer')

    if (!this.submitPaymentValue) {
      this.processPaymentBtnTarget.classList.add('hidden')
    }
  }

  submitPayment() {
    this.submitPaymentValue = true
    this.processPaymentBtnTarget.classList.remove('hidden')
    this.submitFooter.classList.add('hidden')
  }

  noPayment() {
    this.submitPaymentValue = false
    this.processPaymentBtnTarget.classList.add('hidden')
    this.submitFooter.classList.remove('hidden')
  }

  paymentProcessingComponent() {
    return this.application.getControllerForElementAndIdentifier(
      this.processPaymentTarget,
      'payment-methods--component',
    )
  }
}
