import { Turbo } from '@hotwired/turbo-rails'
import { escape } from '../../javascript/helpers/html_helper'

// TODO: This could use a hefty refactor, it's getting a bit out of hand
// Basically all of the stuff should just be set on the trigger honestly
export function setupConfirmations() {
  Turbo.setConfirmMethod((message, element) => {
    const confirmationModal = document.querySelector('#confirmation-modal')
    const tooltips = document.querySelectorAll('.tooltip')
    const modalBody = confirmationModal.querySelector('.modal-body')
    const trigger = document.querySelector(`[data-turbo-confirm="${escape(message)}"]`)
    const titleOverride = trigger?.dataset.turboConfirmTitle
    const title = titleOverride || element.dataset.turboConfirmTitle
    const acceptText = element.dataset.turboConfirmAccept
    const cancelText = element.dataset.turboConfirmDeny
    const acceptStyle = element.dataset.turboConfirmAcceptStyle
    const acceptData = JSON.parse(trigger?.dataset.turboConfirmAcceptData || '{}')
    const denyData = JSON.parse(trigger?.dataset.turboConfirmDenyData || '{}')
    const denyStyle = element.dataset.turboConfirmDenyStyle
    const thingToDelete = element.dataset.turboConfirmDelete
    const thingToDeleteElement = modalBody.querySelector('.thing-to-delete')

    for (const tooltip of [...tooltips]) {
      tooltip.remove()
    }

    setModalSize(confirmationModal, element.dataset.turboConfirmSize)

    modalBody.querySelector('.message').innerHTML = message

    toggleThingToDelete(thingToDelete, thingToDeleteElement)

    setModalText(confirmationModal, title, acceptText, cancelText)
    setModalAttributes(confirmationModal, acceptStyle, denyStyle, acceptData, denyData)

    confirmationModal.classList.add('open')

    return new Promise((resolve) => {
      confirmationModal.addEventListener('modal:hidden', ({ detail }) => {
        resolve(detail.finished)
      })
    })
  })
}

function toggleThingToDelete(thingToDelete, thingToDeleteElement) {
  if (thingToDelete) {
    thingToDeleteElement.classList.remove('hidden')
    thingToDeleteElement.innerHTML = thingToDelete
  } else {
    thingToDeleteElement.innerHTML = ''
    thingToDeleteElement.classList.add('hidden')
  }
}

function setModalText(confirmationModal, title, acceptText, cancelText) {
  if (title) confirmationModal.querySelector('.modal-title').innerText = title
  if (acceptText) confirmationModal.querySelector('#confirmation-modal-accept').innerText = acceptText
  if (cancelText) confirmationModal.querySelector('#confirmation-modal-deny').innerText = cancelText
}

function setModalAttributes(confirmationModal, acceptStyle, denyStyle, acceptData, denyData) {
  const acceptButton = confirmationModal.querySelector('#confirmation-modal-accept')
  const denyButton = confirmationModal.querySelector('#confirmation-modal-deny')

  if (acceptStyle) {
    acceptButton.classList.remove(...acceptButton.classList)
    acceptButton.classList.add('btn')
    acceptButton.classList.add(`btn-${acceptStyle}`)
  }
  if (denyStyle) {
    denyButton.classList.remove(...denyButton.classList)
    denyButton.classList.add('btn')
    denyButton.classList.add(`btn-${denyStyle}`)
  }

  acceptButton.dataset.action = 'click->modal--component#confirm'
  denyButton.dataset.action = 'click->modal--component#hide'

  for (const [key, value] of Object.entries(acceptData)) {
    if (key === 'action') {
      acceptButton.dataset.action += ` ${value}`
    } else {
      acceptButton.setAttribute(`data-${key.replace(/_/g, '-')}`, value)
    }
  }

  for (const [key, value] of Object.entries(denyData)) {
    if (key === 'action') {
      denyButton.dataset.action += ` ${value}`
    } else {
      denyButton.setAttribute(`data-${key.replace(/_/g, '-')}`, value)
    }
  }
}

function setModalSize(confirmationModal, size) {
  confirmationModal.querySelector('.modal-dialog').classList.add(`modal-${size}`)
}
