$(document).on('turbo:load', function () {
  'use strict'

  let $confirmationEmailToggle = $('#confirmation-email-toggle')

  $confirmationEmailToggle.bootstrapToggle('destroy')
  $confirmationEmailToggle.bootstrapToggle()
  $confirmationEmailToggle.bootstrapToggle('enable')

  $confirmationEmailToggle.change(function () {
    if (this.checked) {
      $('.confirmation-email-container').removeClass('hidden')
    } else {
      $('.confirmation-email-container').addClass('hidden')
    }
  })
})
