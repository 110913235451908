import { Sortable, OnSpill, AutoScroll } from 'sortablejs/modular/sortable.core.esm'
Sortable.mount(OnSpill)
Sortable.mount(AutoScroll) // This only applies to old browsers, no clue if it works.

/**
 * Creates an instance of a Sortable with the options
 *
 * @param {HTMLElement} element - The element to make sortable
 * @param {Object} options - sortablejs options to merge with the defaults
 * @return {Sortable} The sortable instance on the element.
 * @example
 *  const sortable = createSortable(document.querySelector('.sortable'), { animation: 200 })
 */
export function createSortable(element, options = {}) {
  options = {
    ghostClass: 'sortable-placeholder',
    easing: 'cubic-bezier(0.83, 0, 0.17, 1)',
    filter: '.sortable-ignore',
    dragoverBubble: false,
    scroll: true,
    revertOnSpill: true,
    animation: 125,
    ...options,
  }

  return Sortable.create(element, options)
}
