function profilePhotoUploader() {
  'use strict'

  $('.uploadable-photo').on('click', function () {
    $('.uploadable-photo-input').trigger('click')
  })

  function putFile(e) {
    var file = null

    var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer)
    var fileList = e.target.files || (dt && dt.files)

    for (var i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i]
        break
      }
    }

    var formData = new FormData()
    formData.append('photo', file)

    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      url: $('.uploadable-photo-container').data().path,
      enctype: 'multipart/form-data',
      method: 'PUT',
      data: formData,
      processData: false,
      contentType: false,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
    }).done((data) => {
      $('.profile-photo-container').html(data)
      profilePhotoUploader()

      const cw = $('.profile-photo').width()
      $('.profile-photo').css({ height: `${cw}px` })

      $(window).resize(() => {
        const width = $('.profile-photo').width()
        $('.profile-photo').css({ height: `${width}px` })
      })
    })
  }

  $('.uploadable-photo-input').on('change', function (e) {
    putFile(e)
  })

  $('.uploadable-photo').on('drop', function (e) {
    e.stopPropagation()
    e.preventDefault()

    putFile(e)
  })

  $('.uploadable-photo-container').on('dragover', function (e) {
    e.stopPropagation()
    e.preventDefault()

    e.dataTransfer.dropEffect = 'copy'
  })

  $('.uploadable-photo-container').on('dragenter', function (e) {
    e.stopPropagation()
    e.preventDefault()
  })
}

$(document).on('turbo:load', function () {
  'use strict'
  profilePhotoUploader()
})
