import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['count']

  toggle({ detail: { collapsed } }) {
    if (!this.hasCountTarget) {
      return
    }

    if (collapsed) {
      this.countTarget.classList.add('hidden')
    } else {
      this.countTarget.classList.remove('hidden')
    }
  }
}
