import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['frame']
  static values = { on: String, off: String, id: String }

  toggle(event) {
    Promise.all(event.target.getAnimations().map((animation) => animation.finished)).then(() => {
      this.frame.src = this.isOn ? this.offValue : this.onValue
    })
  }

  ///
  /// private
  ///
  get isOn() {
    return !this.frame.src || this.frame.src.includes(this.onValue)
  }

  get frame() {
    if (this.hasFrameTarget) {
      return this.frameTarget
    }

    if (this.hasIdValue) {
      return document.querySelector(`#${this.idValue}`)
    }

    return this.element
  }
}
