import { Controller } from '@hotwired/stimulus'
import { post } from '../../helpers/fetch_helper'

export default class extends Controller {
  static values = { serviceFee: String }
  static targets = [
    'checkout',
    'hiddenTotalField',
    'creditCardContainer',
    'submissionIsInvoiced',
    'noPaymentRequired',
    'additionalCharges',
    'taxTotal',
    'taxLineItem',
    'subtotal',
    'total',
    'serviceFee',
    'serviceFeeLineItem',
    'inventoryItem',
  ]

  connect() {
    this.rawCart = new Set()

    // Since these aren't exclusively used by LPs its weird to have them
    // as targets, but it's nice to have reference to them.
    this.stripeCardElement = document.querySelector('#stripe-card-element')
    this.usaEpayCardElement = document.querySelector('#usa-epay-card-element')
    this.alertsElement = document.querySelector('#global-alerts')
  }

  addToCart(event) {
    const cartDataArr = JSON.parse(event.currentTarget.getAttribute('cartData'))

    // Kinda stupid but I didnt know about stimulus values when I wrote this controller ig
    // so it does some dumbassery
    for (const cartData of cartDataArr) {
      this.rawCart.add(JSON.stringify(cartData))
    }

    this.setCheckoutContent()
  }

  toggleFromCart(event) {
    const cartData = event.currentTarget.getAttribute('cartData')
    if (event.currentTarget.checked) {
      this.rawCart.add(cartData)
    } else {
      this.rawCart.delete(cartData)
    }

    this.setCheckoutContent()
  }

  toggleOneFromCart(event) {
    const cartDataArr = JSON.parse(event.currentTarget.getAttribute('cartData'))
    if (this.previousSingleCartData) {
      for (const cartData of this.previousSingleCartData) {
        this.rawCart.delete(JSON.stringify(cartData))
      }
    }

    // Kinda stupid but I didnt know about stimulus values when I wrote this controller ig
    // so it does some dumbassery
    for (const cartData of cartDataArr) {
      this.rawCart.add(JSON.stringify(cartData))
    }
    this.previousSingleCartData = cartDataArr

    this.setCheckoutContent()
  }

  clearCart() {
    this.rawCart.clear()
    this.setCheckoutContent()
    this.clearInventoryItems()
  }

  setOrderTotal() {
    const subtotal = [...this.cart].reduce((acc, item) => acc + item.price, 0)

    this.hiddenTotalFieldTarget.value = subtotal

    if (subtotal > 0) {
      if (this.hasCreditCardContainerTarget) {
        this.creditCardContainerTarget.classList.remove('hidden')
        this.hasNoPaymentRequiredTarget && this.noPaymentRequiredTarget.classList.add('hidden')
      } else {
        this.submissionIsInvoicedTarget.classList.remove('hidden')
      }
    } else if (this.hasCreditCardContainerTarget) {
      this.hideCreditCardInput()
    } else {
      this.submissionIsInvoicedTarget.classList.add('hidden')
    }

    const items = [...this.cart].map((item) => ({ type: item.type, id: item.id }))
    const itemQueryString = new URLSearchParams({ items: JSON.stringify(items) })
    post(`${this.hiddenTotalFieldTarget.getAttribute('data-calculation-path')}?${itemQueryString}`)
      .then(async (response) => ({
        status: response.status,
        json: await response.json(),
      }))
      .then((data) => {
        if (data.status === 200) {
          if (this.hasSubtotalTarget) this.subtotalTarget.textContent = data.json.subtotal

          for (const target of this.totalTargets) {
            target.textContent = data.json.total
          }
          this.hiddenTotalFieldTarget.value = data.json.totalCents

          this.toggleAdditionalChargesVisibility(data.json)

          this.handleTaxResponse(data.json)
          this.handleServiceFeeResponse(data.json)
        } else {
          this.alertsElement.textContent = data.text
        }
      })
  }

  ///
  /// private
  ///
  setCheckoutContent() {
    for (const target of this.checkoutTargets) {
      target.textContent = ''

      for (const item of this.cart) {
        this.appendCartItem(target, item)
      }
    }
  }

  hideCreditCardInput() {
    this.creditCardContainerTarget.classList.add('hidden')
    this.hasNoPaymentRequiredTarget && this.noPaymentRequiredTarget.classList.remove('hidden')

    this.creditCardContainerTarget.querySelector('iframe').src =
      this.creditCardContainerTarget.querySelector('iframe').src

    if (this.stripeCardElement) {
      this.stripeCardElement.dispatchEvent(new Event('clear:stripe'))
    }

    if (this.usaEpayCardElement) {
      this.usaEpayCardElement.dispatchEvent(new Event('clear:usaepay'))
    }
  }

  appendCartItem(element, item) {
    const cartItem = document.createElement('div')
    const itemDesc = document.createElement('div')
    const itemPrice = document.createElement('div')
    cartItem.classList.add('item')
    itemDesc.classList.add('item-description')
    itemPrice.classList.add('item-price')

    itemDesc.textContent = item.name
    itemPrice.textContent = item.formatted_price

    cartItem.appendChild(itemDesc)
    cartItem.appendChild(itemPrice)
    element.appendChild(cartItem)
  }

  clearInventoryItems() {
    for (const item of this.inventoryItemTargets) {
      item.checked = false
    }
  }

  toggleAdditionalChargesVisibility(payload) {
    if (!this.hasAdditionalChargesTarget) return

    if (
      payload.taxTotal.match(/\d/g).join('') === '000' &&
      (!this.hasServiceFeeTarget || payload.serviceFeeTotal.match(/\d/g).join('') === '000')
    ) {
      this.additionalChargesTarget.classList.add('hidden')
    } else {
      this.additionalChargesTarget.classList.remove('hidden')
    }
  }

  handleTaxResponse(payload) {
    if (this.hasTaxLineItemTarget && payload.taxTotal.match(/\d/g).join('') === '000') {
      this.taxLineItemTarget.classList.add('hidden')
    } else if (this.hasTaxLineItemTarget) {
      this.taxLineItemTarget.classList.remove('hidden')
    }
    this.taxTotalTarget.textContent = payload.taxTotal
  }

  handleServiceFeeResponse(payload) {
    if (!this.hasServiceFeeTarget) return

    if (this.hasServiceFeeLineItemTarget && payload.serviceFeeTotal.match(/\d/g).join('') === '000') {
      this.serviceFeeLineItemTarget.classList.add('hidden')
    } else if (this.hasServiceFeeLineItemTarget) {
      this.serviceFeeLineItemTarget.classList.remove('hidden')
    }
    this.serviceFeeTarget.textContent = payload.serviceFeeTotal
  }

  get cart() {
    return [...this.rawCart].map((item) => JSON.parse(item))
  }
}
