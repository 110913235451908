import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'form', 'image']

  edit() {
    this.inputTarget.click()
  }

  update() {
    const file = this.inputTarget.files[0]
    const url = URL.createObjectURL(file)

    this.imageTarget.src = url
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}
