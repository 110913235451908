/* eslint-disable no-unused-vars */
/* global initializeProspectOffWarning */
/* global initializeClipboardCopyButton */
/* global initializeProgramSignup */
/* global initializeSchedulerBehavior */
/* global loadLazyPartial */
/* global initializeInventoryItemCheckbox */
/* global initializeProgramSignupBehavior */
/* global initializeLandingPageModules */
/* global initializeTrialMembershipBehavior */
/* global intlTelInput */

// TODO: Move all this stuff to a stimulus controller or something
export function enableApplyToPreviewButton() {
  'use strict'

  $('.apply-btn').addClass('btn-primary')
}

export function removeDeletedElements() {
  for (const d of document.querySelectorAll('.delete')) {
    if (d.value === '1') {
      d.closest('.selected-module.hidden').remove()
    }
  }
}

export function initializeEmbedCodeModal() {
  'use strict'

  var $modal = $('#view-embed-code-modal')
  $modal.on('show.bs.modal', function (e) {
    var $this = $(this)
    var $target = $this.find('pre.embed-code code')
    var embedcode = $(e.relatedTarget).data('embedcode')
    $target.text(embedcode)
    $this.find('textarea.embed-code').val(embedcode)
    initializeClipboardCopyButton($modal)
  })
}

export function initializeTemplateStartDatePicker() {
  'use strict'

  $('.start-date-field').change(function () {
    const $checkboxField = $(this).closest('.table-row').find('.membership-template-check')
    $checkboxField.trigger('click')
    enableApplyToPreviewButton()
  })
}

export function initializeDeleteMembershipTemplateCheckboxes() {
  $('.membership-template-check').click(function () {
    const $this = $(this)
    if ($this.hasClass('checked')) {
      $this.siblings('.delete').val(0)
    } else {
      $this.siblings('.delete').val(1)
    }
  })
}

export function initializeCollapsibleModule($module) {
  'use strict'
  $module.on('show.bs.collapse', function () {
    const $selectedModule = $(this).closest('.selected-module.row')
    $selectedModule.removeClass('collapsed')

    $selectedModule.find('.expand-module').find('svg').toggleClass('chevron-rotate')
  })

  $module.on('hidden.bs.collapse', function () {
    const $selectedModule = $(this).closest('.selected-module.row')
    $selectedModule.addClass('collapsed')

    $selectedModule.find('.expand-module').find('svg').toggleClass('chevron-rotate')
  })
}

export function initializeCollapsibleModules() {
  'use-strict'
  const moduleNames = [
    'header',
    'agreement',
    'description',
    'inventory',
    'lcf',
    'program',
    'scheduler',
    'trial-membership',
  ]
  for (const moduleName of moduleNames) {
    initializeCollapsibleModule($(`#${moduleName}-module-content`))
  }
}

export function handleProspectCreationToggle() {
  'use strict'

  // Gather data on all of the form fields currently displayed
  var formFieldInputs = $('.selected-form-field')
  var formFieldInputsRequiredToCreateProspects = []
  $.each(formFieldInputs, function (index, value) {
    var key = $(value).find('.selected-form-field-label input:first-child').attr('value')
    var requireness = $(value).find('.selected-form-field-requireness input').is(':checked')

    if (key === 'email' && requireness === true) formFieldInputsRequiredToCreateProspects.push(key)

    if (key === 'name' && requireness === true) formFieldInputsRequiredToCreateProspects.push(key)
  })

  // Check and see if the form fields necessary for creating a prospect exist
  var requiredFieldsPresent =
    jQuery.inArray('email', formFieldInputsRequiredToCreateProspects) !== -1 &&
    jQuery.inArray('name', formFieldInputsRequiredToCreateProspects) !== -1

  // Check and see if this form currently has "prospect creation" enabled.
  var $prospectCreationToggle = $('#prospect-creation-toggle')
  let profitItemOptions = document.querySelectorAll(
    '.module-option-programs, .module-option-inventory_items, .module-option-trial_memberships',
  )

  if (
    requiredFieldsPresent ||
    $('.selected-module .lead-capture-form-item input:checked').data('canEnableProspecting')
  ) {
    // has the necessary fields
    $prospectCreationToggle.bootstrapToggle('destroy')
    $prospectCreationToggle.bootstrapToggle()
    $prospectCreationToggle.bootstrapToggle('enable')
    if ($prospectCreationToggle.hasClass('disabled')) {
      $prospectCreationToggle.parent().addClass('disabled')
    }

    enableProfitItemOptions(profitItemOptions)

    $('#name-warning').addClass('hidden')
  } else {
    // does not have the necessary fields required to permit "prospect creation"
    if ($('#prospect-creation-toggle').length > 0 && $('#prospect-creation-toggle')[0].checked) {
      $('#name-warning-modal').modal('show')
    }

    $('#name-warning').removeClass('hidden')

    disableProfitItemOptions(profitItemOptions)

    $prospectCreationToggle.bootstrapToggle('destroy')
    $prospectCreationToggle.bootstrapToggle()
    $prospectCreationToggle.bootstrapToggle('off')
    $prospectCreationToggle.bootstrapToggle('disable')
  }
}

export function disableProfitItemOptions(options) {
  for (const option of options) {
    option.classList.add('disabled')
  }
}

export function enableProfitItemOptions(options) {
  for (const option of options) {
    let modName = option.dataset.moduleName
    let selectedModule = document.querySelector(`.selected-module input[name="modules[${modName}][key]"]`)

    if (option.classList.contains('disabled') && !selectedModule) {
      option.classList.remove('disabled')
    }
  }
}

// Reload Telephone Input
export function initializeTelephoneInputBehavior(element) {
  const phoneInputs = element.contentWindow.document.querySelectorAll('.public input[type=tel]')

  for (const phoneInput of phoneInputs) {
    intlTelInput(phoneInput, {
      utilsScript: '/assets/intl-tel-input/utils.js',
      hiddenInput: phoneInput.dataset.inputname || '',
      formatOnDisplay: true,
      separateDialCode: true,
      initialCountry: document.body.dataset.countryCode || 'us',
    })
  }
}

export function reloadPreview() {
  const preview = document.querySelector('#preview-container')
  const oldFrame = preview.querySelector('iframe')
  const newFrame = document.createElement('iframe')
  newFrame.src = oldFrame.src
  newFrame.classList.add('hidden')
  preview.appendChild(newFrame)
  newFrame.addEventListener('load', () => {
    preview.removeChild(oldFrame)
    preview.style['background-color'] = document
      .querySelector('.third-color')
      .querySelector('.minicolors-swatch-color').style['background-color']
    newFrame.classList.remove('hidden')
    initializeTelephoneInputBehavior(newFrame)
  })
}

$(document).on('turbo:load', function () {
  'use strict'
  initializeTemplateStartDatePicker()
  initializeDeleteMembershipTemplateCheckboxes()
  initializeCollapsibleModules()
  $('.apply-btn').click(function () {
    if (!$(this).hasClass('btn-primary')) return

    var $form = $('#lead-capture-form, #landing-page-form')
    $.ajax({
      type: $form.attr('method'),
      url: $form.attr('action'),
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'))
      },
      data: $form.serialize(),
      complete(data) {
        if (data.status === 200 || data.status === 204) {
          reloadPreview()
          $(document).trigger('lazy-load:complete')
          $('.lazy-load#landing-pages').trigger('lazy-load:complete')
          const leftSection = $('.reload-left-section')
          let collapsdModules = {}
          leftSection.find('.selected-module').each(function () {
            const $module = $(this).find('.content')
            const id = $module.attr('id').split('-')[0]
            if ($module.hasClass('in')) {
              collapsdModules[id] = false
            } else {
              collapsdModules[id] = true
            }
          })
          const encodedCollapsedModules = encodeURIComponent(JSON.stringify(collapsdModules))
          $.ajax({
            type: 'GET',
            url: `${leftSection.data('path')}?collapsed_modules=${encodedCollapsedModules}`,
            dataType: 'json',
            success(_data) {
              leftSection.html(_data.partial)
              initializeInventoryItemCheckbox()
              initializeSchedulerBehavior()
              initializeTemplateStartDatePicker()
              initializeDeleteMembershipTemplateCheckboxes()
              initializeProgramSignupBehavior()
              initializeCollapsibleModules()
              initializeLandingPageModules($('#additional-sidebar'))
              removeDeletedElements()
              initializeTrialMembershipBehavior()
            },
          })
        } else {
          $('#global-alerts').append(data.responseText)
        }

        return false
      },
    })

    $('.apply-btn').removeClass('btn-primary')
  })

  $('input.color-picker').change(function () {
    enableApplyToPreviewButton()
  })

  $('.settings-toggle').click(function (event) {
    event.preventDefault()

    const settings = $('#settings')
    if (settings.hasClass('hidden')) {
      handleProspectCreationToggle()

      const membershipItemsWrapper = document.querySelector('.membership-items')
      const checkboxes = membershipItemsWrapper
        ? membershipItemsWrapper.querySelectorAll('input[type="checkbox"]')
        : []
      const hasMemberships = [...checkboxes].some((checkbox) => checkbox.checked)
      const toggleCreateProspectButton = document
        .querySelector('.create-prospect-toggle-container')
        .querySelector('.toggle.btn')

      if (hasMemberships) {
        if (toggleCreateProspectButton.classList.contains('off')) {
          toggleCreateProspectButton.click()
        }
        toggleCreateProspectButton.classList.add('disabled')
      }

      settings.animate({ left: '0px' }, 'fast').removeClass('hidden')
    } else {
      settings.animate({ left: '-1000px' }, 'fast', function () {
        settings.addClass('hidden')
      })
    }

    initializeProspectOffWarning()
    return false
  })
})
/* eslint-enable no-unused-vars */
