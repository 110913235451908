import ApplicationController from './application_controller'
import consumer from '../channels/consumer'

export default class extends ApplicationController {
  static targets = [
    'progress',
    'count',
    'processingIcon',
    'successIcon',
    'sendingMessage',
    'sentMessage',
    'allFailedMessage',
    'failureIcon',
    'failedPeople',
    'failureMessage',
    'failureCount',
    'failureSingular',
    'failurePlural',
    'confirmationEmail',
  ]

  static values = { current: Number, max: Number, channel: String }

  completed = false
  total = 0
  failures = 0
  failedPeopleHoldingCell = []

  connect() {
    this.channel = this.createChannel(this)

    this.alertsFrame.classList.add('hidden')

    document.addEventListener(
      'turbo:submit-end',
      () => {
        this.dispatch('begin-action', { bubbles: true })
      },
      { once: true },
    )
  }

  failedPeopleTargetConnected() {
    if (this.failedPeopleHoldingCell.length > 0) {
      this.failureMessageTarget.classList.remove('hidden')
    }

    if (this.failedPeopleHoldingCell.length === 1) {
      this.failureSingularTarget.classList.remove('hidden')
      this.failurePluralTarget.classList.add('hidden')
    } else {
      this.failureSingularTarget.classList.add('hidden')
      this.failurePluralTarget.classList.remove('hidden')
    }

    this.failureCountTarget.textContent = this.failedPeopleHoldingCell.length

    for (const html of this.failedPeopleHoldingCell) {
      this.failedPeopleTarget.insertAdjacentHTML('beforeend', html)
    }

    this.failedPeopleHoldingCell = []
  }

  progressTargetConnected() {
    this.displayProgress()

    if (this.completed) {
      this.displaySuccess()

      this.displayProgressFailures()
    }
  }

  disconnect() {
    this.channel.unsubscribe()

    this.resetAlerts()
    this.alertsFrame.classList.remove('hidden')
  }

  ///
  /// private
  ///

  createChannel(self) {
    return consumer.subscriptions.create(this.channelValue, {
      received: (data) => {
        if (data.status === 'success') {
          self.incrementProgress()
        } else if (data.status === 'failure') {
          self.displayFailedPeople(data.entity_html)
        } else if (data.status === 'complete') {
          self.completed = true
          self.total = data.total
          self.failures = data.failures

          self.displayCompletion()
          self.displayProgressFailures()
        }
      },
    })
  }

  incrementProgress() {
    this.currentValue += 1

    this.displayProgress()
  }

  displayProgress() {
    if (!this.hasProgressTarget) return

    if (this.currentValue >= this.maxValue) {
      this.displaySuccess()
    }

    this.progressTarget.setAttribute('value', this.currentValue)

    this.countTarget.textContent = this.currentValue
  }

  displaySuccess() {
    if (!this.hasProcessingIconTarget) return

    this.sendingMessageTarget.classList.add('hidden')
    this.sentMessageTarget.classList.remove('hidden')

    this.processingIconTarget.addEventListener(
      'animationiteration',
      () => {
        this.processingIconTarget.classList.add('hidden')
        this.successIconTarget.classList.remove('hidden')

        this.successIconTarget.classList.add('opacity-100')
        this.successIconTarget.classList.remove('opacity-0')
      },
      { once: true },
    )
  }

  displayFailure() {
    this.sendingMessageTarget.classList.add('hidden')
    this.allFailedMessageTarget.classList.remove('hidden')

    this.processingIconTarget.addEventListener(
      'animationiteration',
      () => {
        this.processingIconTarget.classList.add('hidden')
        this.failureIconTarget.classList.remove('hidden')

        this.failureIconTarget.classList.add('opacity-100')
        this.failureIconTarget.classList.remove('opacity-0')
      },
      { once: true },
    )

    if (this.hasConfirmationEmailTarget) {
      this.displayConfirmationText()
    }
  }

  displayCompletion() {
    if (this.total === this.failures) {
      this.displayFailure()
    } else {
      this.displaySuccess()
    }
  }

  displayFailedPeople(personHtml) {
    if (this.hasFailedPeopleTarget) {
      this.failureMessageTarget.classList.remove('hidden')
      this.failedPeopleTarget.insertAdjacentHTML('beforeend', personHtml)

      this.failureCountTarget.textContent = this.failedPeopleTarget.children.length

      if (this.failedPeopleTarget.children.length === 1) {
        this.failureSingularTarget.classList.remove('hidden')
        this.failurePluralTarget.classList.add('hidden')
      } else {
        this.failureSingularTarget.classList.add('hidden')
        this.failurePluralTarget.classList.remove('hidden')
      }
    } else {
      this.failedPeopleHoldingCell.push(personHtml)
    }

    if (this.hasConfirmationEmailTarget) {
      this.displayConfirmationText()
    }
  }

  displayProgressFailures() {
    if (!this.hasProgressTarget) return
    if (this.failures === 0) return

    this.progressTarget.classList.add('progress-with-failures')
  }

  displayConfirmationText() {
    this.confirmationEmailTarget.classList.remove('hidden')
  }
}
