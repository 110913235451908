import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['rbToggle', 'submit', 'rbSection', 'rbResumeDate', 'membershipResumeDate']

  toggleRecurringBilling() {
    if (this.rbToggleTarget.checked) {
      this.rbSectionTarget.classList.remove('hidden')
    } else {
      this.rbSectionTarget.classList.add('hidden')
    }
    this.checkSubmit()
  }

  checkSubmit() {
    if (this.membershipResumeDateTarget.value === '') {
      this.disableSubmit()
      return
    }

    if (this.hasRbResumeDateTarget) {
      if (this.rbResumeDateTarget.value === '') {
        this.disableSubmit()
      } else {
        this.enableSubmit()
      }
    } else {
      this.enableSubmit()
    }
  }

  enableSubmit() {
    this.submitTarget.disabled = false
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }
}
