/* global loadLazyPartial */
/* global addPDFPreview */
/* global intlTelInput */

function agreementToggle() {
  'use strict'

  var behavior = {
    template() {
      toggleButton('.template-agreement')

      $('.choose-template').removeClass('text-muted')
      $('.template-select-box').removeClass('hidden')
      $('.agreement-template').removeClass('hidden')
      $('.dummy-select-box').addClass('hidden')

      $('.agreement-editor').addClass('hidden')
      $('.new-upload-pdf').addClass('hidden')
      $('.new-agreement-editor').addClass('hidden')
      $('#add-new-agreement').data().mode = 'template'
    },
    custom() {
      toggleButton('.custom-agreement')

      $('.choose-template').removeClass('text-muted')
      $('.template-select-box').removeClass('hidden')
      $('.dummy-select-box').addClass('hidden')

      $('.agreement-template').addClass('hidden')
      $('.agreement-editor').removeClass('hidden')
      $('.new-upload-pdf').addClass('hidden')
      $('.new-agreement-editor').addClass('hidden')
      $('#add-new-agreement').data().mode = 'custom'
    },
    newTemplate() {
      toggleButton('.new-template-agreement')

      $('.choose-template').addClass('text-muted')
      $('.template-select-box').addClass('hidden')
      $('.dummy-select-box').removeClass('hidden')
      $('.dummy-select-box').addClass('disabled')

      $('.agreement-template').addClass('hidden')
      $('.agreement-editor').addClass('hidden')
      $('.new-upload-pdf').removeClass('hidden')
      $('.new-agreement-editor').removeClass('hidden')
      $('#add-new-agreement').data().mode = 'newTemplate'

      let dropzoneComp = document.querySelector('.dropzone.dropzone-default').dropzone
      if (dropzoneComp) {
        dropzoneComp.removeAllFiles()
      }
    },
  }

  function toggleButton(button) {
    var buttons = ['.template-agreement', '.custom-agreement', '.new-template-agreement']
    var disabledButtons = buttons.filter(function (item) {
      return item !== button
    })
    disabledButtons.forEach(function (btn) {
      $(btn).removeClass('btn-primary')
      $(btn).addClass('btn-default-alt')
    })

    $(button).addClass('btn-primary')
    $(button).removeClass('btn-default-alt')
  }

  return behavior
}

function agreementToggleBehavior() {
  'use strict'

  $('body').on('click', '.template-agreement', function () {
    agreementToggle().template()
  })

  $('body').on('click', '.custom-agreement', function () {
    agreementToggle().custom()
  })

  $('body').on('click', '.new-template-agreement', function () {
    agreementToggle().newTemplate()
  })
}

function selectAgreementBehavior() {
  'use strict'

  var behavior = {
    loadTemplate(templateText) {
      $('.template-text').empty()
      $('.template-text').html(templateText)
    },
    loadTrix(templateText) {
      var trix = $('.agreement-trix')[0]
      trix.setAttribute('contenteditable', true)
      $('#agreement-text').val('')
      trix.editor.loadHTML('')
      trix.editor.loadHTML(templateText)
    },
  }

  return behavior
}

// I should feel bad for this function tbh
function initializeShareAndSign(modal, response) {
  'use strict'
  const shareUrl = response.share_url
  modal.querySelector('a.sign-here').href = shareUrl

  const emailBtn = modal.querySelector('.open-email')
  const smsBtn = modal.querySelector('.open-sms')
  const emailDiv = modal.querySelector('.email')
  const smsDiv = modal.querySelector('.sms')
  const sms_field = modal.querySelector('.sms input')
  const email_field = modal.querySelector('.email input')

  const smsIntlInput = intlTelInput(sms_field, {
    utilsScript: '/assets/intl-tel-input/utils.js',
    formatOnDisplay: true,
    separateDialCode: true,
    initialCountry: document.body.dataset.countryCode || 'us',
  })

  modal.querySelector('a.sign-here')
  $(modal)
    .find('a.sign-here')
    .on('click', function () {
      emailBtn.classList.remove('active')
      smsBtn.classList.remove('active')
      emailDiv.classList.add('hidden')
      smsDiv.classList.add('hidden')
    })

  $(modal)
    .find('.open-sms')
    .on('click', function () {
      emailBtn.classList.remove('active')
      smsBtn.classList.add('active')
      emailDiv.classList.add('hidden')
      smsDiv.classList.remove('hidden')
      email_field.required = false
      sms_field.required = true
    })

  $(modal)
    .find('.open-email')
    .on('click', function () {
      emailBtn.classList.add('active')
      smsBtn.classList.remove('active')
      emailDiv.classList.remove('hidden')
      smsDiv.classList.add('hidden')
      email_field.required = true
      sms_field.required = false
    })

  $(modal).on('hidden.bs.modal', function () {
    $('#save-agreement').attr('disabled', false)
  })

  const agreementId = response.agreement_id
  const sendSelectors = ['button.send-email', 'button.send-sms']

  for (const selector of sendSelectors) {
    modal.querySelector(selector).addEventListener('click', () => {
      const option = selector.split('-')[1]

      if (option === 'email') {
        email_field.reportValidity()
        if (!email_field.checkValidity()) return
      }

      let sms_value = ''
      if (option === 'sms' && !sms_field.value) {
        sms_field.reportValidity()
        if (!sms_field.checkValidity()) return
      } else {
        sms_value = smsIntlInput.getNumber()
      }

      $.post(
        `/agreements/${agreementId}/share`,
        {
          type: option,
          email: email_field.value,
          sms: sms_value,
          share_url: shareUrl,
        },
        function () {
          $(modal).modal('hide')
          if (option === 'email') {
            const $email_modal = $('#email-confirmation')
            $email_modal.modal('show')
            $email_modal.on('hidden.bs.modal', function () {
              loadLazyPartial($('.module#agreements').data().path, $('.module#agreements'))
              $('#save-agreement').attr('disabled', false)
            })
          } else if (option === 'sms') {
            const $sms_modal = $('#sms-confirmation')
            $sms_modal.modal('show')
            $sms_modal.on('hidden.bs.modal', function () {
              loadLazyPartial($('.module#agreements').data().path, $('.module#agreements'))
              $('#save-agreement').attr('disabled', false)
            })
          }
        },
      )
    })
  }
}

function saveAndShareBehavior() {
  'use strict'
  $('body').on('click', '#save-agreement', function () {
    if ($(this).attr('disabled')) return

    $(this).attr('disabled', true)
    var templateText = $('#agreement-preview .agreement-content-container').html()

    var selectedTemplate = $('#select-agreements option:selected').data().templateId
    var signatureType = $('#select-agreements option:selected').data().signatureType
    const personId = document.querySelector('#add-new-agreement').dataset.personId
    const personType = document.querySelector('#add-new-agreement').dataset.personType

    if (personId) {
      var data = {
        template_id: selectedTemplate,
        content: templateText,
        signature_type: signatureType,
      }

      $.post(`/${personType}/${personId}/agreements`, data, function (response) {
        $('#preview-agreement-go-back').addClass('hidden')
        $('#close-agreement-modal').removeClass('hidden')

        $('#agreement-preview').modal('hide')

        const shareModal = document.getElementById('share-and-sign-agreement')
        $(shareModal).modal('show')
        initializeShareAndSign(shareModal, response)
      })
    }
  })
}

function goBackBehavior() {
  'use strict'

  $('body').on('click', '#preview-agreement-go-back', function () {
    $('#agreement-preview').modal('hide')
    $('#add-new-agreement').modal('show')
  })
}

function templateSelectBehavior() {
  'use strict'

  $(document).on('shown.bs.modal', '#add-new-agreement', function () {
    $('#dummy-select-agreements').select2({
      placeholder: $('#dummy-select-agreements').data().placeholder,
      theme: 'bootstrap',
      width: '100%',
    })
    $('#select-agreements').select2({
      placeholder: $('#select-agreements').data().placeholder,
      theme: 'bootstrap',
      width: '100%',
    })
  })

  $('body').on('change', '#select-agreements', function () {
    var templateText = $(this).val()

    selectAgreementBehavior().loadTemplate(templateText)
    selectAgreementBehavior().loadTrix(templateText)

    $('.select-template-text').addClass('hidden')
    $('.preview-agreement')[0].disabled = false
  })

  $('body').on('click', '.preview-agreement', function () {
    $('#agreement-preview').modal('show')
    $('#agreement-preview #save-agreement')[0].disabled = true
    $('#agreement-preview .agreement-content-container').html(agreementPreviewLoading())
    var templateId = $('#select-agreements option:selected').data().templateId
    var studentId = $('#add-new-agreement').data().studentId
    var previewPath = $(this).data().path
    var csrfToken = $('meta[name="csrf-token"]').attr('content')

    var override_tokens = {}
    $('.override-tokens .form-fields input').each(function () {
      if (this.value !== '') override_tokens[this.id] = this.value
    })

    var data = {
      template_id: templateId,
      student_id: studentId,
      override_tokens,
    }

    if ($('#add-new-agreement').data().mode === 'custom') {
      let trix = $('.agreement-trix')[0]
      data.content = trix.value
    } else if ($('#add-new-agreement').data().mode === 'newTemplate') {
      let trix = $('.new-agreement-trix')[0]
      data.content = trix.value
    } else if ($('#add-new-agreement').data().mode === 'template') {
      data.content = $('.template-text').html()
    }

    $.ajax({
      type: 'POST',
      url: previewPath,
      data,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken)
      },
      complete(_data) {
        if (_data.status === 200) {
          $('#agreement-preview .agreement-content-container').html(_data.responseText)
          $('#agreement-preview #save-agreement')[0].disabled = false
        } else {
          $('#global-alerts').append(_data.responseText)
          return false
        }
        return false
      },
    })
  })
}

function agreementPreviewLoading() {
  return `
  <div class="flex justify-center">
    <i class='fa fa-spinner fa-2x fa-spin'></i>
  </div>
  `
}

function cancelClickBehavior() {
  $('body').on('click', '#cancel-agreement', function () {
    $('#select-agreements').val(null).trigger('change')

    var trix = $('.agreement-trix')[0]
    $('#agreement-text').val('')
    trix.editor.loadHTML('')

    agreementToggle().template()
  })
}

function agreementClickBehavior() {
  'use strict'

  $('body').on('click', '.show-agreement', function () {
    $('#agreement-show').modal('show')
    var path = $(this).data().path
    var title = $(this).data().title
    var token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      type: 'GET',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(data) {
        if (data.status === 200) {
          $('#agreement-show h5.modal-title').text(title)
          $('#agreement-show .agreement-content-container').html(data.responseJSON.content)

          const pdfViewer = document.querySelector('#agreement-pdf-viewer')
          if (pdfViewer) {
            addPDFPreview(pdfViewer)
          }
          const form = document.querySelector('#send-agreement-form')
          if (data.responseJSON.share_url) {
            $('.share-agreement').removeClass('hidden')
            const shareModal = document.getElementById('share-and-sign-agreement')
            initializeShareAndSign(shareModal, data.responseJSON)

            $('.share-agreement').on('click', function () {
              $('#agreement-show').modal('hide')
              $(shareModal).modal('show')
            })
            $('.send-agreement').addClass('hidden')
            form.action = null
          } else {
            form.action = `/agreements/email_agreements/${data.responseJSON.agreement_id}`
            $('.share-agreement').addClass('hidden')
            $('.send-agreement').removeClass('hidden')

            document.addEventListener(
              'turbo:submit-end',
              () => {
                $('#agreement-send').removeClass('open in')
                $('#agreement-send').modal('hide')
              },
              { once: true },
            )
          }
        } else {
          $('#global-alerts').append(data.responseText)
          return false
        }
        return false
      },
    })
  })
}

function toggleTokenOverrideChevron(e) {
  $(e.target).prev('.override-token-header').find('i').toggleClass('fa-chevron-down fa-chevron-up')
}

$(document).on('turbo:load', function () {
  'use strict'

  agreementClickBehavior()
  agreementToggleBehavior()
  templateSelectBehavior()
  goBackBehavior()
  saveAndShareBehavior()
  cancelClickBehavior()

  $(document).on('hidden.bs.collapse', toggleTokenOverrideChevron)
  $(document).on('shown.bs.collapse', toggleTokenOverrideChevron)
})
