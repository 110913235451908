import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.formData = new FormData(this.element)
  }

  toggleSubmit() {
    if (!this.hasSubmitTarget) return

    const newData = new FormData(this.element)
    const oldValues = new Set(Array.from(this.formData).map((arr) => arr[1]))
    const newValues = new Set(Array.from(newData).map((arr) => arr[1]))

    const symmetricDifference = oldValues.symmetricDifference(newValues)

    this.submitTarget.hidden = symmetricDifference.size <= 0
  }

  reset() {
    this.submitTarget.hidden = true
    this.formData = new FormData(this.element)
  }
}
