import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    this.original ||= this.containerTargets.map((container) => container.hidden)
  }

  swap() {
    this.containerTargets.forEach((container) => (container.hidden = !container.hidden))
  }

  swapFromOriginal() {
    const current = this.containerTargets.map((container) => container.hidden)

    if (current.every((value, index) => value === this.original[index])) {
      this.swap()
    }
  }

  swapToOriginal() {
    const current = this.containerTargets.map((container) => container.hidden)

    if (!current.every((value, index) => value === this.original[index])) {
      this.swap()
    }
  }
}
