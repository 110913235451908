$(document).on('turbo:load', function () {
  'use strict'

  jQuery.expr.filters.onscreen = function (el) {
    if (navigator.webdriver) return true

    if ($(el).css('display') === 'none') return false

    var rect = el.getBoundingClientRect()
    return !(
      rect.x + rect.width < 0 ||
      rect.y + rect.height < 0 ||
      rect.x > window.innerWidth ||
      rect.y > window.innerHeight
    )
  }
})
