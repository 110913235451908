/* eslint-env jquery */
/* global Userback */
/* eslint no-global-assign: 0 */
/* eslint no-use-before-define: 0 */
/* eslint camelcase: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-native-reassign: 0 */

Userback = window.Userback || {}

export function initializeFeedback() {
  'use strict'

  Userback.open('capture')
}

$(document).on('turbo:load', function () {
  'use strict'

  if ($('body.has-feedback').length) {
    var projectId = $('body').data('feedback-project')
    Userback.access_token = `8566|${projectId}|BcbFm1QUKVNOwg4RcNBWdJ4xc5YRBRY7m1I47JAqsQ65v8B9Cq`
    Userback.widget_settings = {
      language: $('body').data('locale'),
    }
    Userback.email = $('body').data('user-email')
    Userback.user_data = {
      id: $('body').data('user-id'),
      info: {
        name: $('body').data('user-name'),
        school_name: $('body').data('school-name'),
        school_id: $('body').data('school-id'),
      },
    }
    ;(function (id) {
      var s = document.createElement('script')
      s.async = 1
      s.src = 'https://static.userback.io/widget/v1.js'
      var parent_node = document.head || document.body
      parent_node.appendChild(s)
    })('userback-sdk')

    // 15046 is the default project ID for General Feedback (production bugs/feature reqs/etc).
    // 15048 is the Beta Feedback project -- we want it to be active but hidden so that we can
    // manually render the button in sections that we care about.
    var hide = projectId === 15048
    Userback.widget_settings.autohide = hide

    $('.feedback-initializer').click(function () {
      Userback.open()
    })
  }
})
