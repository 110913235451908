/* global initializeTooltips */
// TODO: create an esm version of initializeTooltips so that we aren't
// poluting our files with random globals.

import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['table', 'tbody', 'infinity', 'spinner', 'footer', 'all', 'more']

  showMore() {
    if (this.moreTarget.classList.contains('disabled')) {
      return
    }

    fetch(this.moreTarget.dataset.path, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (this.hasTbodyTarget) {
          this.tbodyTarget.insertAdjacentHTML('beforeend', data.table_data)
        } else {
          this.tableTarget.insertAdjacentHTML('beforeend', data.table_data)
        }

        this.footerTarget.innerHTML = data.footer
        initializeTooltips($(this.element.parentElement))
      })
  }

  showAll() {
    this.showMore()
    this.allTarget.classList.add('disabled')
    this.moreTarget.classList.add('disabled')

    const that = this
    ;(function onScroll() {
      that.infinityTarget.addEventListener('scroll', function scroll() {
        const bottom =
          that.infinityTarget.scrollHeight - Math.abs(that.infinityTarget.scrollTop) >=
          that.infinityTarget.offsetHeight

        if (bottom) {
          that.spinnerTarget.classList.remove('hidden')

          if (that.moreTarget.parentElement.classList.contains('at-bottom')) {
            that.spinnerTarget.classList.add('hidden')
          } else {
            that.infinityTarget.removeEventListener('scroll', scroll)
            fetch(that.moreTarget.dataset.path, {
              method: 'GET',
            })
              .then((response) => response.json())
              .then((data) => {
                initializeTooltips()
                if (that.hasTbodyTarget) {
                  that.tbodyTarget.insertAdjacentHTML('beforeend', data.table_data)
                } else {
                  that.tableTarget.insertAdjacentHTML('beforeend', data.table_data)
                }
                that.footerTarget.innerHTML = data.footer
                that.allTarget.classList.add('disabled')
                that.moreTarget.classList.add('disabled')
                onScroll()
              })
          }
        }

        that.allTarget.classList.add('disabled')
        that.moreTarget.classList.add('disabled')
      })
    })()
  }
}
