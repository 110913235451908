import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['due', 'hiddenValue']
  static values = { total: Number }

  updateChangeDue() {
    if (this.totalValue < this.hiddenValueTarget.value) {
      this.dueTarget.value = this.hiddenValueTarget.value - this.totalValue
    } else {
      this.dueTarget.value = 0
    }
  }
}
