import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['tom-select', 'collapse--component']
  static targets = ['nextMembership', 'hiddenRank']
  static values = { nextMembership: String }

  handleProgramChange({ target }) {
    this.disableAllRankDropdowns()

    const rankDropdown = this.tomSelectOutlets.find(
      (sel) => parseInt(sel.context.element.dataset.programId) === parseInt(target.value),
    )
    const allowEnable = rankDropdown.context.element.dataset.allowEnable === 'true'

    const hiddenRank = this.hiddenRankTargets.find(
      (sel) => parseInt(sel.dataset.programId) === parseInt(target.value),
    )

    if (allowEnable) {
      rankDropdown.tomSelect.enable()
    } else {
      hiddenRank.disabled = false
    }
  }

  selectNoProgram() {
    this.disableAllRankDropdowns()
  }

  //
  // private
  //

  disableAllRankDropdowns() {
    this.tomSelectOutlets.forEach((rankDropdown) => {
      rankDropdown.tomSelect.disable()
    })
    this.hiddenRankTargets.forEach((hiddenRank) => {
      hiddenRank.disabled = true
    })
  }
}
