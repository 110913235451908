/* global loadLazyPartial */

// TODO: Componentitize Me Captain

// Takes a module by key (#memberships, #attendances)
// and focuses the nav bar and the module
export function highlightModule(selectedModule) {
  var name = selectedModule.substring(1)

  // scroll back to the top since the default behavior when setting the anchor
  // is to scroll to an id matching it and we don't want that.
  // Unless we're mobile, in which case we have to be scrolled down to see the things.
  if (window.innerWidth > 768) window.scrollTo({ top: 0 })

  focusModules(name)
  focusNav(name)
}

function showAll() {
  window.location.hash = ''
  unfocusModule()
  unfocusNav()
}

function focusModules(selectedType) {
  'use strict'

  var $selectedModules = $(`.person-profile #${selectedType}, .dashboard-${selectedType}`)
  $selectedModules.removeClass('hidden')

  // hide others
  var possibleModules = $.map($('.person-profile .nav-link, .dashboard .nav-link'), (div) =>
    div.id.replace(/-nav$/, ''),
  )
  possibleModules.forEach(function (module) {
    if (module !== selectedType) $(`.person-profile #${module}, .dashboard-${module}`).addClass('hidden')
  })

  // Lazy load if needed
  var lazyLoad
  if (selectedType === 'attendances') lazyLoad = $selectedModules.children().find('.lazy-load')
  else lazyLoad = $selectedModules

  $.each(lazyLoad, function (i, lazyLoadItem) {
    var item = $(lazyLoadItem)
    if (item.hasClass('lazy-load') && !item.hasClass('lazy-loaded')) loadLazyPartial(item.data('path'), item)
  })
}

function focusNav(selectedName) {
  $('.nav-link').each(function () {
    $(this).removeClass('active')
  })

  $(`.nav-link#${selectedName}-nav`).addClass('active')
}

function unfocusModule() {
  var possible_modules = $.map($('.person-profile .nav-link, .dashboard .nav-link'), (div) =>
    div.id.replace(/-nav$/, ''),
  )
  possible_modules.forEach(function (module) {
    $(`.person-profile #${module}, .dashboard-${module}`).each(function () {
      if (this.hasAttribute('data-visible') && !$(this).data('visible')) $(this).addClass('hidden')
      else $(this).removeClass('hidden')
    })
  })
}

function unfocusNav() {
  $('.nav-link').each(function () {
    $(this).removeClass('active')
  })

  $('#all-nav.nav-link').addClass('active')
}

//
// Focuses a module that is selected from the nav bar
//
$(document).on('turbo:load', function () {
  'use strict'

  // Mobile select dropdown
  $('.module-nav-select .mobile-select-menu').on('change', function () {
    var selectedModule = $(this.value)[0].id.replace(/-nav$/, '')
    if (selectedModule === 'all') showAll()
    else highlightModule(`#${selectedModule}`)
  })

  // Desktop & Tablet nav link
  $('.nav-link').on('click', function () {
    var wasActive = $(this).hasClass('active')

    // Toggle shown module
    var selectedModule = this.id.replace(/-nav$/, '')
    if (wasActive || selectedModule === 'all') showAll()
    else highlightModule(`#${selectedModule}`)
  })
})
