import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['amount', 'taxTotal', 'subtotal', 'salesTax', 'grandTotal']
  static outlets = ['line-item-calculate']

  useTax = true

  lineItemCalculateOutletConnected(outlet) {
    outlet.useTax = this.useTax
  }

  calculate() {
    if (this.amountTargets.length === 0) return

    this.subtotalTarget.value = this.subtotal
    this.salesTaxTarget.value = this.salesTax
    this.grandTotalTarget.value = this.grandTotal
  }

  toggleTax() {
    this.useTax = !this.useTax

    for (const outlet of this.lineItemCalculateOutlets) {
      outlet.toggleTax()
      outlet.updateAmount()
    }

    this.calculate()
  }

  get subtotal() {
    const value = this.amountTargets
      .filter((target) => !target.closest('.form-fields').querySelector('.swap-checkbox')?.checked)
      .map((target) => parseFloat(target.value) || 0)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    return Math.round(value)
  }

  get salesTax() {
    if (!this.useTax) return 0

    const value = this.taxTotalTargets
      .filter((target) => !target.closest('.form-fields').querySelector('.swap-checkbox')?.checked)
      .map((target) => parseFloat(target.value) || 0)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    return Math.round(value)
  }

  get grandTotal() {
    return Math.round(this.subtotal + this.salesTax)
  }
}
