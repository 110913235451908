import { Controller } from '@hotwired/stimulus'
import { initializeTooltips, initializeDropdownTabs } from '../../../../vendor/assets/javascripts/voltage'
import { initializeSelectAllCheckboxes } from '../../../assets/javascripts/forms'
import {
  initializeProspectLeadStateDropdowns,
  initializeProspectNoting,
  initializeAssignedToFields,
} from '../../../assets/javascripts/school/prospects'

export default class extends Controller {
  connect() {
    initializeTooltips($(this.element))
    initializeDropdownTabs($(this.element))
    initializeProspectLeadStateDropdowns($(this.element))
    initializeAssignedToFields($(this.element))
    initializeProspectNoting($(this.element))
    initializeSelectAllCheckboxes($("input.select-all-item[type='checkbox']"), true)
  }
}
