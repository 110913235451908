import ApplicationController from '../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static outlets = ['payment-processing']
  static targets = ['tab', 'cc', 'magstripe']

  showCc({ target }) {
    this.resetTabs()

    target.classList.add('active')

    this.ccTarget.classList.remove('hidden')
    this.magstripeTarget.classList.add('hidden')

    this.disableMagstripeFields()
    this.enableCcFields()

    if (this.hasPaymentProcessingOutlet) {
      this.paymentProcessingOutlet.bindSubmit()
    }
  }

  showMagstripe({ target }) {
    this.resetTabs()

    target.classList.add('active')

    this.ccTarget.classList.add('hidden')
    this.magstripeTarget.classList.remove('hidden')

    this.disableCcFields()
    this.enableMagstripeFields()

    if (this.hasPaymentProcessingOutlet) {
      this.paymentProcessingOutlet.unbindSubmit()
    }
  }

  ///
  /// private
  ///

  resetTabs() {
    this.tabTargets.forEach((tab) => tab.classList.remove('active'))
  }

  disableCcFields() {
    this.disableFieldsFor(this.ccTarget)
  }

  enableCcFields() {
    this.enableFieldsFor(this.ccTarget)
  }

  disableMagstripeFields() {
    this.disableFieldsFor(this.magstripeTarget)
  }

  enableMagstripeFields() {
    this.enableFieldsFor(this.magstripeTarget)
  }

  disableFieldsFor(element) {
    element.querySelectorAll('input').forEach((input) => {
      input.disabled = true
    })
  }

  enableFieldsFor(element) {
    element.querySelectorAll('input').forEach((input) => {
      input.disabled = false
    })
  }
}
