import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['collapseElement', 'ignoreElement']

  beginToggle(event) {
    if (this.ignoreClick(event)) return

    this.collapseElementTarget.classList.remove('hidden')
    this.dispatch('going', { detail: { collapsed: this.element.classList.contains('collapsed') } })

    setTimeout(() => {
      this.element.classList.add('going')
      this.element.classList.toggle('collapsed')
    })
  }

  completeToggle() {
    this.element.classList.remove('going')

    this.dispatch('complete', { detail: { collapsed: this.element.classList.contains('collapsed') } })
  }

  open() {
    this.element.classList.remove('collapsed')
  }

  close() {
    this.element.classList.add('collapsed')
  }

  ignoreClick(event) {
    let ignore = false

    if (event.target.tagName === 'A' || event.target.tagName === 'BUTTON') ignore = true

    this.ignoreElementTargets.forEach((element) => {
      if (element === event.target || element === event.target.parentElement) ignore = true
    })

    return ignore
  }
}
