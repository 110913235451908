import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { percentage: Number }

  connect() {
    setTimeout(() => {
      this.element.style.setProperty('--value', this.percentageValue || 0)
    }, 200)
  }
}
