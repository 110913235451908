import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['ranks']

  connect() {
    if (this.hasRanksTarget) {
      $(this.ranksTarget).on('select2:select', this.updateRankPreview)
    }
  }

  disconnect() {
    if (this.hasRanksTarget) {
      $(this.ranksTarget).off()
    }
  }

  updateRankPreview() {
    // Since the eventListener in connect is called from jQuery,
    // it modifies this, so that it is not the class itself, but the jQuery object,
    // so we can't do any of the actual stimulus things here
    const frame = document.querySelector('#program-ranks')

    if (frame) {
      if (this.value.split('_')[0] === 'from') {
        const program_id = this.value.split('_')[1]
        frame.src = `${window.location}/${program_id}/ranks`
      } else {
        frame.src = undefined
        frame.innerHTML = ''
      }
    }
  }
}
