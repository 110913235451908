import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'ranks',
    'programs',
    'billingContainer',
    'billingToggle',
    'billingAmount',
    'rankLabel',
    'programLabel',
  ]

  filterRanks({ target }) {
    const selectedOption = target.selectedOptions[0]
    if (selectedOption.value === 'No Program') return
    if (selectedOption.hasAttribute('data-ranks')) {
      this.ranksTarget.disabled = false
      this.rankSelect.enable()
    } else {
      this.ranksTarget.disabled = true
      this.rankSelect.disable()
    }

    const ranks = JSON.parse(selectedOption.getAttribute('data-ranks'))

    // The distinction between `option` and `item` here isn't super clear
    // but from the docs looks like option is the select options and item
    // is the SELECTED thing, so we have to do both here
    this.rankSelect.clear()
    this.rankSelect.clearOptions()

    if (!ranks) {
      this.rankSelect.addOption({ value: '', text: 'N/A' })
      this.rankSelect.addItem('')
      return
    }

    ranks.forEach((rank) => {
      this.rankSelect.addOption({ value: rank?.id, text: rank?.name || 'N/A' })
    })

    this.rankSelect.addItem(ranks[0].id)
  }

  selectMembership({ target }) {
    const selectedOption = target.selectedOptions[0]
    let params = new URLSearchParams()
    params.set('membership_template_id', selectedOption.value)
    const src = `/students/new?${params.toString()}`
    const frame = 'signup_section'
    this.setFrame({ params: { frame, src } })
  }

  toggleBilling({ target }) {
    if (target.checked) {
      this.billingContainerTarget.classList.remove('hidden')
      this.billingAmountTarget.disabled = false
    } else {
      this.billingContainerTarget.classList.add('hidden')
      this.billingAmountTarget.disabled = true
    }
  }

  durationKindChange({ target }) {
    if (target.value === 'attendance-based') {
      this.programSelect.removeOption('-1')
      const firstValue = Object.keys(this.programSelect.options)[0]
      this.programSelect.setValue(firstValue, false)
      this.programLabelTarget.classList.add('required')
      this.rankLabelTarget.classList.add('required')
    } else if (!this.programSelect.getOption('-1')) {
      this.programSelect.addOption({ value: '-1', text: 'No Program' })
      this.programSelect.setValue('-1', false)
      this.programSelect.addItem('-1')
      this.programSelect.refreshOptions(false)
      this.programLabelTarget.classList.remove('required')
      this.rankLabelTarget.classList.remove('required')
    }
  }

  ///
  /// private
  ///

  get programSelect() {
    return this.programsTarget.tomselect
  }

  get rankSelect() {
    return this.ranksTarget.tomselect
  }
}
