export const Pallette = {
  primaryHighlight: '#f2a900',
  secondaryDark: '#93928F',
  secondaryDarker: '#717171',
  secondaryDarkest: '#404040',
  secondaryDarkerThanDarkest: '#333',
  secondaryLight: '#e9e9e8',
  secondaryLighter: '#f2f2f2',
  white: '#fff',
  black: '#5d5c57',
  gray: '#808080',
  red: '#d9433e',
  blue: '#337ab7',
  green: '#449d44',
  purple: '#7653ad',
  almostBlack: '#262626',
  pureBlack: '#000',
  lightOrange: '#fff0d2',
  darkOrange: '#e8cd95',
  notificationsRed: '#ab6868',
  notificationsGreen: '#548054',
  notificationsBlue: '#44637d',
}

export const PalletteDark = {
  white: '#d9d9d9',
}
