/* global initializeDropdownTabs */
/* global initializeCounters */
/* global initializeTooltips */
/* global loadLazyPartial */
/* global initializeWidgetCollapseExpand */

function collapseExpandWidgetIfUserHasNoPreference(userPreferenceSelector) {
  'use strict'

  var windowWidth = 768

  if (!$(userPreferenceSelector).hasClass('user-preference')) {
    if (window.innerWidth <= windowWidth) $(userPreferenceSelector).removeClass('in')
    else $(userPreferenceSelector).addClass('in')
  }
}

function collapseExpandVitalsWidgetIfUserHasNoPreference() {
  collapseExpandWidgetIfUserHasNoPreference('#vitals-stat-widgets')
}

function updateVitalsWidgetDisplayPreference(path, isDisplayed) {
  'use strict'

  $.ajax({
    type: 'PUT',
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'))
    },
    url: path,
    data: { session: { isVitalsWidgetDisplayed: isDisplayed } },
  })

  $('#vitals-stat-widgets').addClass('user-preference')
}

$(document).on('turbo:load', function () {
  'use strict'

  collapseExpandVitalsWidgetIfUserHasNoPreference()

  $('.lazy-load.dashboard-vitals').on('lazy-load:complete', function () {
    collapseExpandVitalsWidgetIfUserHasNoPreference()

    initializeDropdownTabs($(this))
    initializeCounters($(this))

    $('.lazy-load.dashboard-vitals .dropdown-tabs .dropdown-tabs-menu li a').on(
      'voltage:dropdown-tabs:selected',
      function () {
        var $this = $(this)
        var $target = $(this).closest('.lazy-load')
        var path = $this.data('path')

        loadLazyPartial(path, $target)
      },
    )

    $('#vitals-stat-widgets').on('hidden.bs.collapse', function () {
      var $this = $(this)
      var path = $this.data('path')
      $('#vitals-toggler').addClass('fa-chevron-down')
      $('#vitals-toggler').removeClass('fa-chevron-up')

      updateVitalsWidgetDisplayPreference(path, false)
    })

    $('#vitals-stat-widgets').on('shown.bs.collapse', function () {
      var $this = $(this)
      var path = $this.data('path')

      $('#vitals-toggler').addClass('fa-chevron-up')
      $('#vitals-toggler').removeClass('fa-chevron-down')

      updateVitalsWidgetDisplayPreference(path, true)
    })

    $(window).on('resize', function () {
      collapseExpandVitalsWidgetIfUserHasNoPreference()
    })
  })

  $('.lazy-load.dashboard-recent-activity').on('lazy-load:complete', function () {
    initializeTooltips($(this))

    var $collapsiblePanel = $(this).find('section.panel.panel-default .panel-body')
    initializeWidgetCollapseExpand($collapsiblePanel)
  })

  $('.lazy-load.dashboard-calendar').on('lazy-load:complete', function () {
    var $collapsiblePanel = $(this).find('section.panel.panel-default .panel-body')
    initializeWidgetCollapseExpand($collapsiblePanel)
  })

  $('.lazy-load.dashboard-birthdays').on('lazy-load:complete', function () {
    const $collapsiblePanel = $(this).find('section.panel.panel-default .panel-body')
    initializeWidgetCollapseExpand($collapsiblePanel)
    initializeCollapsibleDropdowns()
  })
})

$(document).on('turbo:before-cache', () => {
  $('[data-toggle="tooltip"]').tooltip('hide')
  $('select.select2.select2-hidden-accessible').select2('destroy')
})

function initializeCollapsibleDropdown($dropdownName) {
  'use strict'
  $dropdownName.find('.birthday').on('show.bs.collapse', function (e) {
    e.stopPropagation()
    $dropdownName.removeClass('collapsed')
    $dropdownName.find('.target-icon').toggleClass('chevron-rotate')
  })

  $dropdownName.find('.birthday').on('hidden.bs.collapse', function (e) {
    e.stopPropagation()
    $dropdownName.addClass('collapsed')
    $dropdownName.find('.target-icon').toggleClass('chevron-rotate')
  })
}

function initializeCollapsibleDropdowns() {
  'use strict'
  const dropdownNames = ['first-month', 'next-month', 'month-after-next']
  for (const dropdownName of dropdownNames) {
    initializeCollapsibleDropdown($(`#${dropdownName}`))
  }
}
