/* eslint-env jquery */
/* global Turbo */

$(document).on('turbo:load', function () {
  'use strict'

  $('form#user-login-form').submit(function () {
    Turbo.cache.clear()
  })
})
