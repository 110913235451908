/* eslint-env jquery */
/* global initializeDropdownTabs */
/* global enableApplyToPreviewButton */
/* global handleProspectCreationToggle */
/* global initializeEmbedCodeModal */
/* eslint no-use-before-define: 0 */

function handleLCFNewFieldCreationSubmission(element, lastOrder) {
  'use strict'

  var $createBtn = $(element)
  var $customFieldTarget = $createBtn.closest('.custom-form-field-creator-wrapper')
  var nameValue = $customFieldTarget.find('input').val()
  if (nameValue.trim().length > 0) {
    var path = $createBtn.data('path')
    var token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      type: 'POST',
      url: path,
      data: { name: nameValue },
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(data) {
        if (data.status === 200) {
          $customFieldTarget.html(data.responseText)
          initializeLeadCaptureFormFields($customFieldTarget.find('.selected-form-field'))
          $customFieldTarget
            .find('.selected-form-field')
            .find('.selected-form-field-orderness input')
            .val(lastOrder + 1)
          enableApplyToPreviewButton()

          var collection = $customFieldTarget.parent().children()
          collection.find('.selected-form-field-orderness .move-field-down').removeClass('disabled')
          collection.find('.selected-form-field-orderness .move-field-up').removeClass('disabled')
          collection.first().find('.selected-form-field-orderness .move-field-up').addClass('disabled')
          collection.last().find('.selected-form-field-orderness .move-field-down').addClass('disabled')

          $customFieldTarget.find('.selected-form-field').unwrap()
        } else {
          $('#global-alerts').append(data.responseText)
        }
      },
    })
  }
}

function updateLeadCaptureFormStatus(container, path, button) {
  'use strict'

  button.attr('disabled', true)

  var token = $('meta[name="csrf-token"]').attr('content')
  $.ajax({
    type: 'PUT',
    url: path,
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    },
    complete(data) {
      if (data.status === 200) {
        container.fadeOut('fast', function () {
          container.html(data.responseText)
          initializeDropdownTabs(container)
          initializeLeadCaptureFormStatusDropdowns(container)

          container.fadeIn('fast')
        })
      } else {
        $('#global-alerts').append(data.responseText)
      }
    },
  })
}

function initializeLeadCaptureFormStatusDropdowns(parent) {
  'use strict'

  $(parent)
    .find('.lead-capture-form-container .dropdown-tabs .dropdown-tabs-menu li a')
    .on('voltage:dropdown-tabs:selected', function () {
      var $this = $(this)
      var $target = $this.closest('.lead-capture-form-wrapper')
      var path = $this.data('path')
      var $parentButton = $this.closest('.dropdown-tabs').find('button')

      return updateLeadCaptureFormStatus($target, path, $parentButton)
    })
}

function initializeLeadCaptureFormFields(parent) {
  'use strict'

  $(parent)
    .find('.selected-form-field-requireness')
    .on('click', function () {
      if (!$(this).find('.always-required').length) {
        var $actualFormField = $(this).find('input')
        $actualFormField.attr('checked', !$actualFormField.attr('checked')).trigger('change')

        $(this).find('.is-required').toggleClass('active')
        $(this).find('.not-required').toggleClass('active')

        enableApplyToPreviewButton()
        handleProspectCreationToggle()
      }
    })

  $(parent)
    .find('.selected-form-field-orderness .move-field-up')
    .on('click', function () {
      if (!$(this).hasClass('disabled')) {
        var $widget = $(this).closest('.selected-form-field')
        var $sibling = $widget.prev()

        var $parent = $(this).closest('.selected-form-field-orderness')
        var $hiddenFormField = $($parent.find("input[type='Number']")[0])
        var hiddenFormFieldValue = parseInt($hiddenFormField.val())

        var $hiddenFormFieldDos = $($sibling.find(".selected-form-field-orderness input[type='Number']")[0])
        var hiddenFormFieldDosValue = parseInt($hiddenFormFieldDos.val())

        $widget.find(".selected-form-field-orderness input[type='Number']").val(hiddenFormFieldDosValue)
        $sibling.find(".selected-form-field-orderness input[type='Number']").val(hiddenFormFieldValue)

        $sibling.before($widget)
        $widget.addClass('blink')
        setTimeout(function () {
          $widget.removeClass('blink')
        }, 2000)

        var collection = $widget.parent().children()
        collection.find('.selected-form-field-orderness .move-field-down').removeClass('disabled')
        collection.find('.selected-form-field-orderness .move-field-up').removeClass('disabled')
        collection.first().find('.selected-form-field-orderness .move-field-up').addClass('disabled')
        collection.last().find('.selected-form-field-orderness .move-field-down').addClass('disabled')

        enableApplyToPreviewButton()
      }
    })

  $(parent)
    .find('.selected-form-field-orderness .move-field-down')
    .on('click', function () {
      if (!$(this).hasClass('disabled')) {
        var $widget = $(this).closest('.selected-form-field')
        var $sibling = $widget.next()

        var $parent = $(this).closest('.selected-form-field-orderness')
        var $hiddenFormField = $($parent.find("input[type='Number']")[0])
        var hiddenFormFieldValue = parseInt($hiddenFormField.val())

        var $hiddenFormFieldDos = $($sibling.find(".selected-form-field-orderness input[type='Number']")[0])
        var hiddenFormFieldDosValue = parseInt($hiddenFormFieldDos.val())

        $widget.find(".selected-form-field-orderness input[type='Number']").val(hiddenFormFieldDosValue)
        $sibling.find(".selected-form-field-orderness input[type='Number']").val(hiddenFormFieldValue)

        $widget.before($sibling)
        $widget.addClass('blink')
        setTimeout(function () {
          $widget.removeClass('blink')
        }, 2000)

        var collection = $widget.parent().children()
        collection.find('.selected-form-field-orderness .move-field-down').removeClass('disabled')
        collection.find('.selected-form-field-orderness .move-field-up').removeClass('disabled')
        collection.first().find('.selected-form-field-orderness .move-field-up').addClass('disabled')
        collection.last().find('.selected-form-field-orderness .move-field-down').addClass('disabled')

        enableApplyToPreviewButton()
      }
    })

  $(parent)
    .find('.deselect-form-field')
    .click(function () {
      var $selectedFormField = $(this).closest('.selected-form-field')
      var $keyField = $selectedFormField.find('.selected-form-field-label input')

      $selectedFormField.remove()
      var splittedString = $keyField.attr('name').split('[')
      if (splittedString.length > 1) {
        var key = splittedString[1].split(']')[0]
        $('.form-field-options').find(`.form-option-${key}`).removeClass('disabled')
        enableApplyToPreviewButton()
        handleProspectCreationToggle()
      }
    })
}

$(document).on('turbo:load', function () {
  'use strict'

  $('.lazy-load#lead-capture-forms').on('lazy-load:complete', function () {
    initializeDropdownTabs($(this))
    initializeLeadCaptureFormStatusDropdowns($(this))
    initializeEmbedCodeModal()
  })

  initializeLeadCaptureFormStatusDropdowns($(this))
  initializeEmbedCodeModal()
  initializeLeadCaptureFormFields($('#additional-sidebar'))

  $('.form-option').on('click', function () {
    if (!$(this).hasClass('disabled')) {
      var $this = $(this)
      var $target = $('#lead-capture-form > .content')
      if (!$this.hasClass('custom-field-option')) $this.addClass('disabled')

      var lastOrder = parseInt(
        $('#additional-sidebar')
          .find('.selected-form-field')
          .last()
          .find('.selected-form-field-orderness input')
          .val(),
      )

      var path = $this.data('path')
      $.ajax({
        type: 'GET',
        url: path,
        complete(data) {
          if (data.status === 200) {
            $target.append(data.responseText)
            initializeLeadCaptureFormFields($('#additional-sidebar').find('.selected-form-field').last())
            var $lastField = $('#additional-sidebar').find('.selected-form-field').last()
            $lastField.find('.selected-form-field-orderness input').val(lastOrder + 1)
            if ($this.hasClass('custom-field-option')) {
              $lastField.find('input').focus()

              $lastField.find('input').on('keydown', function (e) {
                if (e.keyCode === 13) {
                  e.preventDefault()
                  handleLCFNewFieldCreationSubmission($lastField.find('.btn.create-field-btn'), lastOrder)
                }
              })

              $lastField.find('.btn.create-field-btn').on('click', function () {
                handleLCFNewFieldCreationSubmission($(this), lastOrder)
              })
            } else {
              enableApplyToPreviewButton()
            }

            $lastField[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          } else {
            $('#global-alerts').append(data.responseText)
            $this.removeClass('disabled')
          }
        },
      })
    }
  })
})
