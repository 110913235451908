/* global FullCalendar */
/* global initializeAdditionalInfoModalContent */
/* global Turbo */
/* global isMobile */
/* global initializeTooltips */
/* global loadLazyPartial */
/* global moment */
/* global buildListViewElement */
/* global buildEventContent */

import { getCookie, setCookie } from '../../javascript/helpers/cookies'

function activateClipboard() {
  const schoolButton = document.querySelector('.calendar-sync-copy-school')
  if (schoolButton !== null) {
    schoolButton.addEventListener('click', () => {
      const inputField = document.querySelector('#sync-url-school')
      inputField.select()
      navigator.clipboard.writeText(inputField.value)
    })
  }

  const personalButton = document.querySelector('.calendar-sync-copy-personal')
  if (personalButton !== null) {
    personalButton.addEventListener('click', () => {
      const inputField = document.querySelector('#sync-url-personal')
      inputField.select()
      navigator.clipboard.writeText(inputField.value)
    })
  }
}

let calendar
// TODO: This whole thing is a mess. We should really uh.. fix it.
// Correction, this is an actual nightmare.
$(document).on('turbo:load', function () {
  'use strict'

  $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } })

  function updateDate(info) {
    const updatedStartDate = new Date(info.event.startStr)
    const updatedEndDate = new Date(info.event.endStr)
    const moduloDistanceStartDate = updatedStartDate.getMinutes() % 15
    const moduloDistanceEndDate = updatedEndDate.getMinutes() % 15

    if (moduloDistanceStartDate <= 8) {
      updatedStartDate.setMinutes(updatedStartDate.getMinutes() - moduloDistanceStartDate)
    } else {
      updatedStartDate.setMinutes(updatedStartDate.getMinutes() + (15 - moduloDistanceStartDate))
    }

    if (moduloDistanceEndDate <= 8) {
      updatedEndDate.setMinutes(updatedEndDate.getMinutes() - moduloDistanceEndDate)
    } else {
      updatedEndDate.setMinutes(updatedEndDate.getMinutes() + (15 - moduloDistanceEndDate))
    }

    const eventType = info.event.id.split('_')[0]
    const eventId = info.event.id.split('_')[1]

    function updatedData() {
      if (eventType === 'class') {
        let data = {
          old_event_date: info.oldEvent._def.extendedProps.object_type.start_time.slice(0, 10),
          id: eventId,
          class_event: {
            name: info.event._def.title,
            date: moment(updatedStartDate).format('YYYY-MM-DD'),
            start_am_pm: moment(updatedStartDate).format('A'),
            end_am_pm: moment(updatedEndDate).format('A'),
            start_time: moment(updatedStartDate).format('h:mm'),
            end_time: moment(updatedEndDate).format('h:mm'),
            object_type: info.event._def.extendedProps.object_type.event.object_type,
            type: eventType,
          },
        }
        return data
      }
      let data = {
        start_date: updatedStartDate.toISOString(),
        end_date: updatedEndDate,
        type: eventType,
        id: eventId,
        person_type: info.event.extendedProps.dataPath.split('/')[1],
        person_id: info.event.extendedProps.dataPath.split('/')[2],
      }
      return data
    }

    function updatePath() {
      if (eventType === 'class') {
        let class_id = info.event._def.publicId.replace(/\D/g, '')
        let updatedPath = `schools/calendar/class_events/${class_id}/update/?`
        return updatedPath
      }
      let updatedPath = 'school/calendar/update_date'
      return updatedPath
    }

    if (eventType === 'class') {
      document.dispatchEvent(
        new CustomEvent('confirmation-modal:open', {
          detail: { data: updatedData(), path: updatePath(), info: info.el, calendar },
        }),
      )
    }
  }

  function closePopoverWithOutsideClick() {
    $(document).mouseup(function (e) {
      var tooltip = $('.fullcalendar-tooltip')
      if (!tooltip.is(e.target) && tooltip.has(e.target).length === 0) tooltip.remove()
    })
  }

  function centerPosition() {
    return '55%; top: 50%; transform: translate(-50%, -50%); position: fixed;">'
  }

  function dynamicPositionPopover(jsEvent) {
    if (isMobile()) return centerPosition()
    let left = jsEvent.pageX
    const top = jsEvent.pageY

    // The width of the popup can vary but its usually at least 320 pixels wide
    if (left > window.innerWidth - 320) {
      left = window.innerWidth - 320
    }

    return `${left}px; top: ${top}px;" >`
  }

  function dynamicPositionScrollablePopover(currentMousePos) {
    if (isMobile()) return centerPosition()
    return `${currentMousePos.x - 20}px; top: ${currentMousePos.y - 250}px;" position: absolute;" >`
  }

  function editEvent(path, fullCalendar) {
    var $updateEventModal = $('#update-event')
    var $globalAlerts = $('#global-alerts')

    $updateEventModal.html('<div class="spinner spinner-grow center" />')
    $updateEventModal.modal('show')

    $.get(path, () => {})
      .done(function (data) {
        $updateEventModal.html(data)
        initializeAdditionalInfoModalContent()

        $updateEventModal.modal()
        $updateEventModal.find('#description').focus()
        $updateEventModal.find('form').on('ajax:complete', function (e) {
          if (e.originalEvent.detail[0].status === 200) {
            fullCalendar.refetchEvents()
            $updateEventModal.modal('hide')
          } else if (e.originalEvent.detail[0].status === 403) {
            const errors = $('#update-event .modal-body .errors')
            errors.text(e.originalEvent.detail[0].responseText)
          } else {
            $globalAlerts.append(e.originalEvent.detail[0].responseText)
            $updateEventModal.modal('hide')
          }
        })
      })
      .fail(function (response) {
        $globalAlerts.append(response.responseText)
      })
  }

  const CustomViewConfig = {
    classNames: ['custom-list-view'],

    content(props) {
      const segs = FullCalendar.sliceEvents(props)
      if (calendar.shouldReloadView === true) {
        calendar.shouldReloadView = false

        // just shove the html that is currently there.. there again I guess
        return { domNodes: [document.querySelector('.fc-customListView')] }
      }

      return { domNodes: [buildListViewElement(segs, calendar)] }
    },
    visibleRange(currentDate) {
      const start = new Date(currentDate.valueOf())
      const end = new Date(currentDate.valueOf())
      end.setDate(end.getDate() + 7)

      return { start, end }
    },
    dayCount: 7,
  }

  const customListView = FullCalendar.createPlugin({
    views: {
      customList: CustomViewConfig,
    },
  })

  if ($('#calendar').length) {
    var currentMousePos = {
      x: -1,
      y: -1,
    }
    $(document).mousemove(function (event) {
      currentMousePos.x = event.pageX
      currentMousePos.y = event.pageY
    })

    var closeDiv = "<div class='close-container'> <i class='close-more fa fa-times'></i></div>"
    var toolTipDiv =
      "<div class='fullcalendar-tooltip'" +
      'id="fullcalendar-tooltip-scrollable"' +
      'style="position: absolute; z-index: 999; left: '

    closePopoverWithOutsideClick()

    calendar = new FullCalendar.Calendar(document.getElementById('calendar'), {
      locale: $('html')[0].lang,
      now: $('#calendar').data('now'),
      timeZone: $('body').data('timezone'),
      headerToolbar: false,
      initialView: getCookie('preferred_calendar_view') || 'timeGridWeek',
      themeSystem: 'bootstrap',
      nowIndicator: true,
      expandRows: true,
      dayMaxEvents: 3,
      navLinks: true,
      height: 'fit-content',
      snapDuration: '00:15:00',
      plugins: [customListView],
      navLinkDayClick(date) {
        if (moment.utc(date).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
          activateTodayButton()
        }

        this.changeView('timeGridDay', date)
        const startDate = calendar.view.currentStart

        $('.fc-toolbar-title').text(
          startDate.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
          }),
        )

        $('#mini-calendar-panel').removeClass('hidden')
      },
      moreLinkClick(info) {
        $('.fullcalendar-tooltip').remove()
        var eventsMarkup = info.allSegs
          .map(function (e) {
            return e.event.extendedProps.details
          })
          .slice(3)
          .join('')

        $('body').append(
          `${toolTipDiv + dynamicPositionScrollablePopover(currentMousePos) + closeDiv + eventsMarkup}</div>`,
        )
        initializeTooltips($('#fullcalendar-tooltip-scrollable'))
      },
      datesSet(info) {
        const button = $(`.fc-${info.view.type}-button`)
        $(button)
          .parent()
          .siblings('li')
          .each(function () {
            $(this).find('.active').removeClass('active')
          })
        $(button).addClass('active')
        $('.fc-header-toolbar .dropdown-btn .btn-text').text(button.first().text())
      },
      eventContent(info) {
        let content = buildEventContent(info)
        return { domNodes: [content] }
      },
      events: {
        url: $('#calendar').data('events'),
        method: 'GET',
        extraParams() {
          return {
            calendars: $('#calendar-options:visible input:checked')
              .map(function () {
                return this.id
              })
              .get()
              .join(';'),
            programs: $('#program-options:visible input:checked')
              .map(function () {
                return this.id
              })
              .get()
              .join(';'),
          }
        },
        failure(data) {
          $('#global-alerts').append(data.xhr.responseText)
        },
      },
      eventClick(info) {
        $('.fullcalendar-tooltip').remove()
        const id = info.event.id
        const details = info.event.extendedProps.details

        $('body')
          .append(
            `<div class='fullcalendar-tooltip tooltip-hover' 'id="fullcalendar-tooltip" data-id='
            ${id}' style="position: absolute; z-index: 999; left: ${dynamicPositionPopover(
              info.jsEvent,
            )}${closeDiv}${details}</div>`,
          )
          .fadeIn('fast')
        initializeTooltips($('#fullcalendar-tooltip'))
      },
      eventDragStart(info) {
        if (info.event.id.includes('class')) {
          info.el.dataset.controller = 'confirmation-submit'
          info.el.dataset.action = 'mouseup->confirmation-submit#showEditConfirmation'
          info.el.dataset.title = 'Save Changes'
          info.el.dataset.confirmText = 'Save'
          info.el.dataset.denyText = 'Cancel'
          info.el.dataset.message =
            'Are you sure you would like to save these changes? These changes will only affect this event.'
        }
        $('.fullcalendar-tooltip').remove()
      },
      eventDrop(info) {
        updateDate(info)
      },
      eventResize(info) {
        updateDate(info)
      },
    })
    updateCalendarDate()
    $(window).resize(function () {
      updateCalendarDate()
    })

    calendar.render()
  }

  function updateCalendarDate(options = {}) {
    if (options.date) {
      calendar.gotoDate(new Date(options.date))
    }

    const startDate = calendar.view.currentStart
    const endDate = calendar.view.activeEnd
    const shouldShowYear = !window.matchMedia('(min-width: 992px) and (max-width: 1200px)').matches

    switch (calendar.view.type) {
      case 'dayGridMonth':
        $('.fc-toolbar-title').text(
          startDate.toLocaleDateString(undefined, { month: 'long', year: 'numeric', timeZone: 'UTC' }),
        )
        break
      case 'timeGridDay':
        $('.fc-toolbar-title').text(
          startDate.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
          }),
        )
        break
      case 'customList':
      case 'timeGridWeek':
        // FCs activeEnd (and currentEnd) include the day after the range
        endDate.setDate(endDate.getDate() - 1)

        if (shouldShowYear) {
          $('.fc-toolbar-title').text(`
          ${startDate.toLocaleDateString(undefined, {
            month: 'short',
            year: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
          })}
          -
          ${endDate.toLocaleDateString(undefined, {
            month: 'short',
            year: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
          })}
        `)
        } else {
          $('.fc-toolbar-title').text(`
            ${startDate.toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              timeZone: 'UTC',
            })}
            -
            ${endDate.toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              timeZone: 'UTC',
            })}
          `)
        }
        break
      default:
        break
    }
  }

  $(document).on('turbo:submit-end', function (event) {
    if (event.detail.success && event.detail.formSubmission.formElement.action.includes('/calendar')) {
      if (calendar) {
        // Hacky way to refresh the Upcoming Schedule
        const trayFrame = document.getElementById('upcoming-tray')
        const frame = document.getElementById('upcoming')
        const { src } = frame
        frame.src = null
        frame.src = src
        trayFrame.src = null
        trayFrame.src = src

        calendar.refetchEvents()
      }

      const selectors = ['.lazy-load#students-calendar', '.lazy-load#prospects-calendar']
      for (const selector of selectors) {
        const $target = $(`${selector}`)
        if ($target.length) {
          const path = $target.data().path
          loadLazyPartial(path, $target, true, false)
        }
      }
    }
  })

  function activateTodayButton() {
    $('.fc-today-button').removeAttr('disabled')
  }

  $(document).on('calendar:updateCalendarDate', function (event) {
    const date = event.detail.date

    updateCalendarDate({ date })
    activateTodayButton()
  })

  $('.fc-prev-button').click(function () {
    if (calendar.view.type === 'customList') {
      const newDate = new Date(calendar.view.currentStart)
      newDate.setDate(newDate.getDate() - 7)
      updateCalendarDate({ date: newDate })
    } else {
      calendar.prev()
      updateCalendarDate()
    }

    activateTodayButton()
  })

  $('.fc-next-button').click(function () {
    if (calendar.view.type === 'customList') {
      const newDate = new Date(calendar.view.currentStart)
      newDate.setDate(newDate.getDate() + 7)
      updateCalendarDate({ date: newDate })
    } else {
      calendar.next()
      updateCalendarDate()
    }

    activateTodayButton()
  })

  $('.fc-today-button').click(function () {
    calendar.today()
    updateCalendarDate({ date: new Date() })
    $(this).attr('disabled', 'disabled')
  })

  $('.fc-dayGridMonth-button').click(function () {
    $(this)
      .parent()
      .siblings('li')
      .each(function () {
        $(this).find('.active').removeClass('active')
      })
    $(this).addClass('active')
    calendar.changeView('dayGridMonth')
    $('#mini-calendar-panel').addClass('hidden')
    updateCalendarDate()
    setCookie('preferred_calendar_view', 'dayGridMonth')
  })

  $('.fc-timeGridWeek-button').click(function () {
    $(this)
      .parent()
      .siblings('li')
      .each(function () {
        $(this).find('.active').removeClass('active')
      })
    $(this).addClass('active')
    calendar.changeView('timeGridWeek')
    $('#mini-calendar-panel').removeClass('hidden')
    updateCalendarDate()
    setCookie('preferred_calendar_view', 'timeGridWeek')
  })

  $('.fc-timeGridDay-button').click(function () {
    $(this)
      .parent()
      .siblings('li')
      .each(function () {
        $(this).find('.active').removeClass('active')
      })
    $(this).addClass('active')
    calendar.changeView('timeGridDay')
    $('#mini-calendar-panel').removeClass('hidden')
    updateCalendarDate()
    setCookie('preferred_calendar_view', 'timeGridDay')
  })

  $('.fc-customList-button').click(function () {
    $(this)
      .parent()
      .siblings('li')
      .each(function () {
        $(this).find('.active').removeClass('active')
      })
    $(this).addClass('active')
    calendar.changeView('customList')
    $('#mini-calendar-panel').removeClass('hidden')
    updateCalendarDate()
    setCookie('preferred_calendar_view', 'customList')
  })

  $('.fc-syncButton-button').click(function () {
    $('#calendar-sync-modal').modal('show')
  })

  $('#calendar-options input').change(function () {
    if (this.id === 'classes-calendar-option') {
      for (const checkbox of [...document.querySelectorAll('#program-options input')]) {
        checkbox.disabled = !this.checked
        checkbox.checked = this.checked
      }
    }

    calendar.refetchEvents()
  })

  $('#program-options input').change(function () {
    calendar.refetchEvents()
  })

  $('#sidebar-container').on('voltage:sidebar:collapsed voltage:sidebar:expanded', function () {
    if (calendar) calendar.updateSize()
  })

  $('body').on('click', '.close-more', function () {
    $('.fullcalendar-tooltip').remove()
  })

  $('body').on('click', '.edit-link', function () {
    editEvent($(this).data().editPath, calendar)
    $('.fullcalendar-tooltip').remove()
  })

  $('.new-event').on('click', function () {
    var $newEventModal = $('#new-event')
    var $globalAlerts = $('#global-alerts')
    var path = $(this).data().path

    $newEventModal.html('<div class="spinner spinner-grow center" />')
    $newEventModal.modal('show')

    $.get(path)
      .done(function (data) {
        $newEventModal.html(data)
        initializeAdditionalInfoModalContent()

        $newEventModal.modal()
        $newEventModal.find('#description').focus()
        $newEventModal.find('form').on('ajax:complete', function (e) {
          if (e.originalEvent.detail[0].status === 200) {
            $newEventModal.modal('hide')
            Turbo.visit(window.location.toString())
          } else {
            $globalAlerts.append(e.originalEvent.detail[0].responseText)
            $newEventModal.modal('hide')
          }
        })
      })
      .fail(function (response) {
        $globalAlerts.append(response.responseText)
      })
  })

  activateClipboard()
})
