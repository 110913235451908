import ApplicationController from '../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static targets = ['dialog']

  connect() {
    this.submitCallback = this.submit.bind(this)
    this.modalHiddenCallback = this.delete.bind(this)
    this.renderCallback = this.render.bind(this)

    this.element.addEventListener('modal:hidden', this.modalHiddenCallback, true)

    document.addEventListener('turbo:before-frame-render', this.renderCallback, true)
  }

  disconnect() {
    this.element.removeEventListener('modal:hidden', this.modalHiddenCallback, true)
    document.removeEventListener('turbo:submit-end', this.submitCallback, true)
    document.removeEventListener('turbo:before-frame-render', this.renderCallback, true)
  }

  ///
  /// private
  ///
  addSubmitListener() {
    document.addEventListener('turbo:submit-end', this.submitCallback, true)
  }

  submit(event) {
    if (event.detail.success) {
      this.hide()
    }
  }

  render({ detail }) {
    const defaultRendering = detail.render

    detail.render = async (currentElement, newElement) => {
      if (!currentElement.matches('#modal')) {
        await defaultRendering(currentElement, newElement)
        return
      }

      const newDialogClasses = newElement.querySelector('.modal-dialog').classList
      const oldContent = currentElement.querySelector('.modal-content')
      const newContent = newElement.querySelector('.modal-content')

      this.dialogTarget.classList.remove(...this.dialogTarget.classList)
      this.dialogTarget.classList.add(...newDialogClasses)

      await defaultRendering(oldContent, newContent)

      window.FontAwesome?.dom?.i2svg()
    }
  }

  hide() {
    this.element.classList.remove('open', 'in')

    this.waitForAnimations(this.element, () => {
      this.element.dispatchEvent(new CustomEvent('modal:hidden', { detail: { finished: false } }))
    })

    document.body.style.marginRight = null
  }

  delete() {
    this.element.remove()
  }
}
