import ApplicationController from '../../javascript/controllers/application_controller'

export const DEFAULT_TIMEOUT = 15000

export default class extends ApplicationController {
  static targets = ['pdfViewer']
  static values = { filePath: String, fileName: String, adobeId: String }

  connect() {
    // eslint-disable-next-line no-undef
    if (typeof AdobeDC === 'undefined') {
      document.addEventListener(
        'adobe_dc_view_sdk.ready',
        () => {
          this.setPDFViewer()
        },
        {},
      )
    } else {
      this.setPDFViewer()
    }
  }

  ///
  /// private
  ///

  setPDFViewer() {
    // eslint-disable-next-line no-undef
    let adobeDCView = new AdobeDC.View({ clientId: this.adobeIdValue, divId: this.pdfViewerTarget.id })
    let previewFilePromise = adobeDCView.previewFile(
      {
        content: { location: { url: this.filePathValue } },
        metaData: { fileName: this.fileNameValue },
      },
      { embedMode: 'SIZED_CONTAINER' },
    )

    const waitPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    const pdfTimeout = (pdfPromise, ms) =>
      Promise.race([
        pdfPromise,
        waitPromise(ms).then(() => {
          throw new Error(`PDF Viewer timeout after ${ms} ms`)
        }),
      ])

    pdfTimeout(previewFilePromise, DEFAULT_TIMEOUT).catch(() => {
      this.pdfViewerTarget.innerHTML = this.constructObjectViewer()
    })
  }

  constructObjectViewer() {
    // eslint-disable-next-line max-len
    return `<object data='${this.filePathValue}' class="h-[34rem]" type: 'application/pdf' width="100%" height:"100%"></object>`
  }
}
