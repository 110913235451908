import { Controller } from '@hotwired/stimulus'
import { elementIsVisible } from '../../helpers/visibility_helper'

export default class extends Controller {
  static targets = ['navButton', 'module', 'selectedClassDetails', 'customFormFields', 'header']

  connect() {
    if (this.navButtonTargets.length !== this.moduleTargets.length) {
      throw new Error('Mismatched number of step buttons and modules.')
    }

    this.steps = this.navButtonTargets.map((button, index) => index + 1)
    this.currentStep = 1
  }

  nextStep(event) {
    event.preventDefault()
    if (!this.visibleFieldsAreValid()) return
    this.currentStep += 1
    this.setStep(this.currentStep)
  }

  previousStep(event) {
    event.preventDefault()
    this.currentStep -= 1
    this.setStep(this.currentStep)
  }

  jumpToStep(event) {
    event.preventDefault()
    if (event.currentTarget.classList.contains('disabled')) return

    const step = parseInt(event.currentTarget.dataset.step)
    this.currentStep = step
    this.setStep(step)
  }

  ///
  /// private
  ///
  setStep(step) {
    this.shiftNavigation(step)

    window.scrollTo(0, this.headerTarget.offsetTop - 10)

    if (step === 1) this.resetState()

    this.shiftCarousels(step)
  }

  shiftNavigation(step) {
    const otherSteps = this.steps.filter((_step) => _step !== step)
    const index = step - 1

    this.navButtonTargets[index].classList.add('active')
    this.moduleTargets[index].classList.remove('hidden')
    this.navButtonTargets[index].classList.remove('disabled')
    this.navButtonTargets[index].classList.remove('reached')

    for (const _step of otherSteps) {
      const _index = _step - 1
      if (_step < step) {
        this.navButtonTargets[_index].classList.add('reached')
        this.navButtonTargets[_index].classList.remove('active')
        this.navButtonTargets[_index].classList.remove('disabled')
      } else {
        this.navButtonTargets[_index].classList.remove('reached')
        this.navButtonTargets[_index].classList.remove('active')
        this.navButtonTargets[_index].classList.add('disabled')
      }
    }
  }

  shiftCarousels(step) {
    const index = step - 1
    this.cleanCarouselItems(this.navButtonTargets)
    this.cleanCarouselItems(this.moduleTargets)

    this.setCarouselPosition(index, this.navButtonTargets)
    this.setCarouselPosition(index, this.moduleTargets)

    this.hideCarouselItems(this.navButtonTargets, true, false)
    this.hideCarouselItems(this.moduleTargets, false, true)
  }

  setCarouselPosition(index, items) {
    const currentItem = items[index]
    const prevItem = items[index - 1]
    const futureItem = items[index + 1]

    if (currentItem) {
      currentItem.classList.add('active')
    }

    if (prevItem) {
      prevItem.classList.add('prev')
    }

    if (futureItem) {
      futureItem.classList.add('next')
    }
  }

  cleanCarouselItems(items) {
    for (const item of items) {
      item.classList.remove('active', 'next', 'prev', 'hidden', 'hidden-xs')
    }
  }

  hideCarouselItems(items, small, hideInactive) {
    for (const item of items) {
      // Hide items that are not currently on screen
      const shouldHide = !(
        item.classList.contains('active') ||
        item.classList.contains('next') ||
        item.classList.contains('prev')
      )

      if (shouldHide) {
        item.classList.add(small ? 'hidden-xs' : 'hidden')
      }

      // Hide items on screen that are not active
      if (hideInactive && !item.classList.contains('active')) {
        item.classList.add('hidden')
      }
    }
  }

  resetState() {
    if (!this.hasSelectedClassDetailsTarget) return

    const fields = this.selectedClassDetailsTarget.querySelectorAll('input')
    const items = this.selectedClassDetailsTarget.querySelectorAll('.item')

    for (const field of fields) {
      if (field.id === 'class_required') return

      field.value = ''
    }

    for (const item of items) {
      item.querySelector('div').textContent = ''
    }
  }

  visibleFieldsAreValid() {
    if (!this.hasCustomFormFieldsTarget) return true

    const fields = [...this.customFormFields, ...this.publicController.agreementTokenFields].filter((field) =>
      elementIsVisible(field),
    )
    if (!fields.length) return true

    const signed = this.publicController.signatures.every((signature) => signature.completed)

    if (!signed) {
      this.publicController.reportSignatureValidity()
    }

    return fields.reduce((acc, field) => acc && field.reportValidity(), true) && signed
  }

  get customFormFields() {
    return [...this.customFormFieldsTarget.querySelectorAll('input')]
  }

  get publicController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'public')
  }
}
