/* global enableApplyToPreviewButton */

// eslint-disable-next-line no-unused-vars
export function initializeSchedulerBehavior() {
  'use strict'

  $('#scheduler-required').change(function () {
    enableApplyToPreviewButton()
  })
}
