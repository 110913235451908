/* global loadLazyPartial */

$(document).on('turbo:load', function () {
  'use strict'

  $('body').on('click', '.comments_filter_by_staff', function (event) {
    event.preventDefault()

    var $target = $('#comments')
    var path = $(this).data().path

    loadLazyPartial(path, $target)
  })
})
