import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['lineItem']

  remove({ target }) {
    if (this.lineItemTargets.length === 1) {
      const newInputs = this.lineItemTarget.querySelectorAll('input')

      for (const input of newInputs) {
        if (input.getAttribute('data-duplicate-fieldset-ignore') !== null) continue

        this.resetValues(input)
      }
      this.cleanSelects()
    } else {
      target.closest('.form-fields').remove()
      this.lineItemTarget.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  resetValues(input) {
    if (input.getAttribute('data-duplicate-fieldset-disabled') === null) {
      input.classList.remove('disabled')
      input.removeAttribute('readonly')
      input.value = ''
      input.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  cleanSelects() {
    const selects = this.lineItemTarget.querySelectorAll('select')
    for (const select of selects) {
      select.tomselect.clear()
      select.tomselect.load('')
      select.nextSibling.classList.add('cursor-pointer')
      select.nextSibling.querySelector('.ts-control').classList.remove('disabled')
    }
  }
}
