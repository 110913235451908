import { Controller } from '@hotwired/stimulus'

// This only works with a checkbox or radio buttons being the disabler at the moment
// will add support for other element types as we need them
export default class extends Controller {
  static targets = ['disabler', 'disablee']
  static values = {
    disable: { type: String, default: '0' },
  }

  connect() {
    if (this.hasDisablerTarget) this.toggle(false)
  }

  toggle(shouldFocus = true) {
    if (this.disablerTarget.type === 'checkbox' || this.disablerTarget.type === 'radio') {
      this.disableeTargets.forEach((disablee) => {
        disablee.disabled = !this.disablerTarget.checked
      })
    } else {
      this.disableeTargets.forEach((disablee) => {
        disablee.disabled = this.disablerTarget.value === this.disableValue
      })
    }
    if (!this.disableeTarget.disabled && shouldFocus) this.disableeTarget.focus()
    if (this.disablerTarget.disabled) this.disableeTarget.value = ''
  }

  betterToggle() {
    this.disableeTarget.disabled = !this.disableeTarget.disabled
  }

  add() {
    this.disableeTarget.disabled = true
    if (this.disableeTarget.getAttribute('data-toggle-disable-remove-value') !== null) {
      this.disableeTarget.value = ''
      this.disableeTarget.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  remove() {
    this.disableeTarget.disabled = false
  }
}
