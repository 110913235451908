import { Turbo } from '@hotwired/turbo-rails'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:submit-end', this.submit.bind(this))
  }

  submit(event) {
    if (event.detail.success && event.detail.fetchResponse.response.redirected) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.submit.bind(this))
  }
}
