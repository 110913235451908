import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static targets = ['toggler', 'direction']
  static outlets = ['filterable-list']
  static values = { filters: String, statuses: String }

  apply({ currentTarget, params }) {
    this.selectSort(currentTarget.nextElementSibling, params)
  }

  ///
  /// private
  ///

  selectSort(sortElement, { direction }) {
    this.togglerTarget.childNodes[0].textContent = sortElement.innerText
    this.directionTarget.value = direction

    this.element.closest('form').requestSubmit()
  }
}
