import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useClickOutside(this)
  }

  addHTTPS() {
    if (this.hasHTTP) return

    this.element.value = `https://${this.element.value}`
  }

  clickOutside() {
    if (!this.element.value) return

    this.addHTTPS()
  }

  ///
  /// private
  ///

  get hasHTTP() {
    return this.element.value.match(/^https?:\/\//) || this.element.value.match(/^http:\/\//)
  }
}
