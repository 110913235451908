import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectContainer', 'hiddenContainer', 'hiddenField', 'required']
  static values = {
    show: { type: String, default: '-1' },
  }

  connect() {
    if (this.hasSelectContainerTarget) this.change()
  }

  change() {
    if (this.selectContainerTarget.value === this.showValue) {
      this.hiddenContainerTarget.classList.remove('hidden')
      if (this.hasRequiredTarget) {
        this.requiredTarget.required = true
      }
    } else {
      this.hiddenContainerTarget.classList.add('hidden')
      if (this.hasRequiredTarget) {
        this.requiredTarget.required = false
      }
    }
  }
}
