import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = { relativeTo: String, selected: String }

  connect() {
    this.tomSelect = new TomSelect(this.element, { ...this.sharedOptions, ...this.extraOptions })

    if (this.selectedValue) {
      this.tomSelect.setValue(this.selectedValue?.split(','), true)
    }

    this.tomSelect.on('change', (value) => {
      this.selectedValue = value
    })

    if (!this.hasRelativeToValue) return

    const relativeTo = this.element.closest(this.relativeToValue)

    if (!relativeTo) throw new Error(`Could not find relativeTo element: ${this.relativeToValue}`)

    this.tomSelect.on('dropdown_open', (dropdown) => {
      const { top: relativeToTop, left: relativeToLeft } = relativeTo.getBoundingClientRect()
      const tsControl = this.tomSelect.control
      const {
        top: controlTop,
        left: controlLeft,
        width: controlWidth,
        height: controlHeight,
      } = tsControl.getBoundingClientRect()

      dropdown.style.width = `${controlWidth}px`

      dropdown.style.left = `${controlLeft - relativeToLeft}px`
      dropdown.style.top = `${controlTop - relativeToTop + controlHeight}px`
    })
  }

  disconnect() {
    this.tomSelect.destroy()
  }

  ///
  /// private
  ///
  get defaultOptions() {
    return {
      plugins: ['no_backspace_delete'],
      controlInput: null,
      maxItems: 1,
      selectOnTab: true,
      maxOptions: null,
    }
  }

  get extraOptionsMap() {
    return {
      'tom-select-with-search': { search: true, create: true, createOnBlur: true },
      'tom-select-with-search-no-create': { search: true, create: false, createOnBlur: false },
      'tom-select-multi': { maxItems: null },
      'tom-select-no-search': { search: false, render: { no_results: null } },
    }
  }

  get sharedOptions() {
    const selectedValue = this.selectedValue

    return {
      highlight: false,
      plugins: ['remove_button'],
      persist: false,
      selectOnTab: true,
      maxOptions: null,
      onInitialize() {
        this.wrapper.classList.remove('hidden')

        if (this.control.classList.contains('tom-select-multi')) return

        // We want other tomselects to be initialized before we set the value
        setTimeout(() => {
          this.addItem(selectedValue)
        }, 50)
      },
    }
  }

  get extraOptions() {
    if (this.element.classList.contains('tom-select-with-search')) {
      return this.extraOptionsMap['tom-select-with-search']
    } else if (this.element.classList.contains('tom-select-with-search-no-create')) {
      return this.extraOptionsMap['tom-select-with-search-no-create']
    } else if (this.element.classList.contains('tom-select-multi')) {
      return this.extraOptionsMap['tom-select-multi']
    } else if (this.element.classList.contains('tom-select-no-search')) {
      return this.extraOptionsMap['tom-select-no-search']
    }
    return this.defaultOptions
  }
}
