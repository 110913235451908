import { Controller } from '@hotwired/stimulus'
import { createSortable } from '../helpers/sortable_helper'

// This controller gets a little creative with the Stimulus API to
// let us use Stimulus patterns with SortableJS.

// TOOD: Do that same stuff to the DropSortableController at some point
export default class FormSortableController extends Controller {
  static targets = ['item', 'hiddenInput', 'handle']
  static values = {
    options: Object,
    write: Boolean,
  }

  connect() {
    if (this.hasWriteValue) {
      this.shouldWrite = this.writeValue
    } else {
      this.shouldWrite = true
    }

    const onEnd =
      typeof this.optionsValue.onEndMethod === 'string'
        ? this[this.optionsValue.onEndMethod]
        : this.write.bind(this)

    const options = {
      onEnd,
      draggable: '[data-form-sortable-target="item"]',
      ...this.optionsValue,
    }

    if (this.hasHandleTarget) {
      options.handle = '[data-form-sortable-target="handle"]'
    }

    createSortable(this.element, options)
  }

  itemTargetConnected() {
    this.write()
  }

  ///
  /// private
  ///
  write() {
    if (!this.shouldWrite) return

    for (const [index, input] of this.hiddenInputTargets.entries()) {
      input.value = index
    }

    this.hiddenInputTargets[0].dispatchEvent(new Event('change'))
  }
}
