import ApplicationController from '../../javascript/controllers/application_controller'

import { useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['selectedBadge']
  static outlets = ['filter-group']
  static debounces = ['submitForm']
  static values = { debounce: { type: Boolean, default: true }, badgeId: String }

  connect() {
    if (this.debounceValue) {
      useDebounce(this, { wait: 200 })
    }
    this.modifyFormDataCallback = this.modifyFormData.bind(this)

    this.#formTarget.addEventListener('formdata', this.modifyFormDataCallback)

    this.updateCount()
  }

  disconnect() {
    if (!this.#formTarget) return

    this.#formTarget.removeEventListener('formdata', this.modifyFormDataCallback)
  }

  reset() {
    this.filterGroupOutlets.forEach((filterGroup) => {
      filterGroup.selectAll(false)

      if (filterGroup.hasAllTarget) return

      this.updateCount()
    })

    this.#formTarget.requestSubmit()
  }

  modifyFormData({ formData }) {
    const temp = {}
    const keysToDelete = []

    for (const [key, value] of formData) {
      if (value.length === 0) {
        keysToDelete.push(key)

        continue
      }

      temp[key] ||= []
      temp[key].push(value)
    }

    keysToDelete.forEach((key) => {
      formData.delete(key)
    })

    for (const [key, value] of Object.entries(temp)) {
      formData.set(key, value.join(','))
    }
  }

  submitForm() {
    this.#formTarget.requestSubmit()
  }

  updateCount() {
    if (!this.#selectedBadge) return

    const amountSelected = this.element.querySelectorAll('input[name][type="checkbox"]:checked').length

    this.#selectedBadge.hidden = amountSelected <= 0

    if (amountSelected > 0) {
      this.#selectedBadge.innerText = amountSelected
    }
  }

  get #selectedBadge() {
    if (this.hasSelectedBadgeTarget) return this.selectedBadgeTarget
    if (!this.badgeIdValue) return null

    return document.querySelector(this.badgeIdValue)
  }

  get #formTarget() {
    return this.element.closest('form')
  }
}
