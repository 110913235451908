import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = { digits: Number }

  VALID_KEYS = ['Backspace', 'Enter', 'Tab', '0', 'ArrowLeft', 'ArrowRight']

  connect() {
    this.update()
  }

  denyInvalidKeys(event) {
    if (this.validKey(event.key)) return

    event.preventDefault()
  }

  update() {
    // Takes xx.yyy -> xxyyy
    const withoutDecimal = parseInt(this.value.match(/\d+/g)?.join('')) || 0
    this.value = this.constructDisplayValue(withoutDecimal)
  }

  ///
  /// private
  ///
  validKey(key) {
    return this.VALID_KEYS.includes(key) || !isNaN(key)
  }

  constructDisplayValue(text) {
    if (text === '') return ''

    // Takes xxyyy -> xx.yyy
    const result = (parseFloat(text) / 10 ** this.digitsValue).toFixed(this.digitsValue)

    if (parseFloat(result) >= 100) return (100.0).toFixed(this.digitsValue)

    return result
  }

  get value() {
    return this.inputTarget.value
  }

  set value(text) {
    this.inputTarget.value = text
  }
}
