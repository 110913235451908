import { Controller } from '@hotwired/stimulus'
import { setCookie } from '../../javascript/helpers/cookies'

// TODO: Eventually dashboard modules should probably all use these?
export default class extends Controller {
  static values = { cookieName: String }

  connect() {
    this.element.addEventListener('collapse--component:complete', this.setPreference.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('collapse--component:complete', this.setPreference)
  }

  ///
  /// private
  ///
  setPreference(event) {
    const expirationYears = 10
    const today = new Date()
    const expirationDate = new Date(today.getFullYear() + expirationYears, today.getMonth(), today.getDate())

    setCookie(this.cookieNameValue, !event.detail.collapsed, expirationDate)
  }
}
