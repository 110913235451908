/* global Turbo */
// TODO: Delete this file
// eslint-disable-next-line no-unused-vars
export function initializeWidgetCollapseExpand($collapsiblePanel) {
  $collapsiblePanel.on('hidden.bs.collapse', function () {
    var $this = $(this)
    var $toggler = $this.parent().find('.collapse-toggler')
    $toggler.addClass('fa-chevron-down')
    $toggler.removeClass('fa-chevron-up')
    $this.removeClass('in')
    $this.parent().addClass('collapsed')

    var pref_update = {}
    var $section = $(this).closest('.lazy-load')
    var section_name = $section
      .attr('id')
      .replace(/-module$/, '')
      .replace(/-/, '_')
    pref_update[section_name] = 'collapsed'

    updateDashboardCollapsePreferences(pref_update)
  })

  $collapsiblePanel.on('shown.bs.collapse', function () {
    var $this = $(this)
    var $toggler = $this.parent().find('.collapse-toggler')
    $toggler.addClass('fa-chevron-up')
    $toggler.removeClass('fa-chevron-down')
    $this.addClass('in')
    $this.parent().removeClass('collapsed')

    var pref_update = {}
    var $section = $(this).closest('.lazy-load')
    var section_name = $section
      .attr('id')
      .replace(/-module$/, '')
      .replace(/-/, '_')
    pref_update[section_name] = 'expanded'

    updateDashboardCollapsePreferences(pref_update)
  })
}

function updateDashboardCollapsePreferences(preferences) {
  var path = '/preferences/'
  $.ajax({
    type: 'PUT',
    url: path,
    data: {
      key: 'dashboard_collapse',
      preferences,
    },
    complete(data) {
      if (data.status === 200) Turbo.cache.clear()
      else $('#global-alerts').append(data.responseText)
    },
  })
}
