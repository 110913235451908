import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    sortType: { type: String, default: 'Number' },
    sortDirection: { type: String, default: 'desc' },
  }

  connect() {
    this.sortChildren()
  }

  ///
  /// private
  ///

  sortChildren() {
    if (this.childrenSorted) return

    this.children.sort(this.compare.bind(this)).forEach((child) => this.element.appendChild(child))
  }

  compare(left, right) {
    const leftCode = this.parse(left.getAttribute('data-sort-code')) || 0
    const rightCode = this.parse(right.getAttribute('data-sort-code')) || 0

    if (leftCode < rightCode) return this.sortDirectionValue === 'asc' ? -1 : 1
    if (leftCode > rightCode) return this.sortDirectionValue === 'asc' ? 1 : -1
    return 0
  }

  parse(value) {
    if (this.sortTypeValue === 'Number') return parseFloat(value)
    if (this.sortTypeValue === 'String') return value
  }

  get children() {
    return Array.from(this.element.children)
  }

  get childrenSorted() {
    let [left, ...rights] = this.children

    for (const right of rights) {
      if (this.compare(left, right) > 0) return false

      left = right
    }
    return true
  }
}
