/* eslint-env jquery */

function closeModalAndAlertForSuccess(e, modal) {
  'use strict'
  var xhr = e.detail[2]
  $(modal).modal('toggle')
  $('person-form form').trigger('reset')
  $('#global-alerts').html(xhr.responseText)
}

function alertForFailure(e) {
  'use strict'
  var xhr = e.detail[2]
  $('#global-alerts').html(xhr.responseText)
}

document.addEventListener('turbo:load', function () {
  'use strict'
  var modal = '#new-profile'
  $(modal).on('ajax:success', function (e) {
    closeModalAndAlertForSuccess(e, modal)
  })
  $(modal).on('ajax:error', function (e) {
    alertForFailure(e)
  })
})
