import { Controller } from '@hotwired/stimulus'
import { roundHalfEven } from '../helpers/rounding_helper'

export default class extends Controller {
  static targets = [
    'quantity',
    'description',
    'amountCents',
    'amountCentsHidden',
    'amount',
    'taxRate',
    'taxTotal',
    'paymentReasonId',
    'itemId',
    'itemType',
    'inventoryItemId',
    'itemName',
    'uncategorizedId',
  ]

  static values = {
    admin: { type: Boolean, default: false },
    defaultTaxRate: { type: String, default: '0' },
  }

  useTax = true

  connect() {
    this.updateAmount()
  }

  // when an inventory item is selected
  populate({ target }) {
    const option = target.options[target.selectedIndex]

    if (!option.dataset.id && !option.value) {
      this.resetValues()

      return
    }

    if (!option.dataset.id) {
      this.populateWithoutId(option)

      return
    }

    if (option.dataset.paymentReasonId) {
      this.populatePaymentReason(option)
    }

    if (option.dataset.amountCents) {
      this.populateAmountCents(option)
    }

    this.disableFields()
  }

  disableFields() {
    const paymentReasonSelect = this.paymentReasonIdTarget.nextSibling.querySelector('.ts-control')
    paymentReasonSelect.classList.add('disabled')
    paymentReasonSelect.setAttribute('tabindex', '-1')
    this.paymentReasonIdTarget.nextSibling.classList.add('cursor-not-allowed')
    this.paymentReasonIdTarget.nextSibling.classList.remove('cursor-pointer')
    if (!this.adminValue) this.amountCentsTarget.setAttribute('readonly', 'true')
    if (!this.adminValue) this.amountCentsTarget.setAttribute('tabindex', '-1')
    if (!this.adminValue) this.amountCentsTarget.classList.add('disabled')
  }

  enableFields() {
    const paymentReasonSelect = this.paymentReasonIdTarget.nextSibling.querySelector('.ts-control')
    this.paymentReasonIdTarget.tomselect.clear()
    this.paymentReasonIdTarget.tomselect.load('')
    this.paymentReasonIdTarget.tomselect.setValue(this.uncategorizedIdTarget.value)
    paymentReasonSelect.removeAttribute('tabindex')
    paymentReasonSelect.classList.remove('disabled')
    this.paymentReasonIdTarget.nextSibling.classList.add('cursor-pointer')
    this.paymentReasonIdTarget.nextSibling.classList.remove('cursor-not-allowed')
    this.amountCentsTarget.removeAttribute('readonly')
    this.amountCentsTarget.removeAttribute('tabindex')
    this.amountCentsTarget.classList.remove('disabled')
    this.resetValues()
  }

  resetValues() {
    this.amountCentsHiddenTarget.value = 0
    this.taxRateTarget.value = 0
    this.amountTarget.value = 0
    this.taxTotalTarget.value = 0
    this.taxTotalTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  // when the price(amountCentsHidden) or quantity has changed update the amount
  updateAmount() {
    const quantity = this.quantityTarget.value
    const price = this.amountCentsHiddenTarget.value
    const amountField = quantity * parseFloat(price)
    this.amountTarget.value = amountField

    if (this.useTax) {
      const option = this.descriptionTarget.options[this.descriptionTarget.selectedIndex]
      let taxRate = 0
      if (option.dataset.id) {
        taxRate = option.dataset.taxRate
        this.calculateTax(taxRate)
        this.taxRateTarget.value = taxRate
      } else {
        taxRate = parseFloat(this.defaultTaxRateValue)
        this.taxRateTarget.value = taxRate
        this.calculateTax(taxRate)
      }
    } else {
      this.taxRateTarget.value = 0
      this.taxTotalTarget.value = 0
    }

    this.amountTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  toggleTax() {
    this.useTax = !this.useTax
  }

  ///
  /// private
  ///
  calculateTax(taxRate) {
    this.taxTotalTarget.value = roundHalfEven((taxRate / 100) * this.amountTarget.value, 0)
  }

  populateWithoutId(option) {
    if (this.hasItemNameTarget) {
      this.itemNameTarget.value = option.value
    }

    this.enableFields()
  }

  populatePaymentReason(option) {
    let control = this.paymentReasonIdTarget.tomselect
    control.addOption({ value: option.dataset.paymentReasonId })
    control.addItem(option.dataset.paymentReasonId)
  }

  populateAmountCents(option) {
    this.amountCentsHiddenTarget.value = option.dataset.amountCents

    if (this.hasItemIdTarget) {
      this.itemIdTarget.value = option.dataset.id
      this.itemTypeTarget.value = 'InventoryItem'
    }
    if (this.hasInventoryItemIdTarget) {
      this.inventoryItemIdTarget.value = option.dataset.id
    }

    this.itemNameTarget.value = option.dataset.name
    this.taxRateTarget.value = option.dataset.taxRate
    this.updateAmount()
  }
}
