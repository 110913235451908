import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenText', 'cta', 'ellipsis', 'overflowEllipsis']
  static values = { expand: String, collapse: String }

  connect() {
    this.hidden = true
  }

  toggleFullDisplay(e) {
    e.preventDefault()

    this.hidden = !this.hidden
    if (this.hidden) {
      this.ctaText = this.expandValue
    } else {
      this.ctaText = this.collapseValue
    }
  }

  ///
  /// private
  ///
  get hidden() {
    return this.hiddenTextTarget.hidden
  }

  set hidden(hidden) {
    this.hiddenTextTarget.hidden = hidden

    if (this.hasOverflowEllipsisTarget) {
      this.overflowEllipsisTarget.hidden = hidden
    }

    this.ellipsisTarget.hidden = !hidden
  }

  set ctaText(text) {
    this.ctaTarget.innerHTML = text
  }
}
