/* eslint-env jquery */

$(document).on('turbo:load', function () {
  'use strict'

  let $quickConnectToggle = $('#quick-connect-toggle')

  $quickConnectToggle.bootstrapToggle('destroy')
  $quickConnectToggle.bootstrapToggle()
  $quickConnectToggle.bootstrapToggle('enable')

  if ($quickConnectToggle.prop('checked') === false) $('.sms-selector-container').addClass('hidden')

  // Display "You need a Required Phone#" message when no require phone# (or when on Landing Page)
  let $phoneNumberRequiredField = $('[name="fields[phone][required]"]').parent()
  if (!$phoneNumberRequiredField.length) {
    $quickConnectToggle.parent().addClass('disabled')
    $('.quick-connect-container .phone-number-needed').removeClass('hidden')
  }

  // initial check for lcf qc enable-able
  if ($('.selected-module .lead-capture-form-item input:checked').data('canEnableQuickConnect')) {
    $quickConnectToggle.parent().removeClass('disabled')
    $('.quick-connect-container .phone-number-needed').addClass('hidden')
  }

  // Enable toggling when QC-able LCF selected
  $('.lead-capture-form-item label').on('click', function (e) {
    if ($(e.target).parent().find('input').data('canEnableQuickConnect')) {
      $quickConnectToggle.parent().removeClass('disabled')
      $('.quick-connect-container .phone-number-needed').addClass('hidden')
    } else {
      $quickConnectToggle.parent().addClass('disabled')
      $quickConnectToggle.bootstrapToggle('off')
      $('.quick-connect-container .phone-number-needed').removeClass('hidden')
    }
  })

  // Enable toggling when phone selected on LCF edit
  $('li.form-option-phone').on('click', function () {
    $quickConnectToggle.parent().removeClass('disabled')
    $('.quick-connect-container .phone-number-needed').addClass('hidden')
  })

  // Toggle off when phone number set to not required
  $('input[value=phone]')
    .parents('.other-fields')
    .find('.selected-form-field-requireness .btn')
    .on('click', function (e) {
      if ($(e.target).parents('.btn').find('.is-required.active')) {
        $quickConnectToggle.bootstrapToggle('off')
        $('.quick-connect-container .phone-number-needed').removeClass('hidden')
      } else {
        $quickConnectToggle.parent().removeClass('disabled')
        $('.quick-connect-container .phone-number-needed').addClass('hidden')
      }
    })

  $quickConnectToggle.change(function () {
    if (this.checked) {
      $('.sms-selector-container').removeClass('hidden')

      $phoneNumberRequiredField.find('input').prop('checked', true)
      $phoneNumberRequiredField.find('.is-required').toggleClass('active')
      $phoneNumberRequiredField.find('.not-required').toggleClass('active')
      $('.quick-connect-container .set-phone-number-required').removeClass('hidden')
    } else {
      $('.sms-selector-container').addClass('hidden')
      $('.quick-connect-container .set-phone-number-required').addClass('hidden')
    }
  })

  let $quickConnectEmailToggle = $('#quick-connect-email-toggle')

  $quickConnectEmailToggle.bootstrapToggle('destroy')
  $quickConnectEmailToggle.bootstrapToggle()
  $quickConnectEmailToggle.bootstrapToggle('enable')

  $quickConnectEmailToggle.change(function () {
    if (this.checked) {
      $('.email-selector-container .form-fields').show()
      $('.email-selector-container').removeClass('hidden')
    } else {
      $('.email-selector-container').addClass('hidden')
    }
  })
})
