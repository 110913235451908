/* global Pallette */
/* global Rails */
/* global showLoadingIndicator */
/* global hideLoadingIndicator */
/* global usaepay */

$(document).ready(ePayOnLoad)
$(document).on('lazy-load:complete', ePayOnLoad)

function ePayOnLoad() {
  'use strict'

  if ($('#usa-epay-card-element').length) {
    let FORM = $('#usa-epay-card-element').closest('form')[0]
    var USA_EPAY_PUBLIC_TOKEN = $('#usa-epay-public-token').data('public-token')
    var $usaEpaySubmitButton = $('button[data-disable-with]')
    var USA_EPAY_FORM_SUBMIT_DISABLE_WITH_VALUE = $usaEpaySubmitButton.data('disable-with')
    var USA_EPAY_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE = $usaEpaySubmitButton.html()
    var USA_EPAY_CHARGE_FORM_READY_FOR_SUBMISSION = false
    $usaEpaySubmitButton.removeAttr('data-disable-with')

    if (USA_EPAY_PUBLIC_TOKEN === undefined) throw new Error('No public_token found.')

    var secondaryColor = Pallette.black
    if ($('#credit-card-token').data('colors-secondary-color').length) {
      secondaryColor = $('#credit-card-token').data('colors-secondary-color')
    }

    var tertiaryColor = Pallette.white
    if ($('#credit-card-token').data('colors-tertiary-color').length) {
      tertiaryColor = $('#credit-card-token').data('colors-tertiary-color')
    }

    var invalidColor = Pallette.red
    if ($('#credit-card-token').data('colors-secondary-color').length) {
      invalidColor = $('#credit-card-token').data('colors-secondary-color')
    }

    var client = new usaepay.Client(USA_EPAY_PUBLIC_TOKEN)
    var paymentCard = client.createPaymentCardEntry()
    var style = {
      base: {
        backgroundColor: tertiaryColor,
        color: secondaryColor,
        lineHeight: '36px',
        fontFamily: '"Hind Vadodara", sans-serif',
        fontSize: '16px',
        textTransform: 'uppercase',
        height: '36px',
      },
      '{}\n@media (max-width: 767px) { #payjs-cvv { width: 3em; } #payjs-exp { width: 4em; } }': '',
      invalid: { color: invalidColor },
    }
    paymentCard.generateHTML(style)

    if ($('#usa-epay-card-element').html() === '') {
      paymentCard.addHTML('usa-epay-card-element')
    }

    paymentCard.addEventListener('error', function (errorMessage) {
      if ($('#usa-epay-card-element').closest('.hidden').length) {
        USA_EPAY_CHARGE_FORM_READY_FOR_SUBMISSION = true
      } else {
        var baseErrorContainer = document.getElementById('base-card-errors')
        baseErrorContainer.textContent = ''
        var errorContainer = document.getElementById('usa-epay-card-errors')
        errorContainer.textContent = errorMessage
        $usaEpaySubmitButton.attr('disabled', false)
        $usaEpaySubmitButton.html(USA_EPAY_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
        hideLoadingIndicator()
      }
    })

    document.querySelector('#usa-epay-card-element').addEventListener('clear:usaepay', () => {
      this.innerHTML = ''
      paymentCard.addHTML('usa-epay-card-element')
    })

    $('#usa-epay-card-element')
      .closest('form')
      .on('submit', function () {
        $usaEpaySubmitButton.attr('disabled', true)
        $usaEpaySubmitButton.html(USA_EPAY_FORM_SUBMIT_DISABLE_WITH_VALUE)
        showLoadingIndicator()

        return true
      })

    setTimeout(function () {
      $('#usa-epay-card-element')
        .closest('form')
        .on('submit', function (event) {
          if (USA_EPAY_CHARGE_FORM_READY_FOR_SUBMISSION) return true

          if (event.isDefaultPrevented()) {
            $usaEpaySubmitButton.attr('disabled', false)
            $usaEpaySubmitButton.html(USA_EPAY_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
            hideLoadingIndicator()

            return false
          }

          event.preventDefault()

          client
            .getPaymentKey(paymentCard)
            .then(function (result) {
              $('input#credit-card-token').val(result)
              USA_EPAY_CHARGE_FORM_READY_FOR_SUBMISSION = true
              Rails.fire(FORM, 'submit')
            })
            .catch(function () {
              if ($('#usa-epay-card-element').closest('.hidden').length) {
                USA_EPAY_CHARGE_FORM_READY_FOR_SUBMISSION = true
                Rails.fire(FORM, 'submit')
              } else {
                $usaEpaySubmitButton.attr('disabled', false)
                $usaEpaySubmitButton.html(USA_EPAY_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
                hideLoadingIndicator()
              }
            })

          return false
        })
    }, 500)
  }
}
