/* eslint-env jquery */

$(document).on('turbo:load', function () {
  var fixableHeader = $('.fixable-header')[0]

  if (fixableHeader) {
    var fixableHeaderAltitude = $('.fixable-header').offset().top
    var fixableHeaderHeight = $('.fixable-header').outerHeight(true)
    var fixableHeaderBuffer = $('.fixable-header-buffer')[0]
    fixableHeaderBuffer.style.height = `${fixableHeaderHeight}px`

    /// Pins the header to the top of the screen on scroll
    document.addEventListener('scroll', function () {
      'use strict'
      var distanceFromTop = Math.abs(document.body.getBoundingClientRect().top)

      var desiredWidth = `${$(fixableHeader).parent().width()}px`
      var desiredClass = 'fixable-header-fixed'

      if ($(fixableHeader)[0] !== undefined) {
        if (distanceFromTop >= fixableHeaderAltitude) {
          fixableHeader.classList.add(desiredClass)
          fixableHeader.style.width = desiredWidth
          if (fixableHeaderBuffer) fixableHeaderBuffer.style.display = 'inherit'
        } else {
          fixableHeader.classList.remove(desiredClass)
          fixableHeader.style.width = '100%'
          if (fixableHeaderBuffer) fixableHeaderBuffer.style.display = 'none'
        }
      }
    })
  }
})
