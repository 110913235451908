import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['total', 'adjustment', 'newTotal']
  static values = { code: String, exponent: Number }

  subtract() {
    const total = this.parseTotalTarget
    const adjustment = parseFloat(this.adjustmentTarget.value)
    this.newTotalTarget.innerText = this.constructDisplayValue(total - adjustment)
  }

  resetFields({ target: { defaultValue } }) {
    if (defaultValue === 'write_off') {
      this.adjustmentTarget.value = this.parseTotalTarget
    } else {
      this.adjustmentTarget.value = 0
    }
    this.subtract()
  }

  constructDisplayValue(newTotal) {
    const options = {
      minimumFractionDigits: this.exponentValue,
      maximumFractionDigits: this.exponentValue,
      style: 'currency',
      currency: this.codeValue,
    }
    const result = new Intl.NumberFormat(this.codeValue, options).format(newTotal * this.subunitMultiplier)

    return result
  }

  get subunitMultiplier() {
    return 10 ** -this.exponentValue
  }

  get parseTotalTarget() {
    return parseFloat(this.totalTarget.innerText.replace(/[^0-9|-]/g, ''))
  }
}
