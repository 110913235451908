import { Controller } from '@hotwired/stimulus'
import { Pallette } from '../../../vendor/assets/javascripts/voltage-themes/pallette'

export default class extends Controller {
  static values = { intent: String }

  saveCard(event) {
    event.target.disabled = true

    this.confirmSetupIntent().then((success) => {
      if (success) {
        let parentForm = document.querySelector(`#new-card`)
        parentForm.requestSubmit()
      } else {
        event.target.disabled = false
      }
    })
  }

  confirmSetupIntent() {
    let card = this.card
    let opts = {}
    let name = document.getElementById('stripe-cardholder-name')

    opts.payment_method = {
      card,
      billing_details: {
        name: name.value,
      },
    }

    return this.stripe.confirmCardSetup(this.intentValue, opts).then(function (result) {
      if (result.error) {
        var errorElement = document.getElementById('stripe-card-errors')
        errorElement.textContent = result.error.message
        return false
      }

      $('input#credit-card-token').val(result.setupIntent.payment_method)
      return true
    })
  }

  // eslint-disable-next-line no-unused-vars
  setPaymentToken(tokenize, _tokenTarget) {
    let card = this.card
    let opts = {}
    if (document.querySelector('.save-vault input') && document.querySelector('.save-vault input').checked) {
      opts.setup_future_usage = 'off_session'
    }

    if (tokenize) {
      opts.payment_method = { card }
    } else {
      let vault = document.querySelector('.vaults:checked').dataset.reference
      opts.payment_method = vault
    }

    return this.stripe.confirmCardPayment(this.intentValue, opts).then(function (result) {
      if (result.error) {
        const errorElement = document.getElementById('stripe-card-errors')
        errorElement.textContent = result.error.message
        return false
      }

      $('input#credit-card-token').val(result.paymentIntent.id)
      return true
    })
  }

  connect() {
    const STRIPE_PUBLIC_KEY = $('#stripe-account-token').data('public-key')
    const STRIPE_ACCOUNT_TOKEN = $('#stripe-account-token').data('account')

    // NOTE: This is dumb
    let secondaryColor = Pallette.black

    if ($('body.dark').length) {
      secondaryColor = Pallette.white
    }

    const invalidColor = Pallette.red

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(STRIPE_PUBLIC_KEY, { stripeAccount: STRIPE_ACCOUNT_TOKEN })
    let elements = this.stripe.elements()
    this.card = elements.create('card', {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: secondaryColor,
          color: secondaryColor,
          lineHeight: '36px',
          fontFamily: '"Hind Vadodara", sans-serif',
          fontSize: '19px',
          textTransform: 'uppercase',

          '::placeholder': { color: '#aeaeab' },
        },
        invalid: {
          iconColor: invalidColor,
          color: invalidColor,
        },
      },
    })
    this.card.mount('#stripe-card-form')

    this.card.addEventListener('change', function (event) {
      const displayError = document.getElementById('stripe-card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    document.querySelector('#stripe-card-form').addEventListener('clear:stripe', () => {
      this.card.clear()
    })
  }
}
