import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['registrantList', 'showBtn', 'hideBtn']

  showRegistrants() {
    this.registrantListTarget.classList.remove('hidden')
    this.hideBtnTarget.classList.remove('hidden')
    this.showBtnTarget.classList.add('hidden')
  }

  hideRegistrants() {
    this.registrantListTarget.classList.add('hidden')
    this.hideBtnTarget.classList.add('hidden')
    this.showBtnTarget.classList.remove('hidden')
  }
}
