export function previewBeltGraphic($form) {
  const formData = $form.serialize()
  const path = `/settings/programs/ranks/preview_belt_graphic?${formData}`

  var token = $('meta[name="csrf-token"]').attr('content')
  var html
  $.ajax({
    type: 'GET',
    url: path,
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    },
    async: false,
    complete(data) {
      if (data.status === 200) html = data.responseText
      else return false
    },
  })
  return html
}
