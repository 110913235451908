import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggleBorder({ currentTarget }) {
    let classDetails = this.element.querySelectorAll('.class_detail.border-kicksite-yellow-600')

    classDetails.forEach((currentElement) => {
      currentElement.classList.toggle('border-kicksite-yellow-600')
      currentElement.classList.toggle('border-primary')
    })

    currentTarget.classList.toggle('border-primary')
    currentTarget.classList.toggle('border-kicksite-yellow-600')
  }
}
