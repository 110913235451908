/***
 * Removes extra backdrops and other modals
 */
function removeModals() {
  for (const _modal of [...document.querySelectorAll('.modal')]) {
    $(_modal).on('hidden.bs.modal', function () {
      $(this).remove()
    })

    $(_modal).modal('hide')
  }
  // These are technically vestigial now we should rewrite the modal js
  for (const _backdrop of [...document.querySelectorAll('.modal-backdrop')]) {
    _backdrop.remove()
  }
}

function removeModalOpen() {
  document.body.classList.remove('modal-open')
}

export function manageClassEventDragAndDrop(modal, calendar) {
  $(modal).modal('show')

  const closeBtn = modal.querySelector('.fa-xmark')
  const cancelBtn = modal.querySelector('.btn.btn-default')
  const saveBtn = modal.querySelector('.btn.btn-primary')

  closeBtn.addEventListener(
    'click',
    () => {
      calendar.refetchEvents()
      removeModalOpen()
    },
    {
      once: true,
    },
  ),
    cancelBtn.addEventListener(
      'click',
      () => {
        calendar.refetchEvents()
        removeModalOpen()
      },
      {
        once: true,
      },
    ),
    saveBtn.addEventListener(
      'click',
      () => {
        modal.remove()
        removeModalOpen()
      },
      {
        once: true,
      },
    )
}

function modal(showBackdrop) {
  const _modal = document.createElement('div')
  _modal.classList.add('modal')
  _modal.classList.add('modal-form')
  _modal.classList.add('success-modal')
  _modal.classList.add('fade')
  _modal.setAttribute('role', 'dialog')
  _modal.setAttribute('tabindex', '-1')
  _modal.setAttribute('data-backdrop', showBackdrop ? 'true' : 'false')

  return _modal
}

function modalDialog() {
  const _modalDialog = document.createElement('div')
  _modalDialog.classList.add('modal-dialog')
  return _modalDialog
}

function modalContent() {
  const _modalContent = document.createElement('div')
  _modalContent.classList.add('modal-content')
  return _modalContent
}

function modalHeader() {
  const _modalHeader = document.createElement('div')
  _modalHeader.classList.add('modal-header')
  return _modalHeader
}

function modalTitle(title, options) {
  const _modalTitle = document.createElement('div')
  _modalTitle.classList.add('modal-title')
  if (options.classes) {
    _modalTitle.classList.add(options.classes)
  }
  _modalTitle.innerHTML = title
  return _modalTitle
}

function modalBody() {
  const _modalBody = document.createElement('div')
  _modalBody.classList.add('modal-body')
  return _modalBody
}
function modalBodyMessage(message) {
  const _modalBodyMessage = document.createElement('div')
  _modalBodyMessage.classList.add('text-center')
  _modalBodyMessage.innerHTML = message
  return _modalBodyMessage
}

function modalFooter() {
  const _modalFooter = document.createElement('div')
  _modalFooter.classList.add('modal-footer')
  return _modalFooter
}

function closeButton() {
  const _closeButton = document.createElement('button')
  _closeButton.classList.add('close')
  _closeButton.setAttribute('type', 'button')
  _closeButton.setAttribute('aria-label', 'Close')
  _closeButton.addEventListener('click', removeModals)
  return _closeButton
}

function icon() {
  const _icon = document.createElement('i')
  _icon.classList.add('fa')
  _icon.classList.add('fa-times')
  return _icon
}

function cancelButton(denyText) {
  const _cancelButton = document.createElement('button')
  _cancelButton.classList.add('btn')
  _cancelButton.classList.add('btn-default')
  _cancelButton.setAttribute('type', 'button')
  _cancelButton.addEventListener('click', removeModals)
  _cancelButton.innerHTML = denyText
  return _cancelButton
}

function submitButton(confirmText, form_id) {
  const _submitButton = document.createElement('button')
  _submitButton.classList.add('btn')
  _submitButton.classList.add('btn-primary')
  _submitButton.setAttribute('type', 'submit')
  _submitButton.setAttribute('form', form_id)
  _submitButton.setAttribute('name', 'commit')
  _submitButton.innerHTML = confirmText
  return _submitButton
}

function submitAnchor(confirmText, path, method) {
  const _submitAnchor = document.createElement('a')
  _submitAnchor.classList.add('btn')
  _submitAnchor.classList.add('btn-primary')
  _submitAnchor.setAttribute('href', path)
  _submitAnchor.setAttribute('data-method', method)
  _submitAnchor.addEventListener('click', removeModals)
  _submitAnchor.innerHTML = confirmText
  return _submitAnchor
}

/***
 * Creates a modal with the given title, message, button text and options
 *
 * @param {String} title - The title of the modal
 * @param {String} confirmText - The text of the confirm button
 * @param {String} denyText - The text of the deny button
 * @param {String} message - The message to display in the modal
 * @param {Object} options - The form id or path to submit to as an object
 *
 * @return {HTMLElement} The completely constructed modal
 * @example
 * const modal = createModal('Confirm!', 'OK', 'Cancel', 'Your changes have been saved!', { form_id: '#form' })
 */
export function createModal(
  title,
  confirmText,
  denyText,
  message,
  options = {
    form_id: '',
    path: '',
    method: '',
    payload: '',
    showBackdrop: false,
    classes: '',
  },
) {
  const _modal = modal(options.showBackdrop)
  const _modalDialog = modalDialog()
  const _modalContent = modalContent()
  const _modalHeader = modalHeader()
  const _modalTitle = modalTitle(title, options)
  const _modalBody = modalBody()
  const _modalBodyMessage = modalBodyMessage(message)
  const _modalFooter = modalFooter()
  const _closeButton = closeButton()
  const _icon = icon()
  const _cancelButton = cancelButton(denyText)
  const _submitButton = options.form_id
    ? submitButton(confirmText, options.form_id)
    : submitAnchor(confirmText, options.path, options.method)

  // Construct the header
  _closeButton.appendChild(_icon)
  _modalHeader.appendChild(_modalTitle)
  _modalHeader.appendChild(_closeButton)

  // Construct the body
  _modalBody.appendChild(_modalBodyMessage)

  // Construct the footer
  _modalFooter.appendChild(_cancelButton)
  _modalFooter.appendChild(_submitButton)

  // Slap all of those into the modal-content div
  _modalContent.appendChild(_modalHeader)
  _modalContent.appendChild(_modalBody)
  _modalContent.appendChild(_modalFooter)

  // Construct the final modal
  _modalDialog.appendChild(_modalContent)
  _modal.appendChild(_modalDialog)

  return _modal
}
