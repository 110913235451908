import IMask from 'imask'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['price']

  connect() {
    const element = this.priceTarget
    const maskOptions = {
      mask: Number,
      scale: 2,
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: '.',
    }

    IMask(element, maskOptions)
  }
}
