/* eslint-env jquery */
/* global loadLazyPartial */

$(document).on('turbo:load', function () {
  'use strict'

  var $$$schoolSearchResponse = null
  $('#search').on('keyup', function (e) {
    if (e.key !== 'Tab') {
      var query = $(this).val()

      var $this = $(this)
      var $target = $('#search-results')
      var path = `${$this.data('path')}?query=${query}`

      var before = $this.val()
      setTimeout(function () {
        if (before === $this.val()) {
          if ($$$schoolSearchResponse !== null) $$$schoolSearchResponse.abort()

          $$$schoolSearchResponse = loadLazyPartial(path, $target, true, false)
        }
      }, 300)
    }
  })

  function toggleSearch() {
    if ($(window).width() < 768) {
      $('#profile-dropdown .dropdown').toggleClass('hidden')
      $('.search-active').toggleClass('w-0 w-full')
      $('#profile-dropdown').toggleClass('w-fit w-full z-[1002] z-[1003]')
    }
    $('#search-button').toggleClass('hidden')
    $('#search-container').toggleClass('hidden')
  }

  $('#profile-dropdown').click(function (e) {
    const clickedOnSearchIcon =
      e.target.id === 'search-button' ||
      (e.target.tagName === 'path' && e.target.getAttribute('d').startsWith('M416'))

    if (clickedOnSearchIcon) {
      toggleSearch()
      $('#search').focus()
    }
  })

  $('#search-close').click(function () {
    $('#search').val('')
    toggleSearch()
  })

  $('#search').on('focus', function () {
    $('#search-results').removeClass('hidden')
  })

  $('#search').on('focusout', function () {
    if ($(this).val() === '') {
      $('#search-results').addClass('hidden')
    }
  })
})
