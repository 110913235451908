import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static values = { enabled: Boolean }
  static targets = ['selectAllOnPage', 'selectAllOnAllPages', 'deselectAll', 'selectedAmount', 'execute', 'anchor']

  connect() {
    this.adjustWidth()

    if (this.enabledValue) {
      this.show()
    } else {
      this.hide()
    }

    const selectedAction = document.querySelector(
      '[data-controller="filterable-list--action"] input:checked',
    )?.parentElement

    if (!selectedAction) return

    this.setupActionButton({
      detail: {
        title: selectedAction.getAttribute('data-filterable-list--action-title-value'),
        path: selectedAction.getAttribute('data-filterable-list--action-path-value'),
      },
    })
  }

  show() {
    this.element.classList.remove('invisible')
    this.element.classList.add('opacity-100')
    this.element.classList.remove('opacity-0')
  }

  hide() {
    this.element.classList.remove('opacity-100')
    this.element.classList.add('opacity-0')

    this.waitForAnimations(this.element, () => {
      this.element.classList.add('invisible')
    })
  }

  setupActionButton({ detail: { title, path } }) {
    this.executeTarget.innerText = title
    this.executeTarget.setAttribute('data-filterable-list-path-param', path)
  }

  adjustElements({ detail: { amount, total, perPage } }) {
    this.adjustSelectionButtons(amount, total, perPage)

    this.selectedAmountTarget.innerText = amount
  }

  adjustWidth() {
    this.element.style.width = `${this.element.parentElement.offsetWidth}px`
  }

  ///
  /// private
  ///

  adjustSelectionButtons(amount, total, perPage) {
    if (amount > 0) {
      this.deselectAllTarget.disabled = false
      this.executeTarget.disabled = false
    } else {
      this.deselectAllTarget.disabled = true
      this.executeTarget.disabled = true
    }

    const hasPages = total > perPage

    if (!hasPages) {
      this.selectAllOnPageTarget.classList.remove('hidden')
      this.selectAllOnAllPagesTarget.classList.add('hidden')

      this.selectAllOnPageTarget.disabled = amount === total
      this.selectAllOnAllPagesTarget.disabled = true

      return
    }

    const atLeastOnePageSelected = amount >= perPage

    if (amount === total) {
      if (atLeastOnePageSelected) {
        this.selectAllOnPageTarget.classList.add('hidden')
        this.selectAllOnAllPagesTarget.classList.remove('hidden')

        this.selectAllOnPageTarget.disabled = true
        this.selectAllOnAllPagesTarget.disabled = true

        return
      }

      this.selectAllOnPageTarget.classList.remove('hidden')
      this.selectAllOnAllPagesTarget.classList.add('hidden')

      this.selectAllOnPageTarget.disabled = true
      this.selectAllOnAllPagesTarget.disabled = true

      return
    }

    if (atLeastOnePageSelected) {
      this.selectAllOnPageTarget.classList.add('hidden')
      this.selectAllOnAllPagesTarget.classList.remove('hidden')

      this.selectAllOnPageTarget.disabled = true
      this.selectAllOnAllPagesTarget.disabled = false

      return
    }

    this.selectAllOnPageTarget.classList.remove('hidden')
    this.selectAllOnAllPagesTarget.classList.add('hidden')

    this.selectAllOnPageTarget.disabled = false
    this.selectAllOnAllPagesTarget.disabled = true
  }
}
