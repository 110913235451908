import { Controller } from '@hotwired/stimulus'

export default class ToggleClassController extends Controller {
  static targets = ['element', 'required']
  static values = { selector: String }
  static classes = ['actionable']

  connect() {
    if (this.hasSelectorValue) {
      this.toToggle = document.querySelector(this.selectorValue)
    } else if (this.hasElementTarget) {
      this.toToggle = this.elementTarget
    } else {
      this.toToggle = this.element
    }

    const eventId = this.toToggle.id || this.toToggle.classList[0]

    this.addEvent = new Event(`add:${eventId}`)
    this.removeEvent = new Event(`remove:${eventId}`)
  }

  add() {
    this.toToggle.classList.add(this.actionableClass)
    if (this.hasRequiredTarget) {
      this.requiredTarget.required = false
    }

    document.dispatchEvent(this.addEvent)
  }

  remove() {
    this.toToggle.classList.remove(this.actionableClass)
    if (this.hasRequiredTarget) {
      this.requiredTarget.required = true
    }

    document.dispatchEvent(this.removeEvent)
  }

  toggle() {
    this.toToggle.classList.toggle(this.actionableClass)
  }
}
