import ApplicationController from './application_controller'

export default class extends ApplicationController {
  removeEmailField({ target }) {
    const emailInput = target.closest('.email-input')

    emailInput.nextElementSibling.hidden = false
    emailInput.remove()
  }
}
