/***
 * Removes all extra modal backdrops so there is only one
 */
export function removeExtraBackdrops() {
  if (document.querySelectorAll('.modal-backdrop').length > 1) {
    document.querySelector('.modal-backdrop').remove()
  }
}

export function removeModalOpen() {
  document.body.classList.remove('modal-open')
}
