/* eslint-disable no-unused-vars */
/* global $globalAlerts, loadLazyPartial, */

export function initializeAccountFormSubmit() {
  $('#student-account')
    .find('form')
    .submit(function (e) {
      var btn = $(this).find("input[type='submit']")
      $(btn).val('Submitting')
      $(btn).prop('disabled', true)

      return true
    })

  $('#student-account')
    .find('form')
    .on('ajax:complete', function (e) {
      var status = e.originalEvent.detail[0].status
      if (status === 200 || status === 201) {
        var $target = $(this).closest('.lazy-load')
        var path = $target[0].dataset.path

        loadLazyPartial(path, $target)
        if (status === 201) $('#account-created-modal').modal('show')
      } else {
        $globalAlerts.append(e.originalEvent.detail[0].responseText)
      }
    })
}

export function initializeCloseUserAccount() {
  $('#close-student-account').on('click', function () {
    $('#confirm-close-account-modal').modal('show')
  })

  $('#confirm-close-account').on('click', function (e) {
    e.preventDefault()
    $(this).addClass('disabled')
    var path = $('#close-student-account').data('path')
    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      type: 'DELETE',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(data) {
        $('#confirm-close-account-modal').modal('hide')
        var $target = $('#student-account')
        var loadPath = $target.data('path')

        loadLazyPartial(loadPath, $target)
      },
    })
  })
}

export function initializeAccountRestrictionChecks() {
  $('.restriction-check').unbind('click')

  // TODO: Can we abstract this + inventory-item-check?
  $('.restriction-check').on('click', function () {
    if ($(this)[0].classList.contains('unchecked')) {
      $(this).removeClass('unchecked')
      $(this).addClass('checked')
      $(this).prev().prop('checked', true)
    } else {
      $(this).removeClass('checked')
      $(this).addClass('unchecked')
      $(this).prev().prop('checked', false)
    }
  })
}

export function initializeChangeUserAccountPassword() {
  $('#student-account #change-password').on('click', function () {
    var path = $(this).data('path')

    const $modal = $('#change-password-modal')
    $modal.modal('show')

    $modal.on('click', '.toggle-vis', function () {
      $(this).toggleClass('fa-eye')
      $(this).toggleClass('fa-eye-slash')
      var input = $(this).closest('.password-form').find('input')

      if (input[0].type === 'password') input[0].type = 'text'
      else input[0].type = 'password'
    })

    $(document).on('hidden.bs.modal', '#change-password-modal', function () {
      $('#change-password-modal').find('#password').val('')
      $('#change-password-modal').find('#password_confirmation').val('')
      $('#confirm-change-password').attr('disabled', false)
    })

    $modal.on('click', '#confirm-change-password', function () {
      var password = $modal.find('#password').val()
      var confirmation = $modal.find('#password_confirmation').val()

      // TODO: This logic (matching password confirmation and length) should really be
      // pushed further down the stack, but our user password validation is currently
      // almost completely non-existant, so that would be a bigger task that it
      // should be. This can be a part of DEV-5384 maybe.
      if (password !== confirmation) {
        $modal.find('#password_confirmation').css({ 'border-bottom-color': '#d9433e' })
        $modal.find('.no-matchy-error').removeClass('hidden')
      } else if (password.length < 3) {
        $modal.find('#password_confirmation').css({ 'border-bottom-color': '#d9433e' })
        $modal.find('.too-short-error').removeClass('hidden')
      } else {
        $modal.find('.no-matchy-error').addClass('hidden')
        $modal.find('.too-short-error').addClass('hidden')
        $modal.find('#password_confirmation').css({ 'border-bottom-color': '' })
        $(this).attr('disabled', true)

        $.ajax({
          url: path,
          type: 'PUT',
          data: { user: { password } },
          success() {
            $modal.modal('hide')
            $('#password-updated-modal').modal('show')
          },
        })
      }
    })
  })
}
