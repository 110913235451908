/* global AdobeDC */

export const DEFAULT_TIMEOUT = 15000

// eslint-disable-next-line no-unused-vars
export function addPDFPreview(target) {
  const waitPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  const pdfTimeout = (pdfPromise, ms) =>
    Promise.race([
      pdfPromise,
      waitPromise(ms).then(() => {
        throw new Error(`PDF Viewer timeout after ${ms} ms`)
      }),
    ])

  const adobeDCView = new AdobeDC.View({
    clientId: target.dataset.adobeClientId,
    divId: target.id,
  })
  const previewFilePromise = adobeDCView.previewFile(
    {
      content: { location: { url: target.dataset.path } },
      metaData: { fileName: target.dataset.name },
    },
    {
      embedMode: 'SIZED_CONTAINER',
    },
  )

  pdfTimeout(previewFilePromise, DEFAULT_TIMEOUT).catch(() => {
    // eslint-disable-next-line max-len
    const objectElement = `<object data='${target.dataset.path}' class="h-full" type: 'application/pdf' width="100%" height:"100%"></object>`
    target.innerHTML = objectElement
  })
}
