import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { min: Number, max: Number }

  deny(event) {
    if (event.key !== '.') return

    event.preventDefault()
  }

  clamp() {
    const value = this.element.value
    if (value === '') return

    if (this.hasMaxValue && value > this.maxValue) {
      this.element.value = this.maxValue
    }

    if (value !== this.element.value) {
      this.element.dispatchEvent(new Event('change'))
    }
  }
}
