import { Controller } from '@hotwired/stimulus'
import { Pallette } from '../../../vendor/assets/javascripts/voltage-themes/pallette'
export default class extends Controller {
  static targets = ['token']

  saveCard(event) {
    event.target.disabled = true

    this.setPaymentToken(true, this.tokenTarget).then((success) => {
      if (success) {
        this.parentForm.requestSubmit()
      } else {
        event.target.disabled = false
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  setPaymentToken(tokenize, tokenTarget) {
    if (!tokenize) return Promise.resolve(true)

    this.tokenizer.submission = (resp) => {
      this.element.dispatchEvent(new CustomEvent('tokenized', { detail: resp }))
    }

    this.tokenizer.submit()

    return new Promise((resolve) => {
      this.element.addEventListener('tokenized', function (result) {
        if (result.detail.status === 'success') {
          tokenTarget.value = result.detail.token
          let event = new Event('change')
          tokenTarget.dispatchEvent(event)
          resolve(true)
        } else if (result.detail.status === 'validation') {
          document.getElementById('basys-card-errors').innerHTML = 'Invalid card information'
          resolve(false)
        } else {
          document.getElementById('basys-card-errors').innerHTML = result.detail.msg
          resolve(false)
        }
      })
    })
  }

  mountCard() {
    // NOTE: This is dumb
    this.submitButton.disabled = true
    let secondaryColor = Pallette.black

    if (document.querySelector('body.dark')) {
      secondaryColor = Pallette.white
    }

    const invalidColor = Pallette.red
    const basysPublicKey = document.getElementById('basys-account-token').dataset.publicKey

    const self = this
    // eslint-disable-next-line no-undef
    this.tokenizer = new Tokenizer({
      apikey: basysPublicKey,
      container: document.querySelector('#basys-card-form'),
      onLoad() {
        for (const element of document.querySelectorAll('.basys-form-component .hidden')) {
          element.classList.remove('hidden')
        }

        document.querySelector('#loading').classList.add('hidden')

        self.submitButton.disabled = false
      },
      settings: {
        payment: { placeholderCreditCard: 'CARD NUMBER' },
        styles: {
          '.payment input': {
            border: `solid 2px ${Pallette.secondaryDark}`,
            'border-radius': '5px',

            'padding-top': '20px',
            'padding-bottom': '20px',

            'background-color': 'transparent',
            color: secondaryColor,
            'line-height': '36px',
            'font-family': '"Hind Vadodara", sans-serif',
            'font-size': '19px',
            'text-transform': 'uppercase',
          },

          '.payment .cc input': {
            'padding-top': '20px',
            'padding-bottom': '20px',
          },

          '.payment input.invalid': { border: `solid 2px ${invalidColor}` },
        },
      },
    })
  }

  get parentForm() {
    return this.element.closest('form')
  }

  get submitButton() {
    const formId = this.parentForm.id

    return document.querySelector(`[form="${formId}"]`)
  }
}
