/* eslint-disable no-unused-vars */
/* global enableApplyToPreviewButton */
/* global initializeProgramSignupBehavior */
/* global initializeSchedulerBehavior */

import { enableApplyToPreviewButton } from '../../bizbuilders'

export function addCountToCounter(inputName) {
  'use strict'
  var count = $(`.${inputName}-item-check:checked`).length
  $(`.${inputName}-count`).text(count)
}

export function enableApplyButtonOnNewMembershipSelection() {
  $('#add-new-trial-membership').on('click', function () {
    enableApplyToPreviewButton()
  })
}

export function activateApplyButtonWhenMembershipChecked() {
  'use strict'
  $('body').on('click', 'label[id^=membership]', function () {
    enableApplyToPreviewButton()
  })
  $('label[id^=membership]').off()
  $('label[id^=membership]').on('click', function () {
    const inputId = $(this).siblings()[0].id
    $(`#${inputId}`).prop('checked', !$(`#${inputId}`).prop('checked'))

    enableApplyToPreviewButton()
    addCountToCounter('membership')
  })
  enableApplyButtonOnNewMembershipSelection()
}

export function initializeTrialMembershipBehavior() {
  'use strict'

  activateApplyButtonWhenMembershipChecked()
}

export function initializeProspectOffWarning() {
  'use strict'
  $('.create-prospect-toggle-container .toggle').on('click', function () {
    if ($('.membership-items-container').hasClass('active')) {
      if ($(this).find('input').is(':checked')) {
        $('#prospect-warning').show()
      } else {
        $('#prospect-warning').hide()
      }
    }
  })

  $('.membership-item').on('click', function () {
    $('#prospect-warning').hide()
  })
}

$(document).on('turbo:load', function () {
  'use strict'
  initializeTrialMembershipBehavior()
  initializeProgramSignupBehavior()
  initializeSchedulerBehavior()
  initializeProspectOffWarning()
})
/* eslint-enable no-unused-vars */
