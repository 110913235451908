import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']

  update({ params: { key } }) {
    this.containerTargets.forEach((container) => {
      if (key === container.id) {
        container.hidden = false
      } else {
        container.hidden = true
      }
    })
  }
}
