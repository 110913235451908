/* global initializeAttendanceLogProgramSelector */
/* global initializeTooltips */
/* global initializeDropdownTabs */
/* global loadLazyPartial */
/* global highlightModule */
/* global initializeAccountFormSubmit */
/* global initializeCloseUserAccount */
/* global initializeAccountRestrictionChecks */
/* global initializeChangeUserAccountPassword */
/* global $updateEventModal */
/* global initializeAdditionalInfoModalContent */
/* global gon, filestack */

export function registerProfileModulesDropdownClickEvents(module) {
  'use strict'
  $(module)
    .find('.dropdown-tabs .dropdown-tabs-menu li a')
    .on('voltage:dropdown-tabs:selected', function () {
      var $this = $(this)
      var path = $this.data('path')

      if (module.hasClass('.lazy-load')) {
        var $target = $(this).closest('.lazy-load')
        loadLazyPartial(path, $target)
      } else {
        module[0].src = path
      }
    })
}

export function initializeViewAllProfileItemsActionable(actionableElement) {
  'use strict'

  $(actionableElement).on('click', function () {
    var $this = $(this)
    var $table = $(this).closest('.panel.module').find('table')
    var $footer = $(this).closest('.panel.module').find('.footer')
    var path = $this.data('path')
    if (path === undefined) return

    $.ajax({
      type: 'GET',
      url: path,
      complete(data) {
        $table.find('tbody').html(data.responseJSON.table_data)
        $footer.html(data.responseJSON.footer)
        if (data.status === 200) {
          initializeTooltips($table)
          initializeAgreementDeletes()
        }
      },
    })
  })
}

export function initializeViewMoreProfileItemsActionable(actionableElement) {
  'use strict'

  $(actionableElement).on('click', function () {
    var $this = $(this)
    var $table = $(this).closest('.panel.module').find('table')
    var $footer = $(this).closest('.panel.module').find('.footer')
    var path = $this.data('path')

    $.ajax({
      type: 'GET',
      url: path,
      complete(data) {
        $table.append(data.responseJSON.table_data)
        $footer.html(data.responseJSON.footer)
        if (data.status === 200) {
          initializeTooltips($table)
          initializeViewMoreProfileItemsActionable($footer.find('.view-more-results'))
          initializeViewAllProfileItemsActionable($footer.find('.view-all'))
          initializeInfiniteViewAll($footer.find('.view-all'))
          initializeAgreementDeletes()
          initializeHoverTooltips($table)
          initializePaymentNotesModal($table)
          initializeDocumentDeletes()
        }
      },
    })
  })
}

export function initializeInfiniteViewAll(actionableElement, module) {
  'use strict'

  if (module === undefined) module = $(actionableElement).closest('.panel.module')

  $(actionableElement).click(function () {
    var $viewMoreResults = module.find('.view-more-results')
    var $viewAllResults = module.find('.view-all')
    var $infinityScrollable = module.find('.infinity-scroll')

    $viewMoreResults.click()
    $viewMoreResults.addClass('disabled')
    $viewAllResults.addClass('disabled')

    function onScroll() {
      $infinityScrollable.scroll(function () {
        var bottom = this.scrollTop >= this.scrollHeight - this.offsetHeight

        if (bottom) {
          $('.history-spinner').remove()
          $(this).append("<span class='history-spinner fa fa-spinner fa-spin' <span/>")

          if ($(this).parent().find('.btn-group.at-bottom').length > 0) {
            $('.history-spinner').remove()
          } else {
            $viewMoreResults = module.find('.view-more-results')
            $viewMoreResults.click()
            $(this).unbind('scroll')

            if ($viewMoreResults.length <= 0) $('.history-spinner').remove()
          }
        }
      })
    }

    onScroll()

    $(document).ajaxComplete(function () {
      module.find('.view-more-results').addClass('disabled')
      module.find('.view-all').addClass('disabled')
      $('.history-spinner').remove()
      $('input.select-all[type=checkbox]:checked').prop('checked', false)
      onScroll()
    })
  })
}

function initializeFileUpload() {
  var post_url = $('#customer-document-upload').data('postUrl')
  var options = {
    maxFiles: 5,
    fromSources: ['local_file_system', 'url'],
    displayMode: 'inline',
    container: '#document-upload-inline',
    onUploadDone(files) {
      store_filestack_data(files.filesUploaded, post_url)

      $('#add-documents-modal').modal('hide')
    },
  }

  var client = filestack.init(gon.filestack_api_key)
  client.picker(options).open()

  $('#customer-document-upload').click(function (e) {
    e.preventDefault()

    $('#add-documents-modal').modal('show')
  })

  function store_filestack_data(filesUploaded, url) {
    jQuery.ajax({
      type: 'POST',
      url,
      data: { filestack_attributes: JSON.stringify(filesUploaded) },
      success() {
        if ($('.lazy-load#documents').length) {
          var target = $('.lazy-load#documents')
          var path = target[0].dataset.path

          loadLazyPartial(path, target)
        } else {
          let frame = document.querySelector('#documents')
          if (frame) frame.reload()
        }
      },
    })
  }
}

function initializeDocumentDeletes() {
  $('.delete-documents').on('click', function (e) {
    e.preventDefault()

    var document_ids = []
    $('#documents')
      .find('.documents-checkbox:checked')
      .each(function (i, checkbox) {
        document_ids.push(checkbox.id)
      })

    remove_filestack_data({ document_ids }, $('.delete-documents').data('path'))
  })

  function remove_filestack_data(data, path) {
    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      type: 'DELETE',
      url: path,
      data,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      success() {
        if ($('.lazy-load#documents').length) {
          var target = $('.lazy-load#documents')
          var docsPath = target[0].dataset.path

          loadLazyPartial(docsPath, target)
        } else {
          let frame = document.querySelector('turbo-frame#documents')
          if (frame) frame.reload()
        }
      },
    })
  }
}

export function initializeAgreementDeletes() {
  'use strict'

  $('.student-agreement-delete, .prospect-agreement-delete').on('click', function (e) {
    e.preventDefault()
    var path = $(this).data('path')
    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      type: 'DELETE',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(data) {
        if (data.status !== 200) $('#global-alerts').append(data.responseText)

        var target = $('.lazy-load#agreements')
        loadLazyPartial(target[0].dataset.path, target)
        return false
      },
    })

    return true
  })
}

function initializeCommentDeletes() {
  'use strict'

  $('.person-comment-delete').on('click', function (e) {
    e.preventDefault()
    var path = $(this).data('path')
    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      type: 'DELETE',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(data) {
        if (data.status !== 200) $('#global-alerts').append(data.responseText)
        let frame = document.querySelector('turbo-frame#comments')
        if (frame) frame.reload()
        return false
      },
    })

    return true
  })
}

function initializeAddNewComment(actionableElement) {
  actionableElement.find('.new-comment').on('click', function (e) {
    e.preventDefault()

    var $globalAlerts = $('#global-alerts')
    var path = $(this)[0].dataset.path
    var $modal = $('#comment-modal')

    $modal.html('<div class="spinner spinner-grow center" />')
    $modal.modal('show')

    $.get(path).done(function (data) {
      $modal.html(data)
      $modal.modal()

      $modal.find('.btn-primary').attr('disabled', 'disabled')
      $modal.find('textarea').on('input', function () {
        if (this.value.match('^ *$')) $modal.find('.btn-primary').attr('disabled', 'disabled')
        else $modal.find('.btn-primary').removeAttr('disabled')
      })

      $modal.find('form').on('ajax:complete', function (_e) {
        if (_e.originalEvent.detail[0].status === 200) {
          if ($(this.closest('.lazy-load')).length) {
            var $target = $(this).closest('.lazy-load')
            var loadPath = $target[0].dataset.path

            loadLazyPartial(loadPath, $target)
          } else {
            let frame = document.querySelector('turbo-frame#comments')
            if (frame) frame.reload()
          }

          $modal.modal('hide')
        } else {
          $globalAlerts.append(e.originalEvent.detail[0].responseText)
          $updateEventModal.modal('hide')
        }
      })
    })
  })
}

function defaultProgressionLevelSuccess(data) {
  $('.program-progression-level').html(data.responseText)
  $('.program-progression-level').closest('.item').removeClass('hidden')
}

function getProgramProgressionLevels(student_program_progression_levels_path, onlyExisting, onComplete) {
  'use strict'
  onComplete = onComplete || defaultProgressionLevelSuccess
  onlyExisting = onlyExisting || false
  $.ajax({
    type: 'GET',
    data: { only_existing: onlyExisting },
    url: student_program_progression_levels_path,
    complete: onComplete,
  })
}

function initializeAttendanceModuleActionSelector() {
  'use strict'

  $('#student #attendances ul li a').on('click', function (e) {
    e.preventDefault()

    var $this = $(this)
    var path = $this.data('path')
    var data = {}
    var includeAttendances = $this.data('editable')

    var checkedAttendanceCheckboxes = $(
      '.program-attendance-container input.select-all-item[type=checkbox]:checked',
    )
    if (includeAttendances) {
      if (checkedAttendanceCheckboxes.length > 0) {
        data.attendance_ids = $(checkedAttendanceCheckboxes)
          .map(function () {
            return parseInt($(this).attr('id').replace('attendance_', ''))
          })
          .toArray()
      }
    }

    initializeInfiniteViewAll($(this).find('a.view-all'))

    $('#update-student-modal').html('<div class="spinner spinner-grow center" />')
    $('#update-student-modal').modal('show')
    $('#update-student-modal').on('hidden.bs.modal', function () {
      $('#update-student-modal').unbind()
    })
    $.ajax({
      type: 'GET',
      url: path,
      data,
      complete(_data) {
        $('#update-student-modal').html(_data.responseText)
        initializeAdditionalInfoModalContent()
        var $modalSubmitButton = $('#update-student-modal').find("form button[type='submit']")
        if (!includeAttendances || (checkedAttendanceCheckboxes && checkedAttendanceCheckboxes.length === 0)) {
          $modalSubmitButton.addClass('disabled')
          $modalSubmitButton.prop('disabled', true)
        }
        $('#attendance-program').on('select2:select', function (_e) {
          _e.preventDefault()

          var program_progression_levels_path = $(this).select2('data')[0].element.dataset.path
          getProgramProgressionLevels(program_progression_levels_path, true)

          $modalSubmitButton.removeClass('disabled')
          $modalSubmitButton.prop('disabled', false)
        })
        $('#update-student-modal')
          .find('form')
          .on('ajax:complete', function (_e) {
            if (_e.originalEvent.detail[0].status === 200) {
              $('#update-student-modal').unbind()
              $('#update-student-modal').modal('hide')

              var lazy_load_path = $('#student #attendances .lazy-load').data('path')
              loadLazyPartial(lazy_load_path, $('#student #attendances .lazy-load'))
            } else {
              $('#global-alerts').append(_e.originalEvent.detail[0].responseText)
              $('#update-student-modal').modal('hide')
            }
          })
      },
    })

    return false
  })
}

function initializeTabModule(clickableElements) {
  clickableElements.on('click', function (e) {
    e.preventDefault()
    var path = $(this).data('path')

    var $target = $(this).closest('.lazy-load')

    // this sucks and refreshes the whole module
    if (path && $target.length > 0) {
      loadLazyPartial(path, $target)
    } else if (path) {
      let frame = $(this).closest('turbo-frame')
      if (frame) frame[0].src = path
    }
  })
}

function initializeHoverTooltips($this) {
  $this.find('.hover-tooltip').hover(
    function () {
      $(this).find('[data-toggle="tooltip"]').tooltip('show')
    },
    function () {
      $(this).find('[data-toggle="tooltip"]').tooltip('hide')
    },
  )
}

function initializePaymentNotesModal($this) {
  $this.find('.payment-notes').on('click', function () {
    if ($(this).hasClass('disabled')) return

    var $row = $(this)
    var path = $(this).data('path')
    var content = $(this).data('content')
    var $modal = $('#add-payment-notes-modal')

    $('#add-payment-notes-modal #add-notes-field').val(content)
    $modal.find('form').attr('action', path)
    $modal.modal('show')
    $('#add-payment-notes-modal #add-notes-field').focus()

    $('#add-payment-notes-modal')
      .find('form')
      .on('ajax:complete', function () {
        content = $('#add-notes-field').val()
        if (content === '') {
          $row.removeClass('fa-edit-note')
          $row.addClass('fa-add-note')
          $row.data('content', content)
        } else {
          $row.removeClass('fa-add-note')
          $row.addClass('fa-edit-note')
          $row.data('content', content)
        }

        $('#add-payment-notes-modal').modal('hide')
      })
  })
}

function initializeDropToggleThing($toggleable) {
  $toggleable.on('click', function (e) {
    if ($(e.target).is('a')) return true

    e.preventDefault()
    const row_id = $(this).data('id') || ''
    const el = document.getElementById(`drop-toggle-id-${row_id}`)

    // flip the arrow thingy
    const icon = $(this).find('svg')
    if (icon.css('transform') === 'none') icon.css({ transform: 'rotate(180deg)' })
    else icon.css({ transform: 'none' })

    el.hidden = !el.hidden
  })
}

export function handleAttendanceModuleContextSwitching() {
  $('#attendance-log-context, #attendance-award-context').on('click', function () {
    var $this = $(this)
    var $target = $(this).closest('.lazy-load')
    var path = $this.data('path')
    var $footer = $('.attendance-base-container').find('.footer')
    $footer.html('')
    if ($this[0].id === 'attendance-award-context') {
      $('.attendances-header div.dropdown').addClass('hidden')
    } else {
      $('.attendances-header div.dropdown').removeClass('hidden')
    }
    loadLazyPartial(path, $target)
  })
}

function initializeLeftPanelSelection() {
  $('#profile-left-panel-selector .select-option').on('click', function () {
    var $target = $(this).closest('.lazy-load')
    var path = $(this).data('path')

    loadLazyPartial(path, $target)
  })
  $('#profile-left-panel-selector select').on('change', function () {
    var $target = $(this).closest('.lazy-load')
    var path = $(this).find('option:selected').data('path')

    loadLazyPartial(path, $target)
  })
}

// Assumes the button element has a path value in it's data attributes,
// will invoke the path provided with a PUT, then refresh the lazy load
// partial if successful
function handleButtonUpdate(parentElement, buttonElement) {
  buttonElement.attr('disabled', true)
  var path = buttonElement.data('path')
  var modulePath = parentElement.data('path')
  var $target = parentElement.closest('.lazy-load')
  if (buttonElement.data('returnPath')) modulePath = buttonElement.data('returnPath')

  var token = $('meta[name="csrf-token"]').attr('content')
  $.ajax({
    type: 'PUT',
    url: path,
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    },
    complete(data) {
      if (data.status === 200) {
        if ($target.length) loadLazyPartial(modulePath, $target)
        else parentElement.closest('turbo-frame')[0].reload()
      } else {
        $('#global-alerts').append(data.responseText)
        loadLazyPartial(modulePath, $target)
        return false
      }
      return false
    },
  })
  return true
}

function initializeMembershipActivationButtons(actionableElement) {
  actionableElement.find('.activate-membership').on('click', function () {
    handleButtonUpdate(actionableElement, $(this))
  })
  actionableElement.find('.deactivate-membership').on('click', function () {
    handleButtonUpdate(actionableElement, $(this))
  })
}

function initializeAttendanceAwardProgramSelector() {
  'use strict'

  if ($('.student-attendance-award-items').length) {
    var path = $('.student-attendance-award-items').data('path')

    $('#student-programs-selector .select-option').on('click', function (e) {
      var target = e.target
      var programId = target.getAttribute('value')

      path = $('.student-attendance-award-items').data('path')

      // Remove any "selected" classes
      $('#student-programs-selector .select-option').each(function () {
        this.classList.remove('selected')
      })

      // Add "selected" back to just this element
      target.classList.add('selected')

      populateAttendanceAwards(path, programId)
    })
  }
}

function populateAttendanceAwards(path, programId) {
  'use strict'

  $.ajax({
    type: 'GET',
    url: path,
    data: { programId },
    complete(data) {
      $('.program-attendance-awards-container .content').html(data.responseText)
      initializeTooltips($('.program-attendance-awards-container .content'))
    },
  })
}

function initializeSortingColumns(actionableElement) {
  'use strict'

  $(actionableElement)
    .find('th.sortable-column')
    .click(function () {
      const $this = $(this)
      const sortColumn = this.classList[1]
      const sortAscending = !this.classList.contains('selected-up') && this.classList.contains('selected-down')

      const $target = $this.closest('.lazy-load')
      let path = $this.closest('table').data('path')

      const separator = path.includes('?') ? '&' : '?'
      path += `${separator}sort_by=${sortColumn}&sort_asc=${sortAscending}`

      loadLazyPartial(path, $target)
    })
}

$(document).on('turbo:load', function () {
  'use strict'

  if (window.location.hash) highlightModule(window.location.hash)

  //
  // Modules
  //
  $('#student #attendances .lazy-load').on('lazy-load:complete', function () {
    initializeAttendanceLogProgramSelector()
    initializeAttendanceModuleActionSelector()
    handleAttendanceModuleContextSwitching()
    initializeAttendanceAwardProgramSelector()
    initializeInfiniteViewAll($(this).find('a.view-all'))
    initializeTooltips($(this))
  })

  $('#prospect .lazy-load#finances, #student .lazy-load#finances').on('lazy-load:complete', function () {
    initializeTabModule($('#student #finances #finance-tab-toggle a'))
    initializeTabModule($('#prospect #finances #finance-tab-toggle a'))
    initializeViewMoreProfileItemsActionable($(this).find('a.view-more-results'))
    initializeInfiniteViewAll($(this).find('a.view-all'))
    initializeLeftPanelSelection()
    initializeTooltips($(this))
    initializeHoverTooltips($(this))
    initializeDropToggleThing($('#student #finances .report-year-toggle'))

    initializePaymentNotesModal($(this))
    initializeSortingColumns($(this))
  })

  $('#prospect .lazy-load#agreements, #student .lazy-load#agreements').on('lazy-load:complete', function () {
    initializeDropdownTabs($(this))
    registerProfileModulesDropdownClickEvents($(this))
    initializeViewMoreProfileItemsActionable($(this).find('a.view-more-results'))
    initializeViewAllProfileItemsActionable($(this).find('a.view-all'))
    initializeAgreementDeletes()
    initializeTooltips($(this))
  })

  $('#student .lazy-load#documents, #student #documents').on('lazy-load:complete turbo:frame-load', function () {
    initializeDropdownTabs($(this))
    registerProfileModulesDropdownClickEvents($(this))
    initializeViewMoreProfileItemsActionable($(this).find('a.view-more-results'))
    initializeInfiniteViewAll($(this).find('a.view-all'))
    initializeTooltips($(this))
    initializeFileUpload()
    initializeDocumentDeletes()
  })

  let historySections =
    '#prospect .lazy-load#history, #student .lazy-load#history, #prospect #history, #student #history'
  $(historySections).on('lazy-load:complete turbo:frame-load', function () {
    initializeViewMoreProfileItemsActionable($(this).find('a.view-more-results'))
    initializeInfiniteViewAll($(this).find('a.view-all'))
    initializeTooltips($(this))
  })

  $('#prospect .lazy-load#memberships, #student .lazy-load#memberships').on('lazy-load:complete', function () {
    initializeTooltips($(this))
  })

  $('#prospect .lazy-load#prospects-calendar, #student .lazy-load#students-calendar').on(
    'lazy-load:complete',
    function () {
      initializeDropdownTabs($(this))

      $(this)
        .find('.dropdown-tabs .dropdown-tabs-menu li a')
        .on('voltage:dropdown-tabs:selected', function () {
          var $this = $(this)
          var $target = $(this).closest('.lazy-load')
          var path = $this.data('path')

          loadLazyPartial(path, $target)
        })
    },
  )

  let commentSections =
    '#prospect .lazy-load#comments, #student .lazy-load#comments, #prospect #comments, #student #comments'
  $(commentSections).on('lazy-load:complete turbo:frame-load', function () {
    initializeViewMoreProfileItemsActionable($(this).find('a.view-more-results'))
    initializeInfiniteViewAll($(this).find('a.view-all'))
    initializeAddNewComment($(this))
    initializeCommentDeletes()
  })

  $('#prospect .lazy-load#memberships, #student .lazy-load#memberships').on('lazy-load:complete', function () {
    initializeTabModule($('#student #memberships #memberships-tab-toggle a'))
    initializeLeftPanelSelection()
    initializeMembershipActivationButtons($(this))
  })

  $('#student .lazy-load#student-account').on('lazy-load:complete', function () {
    initializeAccountFormSubmit()
    initializeCloseUserAccount()
    initializeAccountRestrictionChecks()
    initializeChangeUserAccountPassword()
  })

  $('#student .lazy-load#student-family-info').on('lazy-load:complete', function () {
    initializeTooltips($(this))
  })
})
