import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'newContact',
    'newStudent',
    'existingPerson',
    'newPersonForm',
    'existingPersonForm',
    'nameField',
    'emailField',
    'phoneField',
    'existingPersonField',
    'exceedStudentLimit',
    'submit',
  ]

  static values = { newStudentDisable: Boolean }

  // Sounds like Eden is wanting to modify how we sign up a new member in the Family
  // I think we'll eventually replace the toggle() with this
  toggleForm() {
    if (this.newStudentTarget.checked || this.newContactTarget.checked) {
      if (this.newStudentTarget.checked && this.newStudentDisableValue) {
        this.existingPersonFormTarget.classList.add('hidden')
        this.exceedStudentLimitTarget.classList.remove('hidden')
        this.newPersonFormTarget.classList.add('hidden')
        this.submitTarget.classList.add('hidden')
      } else {
        this.newPersonFormTarget.classList.remove('hidden')
        this.nameFieldTarget.removeAttribute('disabled')
        this.emailFieldTarget.removeAttribute('disabled')
        this.phoneFieldTarget.removeAttribute('disabled')
        this.existingPersonFormTarget.classList.add('hidden')
        this.exceedStudentLimitTarget.classList.add('hidden')
        this.submitTarget.classList.remove('hidden')
      }
    } else if (this.existingPersonTarget.checked) {
      this.nameFieldTarget.value = ''
      this.emailFieldTarget.value = ''
      this.phoneFieldTarget.querySelector('#fake_number').value = ''
      this.newPersonFormTarget.classList.add('hidden')
      this.nameFieldTarget.setAttribute('disabled', true)
      this.emailFieldTarget.setAttribute('disabled', true)
      this.phoneFieldTarget.setAttribute('disabled', true)
      this.existingPersonFormTarget.classList.remove('hidden')
      this.exceedStudentLimitTarget.classList.add('hidden')
      this.submitTarget.classList.remove('hidden')
    }
  }
}
