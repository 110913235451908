/* global loadLazyPartial */

$(document).on('turbo:load', function () {
  'use strict'

  function initializeSelect2() {
    $('#select-program').select2({
      placeholder: $('#select-program').data().placeholder,
      theme: 'bootstrap',
    })

    $('#select-rank').select2({
      placeholder: $('#select-rank').data().placeholder,
      theme: 'bootstrap',
    })
  }

  function initializeProgramRows($row) {
    $($row).on('click', function (e) {
      e.preventDefault()
      $(this).toggleClass('removed')
      var hidden_field = $(this).find('.program_row_val')
      hidden_field.prop('disabled', function (i, v) {
        return !v
      })

      var icon = $(this).find('i')
      if (icon.css('transform') === 'none') {
        icon.css({
          transform: 'rotate(45deg)',
          transition: '.25s',
        })
      } else {
        icon.css({ transform: 'none' })
      }

      $('.subtext').removeClass('hidden')
    })
  }

  function loadRanksForProgram(selectedProgram) {
    var ranks = selectedProgram.data().ranks
    ranks = $.map(ranks, (id_name) => ({
      id: id_name[0],
      text: id_name[1],
    }))

    $('#select-rank').empty().select2({
      data: ranks,
      theme: 'bootstrap',
    })
  }

  function createElementFromSelections() {
    var program_id = $('#select-program').val()
    var program_name = $('#select-program option:selected').text()
    var rank_id = $('#select-rank').val()
    var rank_name = $('#select-rank option:selected').text()

    var belt_html = getBeltHtml(program_id, rank_id)
    var div = `
      <div class="program-row row">
        <div class="col-xs-9">
          <input type="hidden" name="program_rank_ids[${program_id}]" value="${rank_id}">
          ${belt_html}
          <span>
            ${rank_name}, ${program_name}
          </span>
        </div>
        <div class="col-xs-3 pull-right remove-program">
          <i class="fas fa-times"></i>
        </div>
      </div>
      `
    $('.current-programs').append(div)
    $(`#select-program option[value='${program_id}']`).remove()
  }

  function getBeltHtml(program_id, rank_id) {
    var path = `/programs/${program_id}/ranks/${rank_id}/belt_image`
    var token = $('meta[name="csrf-token"]').attr('content')
    var html
    $.ajax({
      type: 'GET',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      async: false,
      complete(data) {
        if (data.status === 200) html = data.responseText
        else return false
      },
    })
    return html
  }

  $('#student .lazy-load#programs-and-ranks').on('lazy-load:complete', function () {
    initializeSelect2()
    $.each($('.program-row'), function (_idx, row) {
      initializeProgramRows(row)
    })
  })

  $(document).on('shown.bs.modal', '#manage-programs-modal', function () {
    $('#select-program').removeAttr('required')
    $('#select-rank').removeAttr('required')

    $('#select-program').on('change', function () {
      var selectedProgram = $('#select-program option:selected')
      loadRanksForProgram(selectedProgram)

      $('.add-program-btn').removeAttr('disabled')
    })

    $('.add-program-btn').on('click', function () {
      if ($(this).attr('disabled')) return false

      $('.subtext').removeClass('hidden')

      createElementFromSelections()

      // clear selections
      $('#select-program').val(null).trigger('change')
      $('#select-rank.select2-hidden-accessible').select2('destroy').empty()
      initializeSelect2()
      // initialize add/remove for new row
      initializeProgramRows($('.program-row:last-child')[0])
      // re-disable add button
      $('.add-program-btn').attr('disabled', true)
    })

    $('#manage-programs-modal')
      .find('form')
      .on('ajax:complete', function (e) {
        var $modal = $('#manage-programs-modal')
        var $globalAlerts = $('#global-alerts')
        if (e.originalEvent.detail[0].status === 200) {
          $modal.modal('hide')
          var $target = $(this).closest('.lazy-load')
          var path = $target.data().path
          loadLazyPartial(path, $target, true, true)
          $('#programs-saved').modal('show')
        } else {
          $globalAlerts.append(e.originalEvent.detail[0].responseText)
          $modal.modal('hide')
        }
      })

    var closeBtns = $('#manage-programs-modal').find('[data-dismiss=modal]')
    $.each(closeBtns, function (i, btn) {
      $(btn).on('click', function () {
        $('#program-cancel').modal('show')
      })
    })
  })
})

$(document).on('shown.bs.modal', '#program-cancel', function () {
  $('#dont-cancel-programs').on('click', function () {
    $('#program-cancel').modal('hide')
    $('#manage-programs-modal').modal('show')
  })

  $('#confirm-cancel-programs').on('click', function () {
    // if we refresh the partial, it'll reset the manage modal
    $('#program-cancel').modal('hide')
    var $target = $(this).closest('.lazy-load')
    var path = $target.data().path
    loadLazyPartial(path, $target, true, true)
  })
})
