import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'newCard',
    'existingCard',
    'existingCards',
    'processPaymentBtn',
    'processPaymentIcon',
    'token',
    'tokenizer',
  ]

  static values = { billingForm: String, formId: String }

  connect() {
    // If we have vaults, we want to default to not tokenize, since they'll be showing
    this.tokenize = !this.hasExistingCardsTarget
    this.originalProcessText = this.processPaymentBtnTarget.innerText
  }

  process() {
    this.disableBtn()

    this.billingProviderController.setPaymentToken(this.tokenize, this.tokenTarget).then((success) => {
      if (success) {
        this.parentForm.requestSubmit()
      } else {
        this.enableBtn()
      }
    })
  }

  disableBtn() {
    this.processPaymentBtnTarget.disabled = true

    let nodes = this.processPaymentBtnTarget.childNodes
    let textNode = [...nodes].find((child) => child.nodeType === Node.TEXT_NODE)
    textNode.data = this.processPaymentBtnTarget.dataset.disableWith

    this.processPaymentIconTarget.classList.remove('hidden')
  }

  enableBtn() {
    this.processPaymentBtnTarget.disabled = false

    let nodes = this.processPaymentBtnTarget.childNodes
    let textNode = [...nodes].find((child) => child.nodeType === Node.TEXT_NODE)
    textNode.data = this.originalProcessText

    this.processPaymentIconTarget.classList.add('hidden')
  }

  addNew() {
    this.tokenize = true
    this.transitionIn(this.newCardTarget)
    this.transitionOut(this.existingCardsTarget)
  }

  existingCards(e) {
    this.tokenize = false
    this.transitionIn(this.existingCardsTarget)

    if (this.existingCardTargets.length > 0) {
      let inputNode = this.existingCardTargets[0]
      inputNode.checked = true
    }

    this.transitionOut(this.newCardTarget)

    e.preventDefault()
  }

  //
  // private
  //
  transitionIn(target) {
    target.classList.add('in')
    target.classList.remove('out')
  }

  transitionOut(target) {
    target.classList.add('out')
    target.classList.remove('in')
  }

  get parentForm() {
    return document.querySelector(`#${this.formIdValue}`)
  }

  get billingProviderController() {
    let kebabBilling = this.billingFormValue
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
    let controller_name = `${kebabBilling}-form--component`
    return this.application.getControllerForElementAndIdentifier(this.tokenizerTarget, controller_name)
  }
}
