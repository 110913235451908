/* eslint-env jquery */

/* global App */
/* eslint-disable camelcase */
/* global initializeTooltips */
/* global initializeDropdownTabs */
/* global initializeProspectLeadStateDropdowns */
/* global initializeSelectAllCheckboxes */
/* global initializeTrialMembershipScheduling */
/* global initializeProspectNoting */
/* global initializeAssignedToFields */

$(document).on('turbo:load', function () {
  'use strict'

  var schoolId = $('body.school').data('school-id')
  var userId = $('body.school').data('user-id')
  var isProspectsView = $('body').hasClass('prospects')
  const shouldSet = schoolId && userId && isProspectsView && !App.prospects_bulk_edit_notifications

  if (shouldSet) {
    App.prospects_bulk_edit_notifications = App.cable.subscriptions.create(
      {
        channel: 'ProspectsBulkEditNotificationsChannel',
        schoolId,
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel

          if (validId(data.user_id)) {
            if (data.prospect_id && data.updated_partial) {
              var $prospectContainer = $(`.prospect-wrapper.prospect-${data.prospect_id}-container`)
              if ($prospectContainer.length) {
                $prospectContainer.html(data.updated_partial)

                initializeTooltips($prospectContainer)
                initializeDropdownTabs($prospectContainer)
                initializeProspectLeadStateDropdowns($prospectContainer)
                initializeSelectAllCheckboxes(
                  $prospectContainer.find("input.select-all-item[type='checkbox']"),
                  false,
                )
                initializeAssignedToFields($prospectContainer)
                initializeTrialMembershipScheduling($prospectContainer)
                initializeProspectNoting($prospectContainer)
              }
            }
            if (!data.error) {
              var bulkCheckbox = $('input#select-all')
              bulkCheckbox.prop('checked', false)
              bulkCheckbox.parent().find('.spinner.spinner-grow').remove()
              bulkCheckbox.show()
            }
            if (data.message) {
              if (data.notification_class) $(`.alert.${data.notification_class}`).hide()

              $('#global-alerts').append(data.message)
            }
          }
        },
      },
    )
  }
  function validId(id) {
    return id === undefined || (id > 0 && id === userId) || (id < 0 && Math.abs(id) !== userId)
  }
})
/* eslint-enable camelcase */
