import { Controller } from '@hotwired/stimulus'

// Eventually this should be replaced with purely turbo
// but since the Student Profile modules use tables, we can't put
// turbo frames in them, so we have to click view more a lot of times
export default class extends Controller {
  static targets = ['more', 'infinity', 'spinner']

  viewAll(event) {
    this.spinnerTarget.classList.remove('hidden')
    event.target.disabled = true

    this.fetchMore()

    this.keepDisabled = true
    this.shouldViewAll = true
  }

  moreTargetConnected(element) {
    if (this.keepDisabled) {
      element.disabled = true
    }

    this.requestSent = false
  }

  scroll() {
    if (!this.shouldViewAll) return

    const bottom =
      this.infinityTarget.scrollHeight - Math.abs(this.infinityTarget.scrollTop) >=
      this.infinityTarget.offsetHeight - 500

    if (bottom && this.hasMoreTarget && !this.requestSent) {
      this.requestSent = true
      this.fetchMore()
    }

    if (bottom && !this.hasMoreTarget) {
      this.spinnerTarget.classList.add('hidden')
      this.shouldViewAll = false
    }
  }

  ///
  /// private
  ///

  fetchMore() {
    this.moreTarget.disabled = false

    this.moreTarget.click()

    this.moreTarget.disabled = true
  }

  get keepDisabled() {
    return this._keepDisabled
  }

  set keepDisabled(_keepDisabled) {
    this._keepDisabled = _keepDisabled
  }

  get shouldViewAll() {
    return this._shouldViewAll
  }

  set shouldViewAll(_shouldViewAll) {
    this._shouldViewAll = _shouldViewAll
  }

  get requestSent() {
    return this._requestSent
  }

  set requestSent(_requestSent) {
    this._requestSent = _requestSent
  }
}
