import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = ['requiredItem']
  static outlets = ['signature', 'checkbox-signature', 'agreement-signatures']

  _signatures = []

  connect() {
    // Fade out any success flashes
    setTimeout(() => {
      $('.alert-success').fadeOut('slow')
    }, 2000)

    const customPhoneField = document.querySelector('.custom-form-field input[type=tel].fancy')
    if (customPhoneField) {
      intlTelInput(customPhoneField, {
        utilsScript: '/assets/intl-tel-input/utils.js',
        formatOnDisplay: true,
        hiddenInput: 'full_phone',
        separateDialCode: true,
        initialCountry: document.body.dataset.countryCode || 'us',
      })
    }
  }

  submit(event) {
    const signed = this.signatures.every((signature) => signature.completed)

    if (signed) {
      const rawAgreement = document.querySelector('input#agreement')
      const tokenFields = document.querySelectorAll('#agreement-content input.token-field')
      for (const tokenField of tokenFields) {
        const token = tokenField.dataset.token
        rawAgreement.value = rawAgreement.value.replace(`((${token}))`, `${tokenField.value}`)
      }

      document.querySelector('#lp-submit').disabled = true

      return true
    }

    this.reportSignatureValidity()

    event.preventDefault()
    event.stopPropagation()
    return false
  }

  toggleRequired() {
    const shouldBeRequired = !this.requiredItemTargets.some((i) => i.checked)

    for (const input of this.requiredItemTargets) {
      input.required = shouldBeRequired
    }
  }

  reportSignatureValidity() {
    this.agreementSignaturesOutlet.scrollToCurrent()
    setTimeout(() => {
      this.agreementSignaturesOutlet.flashValidity()
    }, 250)
  }

  get agreementTokenFields() {
    if (!this.hasAgreementSignaturesOutlet) return []

    return [...this.agreementSignaturesOutlet.element.querySelectorAll('input[required]')]
  }

  get signatures() {
    if (this._signatures.length) return this._signatures

    const outlets = this.signatureOutlets.concat(this.checkboxSignatureOutlets)

    outlets.sort((a, b) => {
      if (a.element.compareDocumentPosition(b.element) & Node.DOCUMENT_POSITION_FOLLOWING) {
        return -1
      }

      return 1
    })

    this._signatures = outlets

    return this._signatures
  }
}
