import { Controller } from '@hotwired/stimulus'
import { getStream } from '../helpers/fetch_helper'

export default class extends Controller {
  static values = { streamUrl: String, params: String }

  fetchStream({ target }) {
    if (target.tagName === 'SELECT') {
      getStream(this.constructUrl(target.selectedOptions[0].value))
      return
    }

    getStream(this.constructUrl(target.value))
  }

  ///
  /// private
  ///
  constructUrl(value) {
    const base = `${this.streamUrlValue}/${value}`

    if (this.hasParamsValue) {
      return `${base}?${this.paramsValue}`
    }

    return base
  }
}
