import { Controller } from '@hotwired/stimulus'
import { enableApplyToPreviewButton } from '../../assets/javascripts/school/bizbuilders'

export default class extends Controller {
  connect() {
    $('.trumbo-editor')
      .trumbowyg({
        btns: [
          ['undo', 'redo'],
          ['formatting'],
          ['strong', 'em', 'del'],
          ['foreColor', 'backColor'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['indent', 'outdent'],
          ['removeformat'],
          ['link', 'upload'],
        ],
        plugins: {
          upload: {
            serverPath: '/files.js',
            headers: {
              'X-CSRF-Token': $("meta[name='csrf-token']").attr('content'),
              'X-Requested-With': 'XMLHttpRequest',
            },
            fileFieldName: 'file',
            urlPropertyName: 'url',
          },
        },
        svgPath: this.element.dataset.iconsPath,
        removeformatPasted: true,
      })
      .on('tbwchange', function () {
        if ($('#landing-page-form .apply-btn').length) enableApplyToPreviewButton()
      })
  }

  disconnect() {
    $('.trumbo-editor').trumbowyg('destroy')
  }
}
