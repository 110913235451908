import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { original: String }
  static targets = ['input', 'value', 'display', 'reset']

  update() {
    const value = this.inputTarget.value.toUpperCase()

    if (value === this.originalValue) {
      this.resetTarget.classList.add('invisible')
    } else {
      this.resetTarget.classList.remove('invisible')
    }

    this.valueTarget.textContent = value

    this.displayTarget.style.backgroundColor = value
  }

  reset() {
    this.inputTarget.value = this.originalValue

    this.update()
  }
}
