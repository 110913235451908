import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['adminDetailsInputs', 'sameAsOwnerCheckBox']

  toggleAdminDetails() {
    this.adminDetailsInputsTarget.classList.toggle('!max-h-0')
    this.adminDetailsInputsTarget.querySelectorAll('input').forEach((el) => el.classList.toggle('disabled'))
  }
}
