import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static outlets = ['searchable-person-field']
  static targets = ['quantity']
  static values = { url: String }

  searchablePersonFieldOutletConnected() {
    // This is a bit hacky, but we need to wait for the modal to fully
    // open before focusing, which takes ~250ms
    setTimeout(() => {
      this.#focusPersonField()
    }, 250)
  }

  refreshFrame({ target: { form } }) {
    const params = new URLSearchParams(new FormData(form))
    params.delete('utf8')
    params.delete('authenticity_token')
    const src = `${this.urlValue}?${params.toString()}`

    Turbo.visit(src, { frame: 'ranks_programs' })
  }

  showQuantity() {
    this.#focusPersonField()

    if (!this.hasQuantityTarget) return

    this.quantityTarget.hidden = false
  }

  hideQuantity() {
    this.#focusPersonField()

    if (!this.hasQuantityTarget) return

    this.quantityTarget.hidden = true
  }

  #focusPersonField() {
    if (!this.hasSearchablePersonFieldOutlet) return
    if (this.searchablePersonFieldOutlet.element.value) return

    this.searchablePersonFieldOutlet.tomSelect.focus()
  }
}
