// Entry point for the build script in your package.json
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js')
}

if (!navigator.cookieEnabled) {
  window.location.replace('/cookies_required')
}

import '@hotwired/turbo-rails'
import { StreamActions } from '@hotwired/turbo'
import TurboPower from 'turbo_power'
TurboPower.initialize(StreamActions)
import '@rails/actioncable'

import './initializers'

import '../assets/javascripts/layout'
import 'bootstrap-sass'
import 'bootstrap-toggle'
import 'jquery-minicolors'
require('select2')()
import '../../vendor/assets/javascripts/jquery.gauge'
import '../../vendor/assets/javascripts/intl-tel-input/utils'
import './controllers'
import '../assets/javascripts'

import LocalTime from 'local-time'
LocalTime.start()

import { setupConfirmations } from '../components/confirm_button_to/component'
setupConfirmations()

import embed from 'vega-embed'

window.vegaEmbed = embed
window.dispatchEvent(new Event('vega:load'))

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()
