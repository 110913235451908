import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  copy() {
    const isHidden = this.inputTarget.classList.contains('hidden')

    if (isHidden) {
      this.inputTarget.classList.remove('hidden')
    }

    let textValue = ''
    if (this.inputTarget.tagName === 'INPUT' || this.inputTarget.tagName === 'TEXTAREA') {
      this.inputTarget.select()
      textValue = this.inputTarget.value
    } else {
      textValue = this.inputTarget.innerText.trim()
    }

    // NOTE: This doesn't work without HTTPS, the other method is deprecated
    navigator.clipboard.writeText(textValue)

    if (isHidden) {
      this.inputTarget.classList.add('hidden')
    }
  }
}
