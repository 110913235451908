import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'selectAll', 'checkbox', 'program', 'promotionDate', 'submit']

  static values = { progressText: String }

  submitTargetConnected() {
    this.handleCheckboxChange()
  }

  updateSelectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.selectAllTarget.checked
    })
    this.handleCheckboxChange()
  }

  handleCheckboxChange() {
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.submitTarget.classList.remove('disabled')
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.classList.add('disabled')
      this.submitTarget.disabled = true
    }
  }

  displayInProgress() {
    if (!this.promotionDateTarget.value) {
      return
    }

    this.programTarget.nextSibling.querySelector('.ts-control').classList.add('disabled')
    this.submitTarget.classList.add('disabled')
    this.submitTarget.disabled = true
    this.submitTarget.value = this.progressTextValue
    this.promotionDateTarget.nextSibling.disabled = true
  }
}
