import ApplicationController from './application_controller'
import consumer from '../channels/consumer'

export default class extends ApplicationController {
  static targets = ['progress']

  static values = { current: Number, max: Number, channel: String }
  completed = false
  total = 0
  failures = 0

  connect() {
    this.channel = this.createChannel(this)
    document.addEventListener(
      'turbo:submit-end',
      () => {
        this.dispatch('begin-action', { bubbles: true })
      },
      { once: true },
    )
  }

  createChannel(self) {
    return consumer.subscriptions.create(this.channelValue, {
      received: (data) => {
        if (data.status === 'start') {
          self.maxValue = data.max
          self.updateMaxValue()
        } else if (data.status === 'success') {
          self.incrementProgress()
        } else if (data.status === 'complete') {
          self.completed = true
          self.total = data.total
          self.failures = data.failures

          self.displayProgressFailures()
        }
      },
    })
  }

  incrementProgress() {
    this.currentValue += 1

    this.displayProgress()
  }

  updateMaxValue() {
    this.progressTarget.setAttribute('max', this.maxValue)
  }

  displayProgress() {
    if (!this.hasProgressTarget) return

    this.progressTarget.setAttribute('value', this.currentValue)
  }

  displayProgressFailures() {
    if (!this.hasProgressTarget) return
    if (this.failures === 0) return

    this.progressTarget.classList.add('progress-with-failures')
  }
}
