import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['autopay', 'save']

  displayPreexistingBillings(event) {
    if (event.target.value) {
      const payerType = document.querySelector("input[name='recurring_billing[payer_type]']").value
      const payerId = event.target.value

      let params = new URLSearchParams()
      params.set('payer_id', payerId)
      params.set('payer_type', payerType)

      const src = `/recurring_billings/new?${params.toString()}`
      const frame = 'additional_recurring_billings'
      this.setFrame({ params: { frame, src } })
    }
  }

  toggleAutopayModalSubmission(event) {
    if (event.target.checked) {
      this.autopayTarget.classList.remove('hidden')
      this.saveTarget.classList.add('hidden')
    } else {
      this.autopayTarget.classList.add('hidden')
      this.saveTarget.classList.remove('hidden')
    }
  }
}
