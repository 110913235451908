import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  disconnect() {
    document.removeEventListener('turbo:submit-end', this.submitCallback, true)
  }

  hide() {
    this.element.classList.remove('open', 'in')

    Promise.all(this.element.getAnimations().map((animation) => animation.finished)).then(() => {
      document.dispatchEvent(new Event('modal:hidden'))
    })
  }

  submit(event) {
    if (event.detail.success) {
      this.hide()
    }
  }

  submitAndHide() {
    this.submitCallback = this.submit.bind(this)
    document.addEventListener('turbo:submit-end', this.submitCallback, true)
  }
}
