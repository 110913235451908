import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['item', 'opener']
  static values = { ignore: String }
  static outlets = ['tooltip']

  connect() {
    document.addEventListener('turbo:before-render', this.clickOutside.bind(this))

    useClickOutside(this)
  }

  disconnect() {
    document.removeEventListener('turbo:before-render', this.clickOutside.bind(this))
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.tooltipOutlets.forEach((tooltip) => {
      tooltip.off()
    })

    this.element.classList.remove('collapsed')
    this.openerTarget.classList.add('active')
  }

  close() {
    this.tooltipOutlets.forEach((tooltip) => {
      tooltip.on()
    })

    this.collapseExpandedItems(true)
    this.element.classList.add('collapsed')
    this.openerTarget.classList.remove('active')
  }

  clickOutside(event) {
    if (!this.isOpen) return

    if (this.hasIgnoreValue) {
      const ignoreElement = document.querySelector(this.ignoreValue)
      if (ignoreElement && (event.target === ignoreElement || ignoreElement.contains(event.target))) {
        return
      }
    }

    this.element.classList.add('collapsed')
    this.openerTarget.classList.remove('active')

    this.tooltipOutlets.forEach((tooltip) => {
      tooltip.on()
    })

    for (const item of this.notCollapsedItems) {
      item.classList.add('collapsed', 'in')

      setTimeout(() => {
        item.classList.remove('in')
      }, 10)
    }
  }

  expandCollapseItem(event) {
    let clickedItem = event.target.closest('li')

    if (clickedItem.classList.contains('collapsed')) {
      this.collapseExpandedItems()
      clickedItem.classList.remove('collapsed')
    } else {
      clickedItem.classList.add('collapsed')
    }
  }

  collapseExpandedItems(heckin_fast) {
    let classes = heckin_fast ? ['collapsed', 'in'] : ['collapsed']
    for (const item of this.notCollapsedItems) {
      item.classList.add(...classes)
    }
  }

  get notCollapsedItems() {
    return this.itemTargets.filter((item) => !item.classList.contains('collapsed'))
  }

  get isOpen() {
    return !this.element.classList.contains('collapsed')
  }
}
