import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = {
    type: String,
    statuses: String,
    placeholder: String,
    polymorphic: Boolean,
    selectedType: { type: String, default: 'Person' },
  }

  initialize() {
    this.tomSelect = new TomSelect(this.element, {
      search: true,
      persist: false,
      highlight: false,
      addPrecedence: true,
      selectOnTab: true,
      valueField: 'id',
      labelField: 'text',
      searchField: 'text',
      placeholder: this.placeholderValue,
      load: this.load.bind(this),
      render: {
        item: this.renderItem.bind(this),
        option: this.renderOption,
        loading: this.renderLoading,
      },
    })
  }

  connect() {
    this.tomSelect.clearOptions()
  }

  updateType(pluralType) {
    if (pluralType === 'families') {
      this.type = 'Family'
      return
    }

    this.type = 'Person'
  }

  ///
  /// private
  ///
  set type(value) {
    // This is kind of stupid but like.. whatever
    this.selectedTypeValue = value
    this.element.nextElementSibling.nextElementSibling.value = value
  }

  load(query, callback) {
    const url = this.path(query)

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        callback(data.results)
      })
      .catch(() => callback())
  }

  // Doing this in the render call is supremely stupid but
  // the actual tom-select callbacks just.. like don't work?
  renderItem(item, escape) {
    if (this.polymorphicValue) {
      this.updateType(item.type)
    }

    return `<span class="flex gap-1 items-center">
              <img src="${escape(item.image)}" class="employee-image !w-4 !h-4" />
              ${escape(item.text)}
            </span>`
  }

  renderOption(item, escape) {
    return `<span class="flex gap-2 items-center">
              <img src="${escape(item.image)}" class="employee-image !w-10 !h-10" />
              ${escape(item.text)}
            </span>`
  }

  renderLoading() {
    return `<i class="fa-solid fa-spinner-third fa-spin fa-2x flex mx-auto"></i>`
  }

  path(query) {
    const url = new URL(this.currentUrl)
    url.pathname = 'select_search'
    url.searchParams.set('format', 'json')
    url.searchParams.set('query', query)
    url.searchParams.set('types', this.typeValue)
    url.searchParams.set('statuses', this.statusesValue)

    return url.toString()
  }

  get currentUrl() {
    return `${window.location.protocol}//${window.location.host}`
  }
}
