import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  static outlets = ['filter-section']
  static values = { previousFilters: Array, filters: Array, title: String, path: String, bannerText: String }
  static targets = ['checkbox']

  select() {
    this.dispatch('selected', {
      prefix: 'action',
      bubbles: true,
      detail: { title: this.titleValue, path: this.pathValue, bannerText: this.bannerTextValue },
    })

    this.#selectAction(this.filtersValue)
  }

  cancel() {
    this.dispatch('deselected', {
      prefix: 'action',
      bubbles: true,
    })

    this.#resetList()
  }

  get checked() {
    return this.checkboxTarget.checked
  }

  ///
  /// private
  ///

  #selectAction() {
    this.#removeFilters()
    this.#selectFilters(this.filtersValue)
    this.element.closest('form').requestSubmit()
    this.filterSectionOutlet.updateCount()
  }

  #resetList() {
    this.#removeFilters()
    this.checkboxTarget.checked = false
    this.modifyQueryStringInline('quick_action', '')
    this.element.closest('form').requestSubmit()
    this.filterSectionOutlet.updateCount()
  }

  #selectFilters(filters) {
    filters.forEach((filter) => {
      const checkbox = document.querySelector(`#${filter}`)

      if (!checkbox) return

      checkbox.checked = true
      checkbox.dispatchEvent(new CustomEvent('change', { detail: { dispatch: false } }))
    })
  }

  #removeFilters() {
    this.filterSectionOutlet.filterGroupOutlets.forEach((filterGroup) => {
      filterGroup.checkboxTargets.forEach((checkbox) => {
        if (!checkbox) return

        checkbox.checked = false
        checkbox.dispatchEvent(new CustomEvent('change', { detail: { dispatch: false } }))
      })
    })
  }
}
