/* global isMobile */

import { Controller } from '@hotwired/stimulus'
import { createSortable } from '../helpers/sortable_helper'
import { put } from '../helpers/fetch_helper'

/**
 * Class representing the submit on drop version of the sortable controller
 * @extends Controller
 */
export default class DropSortableController extends Controller {
  static values = { options: Object, path: String }
  static targets = ['item']

  /**
   * Initialize the Events and Sortable when the Stimulus controller is 'connected'
   */
  connect() {
    const options = {
      onEnd: (event) => {
        this.submit(event)
      },
      draggable: `[data-drop-sortable-target='item']`,
      ...this.optionsValue,
    }

    if (isMobile()) {
      options.handle = '.fa-grip-dots-vertical'
    }

    this.itemTargets.map((item) => item.classList.add('draggable-item'))
    createSortable(this.element, options)
  }

  submit() {
    // When the dragged element is dropped, then update the order of all the elements in db
    const ids = this.itemTargets.map((item) => item.dataset.id)
    put(this.pathValue, { ids })
  }
}
