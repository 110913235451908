import {
  formatSelect2Option,
  initializeSelect2Fields,
  findCheckedCheckBoxes,
  initializeSelectAllCheckboxes,
  initializeColorPickerFields,
  initializeButtonToggleFormFields,
  autoexpandDefinedInputs,
  clickedOutsideInPlaceEditableField,
  initializeDiscardButton,
  initializeFormFieldEdits,
} from '../../assets/javascripts/forms'

window.formatSelect2Option = formatSelect2Option
window.initializeSelect2Fields = initializeSelect2Fields
window.findCheckedCheckBoxes = findCheckedCheckBoxes
window.initializeSelectAllCheckboxes = initializeSelectAllCheckboxes
window.initializeColorPickerFields = initializeColorPickerFields
window.initializeButtonToggleFormFields = initializeButtonToggleFormFields
window.autoexpandDefinedInputs = autoexpandDefinedInputs
window.clickedOutsideInPlaceEditableField = clickedOutsideInPlaceEditableField
window.initializeDiscardButton = initializeDiscardButton
window.initializeFormFieldEdits = initializeFormFieldEdits
