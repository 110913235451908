import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // these have to be jquery because bootstrap uses jquery events
    $(this.element).on('hide.bs.dropdown', this.hideAllSubmenus.bind(this))
  }

  disconnect() {
    $(this.element).off('hide.bs.dropdown', this.hideAllSubmenus.bind(this))
  }

  showSubmenu({ currentTarget, params: { submenuId } }) {
    this.hideAllSubmenus()

    const submenu = document.querySelector(`#${submenuId}`)

    submenu.classList.remove('hidden')
    submenu.classList.add('!block')
    submenu.setAttribute('data-open', '')

    const { top: itemTop, width: itemWidth } = currentTarget.getBoundingClientRect()
    const parentMenuTop = currentTarget.closest('.dropdown-menu').getBoundingClientRect().top
    const { height: toggleHeight } = this.element.getBoundingClientRect()

    submenu.style.left = `${itemWidth}px`
    submenu.style.top = `${toggleHeight + itemTop - parentMenuTop}px`
  }

  hideSubmenu({ params: { submenuId } }) {
    const submenu = document.querySelector(`#${submenuId}`)
    const mouseInSubmenu = submenu.matches(':hover')

    if (mouseInSubmenu) return

    submenu.classList.add('hidden')
    submenu.classList.remove('!block')
    submenu.removeAttribute('data-open')
  }

  hideAllSubmenus() {
    const submenus = this.element.querySelectorAll('.dropdown-submenu[data-open]')

    submenus.forEach((submenu) => {
      submenu.classList.add('hidden')
      submenu.classList.remove('!block')
      submenu.removeAttribute('data-open')
    })
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
}
