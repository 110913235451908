import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = { on: String, off: String }

  toggle() {
    this.inputTarget.value = this.inputTarget.value === this.onValue ? this.offValue : this.onValue
  }
}
