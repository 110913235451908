/* global Pallette */
/* global Rails */
/* global showLoadingIndicator */
/* global hideLoadingIndicator */
/* eslint-disable camelcase */
/* eslint-disable new-cap */
/* global Tokenizer */

$(document).ready(basysOnLoad)
$(document).on('lazy-load:complete', basysOnLoad)

function basysOnLoad() {
  'use strict'

  if ($('#basys-card-element').length) {
    // NOTE: Clear it out in case we're re-initializing it
    $('#basys-card-element').html('')

    var secondaryColor = Pallette.black
    if ($('#credit-card-token').data('colors-secondary-color').length) {
      secondaryColor = $('#credit-card-token').data('colors-secondary-color')
    }

    var tertiaryColor = Pallette.white
    if ($('#credit-card-token').data('colors-tertiary-color').length) {
      tertiaryColor = $('#credit-card-token').data('colors-tertiary-color')
    }

    var invalidColor = Pallette.red
    if ($('#credit-card-token').data('colors-secondary-color').length) {
      invalidColor = $('#credit-card-token').data('colors-secondary-color')
    }

    var BASYS_PUBLIC_KEY = $('#basys-account-token').data('public-key')

    let FORM = $('#basys-card-element').closest('form')[0]
    var $basysSubmitButton = $('button[data-disable-with]')
    var BASYS_FORM_SUBMIT_DISABLE_WITH_VALUE = $basysSubmitButton.data('disable-with')
    var BASYS_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE = $basysSubmitButton.html()
    var BASYS_CHARGE_FORM_READY_FOR_SUBMISSION = false
    $basysSubmitButton.removeAttr('data-disable-with')

    var tokenizer = new Tokenizer({
      apikey: BASYS_PUBLIC_KEY,
      container: document.querySelector('#basys-card-element'),
      submission(resp) {
        $('#basys-card-element').removeClass('invalid')
        switch (resp.status) {
          case 'success':
            $('#credit-card-token').val(resp.token)
            BASYS_CHARGE_FORM_READY_FOR_SUBMISSION = true
            Rails.fire(FORM, 'submit')
            break
          case 'error':
            if ($('#basys-card-element').closest('.hidden').length) {
              BASYS_CHARGE_FORM_READY_FOR_SUBMISSION = true
              Rails.fire(FORM, 'submit')
            } else {
              $('#basys-card-errors').html(resp.msg)
              $basysSubmitButton.attr('disabled', false)
              $basysSubmitButton.html(BASYS_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
              hideLoadingIndicator()
            }
            break
          case 'validation':
            if ($('#basys-card-element').closest('.hidden').length) {
              BASYS_CHARGE_FORM_READY_FOR_SUBMISSION = true
              Rails.fire(FORM, 'submit')
            } else {
              $('#basys-card-errors').html('Invalid card information')
              $basysSubmitButton.attr('disabled', false)
              $basysSubmitButton.html(BASYS_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
              hideLoadingIndicator()
            }
            break
          // skip default case
        }
      },
      settings: {
        payment: { placeholderCreditCard: 'CARD NUMBER' },
        styles: {
          '.payment input': {
            border: 'none',
            'border-bottom': `solid 2px ${Pallette.secondaryDark}`,
            'border-radius': '0',

            'padding-top': '20px',
            'padding-bottom': '20px',

            'background-color': tertiaryColor,
            color: secondaryColor,
            'line-height': '36px',
            'font-family': '"Hind Vadodara", sans-serif',
            'font-size': '19px',
            'text-transform': 'uppercase',
          },

          '.payment .cc input': {
            'padding-top': '20px',
            'padding-bottom': '20px',
          },

          '.payment input.invalid': { 'border-bottom': `solid 2px ${invalidColor}` },
        },
      },
    })

    $('#basys-card-element')
      .closest('form')
      .on('submit', function () {
        $basysSubmitButton.attr('disabled', true)
        $basysSubmitButton.html(BASYS_FORM_SUBMIT_DISABLE_WITH_VALUE)
        showLoadingIndicator()
        return true
      })

    setTimeout(function () {
      $('#basys-card-element')
        .closest('form')
        .on('submit', function (event) {
          if (BASYS_CHARGE_FORM_READY_FOR_SUBMISSION) return true

          if (event.isDefaultPrevented()) {
            $basysSubmitButton.attr('disabled', false)
            $basysSubmitButton.html(BASYS_FORM_SUBMIT_DISABLE_WITH_ORIGINAL_VALUE)
            hideLoadingIndicator()

            return false
          }

          event.preventDefault()
          tokenizer.submit()
          return false
        })
    }, 500)
  }
}
