import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'module', 'dropdownButton', 'profileNav']
  static classes = ['highlight']
  static values = { selectedModule: String }

  connect() {
    this.changeTab({ params: { module: this.selectedModuleValue } })
  }

  changeTab({ params: { module } }) {
    const tab = this.#getTab(module) || this.#getTab('all')
    const mod = this.#getModule(module)
    this.#highlightTab(tab)
    this.#focusModule(mod)
    this.#updateProfileNav(module)
  }

  #focusModule(module) {
    const url = new URL(window.location)
    const searchParams = new URLSearchParams(window.location.search)
    if (module) {
      this.#hideAllModules()
      module.hidden = false
      searchParams.set('module', module.id)
    } else {
      this.#showAllModules()
      searchParams.set('module', 'all')
    }

    url.search = searchParams
    window.history.replaceState(history.state, '', url)
  }

  #showAllModules() {
    this.moduleTargets.forEach((module) => {
      module.hidden = false
    })
  }

  #hideAllModules() {
    this.moduleTargets.forEach((module) => {
      module.hidden = true
    })
  }

  #highlightTab(tab) {
    if (this.hasDropdownButtonTarget) {
      this.dropdownButtonTarget.textContent = tab.textContent
    }

    this.tabTargets.forEach((target) => target.classList.remove(...this.highlightClasses))
    tab.classList.add(...this.highlightClasses)
  }

  #getTab(id) {
    return this.tabTargets.find((target) => target.id === `${id}-nav`)
  }

  #getModule(id) {
    return this.moduleTargets.find((target) => target.id === id)
  }

  #updateProfileNav(module) {
    this.profileNavTargets.forEach((member) => {
      const url = new URL(member.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.set('module', module)
      url.search = searchParams
      member.href = url
    })
  }
}
