import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['childContainer', 'radioBtnGroupSelector']
  static values = { groupSelector: String, childSelector: String }

  hideChildren() {
    for (const child of this.childContainerTargets) {
      child.classList.add('hidden')
    }

    if (this.childSelectorValue !== '') {
      for (const selector of this.childSelectorValue.split(/\s+/)) {
        for (const child of document.querySelectorAll(selector)) {
          child.classList.add('hidden')
        }
      }
    }
  }

  showChildren() {
    for (const child of this.childContainerTargets) {
      child.classList.remove('hidden')
    }

    if (this.childSelectorValue !== '') {
      for (const selector of this.childSelectorValue.split(/\s+/)) {
        for (const child of document.querySelectorAll(selector)) {
          child.classList.remove('hidden')
        }
      }
    }
  }

  toggleOn() {
    this.radioButtonGroupController().hideAllChildren()

    this.showChildren()
  }

  radioButtonGroupController() {
    let radioBtnGroup = document.querySelector(this.groupSelectorValue)
    return this.application.getControllerForElementAndIdentifier(radioBtnGroup, 'radio-btn-group')
  }
}
