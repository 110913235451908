import { Controller } from '@hotwired/stimulus'
import { initializeDropdownTabs, initializeTooltips } from '../../../vendor/assets/javascripts/voltage'
import { put } from '../helpers/fetch_helper'

// TODO: Most of the stuff here isn't great and should be refactored
export default class extends Controller {
  connect() {
    initializeDropdownTabs($(this.element))
    initializeTooltips($(this.element))

    // This should just be links with turbo tbh
    this.initializeSortingColumns()

    this.element.addEventListener('collapse--component:complete', this.setCollapseExpandPreference.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('collapse--component:complete', this.setCollapseExpandPreference)
  }

  ///
  /// private
  ///
  initializeSortingColumns() {
    for (const header of [...this.element.querySelectorAll('th.sortable-column')]) {
      header.addEventListener(
        'click',
        () => {
          const sortColumn = header.classList[1]
          const sortAscending = !header.classList.contains('selected-up')
          const url = new URL(this.frame.src)

          url.searchParams.set('sort_by', sortColumn)
          url.searchParams.set('sort_asc', sortAscending)

          this.frame.src = url
        },
        { once: true },
      )
    }
  }

  setCollapseExpandPreference(event) {
    const collapsed = event.detail.collapsed
    const sectionName = this.frame.id.replaceAll(/-/g, '_')

    put('/preferences', {
      key: 'dashboard_collapse',
      preferences: {
        [sectionName]: collapsed ? 'collapsed' : 'expanded',
      },
    })
  }

  get frame() {
    return this.element.closest('turbo-frame')
  }
}
