$(document).on('turbo:load', function () {
  'use strict'

  $('#overlay .hide-notification').on('click', function () {
    var $this = $(this)

    $this.closest('#overlay').fadeOut(function () {
      var $reminder = $('.reminder-message-container')
      $reminder.hide()
      $reminder.removeClass('hidden')
      $reminder.fadeIn()
    })

    var path = $this.data('path')
    var token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      type: 'PUT',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
    })
  })

  $(document).mousemove(function () {
    var alertsToHide = $('#global-alerts').find('.alert.alert-fade')
    alertsToHide.removeClass('alert-fade')
    alertsToHide.addClass('alert-fading')

    setTimeout(function () {
      alertsToHide.fadeOut('slow')
    }, 5000)
  })
})
