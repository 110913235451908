import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static values = { name: String }
  static targets = ['input']

  connect() {
    this.intlTelInput = intlTelInput(this.inputTarget, {
      utilsScript: '/assets/intl-tel-input/utils.js',
      formatOnDisplay: true,
      separateDialCode: true,
      hiddenInput: this.nameValue,
      initialCountry: document.body.dataset.countryCode || 'us',
    })
  }

  disconnect() {
    this.inputTarget.value = this.intlTelInput.getNumber()
    this.intlTelInput.destroy()
  }

  setHiddenInput() {
    this.inputTarget.nextElementSibling.value = this.intlTelInput.getNumber()
  }
}
