import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    setTimeout(() => {
      $(this.element).tooltip({ html: true, delay: { show: 250, hide: 100 }, trigger: 'hover' })
    })
  }

  off() {
    $(this.element).tooltip('hide')
    $(this.element).tooltip('disable')
  }

  on() {
    $(this.element).tooltip('enable')
  }

  swapText({ params: { text } }) {
    $(this.element).attr('data-original-title', text).tooltip('fixTitle')
  }
}
