/* global loadLazyPartial */

import Dropzone from 'dropzone'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileInput', 'dropZoneContainer']

  connect() {
    this.dropZone = this.createDropZone()
    Dropzone.autoDiscover = false
    this.hideFileInput()

    this.dropZone.on('addedfile', () => {
      if (this.dropZone.files.length === 1) {
        document.querySelector('.file-details').style.display = 'none'
        document.querySelector('.new-agreement-editor').classList.add('hidden')
        document.querySelector('.override-token-container').classList.add('hidden')
        document.querySelector('.btn-primary.preview-agreement').innerText = this.submitText
      } else if (this.dropZone.files.length > 1) {
        this.dropZone.removeFile(this.dropZone.files[0])
      }
    })

    this.dropZone.on('removedfile', (file) => {
      const agreementModal = document.querySelector('#add-new-agreement')
      if (agreementModal && file.previewElement && this.dropZone.files.length === 0) {
        file.previewElement.classList.remove('dz-file-preview')
        document.querySelector('.file-details').style.display = 'initial'
        document.querySelector('.new-agreement-editor').classList.remove('hidden')
        document.querySelector('.override-token-container').classList.remove('hidden')
        document.querySelector('.btn-primary.preview-agreement').innerText = this.previewText
      }
    })

    this.dropZone.on('success', (file, responseData) => {
      $('#add-new-agreement').modal('hide')
      this.dropZone.removeFile(file)
      let agreementsComp = document.querySelector('#agreements')
      document.querySelector('#global-alerts').innerHTML = responseData.content
      if (agreementsComp) {
        loadLazyPartial(agreementsComp.dataset.path, $(agreementsComp), true, true)
      }
    })

    this.dropZone.on('error', (file, responseData) => {
      this.dropZone.removeFile(file)
      if (responseData.partial) {
        $('#add-new-agreement').modal('hide')
        if (responseData.partial) {
          document.querySelector('#global-alerts').innerHTML = responseData.partial
        }
        return false
      }
    })
  }

  hideFileInput() {
    this.fileInputTarget.disabled = true
    this.fileInputTarget.style.display = 'none'
  }

  processFiles(event) {
    if (this.dropZone.files.length > 0) {
      event.preventDefault()
      event.stopPropagation()
      this.dropZone.processQueue()
    }
  }

  get headers() {
    return { 'X-CSRF-Token': this.getMetaValue('csrf-token') }
  }

  get url() {
    return this.fileInputTarget.getAttribute('data-direct-upload-url')
  }

  get maxFiles() {
    return this.fileInputTarget.getAttribute('data-max-files') || 1
  }

  get maxFileSize() {
    return this.fileInputTarget.getAttribute('data-max-file-size') || 30
  }

  get acceptedFiles() {
    return this.fileInputTarget.getAttribute('data-accepted-files')
  }

  get addRemoveLinks() {
    return this.fileInputTarget.getAttribute('data-add-remove-links') || true
  }

  get submitText() {
    return this.fileInputTarget.getAttribute('data-submit-text')
  }

  get previewText() {
    return this.fileInputTarget.getAttribute('data-preview-text')
  }

  get previewContainer() {
    return this.fileInputTarget.getAttribute('data-preview-container')
  }

  get previewTemplate() {
    return this.fileInputTarget.getAttribute('data-preview-template')
  }

  //
  // private
  //
  createDropZone() {
    return new Dropzone(this.dropZoneContainerTarget, {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      autoProcessQueue: false,
      previewsContainer: this.previewContainer,
      previewTemplate: document.querySelector(this.previewTemplate).innerHTML,
    })
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    if (element) {
      return element.getAttribute('content')
    }
  }
}
