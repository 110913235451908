import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'membershipAmount',
    'membershipDueDate',
    'membershipEndDate',
    'taxRate',
    'subtotal',
    'salesTax',
    'grandTotal',
    'taxExempt',
    'dateGroup',
    'dateRadio',
    'attendanceRadio',
    'attendanceGroup',
    'membershipAttendance',
    'unlimitedRadio',
    'toggleRegistrationFee',
    'registrationFeeContainer',
    'registrationFeeAmount',
    'registrationFeeTaxRate',
    'intervalDuration',
  ]

  static outlets = ['flatpickr']

  static values = [
    { name: 'taxExempt', type: Boolean, default: false },
    { name: 'total', type: Number, default: 0 },
    { name: 'subtotal', type: Number, default: 0 },
    { name: 'salesTax', type: Number, default: 0 },
  ]

  toggleRegistrationFee({ params, target }) {
    const parsedTemplateId = params.templateId.toString()
    const registrationFeeInput = this.registrationFeeInput(parsedTemplateId)
    const registrationFeeContainer = this.registrationFeeContainer(parsedTemplateId)
    registrationFeeInput.disabled = !target.checked
    registrationFeeContainer.classList.toggle('hidden')
    this.updateTotals()
  }

  toggleTax() {
    this.taxExemptValue = !this.taxExemptValue
    this.taxExemptTargets.forEach((taxExemptTarget) => {
      taxExemptTarget.value = this.taxExemptValue
    })
    this.updateTotals()
  }

  startDateChanged({ target }) {
    this.updateTotals()
    const endDateOutlet = this.endDateOutlet(target.dataset.templateId)
    if (!endDateOutlet) return

    const selectedDate = new Date(target.value)
    if (endDateOutlet.element.disabled) {
      this.setEndDate(selectedDate, endDateOutlet)
    } else {
      let newDate = new Date(selectedDate.getTime())
      newDate.setDate(newDate.getDate() + 1)
      endDateOutlet.fp.set('minDate', newDate.toISOString().slice(0, 10))
    }
  }

  setEndDate(selectedDate, endDateOutlet) {
    const intervalDurationElement = this.intervalDuration(endDateOutlet.element.dataset.templateId)
    if (!intervalDurationElement) return
    const intervalDuration = intervalDurationElement.value
    const endDate = this.addIntervalToDate(selectedDate, intervalDuration)
    endDateOutlet.fp.setDate(endDate.toISOString().slice(0, 10))
  }

  updateTotals() {
    this.resetTotals()
    this.membershipDueDateTargets.forEach((membershipDueDate) => {
      if (this.isTodayValue(membershipDueDate.value)) {
        const templateId = membershipDueDate.dataset.templateId
        this.calculateTotals(templateId)
      }
    })
    this.updateDisplay()
  }

  durationKindChange({ params: { templateId } }) {
    const parsedTemplateId = templateId.toString()
    if (this.dateRadio(parsedTemplateId).checked) {
      this.handleDateRadioChange(parsedTemplateId)
    } else if (this.attendanceRadio(parsedTemplateId).checked) {
      this.handleAttendanceRadioChange(parsedTemplateId)
    } else if (this.unlimitedRadio(parsedTemplateId).checked) {
      this.handleUnlimitedRadioChange(parsedTemplateId)
    }
  }

  ///
  /// private
  ///

  calculateTotals(templateId) {
    let value = this.amountForMembershipTemplate(templateId)
    const taxRate = this.taxRateForMembershipTemplate(templateId)
    const registrationFee = this.registrationFeeForMembershipTemplate(templateId)
    const registrationFeeTaxRate = this.taxRateForRegistrationFee(templateId)
    let taxAmount = value * taxRate
    if (registrationFee) {
      const registrationFeeTaxAmount = registrationFee * registrationFeeTaxRate
      value += registrationFee
      taxAmount += registrationFeeTaxAmount
    }
    this.subtotalValue += value
    this.salesTaxValue += Math.round(taxAmount)
    const total = value + taxAmount
    this.totalValue += total
  }

  handleUnlimitedRadioChange(templateId) {
    this.attendanceGroup(templateId).classList.add('hidden')
    this.dateGroup(templateId).classList.add('hidden')
    this.membershipEndDate(templateId).disabled = true
    this.membershipEndDate(templateId).nextElementSibling.disabled = true
    this.membershipAttendance(templateId).disabled = true
  }

  handleDateRadioChange(templateId) {
    this.attendanceGroup(templateId).classList.add('hidden')
    this.dateGroup(templateId).classList.remove('hidden')
    this.membershipEndDate(templateId).disabled = false
    this.membershipEndDate(templateId).nextElementSibling.disabled = false
    this.membershipAttendance(templateId).disabled = true
  }

  handleAttendanceRadioChange(templateId) {
    this.attendanceGroup(templateId).classList.remove('hidden')
    this.dateGroup(templateId).classList.add('hidden')
    this.membershipEndDate(templateId).disabled = true
    this.membershipEndDate(templateId).nextElementSibling.disabled = true
    this.membershipAttendance(templateId).disabled = false
  }

  // Early return in case the billing is turned off or we're on full page signup
  // and haven't gotten there yet
  updateDisplay() {
    if (!this.hasSubtotalTarget) return
    if (!this.hasSalesTaxTarget) return
    if (!this.hasGrandTotalTarget) return
    this.subtotalTarget.value = this.subtotalValue
    this.salesTaxTarget.value = this.salesTaxValue
    this.grandTotalTarget.value = this.totalValue
  }

  resetTotals() {
    this.totalValue = 0
    this.subtotalValue = 0
    this.salesTaxValue = 0
  }

  taxRateForMembershipTemplate(templateId) {
    const taxRateElement = this.taxRate(templateId)
    if (!taxRateElement) return 0.0
    return this.taxExemptValue ? 0.0 : parseFloat(taxRateElement.value)
  }

  taxRateForRegistrationFee(templateId) {
    const taxRateElement = this.registrationFeeTaxRate(templateId)
    if (!taxRateElement) return 0.0
    return this.taxExemptValue ? 0.0 : parseFloat(taxRateElement.value)
  }

  amountForMembershipTemplate(templateId) {
    const amountElement = this.membershipAmount(templateId)
    if (!amountElement) return 0.0
    return parseFloat(amountElement.value)
  }

  registrationFeeForMembershipTemplate(templateId) {
    const registrationFeeInput = this.registrationFeeInput(templateId)
    if (registrationFeeInput && !registrationFeeInput.disabled) {
      const amount = this.registrationFeeInput(templateId).value
      return parseFloat(amount)
    }
  }

  isTodayValue(value) {
    if (!value) return false

    const beginningOfDay = new Date()
    const valueDate = new Date(value)

    beginningOfDay.setHours(0, 0, 0, 0)
    return valueDate.toISOString().slice(0, 10) === beginningOfDay.toISOString().slice(0, 10)
  }

  endDateOutlet(templateId) {
    return this.flatpickrOutlets.find(function (outlet) {
      return (
        outlet.element.dataset.templateId === templateId &&
        outlet.element.dataset.calculateMembershipTotalsTarget === 'membershipEndDate'
      )
    })
  }

  addIntervalToDate(date, interval) {
    const [value, unit] = interval.split(' ')
    let newDate = new Date(date.getTime())
    let momentDate = window.moment(newDate)
    momentDate.add(value, unit)
    return momentDate.toDate()
  }

  attendanceGroup(templateId) {
    return this.attendanceGroupTargets.find((el) => el.dataset.templateId === templateId)
  }

  dateGroup(templateId) {
    return this.dateGroupTargets.find((el) => el.dataset.templateId === templateId)
  }

  attendanceRadio(templateId) {
    return this.attendanceRadioTargets.find((el) => el.dataset.templateId === templateId)
  }

  dateRadio(templateId) {
    return this.dateRadioTargets.find((el) => el.dataset.templateId === templateId)
  }

  unlimitedRadio(templateId) {
    return this.unlimitedRadioTargets.find((el) => el.dataset.templateId === templateId)
  }

  registrationFeeInput(templateId) {
    return this.registrationFeeAmountTargets.find((el) => el.dataset.templateId === templateId)
  }

  registrationFeeContainer(templateId) {
    return this.registrationFeeContainerTargets.find((el) => el.dataset.templateId === templateId)
  }

  membershipDueDate(templateId) {
    return this.membershipDueDateTargets.find((el) => el.dataset.templateId === templateId)
  }

  membershipEndDate(templateId) {
    return this.membershipEndDateTargets.find((el) => el.dataset.templateId === templateId)
  }

  membershipAmount(templateId) {
    return this.membershipAmountTargets.find((el) => el.dataset.templateId === templateId)
  }

  membershipAttendance(templateId) {
    return this.membershipAttendanceTargets.find((el) => el.dataset.templateId === templateId)
  }

  taxRate(templateId) {
    return this.taxRateTargets.find((el) => el.dataset.templateId === templateId)
  }

  registrationFeeTaxRate(templateId) {
    return this.registrationFeeTaxRateTargets.find((el) => el.dataset.templateId === templateId)
  }

  intervalDuration(templateId) {
    return this.intervalDurationTargets.find((el) => el.dataset.templateId === templateId)
  }
}
