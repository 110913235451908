import ApplicationController from './application_controller'

export default class extends ApplicationController {
  SCROLL_OFFSET = 200

  connect() {
    this.scrollToError()
  }

  scrollToError() {
    if (!this.input) return

    window.scrollTo({ top: this.scrollTop, behavior: 'smooth' })

    this.input.focus()
  }

  ///
  /// private
  ///

  get scrollTop() {
    return this.input.getBoundingClientRect().top + window.scrollY - this.SCROLL_OFFSET
  }

  get input() {
    let input = this.element.querySelector('.input-danger')
    input ||= this.element.querySelector('.\\!input-danger')

    return input
  }
}
