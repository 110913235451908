/* eslint-env jquery */

/* global App */
/* eslint-disable camelcase */
/* global initializeTooltips */
/* global initializeDropdownTabs */
/* global initializeSelectAllCheckboxes */
/* global initializeStudentStatusDropdowns */
/* global initializeStudentAttendanceLog */

$(document).on('turbo:load', function () {
  'use strict'

  var schoolId = $('body.school').data('school-id')
  var userId = $('body.school').data('user-id')
  var isStudentsView = $('body').hasClass('students')
  const shouldSet = schoolId && userId && isStudentsView && !App.students_bulk_edit_notifications

  if (shouldSet) {
    App.students_bulk_edit_notifications = App.cable.subscriptions.create(
      {
        channel: 'StudentsBulkEditNotificationsChannel',
        schoolId,
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel

          if (validId(data.user_id)) {
            if (data.student_id && data.updated_partial) {
              var $studentContainer = $(`.student-wrapper.student-${data.student_id}-container`)
              if ($studentContainer.length) {
                $studentContainer.html(data.updated_partial)

                initializeTooltips($studentContainer)
                initializeDropdownTabs($studentContainer)
                initializeStudentStatusDropdowns($studentContainer)
                initializeSelectAllCheckboxes(
                  $studentContainer.find("input.select-all-item[type='checkbox']"),
                  false,
                )
                initializeStudentAttendanceLog($studentContainer)
              }
            }
            if (!data.error) {
              var bulkCheckbox = $('input#select-all')
              bulkCheckbox.prop('checked', false)
              bulkCheckbox.parent().find('.spinner.spinner-grow').remove()
              bulkCheckbox.show()
            }
            if (data.message) {
              if (data.notification_class !== undefined) $(`.alert.${data.notification_class}`).hide()

              $('#global-alerts').append(data.message)
            }
          }
        },
      },
    )
  }
  function validId(id) {
    return id === undefined || (id > 0 && id === userId) || (id < 0 && Math.abs(id) !== userId)
  }
})
/* eslint-enable camelcase */
