import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['token', 'tokenizer']
  static values = { provider: String, formId: String }

  connect() {
    this.billingProviderController.mountCard()
  }

  get parentForm() {
    return this.element.closest('form')
  }

  get billingProviderController() {
    let controllerName = this.providerValue
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
    return this.application.getControllerForElementAndIdentifier(this.parentForm, controllerName)
  }
}
