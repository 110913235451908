import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit', 'endDateInput', 'attendanceInput']

  connect() {
    this.validate()
  }

  validate() {
    const endDate = this.endDateInputTarget.value
    const attendance = this.attendanceInputTarget.value
    this.submitTarget.disabled = endDate === '' && (attendance === '' || attendance === '0')
  }
}
