import { get } from '../helpers/fetch_helper'
import ApplicationController from './application_controller'

const PREVIEW_WIDTH_IN_PX = 816 // this is 8.5 inches at 96dpi
const THIRTY_MB = 1024 * 1024 * 30
export default class extends ApplicationController {
  static outlets = ['wysiwyg', 'collapse--component']
  static targets = [
    'preview',
    'warning',
    'signatureTypeCheckbox',
    'fileDropzone',
    'fileField',
    'fileStats',
    'fileName',
    'fileSize',
    'fileError',
    'pdfPreview',
    'templateSelect',
  ]

  static values = {
    tokens: { type: Object, default: {} },
    overrideTokens: { type: Object, default: {} },
    checkboxHtml: String,
    signatureHtml: String,
    currentHtml: String,
  }

  connect() {
    // Weird hack to get around some odd froala behavior
    if (this.element.querySelector('.fr-box')) {
      this.setup()
    }

    if (!this.hasPdfPreviewTarget) return

    this.pdfPreviewTarget.onload = async () => {
      if (!this.pdfPreviewTarget.getAttribute('src')) return

      this.previewTarget.classList.add('animate-fade-out-slow')

      await this.waitForAnimations(this.previewTarget, () => {
        this.previewTarget.innerHTML = ''
        this.wysiwygOutlet.editor.html.set(null)

        this.previewTarget.classList.remove('animate-fade-out-slow')
        this.previewTarget.classList.add('hidden')
      })
    }
  }

  setup() {
    this.wysiwygOutlet.editor.events.on('input', () => this.loadContent())
    this.wysiwygOutlet.editor.events.on('contentChanged', () => this.loadContent())

    if (this.shouldShowWarning) {
      this.warningTarget.classList.remove('hidden')
    }

    this.loadContent()
  }

  updateContent({ target }) {
    const selectedOption = target.selectedOptions[0]

    const signatureType = selectedOption.getAttribute('data-signature-type')

    this.selectSignatureType(signatureType)

    let content = ''

    if (selectedOption.hasAttribute('data-content')) {
      content = selectedOption.getAttribute('data-content')
    }

    this.wysiwygOutlet.editor.html.set(content)
    this.wysiwygOutlet.editor.undo.saveStep

    this.loadContent()
  }

  overrideToken({ target, params: { token } }) {
    const value = target.value

    if (value.length > 0) {
      this.overrideTokensValue[token] = value
    } else {
      delete this.overrideTokensValue[token]
    }

    this.loadContent()
  }

  setCheckboxSignature() {
    this.currentHtmlValue = this.checkboxHtmlValue

    if (!this.content.includes('((signature_area))')) return

    this.loadContent()
  }

  setSignature() {
    this.currentHtmlValue = this.signatureHtmlValue

    if (!this.content.includes('((signature_area))')) return

    this.loadContent()
  }

  setNoSignature() {
    this.currentHtmlValue = ''

    if (!this.content.includes('((signature_area))')) return

    this.loadContent()
  }

  selectPerson({ target }) {
    const id = target.value
    const type = document.querySelector('input[name="agreement[agreeable_type]"]').value
    let src = '/agreements/tokens'
    if (!id || !type) {
      this.tokensValue = {}
    }

    src = `${src}?agreeable_id=${id}&agreeable_type=${type}`

    get(src)
      .then((response) => response.json())
      .then((json) => {
        this.tokensValue = json
      })
  }

  openFileField() {
    this.fileFieldTarget.click()
  }

  uploadPdf({ dataTransfer }) {
    this.fileFieldTarget.files = dataTransfer.files

    this.previewPdf()
  }

  previewPdf() {
    const file = this.fileFieldTarget.files[0]

    this.fileDropzoneTarget.classList.add('max-w-lg')
    this.fileDropzoneTarget.classList.remove('max-w-7xl')

    this.showFileStats(file)

    if (file.size > THIRTY_MB || file.type !== 'application/pdf') {
      this.pdfPreviewTarget.src = ''
      this.fileErrorTarget.classList.remove('hidden')
      return
    }

    this.fileErrorTarget.classList.add('hidden')

    const fileReader = new FileReader()
    fileReader.onload = async (event) => {
      this.pdfPreviewTarget.setAttribute('src', `${event.target.result}#toolbar=0&navpanes=0&scrollbar=0`)

      await this.revealPdfPreview()
    }
    fileReader.readAsDataURL(file)
  }

  async removePdf() {
    this.fileFieldTarget.value = null

    this.fileStatsTarget.classList.add('hidden')
    this.fileDropzoneTarget.classList.remove('max-w-lg')
    this.fileDropzoneTarget.classList.add('max-w-7xl')

    this.pdfPreviewTarget.classList.add('hidden')
    this.pdfPreviewTarget.setAttribute('src', '')

    this.previewTarget.classList.add('animate-fade-in-slow')
    this.previewTarget.classList.remove('hidden')

    this.selectSignatureType('2')
    this.templateSelectTarget.tomselect.enable()

    this.signatureTypeCheckboxTargets.forEach((checkbox) => {
      checkbox.disabled = false
    })

    this.collapseComponentOutlet.element.classList.remove('opacity-50', 'pointer-events-none')

    this.fileErrorTarget.classList.add('hidden')

    this.wysiwygOutlet.editor.edit.on()

    this.fileNameTarget.innerText = 'N/A'
    this.fileSizeTarget.innerText = 'N/A'

    await this.waitForAnimations(this.previewTarget, () => {
      this.previewTarget.classList.remove('animate-fade-in-slow')
    })
  }

  ///
  /// private
  ///
  loadContent() {
    if (this.previewHidden) return

    this.previewTarget.innerHTML = this.tokenizedContent
  }

  selectSignatureType(signatureType) {
    if (signatureType === '1') {
      this.currentHtmlValue = this.checkboxHtmlValue
    } else if (signatureType === '2') {
      this.currentHtmlValue = this.signatureHtmlValue
    } else {
      signatureType = 'on'
      this.currentHtmlValue = ''
    }

    this.signatureTypeCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false

      if (checkbox.value === signatureType) {
        checkbox.checked = true
      }
    })

    if (this.signatureTypeCheckboxTargets.every((checkbox) => !checkbox.checked)) {
      this.currentHtmlValue = this.signatureHtmlValue

      this.signatureTypeCheckboxTargets.find((checkbox) => checkbox.value === '2').checked = true
    }
  }

  fileSize(file) {
    const fileSizeInKB = file.size / 1024

    if (fileSizeInKB < 1024) {
      return `${fileSizeInKB.toFixed(2)} KB`
    }

    return `${(fileSizeInKB / 1024).toFixed(2)} MB`
  }

  showFileStats(file) {
    this.fileDropzoneTarget.addEventListener(
      'transitionend',
      async () => {
        this.fileStatsTarget.classList.add('animate-fade-in-slow')
        this.fileStatsTarget.classList.remove('hidden')

        await this.waitForAnimations(this.fileStatsTarget, () => {
          this.fileStatsTarget.classList.remove('animate-fade-in-slow')
        })
      },
      { once: true },
    )

    this.fileNameTarget.innerText = file.name
    this.fileSizeTarget.innerText = this.fileSize(file)
  }

  async revealPdfPreview() {
    this.pdfPreviewTarget.classList.add('animate-fade-in-slow')
    this.pdfPreviewTarget.classList.remove('hidden')

    this.selectSignatureType(null)
    this.templateSelectTarget.tomselect.disable()

    this.signatureTypeCheckboxTargets.forEach((checkbox) => {
      checkbox.disabled = true
    })

    this.collapseComponentOutlet.close()
    this.collapseComponentOutlet.element.classList.add('opacity-50', 'pointer-events-none')

    this.wysiwygOutlet.editor.edit.off()

    await this.waitForAnimations(this.pdfPreviewTarget, () => {
      this.pdfPreviewTarget.classList.remove('animate-fade-in-slow')
    })
  }

  get tokenizedContent() {
    let content = this.wysiwygOutlet.editor.html.get()

    if (this.currentHtmlValue.length > 0) {
      content = content.replace(/\(\(signature_area\)\)/g, this.currentHtmlValue)
    }

    if (!this.hasTokens) return content

    const keys = Object.keys(this.tokensValue).concat(Object.keys(this.overrideTokensValue))

    keys.forEach((token) => {
      const tokenRegex = new RegExp(`\\(\\(${token}\\)\\)`, 'g')
      const tokenValue = this.overrideTokensValue[token] || this.tokensValue[token]

      if (!tokenValue) {
        content = content.replace(tokenRegex, '')
        return
      }

      content = content.replace(tokenRegex, tokenValue)
    })

    return content
  }

  get content() {
    return this.wysiwygOutlet.editor.html.get()
  }

  get hasTokens() {
    return Object.keys(this.tokensValue).length > 0
  }

  get shouldShowWarning() {
    return this.wysiwygOutlet.wysiwygElement.clientWidth !== PREVIEW_WIDTH_IN_PX && this.previewHidden
  }

  get previewHidden() {
    const style = window.getComputedStyle(this.previewTarget)

    if (!this.hasPdfPreviewTarget) return style.display === 'none'

    const pdfStyle = window.getComputedStyle(this.pdfPreviewTarget)

    return style.display === 'none' && pdfStyle.display === 'none'
  }
}
