import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['spinner']

  connect() {
    if (this.shouldImmediatelyRemove) {
      this.element.remove()
      return
    }

    document.body.classList.add('overflow-hidden')

    this.observer = new MutationObserver(this.mutate.bind(this))
    this.observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
      attributeOldValue: true,
    })
  }

  mutate() {
    if (document.documentElement.classList.contains('fontawesome-i2svg-active')) {
      this.spinnerTarget.addEventListener(
        'animationiteration',
        () => {
          this.spinnerTarget.classList.add('!animate-none')
          this.element.classList.add('loading-fade')
          this.element.addEventListener('animationend', () => {
            document.body.classList.remove('overflow-hidden')
            this.element.classList.add('opacity-0')
            this.element.remove()
          })
        },
        { once: true },
      )

      this.observer.disconnect()
    }
  }

  ///
  /// private
  ///

  get shouldImmediatelyRemove() {
    return (
      !document.body.hasAttribute('data-allow-loader') ||
      document.body.classList.contains('classic-wrapper') ||
      document.body.classList.contains('classic-wrapper-beta') ||
      document.documentElement.classList.contains('fontawesome-i2svg-active') ||
      window.matchMedia('(max-width: 767px)').matches ||
      window.matchMedia('(display-mode: standalone)').matches
    )
  }
}
