import { Turbo } from '@hotwired/turbo-rails'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['filterCheckbox', 'multiFilterCheckbox', 'expandButton', 'expandArea']

  connect() {
    const scrollableArea = this.expandAreaTarget.querySelector('.label-group')

    if (scrollableArea.scrollHeight > scrollableArea.clientHeight) {
      this.expandButtonTarget.classList.remove('hidden')
    }
  }

  filter() {
    const url = new URL(window.location)
    const filters = this.selectedFilters

    if (filters.length) {
      url.searchParams.set('filters', filters)
    } else {
      url.searchParams.delete('filters')
    }

    Turbo.cache.clear()
    Turbo.visit(url)
  }

  clear() {
    const url = `${window.location.pathname}`

    Turbo.cache.clear()
    Turbo.visit(url)
  }

  expandCollapseFilters(event) {
    const target = event.target.closest('.filters-area')

    if (target.classList.contains('expanded')) {
      target.classList.remove('expanded')
    } else {
      target.classList.add('expanded')
    }
  }

  selectOneCheckbox(event) {
    event.stopPropagation()
  }

  selectMultipleCheckboxes(event) {
    event.stopPropagation()
    const target = event.target.closest('.type-group')
    const newState = !target.querySelector('input').checked
    const subItems = target.querySelectorAll('.menu-sub-item')

    for (const subItem of subItems) {
      let checkbox = subItem.querySelector('input')
      let icon = subItem.querySelector('svg')

      checkbox.checked = newState
      if (newState) {
        icon.classList.remove('checkbox-disabled')
      } else {
        icon.classList.add('checkbox-disabled')
      }
    }
  }

  get selectedFilters() {
    const filters = this.filterCheckboxTargets.filter((t) => t.checked).map((t) => t.value)

    return filters
  }
}
