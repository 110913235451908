/* eslint-env jquery */

/* global initializeDropdownTabs */
/* global initializeTooltips */
/* global initializeAdditionalInfoModalContent */
/* global findCheckedCheckBoxes */
/* global initializeSelectAllCheckboxes */
/* global Turbo */

/* eslint no-use-before-define: 0 */

function transitionPersonIntoDisabledState() {
  'use strict'

  var checkedCheckBoxes = findCheckedCheckBoxes()

  var bulkCheckbox = $('input#select-all')
  if (bulkCheckbox.is(':checked')) {
    bulkCheckbox.hide()
    bulkCheckbox.prop('checked', false)
    bulkCheckbox.parent().prepend('<div class="spinner spinner-grow" />')
  }
  checkedCheckBoxes.parent().html('<div class="spinner spinner-grow" />')
}

function findCheckedPersonIds(checkedCheckBoxes) {
  'use strict'

  var personIds = []

  checkedCheckBoxes.each(function () {
    let personId = this.dataset.personId
    personIds.push(personId)
  })

  return personIds
}

function handleAdditionalInfoNeededUpdateProspectResponse(container, path, button, data) {
  'use strict'

  $('#update-prospect-modal').html(data.responseText)
  initializeAdditionalInfoModalContent()
  $('#update-prospect-modal').modal('show')
  $('#update-prospect-modal').on('hidden.bs.modal', function () {
    $('#update-prospect-modal').unbind()
  })
  $('#update-prospect-modal')
    .find('form')
    .on('ajax:complete', function (e) {
      if (e.originalEvent.detail[0].status === 200) {
        updateProspectLeadState(container, path, button)
      } else {
        $('#global-alerts').append(e.originalEvent.detail[0].responseText)
      }

      $('#update-prospect-modal').modal('hide')
    })
}

function handleUpdateProspectLeadStateResponse(container, path, button, data) {
  'use strict'

  if (data.status === 200) {
    if ($(container).length > 0) {
      container.fadeOut('fast', function () {
        $('#update-prospect-modal').unbind()
        $('#update-prospect-modal').modal('hide')

        container.html(data.responseText)
        initializeTooltips(container)
        initializeDropdownTabs($(this))
        initializeProspectLeadStateDropdowns(container)
        initializeSelectAllCheckboxes(container.find("input.select-all-item[type='checkbox']"), false)
        initializeAssignedToFields(container)
        initializeProspectNoting($(this))
        container.fadeIn('fast')
      })
    } else {
      $('#update-prospect-modal').unbind()
      $('#update-prospect-modal').modal('hide')
      Turbo.visit(window.location.toString(), { action: 'replace' })
    }
  } else if (data.status === 400) {
    handleAdditionalInfoNeededUpdateProspectResponse(container, path, button, data)
  } else if (data.status === 401) {
    $('#global-alerts').append(data.responseText)
    return false
  } else if (data.status === 404) {
    $('#global-alerts').append(data.responseText)
    return false
  } else {
    $('#global-alerts').append(data.responseText)
    return false
  }

  return true
}

function updateProspectLeadState(container, path, button) {
  'use strict'

  button.attr('disabled', true)

  var token = $('meta[name="csrf-token"]').attr('content')
  $.ajax({
    type: 'PUT',
    url: path,
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    },
    complete(data) {
      return handleUpdateProspectLeadStateResponse(container, path, button, data)
    },
  })
}

export function initializeProspectLeadStateDropdowns(parent) {
  'use strict'

  $(parent)
    .find('.status-modifier .dropdown-tabs-menu li a')
    .on('voltage:dropdown-tabs:selected', function () {
      const $this = $(this)
      const $target = $this.closest('.prospect-wrapper')
      const path = $this.data('path')
      if (!path) return

      const $parentButton = $this.closest('.dropdown-tabs').find('button')

      return updateProspectLeadState($target, path, $parentButton)
    })
}

export function initializeAssignedToFields(parent) {
  'use strict'

  $(parent)
    .find('.employee-image.employee-blank, .employee-image.employee-edit')
    .on('click', function () {
      var $this = $(this)
      var path = $this.data('path')
      $('#update-prospect-modal').html('<div class="spinner spinner-grow center" />')
      $('#update-prospect-modal').modal('show')
      $('#update-prospect-modal').on('hidden.bs.modal', function () {
        $('#update-prospect-modal').unbind()
      })
      $.ajax({
        type: 'GET',
        url: path,
        complete(data) {
          $('#update-prospect-modal').html(data.responseText)
          initializeAdditionalInfoModalContent()
          $('#update-prospect-modal')
            .find('form')
            .on('ajax:complete', function (e) {
              if (e.originalEvent.detail[0].status !== 200) {
                $('#global-alerts').append(e.originalEvent.detail[0].responseText)
              }

              $('#update-prospect-modal').modal('hide')
            })
        },
      })

      return false
    })
}

export function initializeProspectNoting(parent) {
  'use strict'

  $(parent)
    .find('.prospect-note')
    .on('click', function () {
      var $this = $(this)
      var path = $this.data('path')
      $('#update-prospect-modal').html('<div class="spinner spinner-grow center" />')
      $('#update-prospect-modal').modal('show')
      $('#update-prospect-modal').on('hidden.bs.modal', function () {
        $('#update-prospect-modal').unbind()
      })
      $.ajax({
        type: 'GET',
        url: path,
        complete(data) {
          $('#update-prospect-modal').html(data.responseText)
          initializeAdditionalInfoModalContent()
          $('#update-prospect-modal')
            .find('form')
            .on('ajax:complete', function (e) {
              if (e.originalEvent.detail[0].status !== 200) {
                $('#global-alerts').append(e.originalEvent.detail[0].responseText)
              }

              $('#update-prospect-modal').modal('hide')
            })
        },
      })

      return false
    })

  handleTruncatedProspectNotes()

  $(window).resize(function () {
    handleTruncatedProspectNotes()
  })
}

// Makes the dynamic "Read More" logic on a Prospect Note work
function handleTruncatedProspectNotes() {
  'use strict'

  var ps = document.querySelectorAll('.prospect-note p')
  ps.forEach(function (p) {
    // Note :: Without the -1, this logic will not work for FireFox v.68
    // For whatever reason, the FF browser is always off-by-one.
    p.classList[p.scrollHeight - 1 > p.offsetHeight ? 'add' : 'remove']('truncated')
  })
}

function initializeProspectDelete(element) {
  'use strict'

  $(element).on('click', function (e) {
    e.preventDefault()
    var path = element.data('path')
    var token = $('meta[name="csrf-token"]').attr('content')

    $.ajax({
      type: 'DELETE',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      },
      complete(data) {
        if (data.status === 400) {
          $('#update-prospect-modal').html('<div class="spinner spinner-grow center" />')
          $('#update-prospect-modal').modal('show')
          $('#update-prospect-modal').on('hidden.bs.modal', function () {
            $('#update-prospect-modal').unbind()
          })
          $('#update-prospect-modal').html(data.responseText)
          return false
        }
        $('#global-alerts').append(data.responseText)
        return false
      },
    })
    return true
  })
}

function initializeBulkEdit(element) {
  $(element).on('click', function () {
    var bulkAction = $(this).data('bulk-action')
    var path = $(this).data('path')
    var checkedCheckBoxes = findCheckedCheckBoxes()
    var selectedPersonIds = findCheckedPersonIds(checkedCheckBoxes)

    transitionPersonIntoDisabledState()
    $.ajax({
      type: 'POST',
      url: path,
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'))
      },
      data: {
        personIds: selectedPersonIds,
        bulkAction,
      },
      complete(data) {
        if (data.status !== 200) $('#global-alerts').append(data.responseText)

        return false
      },
    })
  })
}

$(document).on('turbo:load', function () {
  'use strict'

  let checkBoxes = document.querySelectorAll("input.select-all-item[type='checkbox']")
  initializeSelectAllCheckboxes($(checkBoxes), true)
  let bulkActions = document.querySelectorAll('.execute-bulk-action')
  initializeBulkEdit(bulkActions)

  $('#prospects').on('turbo:frame-render', function () {
    checkBoxes = document.querySelectorAll("input.select-all-item[type='checkbox']")
    initializeSelectAllCheckboxes($(checkBoxes), true)
    bulkActions = document.querySelectorAll('.execute-bulk-action')
    initializeBulkEdit(bulkActions)
  })

  initializeProspectLeadStateDropdowns($('#prospect-profile.panel.left-sidebar'))
  initializeProspectDelete($('#prospect-delete'))
  initializeProspectDelete($('#prospect-delete-also'))
})
